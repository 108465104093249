import React from 'react';
import styled from 'styled-components'
import MuiModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

interface IProps {
  isOpen: boolean
  children: JSX.Element;
  handleClose(): void
}

export const Modal = ({ isOpen, handleClose, children }: IProps) => {
  return (
      <MuiModal
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isOpen}>
          <Container>
            {children}
          </Container>
        </Fade>
      </MuiModal>
  );
}

const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`