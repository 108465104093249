import * as React from 'react';
import { CartIcon } from '../Icons';
import theme from '../../assets/css/theme';
import styled from 'styled-components';

interface IProps {
  value: number | string,
}

const RevenueValue = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 1.125rem;
text-align: left;
color: ${theme.colorDarkGray};
`;

const Label = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: normal;
font-size: 0.8125rem;
text-align: left;
color: ${theme.colorDarkGray};
`;

const RevenueWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
`;

const RevenueValueWrapper = styled.div`
padding-left: ${(props: ISpaceSizeProp) => props.spaceSize || '1.5rem'};
`;

interface ISpaceSizeProp {
  spaceSize?: string;
}

interface IProps extends ISpaceSizeProp {
  value: number | string;
}

export class CampaignRevenueSize extends React.Component<IProps> {
  public render() {
    return (
      <RevenueWrapper>
        <CartIcon />
        <RevenueValueWrapper spaceSize={this.props.spaceSize}>
          <RevenueValue>${this.props.value.toLocaleString('en')}</RevenueValue>
          <Label> Revenue</Label>
        </RevenueValueWrapper>
      </RevenueWrapper>
    );
  }
}

export default CampaignRevenueSize;
