import React from 'react';
import styled from 'styled-components'
import { variant } from 'styled-system'
import MUIAutocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

interface IProps extends Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> {
  label?: string
  placeholder?: string
}

export const Autocomplete = ({label, placeholder, ...props}: IProps) => {
  const renderInput = (params: any) => (
    <TextField
      {...params}
      variant="outlined"
      label={label}
      placeholder={placeholder}
    />
  )

  return (
    <StyledAutocomplete
      {...props}
      filterSelectedOptions={true}
      renderInput={renderInput}
    />
  )
}

const StyledAutocomplete = styled(MUIAutocomplete)`
  background-color: white;
  width: 100%;

  .MuiFormLabel-root {
    ${() => variant({
      prop: 'size',
      variants: {
        small: {
          fontSize: '0.8125rem'
        },
      }
    })}
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    ${() => variant({
      prop: 'size',
      variants: {
        small: {
          transform: 'translate(20px, -4px) scale(0.85)'
        },
      }
    })}
  }
`