import React from 'react';
import styled from 'styled-components';
import theme from '../assets/css/theme';
import Grid from '@material-ui/core/Grid';
import WarningIcon from '@material-ui/icons/Warning';

const CardContainer = styled(Grid)`
  && {
    box-shadow: ${theme.boxShadow};
    position: relative;
  }
`;

const NotificationContainer = styled(CardContainer)`
  && {
    display: flex;
    justify-content: flex-start;
    background-color: ${theme.colorInfoBackground};
    margin-bottom: 24px;
    padding: 16px;
  }
`;

const StyledInfoIcon = styled(WarningIcon)`
  && {
    align-self: top;
    color: ${theme.colorInfo};
    margin-right: 8px;
  }
`;

interface IProps {
    children: React.ReactNode
}
export const InfoPanel = (props: IProps) => {
    return (
        <NotificationContainer>
            <StyledInfoIcon />
                {props.children}
        </NotificationContainer>
    )
}

