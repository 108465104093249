import  React from 'react';
import { Popper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import theme from '../assets/css/theme';
import { ClickAwayListener } from '@material-ui/core';

const PopperWrapper = styled.div`
  background-color: ${theme.colorWhite};
	border: solid 1px ${theme.colorWhite}; 
	box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5); 
  border-radius: 0.313rem;
  padding: 0.875rem 1rem;
  margin: 0.625rem;
  &:: after{
    border: solid 1px ${theme.colorWhite}; 
    box-shadow: solid 5px 5px 20px rgba(0, 0, 0, 0.5); 
    position: absolute;
    height: 15px;
    width: 15px;
    border-right: solid 2px ${theme.colorWhite};
    border-left: solid 0px ${theme.colorWhite};
    border-top: solid 0px ${theme.colorWhite};
    border-bottom: 2px solid ${theme.colorWhite};
    transform: rotate(45deg);
    top: 69%;
    left: 47%;
    content: "";
    background: ${theme.colorWhite};
  }
`;

const CloseButtonWrapper  = styled.div`
  position: absolute;
  top: 0.625rem;
  right: 0.875rem;
    &:hover{
      color: ${theme.colorGray};
    }
  .MuiSvgIcon-fontSizeSmall{
   font-size: 0.725rem;
  }
`;

interface IProps{
    description: string | undefined;
    open: boolean;
    anchorEl: any;
    closePopupTip : any; 
}

export default function PopupTip(props: IProps) {
  
  if (!props.open) {
    return null;
  }
  
  return (
    <Popper 
    id='simple-popper' 
    open={props.open}  
    anchorEl={props.anchorEl} 
    placement="top"
    >
    <ClickAwayListener onClickAway={props.closePopupTip}>
      <PopperWrapper>
        <CloseButtonWrapper >
          <CloseIcon 
          onClick={props.closePopupTip}
          fontSize="small"
        />
      </CloseButtonWrapper>
        {props.description}
      </PopperWrapper>
    </ClickAwayListener>
    </Popper>    
  );
}


