import * as React from 'react';
import { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
} from '@material-ui/core';
import { GRID_SIZE_12, GRID_SIZE_3, GRID_SIZE_6 } from '../../config';
import styled from 'styled-components';
import { InputText } from '../../components/Form';
import { IUserProfile } from '../../modules/types';
import { pick } from 'lodash';
import Alert from '@material-ui/lab/Alert';
import { validate } from 'email-validator';

interface IProps extends Omit<IUserProfile, 'picture'> {
  onSave: (data: Omit<IUserProfile, 'picture'>) => void;
  error?: string;
}

const InputTextStyled = styled(InputText)`
  && {
    font-family: inherit;
  }
`;

const AccountDetails = (props: IProps) => {

  const [ values, setValues ] = useState(props);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const hasError = () =>
    !values.email ||
    !values.preferred_username ||
    !validate(values.email)
  ;

  const saveProfileChanges = () => {
    props.onSave(pick(values, [
      'name',
      'family_name',
      'nickname',
      'email',
      'preferred_username',
    ]));
  };

  return (
    <Card>
      <form autoComplete="off" noValidate={true}>
        { props.error && <Alert severity="error">{props.error}</Alert> }
        <CardHeader
          subheader="The information can be edited"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid container={true} spacing={GRID_SIZE_3}>
            <Grid item={true} md={GRID_SIZE_6} xs={GRID_SIZE_12}>
              <InputTextStyled
                type="text"
                label="First Name"
                margin="dense"
                value={values.name}
                fullWidth={true}
                name="name"
                onChange={handleChange}
              />
            </Grid>
            <Grid item={true} md={GRID_SIZE_6} xs={GRID_SIZE_12}>
              <InputTextStyled
                type="text"
                label="Last Name"
                margin="dense"
                value={values.family_name}
                fullWidth={true}
                name="family_name"
                onChange={handleChange}
              />
            </Grid>
            <Grid item={true} md={GRID_SIZE_6} xs={GRID_SIZE_12}>
              <InputTextStyled
                type="text"
                label="Nickname"
                margin="dense"
                value={values.nickname}
                fullWidth={true}
                name="nickname"
                onChange={handleChange}
              />
            </Grid>
            <Grid item={true} md={GRID_SIZE_6} xs={GRID_SIZE_12}>
              <InputTextStyled
                type="email"
                label="Email"
                margin="dense"
                value={values.email}
                error={!values.email || !validate(values.email)}
                fullWidth={true}
                name="email"
                onChange={handleChange}
              />
            </Grid>
            <Grid item={true} md={GRID_SIZE_6} xs={GRID_SIZE_12}>
              <InputTextStyled
                type="text"
                label="Login"
                margin="dense"
                value={values.preferred_username}
                fullWidth={true}
                name="preferred_username"
                error={!values.preferred_username}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={saveProfileChanges}
            disabled={hasError()}
          >
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default AccountDetails;
