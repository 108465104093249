import * as React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
min-width: 40px;
min-height: 40px;
width: 2.5rem;
height: 2.5rem;
border: solid 0.0625rem ${props => props.color || '#4ad991'};
border-radius: 50%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`;

interface IProps {
  color?: string,
}

export class  UserPlusIcon extends React.Component<IProps>{
  public render() {
    const color = this.props.color || '#4ad991';
    return (
      <Circle color={color}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <path data-name="User Plus Icon" d="M-218-1589v-2h-2v-2h2v-2h2v2h2v2h-2v2Zm-12,0v-1a4.951,4.951,0,0,1,5-5h3v2h-3a2.913,2.913,0,0,0-2.8,2h5.8v2Zm3-11v-1a4.012,4.012,0,0,1,4-4,4.012,4.012,0,0,1,4,4v1a4.012,4.012,0,0,1-4,4A4.012,4.012,0,0,1-227-1600Zm2-1v1a2.006,2.006,0,0,0,2,2,2.006,2.006,0,0,0,2-2v-1a2.006,2.006,0,0,0-2-2A2.006,2.006,0,0,0-225-1601Z" transform="translate(230 1605)" fill={color}/>
        </svg>

      </Circle>
    );
  }
}

export default UserPlusIcon;
