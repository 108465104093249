import * as React from 'react';
import { LockOpenIcon } from '../Icons';
import theme from '../../assets/css/theme';
import styled from 'styled-components';

interface IProps {
  value: number | string,
}

const CampaignCompletedSizeValue = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 1.125rem;
text-align: left;
color: ${theme.colorDarkGray};
`;

const Label = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: normal;
font-size: 0.8125rem;
text-align: left;
color: ${theme.colorDarkGray};
`;

const CampaignCompletedSizeWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
`;

const CampaignCompletedSizeValueWrapper = styled.div`
padding-left: ${(props: ISpaceSizeProp) => props.spaceSize || '1.5rem'};
`;

interface ISpaceSizeProp {
  spaceSize?: string;
}

interface IProps extends ISpaceSizeProp {
  value: number | string;
}

export class CampaignCompletedSize extends React.Component<IProps> {
  public render() {
    return (
      <CampaignCompletedSizeWrapper>
        <LockOpenIcon />
        <CampaignCompletedSizeValueWrapper spaceSize={this.props.spaceSize}>
          <CampaignCompletedSizeValue>{this.props.value.toLocaleString('en')}</CampaignCompletedSizeValue>
          <Label>Campaigns Completed</Label>
        </CampaignCompletedSizeValueWrapper>
      </CampaignCompletedSizeWrapper>
    );
  }
}

export default CampaignCompletedSize;
