import * as React from 'react';
import { DonutChart } from '../../components/Charts';
import styled from 'styled-components';

const Subtitle = styled.div`
  font-size: 1.125rem;
`;

interface IProps {
  activePersonCount: number | undefined;
  controlPersonCount: number | undefined;
}

const subtitleValue = (total: number) => {
  return(
    <Subtitle>
      <div><strong>{ total.toLocaleString('en') }</strong></div>
      <div>Customers</div>
    </Subtitle>
  )
}

const CampaignParticipantDistributionCard = ({ activePersonCount = 0, controlPersonCount= 0 }: IProps) => {
  const total = activePersonCount + controlPersonCount;
  const convertToPercentage = (count: any) => {
    const percentage = 100;
    return Math.round((count / total) * percentage); 
   }
 
  const data = [
    {
      name: activePersonCount ? `Test group - ${convertToPercentage(activePersonCount)}%` : 'Test group',
      y: activePersonCount, 
      color: '#FE0056'
    }, 
    {
      name: controlPersonCount ? `Control group - ${convertToPercentage(controlPersonCount)}%` : 'Control group', 
      y: controlPersonCount, 
      color: '#433b4b'}
    ]

  return (
    <DonutChart
      title={`Customer Participants Distribution`}
      subtitle={subtitleValue(total)}
      data={data}
    /> 
  )
}

export default CampaignParticipantDistributionCard;
