import React from 'react';
import { Grid } from '@material-ui/core';
import { LoadingSkeleton } from '../../components/LoadingSkeleton';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import {
  GRID_SIZE_2,
  GRID_SIZE_6,
  GRID_SIZE_12,
} from '../../config';

import {
  CardContainer,
  CardColumn,
  CustomerSizeCardColumn,
  TargetedCardColumn,
  TargetedSizeWrapper,
  AvailableCardColumn,
  AvailableSizeWrapper,
  OpportunityCardColumn,
  ButtonCardColumn,
} from './CommonLifeCyceAndLoadingAudience';

const CustomerSizeCardColumnWrapper = styled.div`
display: flex;
  align-items: center;
  justify-content: center;

  @media ${theme.media.tablet} {
    justify-content: flex-start;
  }
`;

const LoadingLifecycleAudienceCard = () => {
  return (
      <CardContainer container={true}>
      <CardColumn item={true} xs={GRID_SIZE_12}>
          <Grid item={true} xs={GRID_SIZE_12}/>
          <LoadingSkeleton variant="text" width = "20%"/>
          <LoadingSkeleton variant="text" width = "40%"/>
      </CardColumn>
        <CustomerSizeCardColumn item={true} lg={GRID_SIZE_2} md={GRID_SIZE_6} sm={GRID_SIZE_6} xs={GRID_SIZE_12}>
          <Grid item={true} xs={GRID_SIZE_12}>
            <CustomerSizeCardColumnWrapper>
          <LoadingSkeleton variant="rect" width = "50%" height="50px" />
            </CustomerSizeCardColumnWrapper>
          </Grid>
        </CustomerSizeCardColumn>
        <CardColumn item={true} lg={GRID_SIZE_2} md={GRID_SIZE_12} xs={GRID_SIZE_12}>
          <Grid item={true}>
            <LoadingSkeleton variant="rect" width = "100%" height="20px"/> 
          </Grid>
        </CardColumn>
        <TargetedCardColumn item={true} lg={GRID_SIZE_2} md={GRID_SIZE_6} sm={GRID_SIZE_6} xs={GRID_SIZE_12}>
          <TargetedSizeWrapper>
          <LoadingSkeleton variant="rect" width = "40%" height="40px"/>
          </TargetedSizeWrapper>
        </TargetedCardColumn>
        <AvailableCardColumn item={true} lg={GRID_SIZE_2} md={GRID_SIZE_6} sm={GRID_SIZE_6} xs={GRID_SIZE_12}>
          <AvailableSizeWrapper>
            <LoadingSkeleton variant="rect" width = "40%" height="40px"/>
          </AvailableSizeWrapper>
        </AvailableCardColumn>
        <OpportunityCardColumn item={true} lg={GRID_SIZE_2} md={GRID_SIZE_6} sm={GRID_SIZE_6} xs={GRID_SIZE_12}>
          <LoadingSkeleton variant="rect" width = "100%" height="40px"/>
        </OpportunityCardColumn>
        <ButtonCardColumn container={true} item={true} lg={GRID_SIZE_2} md={GRID_SIZE_6} sm={GRID_SIZE_6} xs={GRID_SIZE_12}>
          <LoadingSkeleton variant="rect" width = "100%" height="40px"/> 
        </ButtonCardColumn>
      </CardContainer>
  )
}
export default LoadingLifecycleAudienceCard;
