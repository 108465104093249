import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import * as reducers from './reducers';
import rootSaga from './sagas';

const { NODE_ENV } = process.env;

let store: Store;
const sagaMiddleware = createSagaMiddleware();

if (NODE_ENV === 'development') {
  // enable dev tools extension for development mode
  store = createStore(combineReducers(reducers), composeWithDevTools(
    applyMiddleware(sagaMiddleware),
  ));
} else {
  store = createStore(
    combineReducers(reducers),
    applyMiddleware(sagaMiddleware),
  )
}

export default store;

sagaMiddleware.run(rootSaga);
