import * as React from 'react';
import theme from '../../assets/css/theme';

interface IProps {
  color?: string,
}

export const CustomersIcon = (props: IProps) => {
  const color = props.color || theme.colorInactive;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(0 0)">
        <path d="M13,16a.945.945,0,0,1-1-1V6a.945.945,0,0,1,1-1h2a.945.945,0,0,1,1,1v9a.945.945,0,0,1-1,1ZM7,16a.944.944,0,0,1-1-1V1A.945.945,0,0,1,7,0H9a.945.945,0,0,1,1,1V15a.944.944,0,0,1-1,1ZM1,16a.945.945,0,0,1-1-1V11a.945.945,0,0,1,1-1H3a.946.946,0,0,1,1,1v4a.945.945,0,0,1-1,1Z" transform="translate(0 0)" fill={color}/>
      </g>
    </svg>
  );
};

export default CustomersIcon;
