import React from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import Grid from '@material-ui/core/Grid';
import { LoadingSkeleton } from '../../components/LoadingSkeleton';
import {
  GRID_SIZE_2,
  GRID_SIZE_4,
  GRID_SIZE_12,
} from '../../config';

const CampaignCardContainer = styled(Grid)`
   && {
      background-color: ${theme.colorWhite};
      box-shadow: ${theme.boxShadow};
      padding: 1.25rem 1.688rem 1.313rem 1.063rem;
   }
 `;

 const HeaderCardElement = styled(Grid)`
 display: flex;
 justify-content: space-between;

  @media (max-width: 1440px) {
     flex-direction: column;
    }
}
`;

const CardColumn = styled(Grid)`
  && {
    word-break: break-word;
    padding: ${GRID_SIZE_2}px;
  }
`;

const CardRevenueColumnForWithIcon = styled(CardColumn)`
  && {
    justify-content: flex-start;

    @media ${theme.media.laptopL} {
        justify-content: flex-end;
      }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ProportionTotalWrapper = styled.div`
  & > div {
    min-width: 15rem;
  }
`;

 const LoadingCampaignCard = () => {
  return (
    <CampaignCardContainer>
      <HeaderCardElement  >
      <TitleContainer>
        <LoadingSkeleton variant="text" width = "210px" height="20px"/>
      </TitleContainer>
      <ProportionTotalWrapper>
        <LoadingSkeleton variant="text" width = "280px" height="20px"/>
      </ProportionTotalWrapper>
      </HeaderCardElement>

      <Grid container={true} item={true} xs={GRID_SIZE_12}>
        <CardColumn container={true}
              item={true}
              lg={GRID_SIZE_4}
              md={GRID_SIZE_12}
              sm={GRID_SIZE_12}
              xs={GRID_SIZE_12}
              justifyContent="flex-start"
              alignItems="center"
        >
          <LoadingSkeleton variant="text" width = "80px" height="80px"/>
        </CardColumn>
        <CardColumn container={true}
              item={true}
              lg={GRID_SIZE_2}
              md={GRID_SIZE_12}
              sm={GRID_SIZE_12}
              xs={GRID_SIZE_12}
              justifyContent="space-between"
              alignItems="center"
        >
           <LoadingSkeleton variant="rect" width = "50px" height="40px"/>
           <LoadingSkeleton variant="rect" width = "50px" height="40px"/>
        </CardColumn>
        <CardRevenueColumnForWithIcon container={true}
              item={true}
              lg={GRID_SIZE_2}
              md={GRID_SIZE_12}
              sm={GRID_SIZE_12}
              xs={GRID_SIZE_12}
              justifyContent="flex-start"
              alignItems="center"
        >
           <LoadingSkeleton variant="rect" width = "140px" height="40px"/>
        </CardRevenueColumnForWithIcon>
        <CardRevenueColumnForWithIcon container={true}
              item={true}
              lg={GRID_SIZE_2}
              md={GRID_SIZE_12}
              sm={GRID_SIZE_12}
              xs={GRID_SIZE_12}
              justifyContent="flex-start"
              alignItems="center"
        >
           <LoadingSkeleton variant="rect" width = "140px" height="40px"/>
        </CardRevenueColumnForWithIcon>
        <CardRevenueColumnForWithIcon container={true}
              item={true}
              lg={GRID_SIZE_2}
              md={GRID_SIZE_12}
              sm={GRID_SIZE_12}
              xs={GRID_SIZE_12}
              justifyContent="flex-start"
              alignItems="center"
        >
           <LoadingSkeleton variant="rect" width = "140px" height="40px"/>
        </CardRevenueColumnForWithIcon>
      </Grid>
    </CampaignCardContainer>
  )
}
export default LoadingCampaignCard;
