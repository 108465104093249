import React from 'react'
import styled from 'styled-components'
import MUIChip, { ChipProps } from '@material-ui/core/Chip'
import theme from '../assets/css/theme'

interface IProps extends Omit<ChipProps<any, any>, 'primary'> {}

export const InfoChip2 = (props: IProps) => {
  return <MUIChip {...props} />
}

export const InfoChip = styled(MUIChip)`
  &&&.MuiChip-root {
    background-color: ${theme.colorInfoBackground};
    border: solid 1px ${theme.colorInfo};

    .MuiChip-label {
      color: ${theme.colorInfo};
      font-weight: bold;
    }
  }
`