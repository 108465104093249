import React, { useEffect, useState } from 'react'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Calendar, dateFnsLocalizer, } from 'react-big-calendar'
import { Box } from '@material-ui/core'
import find from 'lodash/find';
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux';
import startOfMonth from 'date-fns/startOfMonth'
import startOfWeek from 'date-fns/startOfWeek'
import endOfMonth from 'date-fns/endOfMonth'
import endOfWeek from 'date-fns/endOfWeek'
import { getToday } from '@loypal/lib-growth'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CloseIcon from '@material-ui/icons/Close'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Button } from '../../components/BoxComponents';
import { DATE_FNS_DATE_FORMAT } from '../../config/datetime';
import { useClientCustomization } from '../../utils/hooks';
import { withHeader, InfoPanel } from '../../components';
import theme from '../../assets/css/theme';
import { IStoreState } from '../../modules/types';
import { calendarEntriesRequest } from '../../modules/actions';
import { Modal } from '../../components';
import { BackLink } from '../../components/Navigation';
import ContainerStyled from '../../components/ContainerStyled';

const BACK_URL = '/campaigns'

const locales = {
  'en-US': enUS,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const CustomToolbar = ({ toolbar, view }: any) => {
  const goToBack = () => {
    toolbar.onNavigate('PREV'); 
  };
  const goToNext = () => {
    toolbar.onNavigate('NEXT'); 
  };
  const goToDayView = () => {
    toolbar.onView('day');
  }
  const goToWeekView = () => {
    toolbar.onView('week');
  }
  const goToMonthView = () => {
    toolbar.onView('month');
  }
  
  const date = moment(toolbar.date);
  let label = date.format('MMMM YYYY')

  if (view === "day") {
    label = date.format('dddd MMMM Do YYYY')
  } else if (view === "week") {
    const start_date = moment(toolbar.date).startOf('week');
    const end_date = moment(toolbar.date).endOf('week');
    const weekFormat = `${start_date.format('MMMM Do YYYY')} - ${end_date.format('MMMM Do YYYY')}`
    label = weekFormat
  }

  return (
    <Box>
      <CustomToolbarWrapper>
        <PageAndIconWapper>
          <BackArrowWraper>
            <ArrowBackIosIcon onClick={goToBack} className="arrowBackIcon" />
          </BackArrowWraper>
          <ArrowWraper>
            <ArrowForwardIosIcon onClick={goToNext} className="arrowBackIcon"/>
          </ArrowWraper>
          <PageTitleWrapper>
            <Box>
              {label}
            </Box>
          </PageTitleWrapper>
        </PageAndIconWapper>
        <ButtonGroup variant="contained" aria-label="outlined primary button group">
          <Button onClick ={goToMonthView} >Month</Button>
          <Button onClick ={goToWeekView} >Week</Button>
          <Button onClick ={goToDayView} >Day</Button>
        </ButtonGroup>
      </CustomToolbarWrapper>
    </Box>
  );
}

const CalendarPage = () => {
  const dispatch = useDispatch()
  const { audiences } = useClientCustomization()
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState({title: '', start: '', end: '', active_person_count: '', control_person_count: '', generate_csv_date: '', campaign_revenue: '', control_revenue: '', control_expected_sales: ''});
  const [view, setView] = useState();

  const onViewChange = (newView: any) => {setView(newView)};

  useEffect(() => {
    const today = getToday();
    const currentViewStartDate = startOfWeek(startOfMonth(today));
    const currentViewEndDate = endOfWeek(endOfMonth(today));
    setStartDate(format(currentViewStartDate, DATE_FNS_DATE_FORMAT))
    setEndDate(format(currentViewEndDate, DATE_FNS_DATE_FORMAT))
  }, [])

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(calendarEntriesRequest({ start_date: startDate, end_date: endDate}))
    }
    // eslint-disable-next-line
  }, [startDate, endDate])

  const handleCalendarViewChange = (date: any, _view: any, _action: any) => {
    const start = startOfWeek(startOfMonth(date))
    const end = endOfWeek(endOfMonth(date))
    setStartDate(format(start, DATE_FNS_DATE_FORMAT))
    setEndDate(format(end, DATE_FNS_DATE_FORMAT))
  }

  const calendarEntries = useSelector((state: IStoreState) => {
    return state.campaign.calendar_entries
  })
  
  const calendarEvents = calendarEntries?.map((events) => {
    const eventAudience = find(audiences, (audience) =>  audience.id === events.audience_id);
    return {
      title: eventAudience?.name || events.name,
      start: new Date(events.start_date),
      end: new Date(events.end_date),
      active_person_count: events.active_person_count,
      control_person_count: events.control_person_count,
      generate_csv_date: events.generate_csv_date,
      campaign_revenue: events.campaign_revenue,
      control_revenue: events.control_revenue,
      control_expected_sales: events.control_expected_sales,
    }
  });

  const handleClose = () => {
    setOpen(false);
  };
  
const modified_csv_date = new Date(event.generate_csv_date) ;
const currentDate = new Date();

const body = (
    <Box>
    <TitleWrapper>
      <CloseButton>
        <CloseIcon onClick= {handleClose} fontSize="small" />
      </CloseButton>
      <EventTitle>
        {`${event.title} 
        ${moment(event.start).format('Do MMMM yyyy')} - 
        ${moment(event.end).format('Do MMMM yyyy')}`}
      </EventTitle>
        {modified_csv_date > currentDate ? 
       <InfoPanel>
        {`The numbers below are just an estimation, we will allocate customers into the active and control groups on the ${moment(event.generate_csv_date).format('Do MMMM yyyy')}`}
       </InfoPanel>
       : ''} 
        <PeopleIconWrapper>
          <PeopleAltIcon />
          <PersonCounWrapper>
            <PersonCount>
              {event.active_person_count.toLocaleString()}
            </PersonCount>
            {`customers to be included in campaign export `}
          </PersonCounWrapper>
        </PeopleIconWrapper>
        <IconWrapper>
          <AssessmentIcon />
          <PersonCounWrapper>
            <PersonCount>
              {event.control_person_count.toLocaleString()}
            </PersonCount>
            {`customer record will be quarantined as a control group for reporting and analysis.`}
          </PersonCounWrapper>
        </IconWrapper>
        <IconWrapper>
          <ShoppingCartIcon />
          <PersonCounWrapper>
            <PersonCount>
              ${event.control_revenue.toLocaleString()}
            </PersonCount>
            {`control revenue`}
          </PersonCounWrapper>
        </IconWrapper>
        <IconWrapper>
          < TrendingUpIcon />
          <PersonCounWrapper>
            <PersonCount>
              ${event.campaign_revenue.toLocaleString()}
             </PersonCount>
            {`campaign revenue`}
          </PersonCounWrapper>
        </IconWrapper>
      </TitleWrapper>
    </Box>
  );

  // tslint:disable-next-line:no-shadowed-variable
  const handleOnSelect = (event: any) => {
    setOpen(true);
    setEvent(event);
  }
  
  return (
    <ContainerStyled maxWidth="xl">
      <BackLink text="Back to dashboard" url={BACK_URL} />
      <CalenderPageWrapper className = 'overlay-popup'>
        <Calendar
          localizer={localizer}
          events = {calendarEvents }
          view={view}
          onView={onViewChange}
          components={{
            toolbar: (toolbar) => <CustomToolbar toolbar={toolbar} view={view} />
          }}
          onSelectEvent={handleOnSelect}
          onNavigate={handleCalendarViewChange}
        />
        <Modal 
          isOpen={open} 
          handleClose={handleClose}
        >
          {body}
        </Modal>
      </CalenderPageWrapper>
    </ContainerStyled>
  ); 
}

const PersonCounWrapper = styled.div`
  display: flex;
  gap: 5px;
`

const PersonCount = styled.div`
  font-weight: 600;
`

const EventTitle = styled.div`
  font-size: 1.5rem;
  font-family: Inter-Regular;
  font-weight: 500;
  padding-bottom: 1.5rem;
`

const PeopleIconWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding-left: 1.5rem;
  padding-top: 0.5rem;
`

const IconWrapper = styled.div`
  display: flex;
  gap: 15px;
  padding-left: 1.5rem;
  padding-top: 1rem;
`

const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
`

const TitleWrapper = styled.div `
  width: 800px;
  display: flex;
  flex-direction: column;
  font-size: 0.9375rem;
  justify-content: flex-start;
  color:${theme.colorDarkBlue};
  background-color: ${theme.colorWhite};
  box-shadow: ${theme.boxShadow};
  padding: 1.25rem 1.438rem;
`

const CalenderPageWrapper = styled.div`
  height: 800px;
  margin-top: 2rem;
  .rbc-header{
    text-transform: uppercase;
    font-size: 0.92em;
    font-weight: bold;
    color: ${theme.colorDarkGray};
    padding: 12px;
  }
`

const CustomToolbarWrapper = styled.div` 
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: space-between;
  font-weight: 500;
    .arrowBackIcon {
      font-size: 16px;
      cursor: pointer; 
      color: ${theme.colorDarkBlue};
    }  
  @media (min-width: 958px) and (max-width:1135px){
    display: flex;
    flex-wrap: wrap;
  }
  @media (min-width:150px) and (max-width: 752px){
    display: flex;
    flex-wrap: wrap;
  }
`

const BackArrowWraper = styled.div`
  background-color:transparent;
  height:37px;
  width:30px;
  border-radius:50%;
  line-height:30px;
  display:flex;
  align-items:center;
  justify-content:center;
  &:hover{
    background-color:${theme.colorLightGray}!important;
  }  
`

const ArrowWraper = styled.div`
  background-color:transparent;
  width:30px;
  border-radius:50%;
  line-height:30px;
  display:flex;
  align-items:center;
  justify-content:center;
  &:hover{
  background-color:${theme.colorLightGray}!important;
  }
`

const PageTitleWrapper = styled.div`
  margin-bottom: 0px;
  font-size: 26px;
`

const PageAndIconWapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export default withHeader(CalendarPage);
