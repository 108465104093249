import { useClientCustomization, useFeatureFlag } from '../utils/hooks'

interface IProp {
  featureName: string,
  children: JSX.Element,
}

export const FlaggedFeature = ({ featureName, children }: IProp) => {
  const { clientName } = useClientCustomization()
  const isFeatureLive = useFeatureFlag(featureName, clientName)

  if (!isFeatureLive) { 
    return null
  }

  return children
}