import { createAction } from "redux-act";
import { IError } from "../types";

export * from './user';
export * from './segment';
export * from './audience';
export * from './campaign';
export * from './customers'
export * from './integration'

export const errorsGlobalError = createAction<TypeError | Error | string>();
export const errorsShowGlobalError = createAction<IError>();
export const errorsClearGlobalError = createAction();
