import React from 'react'
import theme from '../../assets/css/theme';

const DEFAULT_DIMENSION = 24

interface IProps {
  color?: string,
  height?: string | number,
  width?: string | number
}

export const ArrowLeftIcon = ({
  color = theme.colorDarkBlue,
  height = DEFAULT_DIMENSION,
  width = DEFAULT_DIMENSION
}: IProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox={`0 0 ${height} ${width}`} width={width}>
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" fill={color}/>
    </svg>
  )
}