import React from 'react'
import { LoadingSkeleton } from '../../components/LoadingSkeleton';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import theme from '../../assets/css/theme'
import {
 GRID_SIZE_0,
 GRID_SIZE_12
 } from '../../config';

const CampaignsSegmentCardContainer = styled.div`
padding: 1.25rem 1.25rem 1.125rem 1.25rem;
box-shadow: ${theme.boxShadow};
background-color: ${theme.colorWhite};
`;

const FullWidthElement = styled.div`
display:flex;
padding: 0.6875rem 0 0.4375rem;
column-gap: 20px;
`;

const CampaignBoxTitle = styled.div`
  display:flex;
  justify-content: space-between;
`;

const CampaignCompletedSize = styled.div`
display:flex;
flex-direction: column;
`;

 const LoadingCampaignsSegmentCard = () => {

  return (
    <CampaignsSegmentCardContainer>
      <CampaignBoxTitle>
        <LoadingSkeleton variant="text" width="77px" height="29px" />
        <LoadingSkeleton variant="text" width="20px" height="25px" />
      </CampaignBoxTitle>

      <FullWidthElement>
        <LoadingSkeleton variant="rect" width="40px" height="40px" />
      <CampaignCompletedSize>
        <LoadingSkeleton variant="text" width="80px" height="25px" />
        <LoadingSkeleton variant="text"  width="124px" height="18px" />
      </CampaignCompletedSize>
      </FullWidthElement>

      <FullWidthElement>
        <LoadingSkeleton variant="rect" width="40px" height="40px" />
      <CampaignCompletedSize>
        <LoadingSkeleton variant="text" width="80px" height="25px" />
        <LoadingSkeleton variant="text"  width="124px" height="18px" />
      </CampaignCompletedSize>
      </FullWidthElement>

      <FullWidthElement>
        <LoadingSkeleton variant="rect" width="40px" height="40px" />
      <CampaignCompletedSize>
        <LoadingSkeleton variant="text" width="80px" height="25px" />
        <LoadingSkeleton variant="text"  width="124px" height="18px" />
      </CampaignCompletedSize>
      </FullWidthElement>

      <FullWidthElement>
        <LoadingSkeleton variant="rect" width="40px" height="40px" />
      <CampaignCompletedSize>
        <LoadingSkeleton variant="text" width="80px" height="25px" />
        <LoadingSkeleton variant="text"  width="124px" height="18px" />
      </CampaignCompletedSize>
      </FullWidthElement>

      <Grid container={true} spacing={GRID_SIZE_0} alignItems="center">
        <Grid container={true} item={true} sm={GRID_SIZE_12} justifyContent="flex-end">
        <LoadingSkeleton variant="rect" width="153px" height="41px" />
        </Grid>
      </Grid>
    </CampaignsSegmentCardContainer>
    )
}
export default LoadingCampaignsSegmentCard;
