import React from 'react';
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { INTEGRATION_TYPES } from '@loypal/lib-growth';
import { InputLabel } from '../../../components/Text';
import { OptionBox } from '../../../components/Form';
import { IIntegrationType } from './integration-options'; 

interface ITypeFormProps {
  platform: string
  selectedIntegrationTypes: string[]
  setValue: (val: any) => void
}

export const IntegrationTypeInput = ({ platform, setValue, selectedIntegrationTypes }: ITypeFormProps) => {
  const integrationTypes: IIntegrationType[] = INTEGRATION_TYPES[platform]
  
  return (
    <>
      <Box mb="12px" mt="24px">
        <InputLabel>Select integration types</InputLabel>
      </Box>
      <TypesContainer>
        {
          integrationTypes.map(({ label, description, value }) => (
            <OptionBox 
              key={label}
              title={label} 
              description={description} 
              value={value} 
              handleOnClick={setValue}
              isSelected={selectedIntegrationTypes.includes(value)}
            />
          ))
        }
      </TypesContainer>
    </>
  )
}

const TypesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`