import * as React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import theme from '../../assets/css/theme';
import { ICampaignsSegmentsCard } from '../../modules/types';
import {
  Button,
  CampaignBoxTitle,
  CampaignRevenueSize,
  CampaignIncrementalRevenueSize,
  CampaignLiveSize,
  CampaignCompletedSize,
} from '../../components/BoxComponents';
import { GRID_SIZE_0, GRID_SIZE_12 } from '../../config';
import { useSegment } from '../../utils/hooks';

const CampaignsSegmentCardContainer = styled.div`
padding: 1.563rem 1.25rem 1.125rem 1.25rem;
box-shadow: ${theme.boxShadow};
background-color: ${theme.colorWhite};
`;

const FullWidthElement = styled.div`
padding: 0.6875rem 0 0.4375rem;
`;

const FullWidthLastElement = styled.div`
padding: 0.6875rem 0 0.9375rem;
`;

const CardButton = styled(Button)`
  margin-top: 30px;
  @media ${theme.media.laptop} {
    margin-top: 0;
  }
`;


export interface ICampaignsSegmentCardProps extends ICampaignsSegmentsCard {
  urlParams: string;
}


const CampaignSegmentCard = (props: ICampaignsSegmentCardProps) => {
  const { segment_id, completed, live, revenue, incremental_revenue, urlParams } = props;
  const { name, description } = useSegment(segment_id);
  return (
    <CampaignsSegmentCardContainer>
      <CampaignBoxTitle
        title={name}
        subtitle={description}
      />

      <FullWidthElement>
        <CampaignCompletedSize value={completed} />
      </FullWidthElement>

      <FullWidthElement>
        <CampaignLiveSize value={live} />
      </FullWidthElement>

      <FullWidthElement>
        <CampaignRevenueSize value={revenue} />
      </FullWidthElement>

      <FullWidthLastElement>
        <CampaignIncrementalRevenueSize value={incremental_revenue} />
      </FullWidthLastElement>

      <Grid container={true} spacing={GRID_SIZE_0} alignItems="center">
        <Grid container={true} item={true} sm={GRID_SIZE_12} justifyContent="flex-end">
          <CardButton to={urlParams ? `/campaigns/segments/${segment_id}${urlParams}` : `/campaigns/segments/${segment_id}`}>View Campaigns</CardButton>
        </Grid>
      </Grid>
    </CampaignsSegmentCardContainer>
  );
}

export default CampaignSegmentCard;
