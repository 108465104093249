import * as React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
min-width: 40px;
min-height: 40px;
width: ${(props: IDiameter) => props.diameter || '2.5rem'};
height: ${(props: IDiameter) => props.diameter || '2.5rem'};
border: solid 0.0625rem ${props => props.color || '#ff7285'};
border-radius: 50%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`;

interface IDiameter {
  diameter?: string;
}

interface IProps extends IDiameter {
  color?: string;
}

export class DownloadIcon extends React.Component<IProps>{
  public render() {
    const color = this.props.color || '#00e9ce';
    return (
      <Circle color={color} diameter={this.props.diameter}>
        <svg data-name="Download Icon" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m12 16c-.205 0-.401-.084-.542-.232l-5.25-5.5c-.455-.476-.117-1.268.542-1.268h2.75v-5.75c0-.689.561-1.25 1.25-1.25h2.5c.689 0 1.25.561 1.25 1.25v5.75h2.75c.659 0 .997.792.542 1.268l-5.25 5.5c-.141.148-.337.232-.542.232z" fill={color}/><path d="m22.25 22h-20.5c-.965 0-1.75-.785-1.75-1.75v-.5c0-.965.785-1.75 1.75-1.75h20.5c.965 0 1.75.785 1.75 1.75v.5c0 .965-.785 1.75-1.75 1.75z" fill={color}/></svg>
      </Circle>
    );
  }
}

export default DownloadIcon;
