import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { connect } from "react-redux";
import {
  clientPicture,
  clientPreferredUsernameName,
  isDataRequested,
  isLoggedIn,
  userFullName,
  userShortName
} from "../../modules/selectors";
import { IStoreState } from "../../modules/types";
import { IUserModel } from "../../modules/types";
import AppBar from '@material-ui/core/AppBar/AppBar';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import clsx from 'clsx';
import styled from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';
// TODO: temporary commented
// import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import SideBar from './SideBar';
import GridRoot from '../GridRoot';
import { Hidden, makeStyles } from '@material-ui/core';
import theme from '../../assets/css/theme';
// TODO: temporary commented
import { LoyPalIcon, /*NotificationIcon*/ } from '../Icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import TopMenu from '../TopMenu';
import MenuItem from "@material-ui/core/MenuItem";
import { userLogout } from "../../modules/actions/user";
import { useClientCustomization } from '../../utils/hooks'
import { BreadCrumbs } from './Breadcrumbs';

interface IProps {
  children: React.ReactNode;
}

interface IHOCProps extends RouteComponentProps {
  is_logged_in: boolean;
  user: IUserModel;
}

interface IPropsHeader extends RouteComponentProps {
  readonly is_data_requested?: boolean,
  isSidebarOpen: boolean,
  userLogout: typeof userLogout,
  onMobileMenuClick?: () => void,
  picture: string,
  nickname: string,
  userFullName: string,
  shortName: string,
}

const drawerMaxWidth = 240;
const drawerMinWidth = 66;
const appBarMinHeight = 70;
const avatarSize = 2.375;

const AvatarStyled = styled(Avatar)`
  && {
    background-color: ${theme.colorDarkGray};
    color: ${theme.colorWhite};
    width: ${avatarSize}rem;
    height: ${avatarSize}rem;
  }
`;

const LinkStyled = styled(Link)`
  display: block;
  text-decoration: none;
  color: inherit;
`;

const useStyles = makeStyles(themeStyles => ({
  toolbar: {
    color: theme.titleColor,
    backgroundColor: theme.colorWhite,
    justifyContent: 'flex-end',
    paddingRight: 20, // keep right padding when drawer closed
    minHeight: appBarMinHeight,
  },
  divider: {
    height: 28,
  },
  mobileIcon: {
    marginRight: 'auto',
  },
  appBar: {
    marginLeft: drawerMinWidth,
    width: '100%',
    boxShadow: theme.boxShadow,
    zIndex: themeStyles.zIndex.drawer - 1,
    transition: themeStyles.transitions.create(['width', 'margin'], {
      easing: themeStyles.transitions.easing.sharp,
      duration: themeStyles.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerMaxWidth,
    width: '100%',
    [themeStyles.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerMaxWidth}px)`,
    },
    transition: themeStyles.transitions.create(['width', 'margin'], {
      easing: themeStyles.transitions.easing.sharp,
      duration: themeStyles.transitions.duration.enteringScreen,
    }),
  },
  appBarSpacer: {
    marginBottom: '2rem',
  },
  content: {
    flexGrow: 1,
    height: `calc(100vh - ${appBarMinHeight}px)`,
    marginTop: appBarMinHeight,
    overflow: 'auto',
  },
  avatar: {
    width: 38,
    height: 38,
    margin: 5,
  },
}));

const HeaderComponent: React.FC<IPropsHeader> = (props) => {
  const classes = useStyles();
  const nickname = props.nickname || props.userFullName;
  const userPicture = !props.picture && !props.shortName ? <AvatarStyled> <AccountCircleIcon /> </AvatarStyled> : <Avatar alt={nickname} src={props.picture} className={classes.avatar}/>;
  const { userLogout: logout } = props;
  const { isLoading } = useClientCustomization();

  return (
    <AppBar position="absolute" className={clsx(classes.appBar, props.isSidebarOpen && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <Hidden mdUp={true}>
          <ListItemIcon className={classes.mobileIcon} onClick={props.onMobileMenuClick}>
            <LoyPalIcon/>
          </ListItemIcon>
        </Hidden>
        <BreadCrumbs />
        { /*TODO: temporary commented*/ }
        {/*<IconButton color="inherit">*/}
        {/*    <NotificationIcon showBadge={true}/>*/}
        {/*</IconButton>*/}
        <Divider orientation="vertical" variant="middle" className={classes.divider}/>
        <TopMenu nickname={nickname}>
          <MenuItem>
            <LinkStyled to="/user/profile">My Profile</LinkStyled>
          </MenuItem>
          <MenuItem onClick={ logout }>Logout</MenuItem>
        </TopMenu>
        {userPicture}
      </Toolbar>
      { (props.is_data_requested || isLoading) && <LinearProgress /> }
    </AppBar>
  );
};

const mapStateToProps = (state: IStoreState) => ({
  is_logged_in: isLoggedIn(state),
  is_data_requested: isDataRequested(state),
  user: state.user,
  nickname: clientPreferredUsernameName(state),
  picture: clientPicture(state),
  shortName: userShortName(state),
  userFullName: userFullName(state),
});

const mapDispatchToProps = {
  userLogout,
};

export const Header = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)
);

export const withHeader = (WrappedComponent: React.ComponentType<any>) => (props: IHOCProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(true);

  const handleIsOpenToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <GridRoot>
      <SideBar isOpen={isOpen} minWidth={drawerMinWidth} maxWidth={drawerMaxWidth}
        onIconToggle={handleIsOpenToggle}  {...props} />
      <Header isSidebarOpen={isOpen} onMobileMenuClick={handleIsOpenToggle} {...props} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer}/>
        <WrappedComponent {...props} />
      </main>
    </GridRoot>
  );
};

export const AppWithHeader = ({ children }: IProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(true);
  const handleIsOpenToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <GridRoot>
      <SideBar 
        isOpen={isOpen} 
        minWidth={drawerMinWidth} 
        maxWidth={drawerMaxWidth}
        onIconToggle={handleIsOpenToggle}
      />
      <Header isSidebarOpen={isOpen} onMobileMenuClick={handleIsOpenToggle} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer}/>
        {children}
      </main>
    </GridRoot>
  );
}

export default withHeader;
