const PROSPECT_ID = 1;
const ARCHIVE_ID = 13;

export interface IData {
  audienceID: number
  [key: string]: any
}

interface IGroupedData {
  [key: string]: IData[]
}

export interface ITotalRelationshipData {
  relationship: string
  [key: string]: number | string
}

export const useTotalRelationshipData = () => {
  const getTotalRelationshipData = (data: IData[], dataKey: string) => {
    const groupedData: IGroupedData = {
      prospect: [],
      active: [],
      archive: []
    }
  
    for (const audienceData of data) {
      if (!audienceData.hasOwnProperty(dataKey)) {
        continue
      }
  
      if (audienceData.audienceID === PROSPECT_ID) {
        groupedData.prospect.push(audienceData)
      } else if (audienceData.audienceID === ARCHIVE_ID) {
        groupedData.archive.push(audienceData)
      } else {
        groupedData.active.push(audienceData)
      }
    }
  
    const prospectTotal = groupedData.prospect.reduce(
      (runningTotal: number, dataPoint: IData) => runningTotal + dataPoint[dataKey],
      0
    );
  
    const activeTotal = groupedData.active.reduce(
      (runningTotal: number, dataPoint: IData) => runningTotal + dataPoint[dataKey],
      0
    );
  
    const archiveTotal = groupedData.archive.reduce(
      (runningTotal: number, dataPoint: IData) => runningTotal + dataPoint[dataKey],
      0
    );
  
    return [
      { relationship: "Prospect", [dataKey]: prospectTotal },
      { relationship: "Active", [dataKey]: activeTotal },
      { relationship: "Archive", [dataKey]: archiveTotal },
    ]
  }

  return getTotalRelationshipData
}