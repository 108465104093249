import * as React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
min-width: 40px;
min-height: 40px;
width: 2.5rem;
height: 2.5rem;
border: solid 0.0625rem ${props => props.color || '#ff7285'};
border-radius: 50%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`;

interface IProps {
  color?: string,
}

export class  LockOpenIcon extends React.Component<IProps>{
  public render() {
    const color = this.props.color || '#ff7285';
    return (
      <Circle color={color}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
          <path data-name="Lock Open Icon" d="M1,15a.945.945,0,0,0,1,1H14a.945.945,0,0,0,1-1V6a.945.945,0,0,0-1-1H6V4A2.006,2.006,0,0,1,8,2a1.8,1.8,0,0,1,1.5.7l.7.7,1.5-1.3L11,1.3A4.335,4.335,0,0,0,8,0,4.012,4.012,0,0,0,4,4V5H2A.945.945,0,0,0,1,6ZM8,8a2.006,2.006,0,0,1,2,2,1.936,1.936,0,0,1-1,1.7V13H7V11.7A1.936,1.936,0,0,1,6,10,2.006,2.006,0,0,1,8,8Z" transform="translate(-1)" fill={color}/>
        </svg>
      </Circle>
    );
  }
}

export default LockOpenIcon;
