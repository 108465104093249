import React, { useMemo } from 'react';
import { Box } from '@material-ui/core'
import { InputLabel } from '../../../components/Text';
import { InputText } from '../../../components/Form';
import { getRequiredInfoFields, IOtherIntegrationInfo } from './integration-options'; 

interface IProps {
  platform: string
  selectedIntegrationTypes: string[]
  setValue: (val: any) => void
  values: {[key: string]: string}
}

export const OtherIntegrationFields = ({ platform, selectedIntegrationTypes, setValue, values }: IProps) => {
  const fields = useMemo(() => {
    let requiredFields: IOtherIntegrationInfo[] = [];

    for (const type of selectedIntegrationTypes) {
      const typeFields = getRequiredInfoFields(platform, type)
      requiredFields = [...requiredFields, ...typeFields]
    }

    return requiredFields;
  }, [platform, selectedIntegrationTypes])


  const getInformation = (key: string) => {
    if (!values.hasOwnProperty(key)) { return '' }

    return values[key]
  }

  if (!fields.length) {
    return null
  }

  return (
    <>
      <Box mb="12px" mt="24px">
        <InputLabel>Add required information</InputLabel>
      </Box>
      {
        fields.map(({ key, label }) => {
          const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setValue({...values, [key]: event.target.value})
          }

          const info = getInformation(key)

          return (
            <Box mb="12px" width="240px" key={key}>
              <InputText
                type="text"
                variant="outlined"
                label={label}
                margin="dense"
                value={info}
                fullWidth={true}
                name="name"
                onChange={handleChange}
              />
            </Box>
          )
        })
      }
    </>
  )
}