import * as React from 'react';

export const LoyPalIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 50 50">
      <g transform="translate(0 0)">
        <g>
          <path d="M370.407,213.934V228.22H352.55v-50h25q21.429,0,21.429,17.857T377.55,213.934Z" transform="translate(-352.55 -178.22)" fill="#dcdadb"/>
        </g>
      </g>
      <g transform="translate(0 0)">
        <path d="M402.55,228.22h-50v-50" transform="translate(-352.55 -178.22)" fill="#9a9c9b"/>
      </g>
      <path d="M377.55,213.934a34.9,34.9,0,0,0,9.547-1.168L352.55,178.22v50h17.857V213.934Z" transform="translate(-352.55 -178.22)" fill="#ffffff"/>
    </svg>
  );
};

export default LoyPalIcon;
