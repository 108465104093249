import * as React from 'react';
import { renderToString } from 'react-dom/server'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { CircularLoading } from '../CircularLoading'

const Title = styled.div`
  color: ${theme.colorDarkGray};
  font-size: 1.125rem;
`;

const DEFAULT_NUM_Y_AXIS_TICKS = 5
const DEFAULT_HEIGHT = 352

export interface IMultiTypeProps {
  title?: string;
  data: any[];
  categories: string[];
  colors?: string[];
  shouldShowDataLabels?: boolean
  isLoading?: boolean
  yAxisTickAmount?: number
  height?: string | number
  yAxisFloor?: number
  yAxisCeiling?: number
  tooltipOpts?: any,
}

export const MultiTypeChart = ({ 
  title, 
  categories, 
  data, 
  yAxisTickAmount = DEFAULT_NUM_Y_AXIS_TICKS, 
  shouldShowDataLabels = true, 
  isLoading = false,
  height = DEFAULT_HEIGHT,
  yAxisFloor = 0,
  yAxisCeiling,
  tooltipOpts,
  ...props
}: IMultiTypeProps) => {
  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  });

  const options: Highcharts.Options = {
    title: {
      text: title ? renderToString(<Title>{title}</Title>) : undefined,
      align: 'left',
      verticalAlign: 'top',
      x: 10,
      y: 21,
    },
    xAxis: {
      categories: isLoading ? Array(categories.length).fill('') : categories,
    },
    yAxis: {
      title: {
        text: null,
      },
      tickAmount: yAxisTickAmount,
      ceiling: yAxisCeiling,
      softMax: yAxisCeiling,
      floor: yAxisFloor,
      softMin: yAxisFloor,
    },
    chart: {
      animation: false,
      height,
      style: {
        fontFamily: theme.ffInterRegular
      },
    },
    credits: {
      enabled: false
    },
    legend: {
      align: 'left',
      layout: 'horizontal',
      verticalAlign: 'bottom'
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      },
      line: {
        marker: {
          enabled: false
        },
        dataLabels: {
          allowOverlap: true,
          enabled: shouldShowDataLabels
        }
      }
    },
    series: data,
  }

  if (tooltipOpts) {
    options.tooltip = tooltipOpts
  }

  return (
    <Container>
      {isLoading && (
        <LoadingContainer>
          <CircularLoading />
        </LoadingContainer>
      )}
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        updateArgs={[true, true, true]}
      />
    </Container>
  )
}

export default MultiTypeChart;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 5;
`