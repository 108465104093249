import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { variant } from 'styled-system';
import theme from '../../assets/css/theme';

interface IProps {
  title: string,
  description?: string
  value: any
  handleOnClick: (val: any) => void
  isSelected?: boolean
}

interface IContainerProps {
  isSelected: boolean
}

export const OptionBox = ({ title, description, value, handleOnClick, isSelected = false }: IProps) => {
  
  const setValue = () => {
    handleOnClick(value)
  }
  
  return (
    <Container onClick={setValue} isSelected={isSelected}>
      { isSelected && <StyledCheckIcon />}
      <Box margin="16px">
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Box>
    </Container>
  )
}

const Container = styled.div<IContainerProps>`
  position: relative;
  border-radius: 12px;
  border: solid 1px ${theme.colorMediumGray};
  cursor: pointer;
  margin: 4px;
  display: flex;
  justify-content: center;
  flex: 0 1 98%;

  :hover {
    box-shadow: ${theme.boxShadowDarker};
    background-color: ${theme.colorWhite};
  }

  ${() => variant({
    prop: 'isSelected',
    variants: {
      true: {
        backgroundColor: theme.colorWhite,
        border: `solid 1px ${theme.colorRed}`,
      },
    }
  })}

  @media ${theme.media.mobileL} {
    flex: 0 1 48%;
  }

  @media ${theme.media.tablet} {
    flex: 0 1 30%;
  }

  @media ${theme.media.laptopM} {
    flex: 0 1 22%;
  }
`

const Title = styled.p`
  margin: 0px;
  font-size: 0.975rem;
  font-weight: 500;
  text-align: center;
`

const Description = styled.p`
  margin-bottom: 4px;
  text-align: center;
`

const StyledCheckIcon = styled(CheckIcon)`
  color: ${theme.colorRed};
  font-size: 20px;
  position: absolute;
  right: 8px;
  top: 8px;
`