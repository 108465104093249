import * as React from 'react';
import { renderToString } from 'react-dom/server'
import Highcharts, {
  ColorString,
  GradientColorStopObject,
  LinearGradientColorObject,
  SeriesAreasplineOptions,
} from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { IChartPoint } from './types';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { ICampaignsPerformanceSeries } from '../../modules/types';
import { map } from 'lodash';
import merge from 'lodash/merge';
import chroma from 'chroma-js';

const Container = styled.div`
  background-color: ${theme.colorWhite};
  box-shadow: ${theme.boxShadow};
`;

const LegendItem = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.titleColor}

  @media ${theme.media.mobileL} {
    flex-direction: row;
    min-width: 140px;
  }
`;

const LegendItemTitle = styled.div`
  font-size: 0.8125rem;
  align-self: flex-start;
  :before {
    display: inline-flex;
    content: "";
    margin-right: 10px;
    height: 8px;
    width: 18px;
    border-radius: 8px;
    background-color: ${(props: { symbolColor: string }) => props.symbolColor || ''};
    align-self: flex-start;
  }
`;

const Title = styled.div`
  color: ${theme.colorDarkGray};
  font-size: 1.125rem;
`;

const Tooltip = styled.div``;

const START_GRADIENT = 1;
const HALF_GRADIENT = 0.3;
const FINISH_GRADIENT = 0;
const X_AXIS_OFFSET = 0.5;

const defaultColors = [
  theme.colorMelrose,
  theme.colorTurquoise,
  theme.colorLightOrange,
  theme.colorBlue,
  theme.colorRed,
];

interface ISeries extends SeriesAreasplineOptions {
  color?: string;
  fillColor?: {
    linearGradient: LinearGradientColorObject,
    stops: GradientColorStopObject[]
  };
}

interface IState {
  options: Highcharts.Options,
}

interface IProps {
  title: string;
  data: ICampaignsPerformanceSeries[];
  categories: string[];
  colors?: string[];
}

export class CurveChart extends React.Component<IProps, IState> {
  public state: IState = {
    options: {
      title: {
        text: renderToString(<Title>{this.props.title}</Title>),
        align: 'left',
        verticalAlign: 'top',
        x: 10,
        y: 21,
      },
      xAxis: {
        categories: this.props.categories,
        min: X_AXIS_OFFSET,
        max: this.props.categories.length - (1 + X_AXIS_OFFSET),
        gridLineWidth: 1,
        gridLineColor: '#f0f4f7',
        lineColor: 'transparent',
        offset: -35,
      },
      yAxis: {
        title: {
          text: null,
        },
      },
      chart: {
        type: 'areaspline',
        animation: false,
        height: 352,
        style: {
          fontFamily: theme.ffInterRegular
        },
      },
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormatter() {
          const {y} = this as IChartPoint;
          return renderToString(<Tooltip>{y}</Tooltip>)
        }
      },
      legend: {
        symbolPadding: 0,
        symbolWidth: 0,
        symbolRadius: 0,
        useHTML: true,
        align: 'left',
        layout: 'horizontal',
        verticalAlign: 'bottom',
        labelFormatter() {
          const {name, color, legendItem} = this as IChartPoint;
          if (legendItem.symbol) {
            legendItem.symbol.element.style.display = 'none';
          }

          return renderToString(<LegendItem>
            <LegendItemTitle symbolColor={color as string}>{name}</LegendItemTitle>
          </LegendItem>)
        }
      },
      plotOptions: {
        areaspline: {
          marker: {
            symbol: 'circle',
            lineWidth: 2,
            fillColor: 'white',
            lineColor: undefined,
            radius: 5
          },
          lineWidth: 2,
          states: {
            hover: {
              lineWidth: 2
            }
          },
          threshold: 0,
        }
      },
      series: this.prepareSeries(this.props.data),
      responsive: {
        rules: [{
          condition: {
            maxWidth: 570
          },
          chartOptions: {
            legend: {
              align: 'left',
              verticalAlign: 'bottom',
              layout: 'horizontal',
              x: 0,
            },
            title: {
              align: 'center',
            },
            chart: {
              height: 600,
              spacingLeft: 0,
            },
          }
        }]
      }
    }
  };

  public render() {
    return <Container>
      <HighchartsReact
        highcharts={Highcharts}
        options={this.state.options}
        updateArgs={[true, true, true]}
      />
    </Container>;
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (prevProps.categories !== this.props.categories || prevProps.data !== this.props.data) {
      this.updateSeries();
    }
  }

  private updateSeries() {
    this.setState({
      options: {
        title: {
          text: renderToString(<Title>{this.props.title}</Title>),
        },
        series: this.prepareSeries(this.props.data),
        xAxis: {
          categories: this.props.categories,
        },
      }
    })
  }

  private getAlphaColor(color: string, alpha: number): ColorString {
    return chroma(color).alpha(alpha).css();
  }

  private prepareSeries( data : ICampaignsPerformanceSeries[]): ISeries[] {
    const HALF_STOP = 0.5;
    const colors = (this.props.colors || []).concat(defaultColors);
    return map(data, (series, index): ISeries => {
      const color = colors[index];
      return merge(series, {
        type: 'areaspline' as 'areaspline',
        color,
        fillColor: {
          linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 0.8,
          },
          stops: [
            [0 , this.getAlphaColor(color, START_GRADIENT)] as GradientColorStopObject,
            [HALF_STOP , this.getAlphaColor(color, HALF_GRADIENT)] as GradientColorStopObject,
            [1 , this.getAlphaColor(color, FINISH_GRADIENT)] as GradientColorStopObject,
          ],
        },
      })
    });
  }
}

export default CurveChart;
