import { createAction } from 'redux-act';
import {
  IUserModel,
  ISegmentRequestApi,
  IUserMeta,
  IUserProfile,
  IUserLoginMeta,
  IUserForgotMeta,
  IUserConfirmForgotMeta,
} from '../../types';
import { IPassword } from '../../../pages/MyProfile/AccountChangePassword';

// Cognito actions
export const clientNameRequest = createAction<IUserMeta>('Put Client Name To Store');
export const clientNameSuccess = createAction<IUserMeta>('Put Client Name To Store was successfully');
export const clientNameFailed = createAction<string>('Put Client Name To Store failed');

// Saving bearer token to local store
export const userLogin = createAction<IUserLoginMeta>('User login');
export const userLoginSuccess = createAction<IUserModel>('User Log in Event Success');
export const userLoginFailed = createAction<string>('User Log in Event Failed');
export const goToEditClientName = createAction('User wants to change the client name');

// Sending request to restore user's password
export const userForgotPassword = createAction<IUserForgotMeta>('Request User Restore Password Message');
export const userForgotPasswordSuccess = createAction<IUserForgotMeta>('Request User Restore Password Message Sent Successfully');
export const userForgotPasswordFailed = createAction<string>('Request User Restore Password Failed');
export const userConfirmForgotPassword = createAction<IUserConfirmForgotMeta>('Confirm User Restore Password');
export const userConfirmForgotPasswordSuccess = createAction('Confirm User Restore Password Successfully');
export const userConfirmForgotPasswordFailed = createAction<string>('Confirm User Restore Password Failed');

// Logging out actions - aren't implemented yet
export const userLogout = createAction('User Log out Event');

// Exporting actions
export const exportCustomersRequest = createAction<ISegmentRequestApi>('Download customers export file');
export const exportCustomersSuccess = createAction('Exported file downloaded successfully');
export const exportCustomersFailed = createAction<string>('Exported file downloading failed');

// Client profile actions
export const userProfileRequest = createAction('Fetch client profile data');
export const userProfileSuccess = createAction<IUserProfile>('Client profile data fetched successfully');
export const userProfileFailed = createAction<string>('Client profile data fetching failed');

// Updating client profile actions
export const userUpdateProfileRequest = createAction<Omit<IUserProfile, 'picture'>>('Update client profile data');
export const userUpdateProfileSuccess = createAction<IUserProfile>('Client profile data updated successfully');
export const userUpdateProfileFailed = createAction<string>('Client profile data updating failed');

// Updating client current password actions
export const userUpdatePasswordRequest = createAction<Omit<IPassword, 'confirm_password'>>('Update client current password');
export const userUpdatePasswordSuccess = createAction('Client current password updated successfully');
export const userUpdatePasswordFailed = createAction<string>('Client current password updating failed');

// Updating client profile picture
export const userUpdateProfilePictureRequest = createAction<Pick<IUserProfile, 'picture'>>('Update client profile picture');
export const userUpdateProfilePictureSuccess = createAction<string>('Client profile picture updated successfully');
export const userUpdateProfilePictureFailed = createAction<string>('Client profile picture updating failed');
