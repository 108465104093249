import * as React from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import PopupTip from '../PopupTip';
import InfoIcon from '@material-ui/icons/Info';

interface IProp {
  icon?: any,
  title: string,
  subtitle?: string
}

interface IState {
  anchorEl: any,
  }
  
const Title = styled.div`
font-family: ${theme.ffInterRegular};
font-size: 1.125rem;
line-height: 1.39;
color: ${theme.titleColor};
`;

const TitleWrapper = styled.div`
padding-bottom: 0.4375rem;
display: flex;
justify-content: space-between;
`;

export class CampaignBoxTitle extends React.Component<IProp, IState>{
  constructor(props: IProp) {
    super(props);
    this.state = {
      anchorEl : null,  
    };
  }
  
  public openPopupTip = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
     this.setState({anchorEl: event.currentTarget})  
  };

  public closePopupTip = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({anchorEl: event.currentTarget})  
 };

  public togglePopupTip = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    const newAnchorEl = this.state.anchorEl ? null : event.currentTarget;
    this.setState({anchorEl: newAnchorEl})   
  };

  public render() {
    const open = Boolean(this.state.anchorEl);
    return (
    <TitleWrapper>
        <Title>{this.props.title}</Title>
        {this.props.subtitle && <div onClick={this.togglePopupTip }><InfoIcon/></div>} 
        <PopupTip 
        description={this.props.subtitle} 
        open={open} 
        anchorEl={this.state.anchorEl} 
        closePopupTip={this.togglePopupTip}
      />
    </TitleWrapper>
    );
  }
}
export default CampaignBoxTitle;
