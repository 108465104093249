import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { IStoreState, IContactabilityFilter } from '../../../modules/types';
import { useClientCustomization, useFeatureFlag } from '../../../utils/hooks';
import { Checkbox } from '../../../components/Form';
import theme from '../../../assets/css/theme';

export const EMAIL_FILTER = 'EMAIL'
interface IProps {
  contactabilityFiltersApplied: IContactabilityFilter[]
  handleOnChangeContactabilityFilter(val: IContactabilityFilter[]): void
}
 
export const ContactabilityFilter = (
  { contactabilityFiltersApplied, handleOnChangeContactabilityFilter }: IProps
) => {
  const { clientName } = useClientCustomization()
  const hasContactabilityFilteringEnabled = useFeatureFlag("campaignContactabilityFiltering", clientName);
  const contactabilityFiltersAvailable = useSelector((state: IStoreState) => {
    return state.campaign.contactability_filters
  })
  
  const contactabilityEmailFilter = contactabilityFiltersAvailable.find(filter => filter.channel === EMAIL_FILTER)
  const isFilteringByEmailContactability = (
    !!contactabilityEmailFilter 
    && contactabilityFiltersApplied.some((filter) => filter.source === contactabilityEmailFilter.source && filter.channel === contactabilityEmailFilter.channel)
  )

  // if the client has contactability filter turned on, 
  // we should be filtering by email subscribers by default
  useEffect(() => {
    if (hasContactabilityFilteringEnabled && !isFilteringByEmailContactability && contactabilityEmailFilter) {
      handleOnChangeContactabilityFilter([contactabilityEmailFilter])
    }
    // eslint-disable-next-line
  }, [contactabilityEmailFilter])

  const handleOnSetContactabilityFilter = () => {
    if (isFilteringByEmailContactability) {
      handleOnChangeContactabilityFilter([])
    } else if (contactabilityEmailFilter) {
      handleOnChangeContactabilityFilter([contactabilityEmailFilter])
    }
  }

  if (!hasContactabilityFilteringEnabled || !contactabilityEmailFilter) { return null }

  // only allow filtering via email contactability at this point
  return (
    <Container>
      <CheckboxContainer>
        <StyledFormControlLabel
          control={
            <Checkbox 
            checked={!isFilteringByEmailContactability}
            onChange={handleOnSetContactabilityFilter}
            />
          }
          label="Include all people regardless of consent for email"
        />
      </CheckboxContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;

  @media ${theme.media.tablet} {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  &&& {
    align-items: flex-start;
    margin-right: 0px;
    margin-left: -7px;
  }

  &&& > span.MuiTypography-root {
    font-size: 14px;
    margin-left: 4px;
    margin-top: 4px;
  }
`