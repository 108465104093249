import * as React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { variant } from 'styled-system'
import theme from '../../assets/css/theme';
import {
  BoxTitle,
  Button,
  SizeValue,
} from '../../components/BoxComponents';
import { GRID_SIZE_12 } from '../../config';
import { IAudienceCard, IStoreState } from '../../modules/types';
import { useAudience } from '../../utils/hooks';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import PersonPinCircleOutlinedIcon from '@material-ui/icons/PersonPinCircleOutlined';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

import {
  CardContainer,
  CardColumn,

} from './CommonLifeCyceAndLoadingAudience';

export interface IProps extends IAudienceCard {
  segment_id: number | string;
  is_data_requested?: boolean;
}

interface IMetricProps {
  value: string;
  title: string;
  greyedOut?: boolean;
  icon: React.ReactElement;
}

interface IValueProps {
  greyedOut: boolean;
}

const LifecycleAudienceCard = (props: IProps) => {
  const { id, volume, targeted, available, untargeted_opportunity, segment_id, total_marketable, total_marketable_avail } = props;
  const { name, description} = useAudience(id)
  const campaignCreatedStatuses = useSelector((state: IStoreState) => {
    return state.campaign.campaign_creation_statuses
  })
  const isCreatingACampaign = !!campaignCreatedStatuses[id - 1]

  return (
    <CardContainer container={true}>
      <CardColumn item={true} xs={GRID_SIZE_12}>
        <TitleContainer item={true} xs={GRID_SIZE_12}>
          <BoxTitle title={name} subtitle={description}/>
          <ButtonContainer>
            <CardButton to={`/segments/${segment_id}/audiences/${id}`}>Activate</CardButton>
          </ButtonContainer>
        </TitleContainer>
      </CardColumn>
      <CustomerSizeCardColumn>
        <PopulationContainer>
          <PopulationTitle>Valued active customers.</PopulationTitle>
          <Population value={volume}/>
        </PopulationContainer>
       { total_marketable !== null && (
         <PopulationContainer>
          <PopulationTitle>Marketable customers.</PopulationTitle>
          <Population value={total_marketable}/>
        </PopulationContainer>
       )}
      </CustomerSizeCardColumn>
      <MetricsContainer>
        <Metric value={`${targeted.toLocaleString('en')}`} title="In Campaign"
          greyedOut={true} icon={<TrackChangesIcon />} />
        <StackedMetric>
          { total_marketable_avail !== null && !isCreatingACampaign && (
            <Metric value={`${total_marketable_avail.toLocaleString('en')}`}
              title="Marketable Customers" icon={<PersonPinCircleOutlinedIcon />}/>
          )}
          <Metric value={`${available.toLocaleString('en')}`}
            title="Available Customers" icon={<PersonOutlinedIcon />}/>
        </StackedMetric>
          <Metric value={`${untargeted_opportunity}`}
            title="Revenue Opportunity" icon={<MonetizationOnOutlinedIcon />}/>
      </MetricsContainer>
    </CardContainer>
  )
}

const Metric = ({ value, title, greyedOut = false, icon }: IMetricProps) => (
  <OpportunityCardColumn>
    <ValueWrapper>
      {icon}
      <PropValue>
        <Value greyedOut={greyedOut}>{value}</Value>
        <Label>{title}</Label>
      </PropValue>
    </ValueWrapper>
  </OpportunityCardColumn>
)

const TitleContainer = styled(Grid)`
  position: relative;
`

const ButtonContainer = styled.div`
  display: flex;
  min-width: 150px;
  position: absolute;
  top: 0;
  right: 0;
`

const CardButton = styled(Button)`
  && {
    width: 100%;
    height: 40px;
    background-color: ${theme.colorRed};
  }
`

const MetricsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-grow: 1;

  @media ${theme.media.laptopLLTE} {
    justify-content: center;
  }
`

const Label = styled.div`
  font-family: ${theme.ffInterRegular};
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  color: ${theme.colorDarkGray};
`

const Value = styled.div<IValueProps>`
  font-family: ${theme.ffInterRegular};
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  ${() => variant({
    prop: 'greyedOut',
    variants: {
      true: {
        color: theme.colorMediumGray,
      },
      false: {
        color: theme.colorDarkGray,
      },
    }
  })}
`

const StackedMetric = styled.div`
  @media ${theme.media.laptopLLTE} {
    order: 3;
    display: flex;
  }
`

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
`

const PropValue = styled.div`
  padding-left: 16px;
  margin-bottom: 16px;
`

const OpportunityCardColumn = styled.div`
  margin: 0px 12px;
`

const CustomerSizeCardColumn = styled(CardColumn)`
  && {
    display: flex;
  }

  && > &:first-child {
    padding-right: 32px;
  }

  @media ${theme.media.laptopLLTE} {
    justify-content: center;
    width: 100%;
    margin-bottom: 16px;
  }
`

const PopulationContainer = styled.div`
  margin-right: 28px;
  margin-bottom: 16px;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
`

const PopulationTitle = styled.div`
  font-size: 14px;
  color: ${theme.colorMediumGray};
  margin-bottom: 8px;
`

const Population = styled(SizeValue)`
  font-size: 2rem;
`

export default LifecycleAudienceCard;
