import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { PLATFORM_CREDENTIALS } from '@loypal/lib-growth';
import { toggleIntegrationsRequest, setIntegrationCredentialsRequest } from '../../../modules/actions';
import { IIntegrationCredential } from '../../../modules/types';
import { withHeader } from '../../../components';
import { PageTitle, InputLabel } from '../../../components/Text';
import ContainerStyled from '../../../components/ContainerStyled';
import { BackLink } from '../../../components/Navigation';
import { Select } from '../../../components/Form';
import { SubmitButton } from '../../../components/BoxComponents/Button'
import { useIntegrationOptions, IIntegrationCreds } from './integration-options'; 
import { CredentialsInputs } from './CredentialsInputs';
import { IntegrationTypeInput } from './IntegrationTypeInput';
import { OtherIntegrationFields } from './OtherIntegrationFields';

interface IIntegrationFlags {
  type: string,
  isActive: boolean
}
const IntegrationsForm = () => {
  const platformOptions = useIntegrationOptions();
  const [platform, setPlatform] = useState('');
  const [integrationFlags, setIntegrationFlags] = useState<IIntegrationFlags[]>([]);
  const [integrationCreds, setIntegrationCreds] = useState<IIntegrationCredential>({});
  const [otherIntegrationInfo, setOtherIntegrationInfo] = useState({});
  const dispatch = useDispatch();

  const handleToggleIntegrationFlag = (type: string) => {
    const existingFilterIndex = integrationFlags.findIndex(flag => flag.type === type);

    if (existingFilterIndex !== -1) {
      const newIntegrationFlags = integrationFlags.filter(flag => flag.type !== type)
      setIntegrationFlags([
        ...newIntegrationFlags,
        { type, isActive: !integrationFlags[existingFilterIndex].isActive}
      ])
    } else {
      setIntegrationFlags([
        ...integrationFlags,
        { type, isActive: true}
      ])
    }
  }

  const handleIntegrationChange = (event: any) => {
    const value = event.target.value
    if (value) {
      setPlatform(value)
    }
  }

  const handleOnSubmit = async () => {
    const flags = integrationFlags.map(integrationFlag => ({ 
      platform, 
      type: integrationFlag.type, 
      is_active: integrationFlag.isActive
    }))
    dispatch(toggleIntegrationsRequest({ flags }))
    dispatch(setIntegrationCredentialsRequest({ platform, credentials: integrationCreds }))
  }
  
  const isFormValid = useMemo(() => {
    if (!platform || !integrationFlags) { return false; }

    const requiredCredentials: IIntegrationCreds[] = PLATFORM_CREDENTIALS[platform];
    const credentialKeys = requiredCredentials.map(({ key }) => key);

    for (const key of credentialKeys) {
      if (!integrationCreds.hasOwnProperty(key)) { return false }
      if (!integrationCreds[key]) { return false }
    }

    return true
  }, [ platform, integrationFlags, integrationCreds])

  const activeIntegrationFlags = useMemo(() => {
    const activeFlags: string[] = [];
    integrationFlags.forEach(flag => {
      if (flag.isActive) {
        activeFlags.push(flag.type)
      }
    })
    return activeFlags
  }, [integrationFlags])

  return (
    <ContainerStyled maxWidth="xl">
      <BackLink />
      <PageTitle>Add an Integration</PageTitle>
      <Box mb="12px">
        <InputLabel>Select a platform</InputLabel>
      </Box>
      <SelectContainer>
        <Select 
          options={platformOptions} 
          value={platform} 
          onChange={handleIntegrationChange}
        />
      </SelectContainer>
      { platform && (
        <>
         <CredentialsInputs platform={platform} setValue={setIntegrationCreds} values={integrationCreds} /> 
          <IntegrationTypeInput 
            platform={platform} 
            setValue={handleToggleIntegrationFlag} 
            selectedIntegrationTypes={activeIntegrationFlags}
          />
          <OtherIntegrationFields 
            platform={platform}
            selectedIntegrationTypes={activeIntegrationFlags}
            setValue={setOtherIntegrationInfo}
            values={otherIntegrationInfo}
          />
          <Box mt="24px">
            <SubmitButton 
              onClick={handleOnSubmit} 
              className={[
                !isFormValid && 'isDisabled'
              ]}
            >
              Save
            </SubmitButton>
          </Box>
        </>
      )}
    </ContainerStyled>
  )
}



export default withHeader(IntegrationsForm);

const SelectContainer = styled.div`
  width: 300px;
`