import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Route, RouteProps as RoutePropsTypes } from "react-router-dom";
import { isLoggedIn } from "../../modules/selectors"
import { IStoreState } from "../../modules/types";
import { isUserTokenExpired } from '../../modules/utils/user';
import { userLogout } from '../../modules/actions';

interface IModelState {
  readonly is_authenticated?: boolean;
  userLogout: typeof userLogout;
}

type Props = RoutePropsTypes & IModelState;

const PrivateRouteComponent = ({is_authenticated, ...rest}: Props) => {
  const { userLogout: logout } = rest;

  if (isUserTokenExpired()) {
    logout();
  }

  return is_authenticated ? <Route {...rest} /> : <Redirect to="/login"/>;
};

const mapStateToProps = (state: IStoreState) => ({
  is_authenticated: isLoggedIn(state),
});

const mapDispatchToProps = {
  userLogout,
};

export const PrivateRoute = connect(mapStateToProps, mapDispatchToProps)(PrivateRouteComponent);
