import * as React from 'react';
import { ColumnChart } from '../../components/Charts';

interface IProps {
  testGroupValue: number;
  controlGroupValue: number;
}

const AverageTransactionValue = ({ testGroupValue = 0, controlGroupValue = 0 }: IProps) => {
  
  const data = [
    {
      name: 'Test group', 
      y: testGroupValue, 
      color: '#7cb5ec'
    }, 
    {
      name: 'Control group', 
      y: controlGroupValue, 
      color: '#7cb5ec'
    }
  ];

  return (
    <ColumnChart
      title={`Average Transaction Value`}
      data={data}
    />
  )
}

export default AverageTransactionValue;