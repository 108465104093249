import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import random from 'lodash/random';
import { useLocation, useParams } from 'react-router-dom';
import WarningIcon from '@material-ui/icons/Warning';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import theme from '../../assets/css/theme';
import {
  IAudienceCard,
  ICampaignCreate,
  IStoreState,
  AppDispatch,
  IContactabilityFilter,
} from '../../modules/types';
import ContainerStyled from '../../components/ContainerStyled';
import { PageTitle } from '../../components/Text';
import AudienceCard from './AudienceCard';
import {
  campaignCreationStatusesRequest,
  contactabilityFiltersRequest,
  campaignCreateRequest,
  audiencesSummaryCardRequest,
} from '../../modules/actions';
import { useAudience } from '../../utils/hooks';
import { CampaignForm } from './CampaignForm';
import AudienceSummaryCard from './AudienceSummaryCard';
import {
  getNotificationInfoRequest,
} from '../../modules/actions';
import { getFilters } from './utils';

interface IInnerProps {
  audience_summary: IAudienceCard;
  is_data_requested?: boolean;
}

const RAND_MIN = 1
const RAND_MAX = 100
const CONTROL_10_PERC = 10
const CONTROL_40_PERC = 40
const CONTROL_50_PERC = 50
const MEDIUM_POPULATION_THRESHOLD = 2500
const SML_POPULATION_THRESHOLD = 625
const SML_POPULATION = 'SML'
const MED_POPULATION = 'MED'
const LRG_POPULATION = 'LRG'

const fetchNotificationData = (
  audienceID: string,
  holdOutGroups: number[],
  search: string,
  dispatchCallback: AppDispatch,
  contactabilityFiltersAvailable: IContactabilityFilter[],
) => {
  const {
    exclude_stores,
    contactability_filters,
    udf_filters,
  } = getFilters(search, contactabilityFiltersAvailable);

  dispatchCallback(getNotificationInfoRequest({
    audience_id: audienceID,
    hold_out_groups: holdOutGroups,
    exclude_stores,
    contactability_filters,
    udf_filters,
  }))
}


export const CampaignCreationPage = ({ audience_summary,  is_data_requested }: IInnerProps) => {
  const [generateDaysRadioButtonPosition, setGenerateDaysRadioButtonPosition] = useState('0');

  const { search } = useLocation()
  const dispatch = useDispatch()
  const { audience_id: audienceID, segment_id: segmentID } = useParams<any>()

  useEffect(() => {
    dispatch(audiencesSummaryCardRequest({audience_id: audienceID}))
    // eslint-disable-next-line
  }, [])

  const contactabilityFiltersAvailable: IContactabilityFilter[] = useSelector((state: IStoreState) => {
    return state.campaign.contactability_filters
  })
  const campaignCreatedStatuses = useSelector((state: IStoreState) => {
    return state.campaign.campaign_creation_statuses
  })

  const created_campaign = useSelector((state: IStoreState) => state.audience.created_campaign)
  const campaignNameError = useSelector((state: IStoreState) => state.audience.campaignNameError)
  const campaignCreatingError = useSelector((state: IStoreState) => state.audience.campaignCreatingError)
  const campaignReDownloadError = useSelector((state: IStoreState) => state.audience.campaignReDownloadError)

  const populationSize = useRef(LRG_POPULATION)
  const holdOutGroups = useRef([...Array.from(Array(CONTROL_50_PERC)).map(() => random(RAND_MIN, RAND_MAX))])

  const handleSetSmlPopulation = () => {
    if (populationSize.current !== SML_POPULATION) {
      populationSize.current = SML_POPULATION
      holdOutGroups.current = Array.from(Array(CONTROL_50_PERC)).map(() => random(RAND_MIN, RAND_MAX))
      fetchNotificationData(audienceID, holdOutGroups.current, search, dispatch, contactabilityFiltersAvailable)
    }
  }

  const handleSetMedPopulation = () => {
    if (populationSize.current !== MED_POPULATION) {
      populationSize.current = MED_POPULATION
      holdOutGroups.current = Array.from(Array(CONTROL_40_PERC)).map(() => random(RAND_MIN, RAND_MAX))
      fetchNotificationData(audienceID, holdOutGroups.current, search, dispatch, contactabilityFiltersAvailable)
    }
  }

  const handleSetLrgPopulation = () => {
    if (populationSize.current !== LRG_POPULATION) {
      populationSize.current = LRG_POPULATION
      holdOutGroups.current = Array.from(Array(CONTROL_10_PERC)).map(() => random(RAND_MIN, RAND_MAX))
      fetchNotificationData(audienceID, holdOutGroups.current, search, dispatch, contactabilityFiltersAvailable)
    }
  }

  useEffect(() => {
    if (audience_summary.total_marketable == null) {
      handleSetSmlPopulation();
    } else if (audience_summary.total_marketable < SML_POPULATION_THRESHOLD) {
      handleSetSmlPopulation();
    } else if (audience_summary.total_marketable < MEDIUM_POPULATION_THRESHOLD) {
      handleSetMedPopulation()
    } else if (audience_summary.total_marketable >= MEDIUM_POPULATION_THRESHOLD) {
      handleSetLrgPopulation()
    }

    // eslint-disable-next-line
  }, [audience_summary.total_marketable])

  useEffect(() => {
    if (!is_data_requested || search ) {
      fetchNotificationData(audienceID, holdOutGroups.current, search, dispatch, contactabilityFiltersAvailable)
    }
    // eslint-disable-next-line
  }, [search])

  useEffect(() => {
    if (!is_data_requested) {
      fetchNotificationData(audienceID, holdOutGroups.current, search, dispatch, contactabilityFiltersAvailable)
      dispatch(contactabilityFiltersRequest())
      dispatch(campaignCreationStatusesRequest())
    }
  // eslint-disable-next-line
  }, [])

  const createCampaign = (payload: ICampaignCreate) => {
      const { exclude_stores, contactability_filters, udf_filters, exclusion_dates } = getFilters(search, contactabilityFiltersAvailable)

      dispatch(campaignCreateRequest({
      segment_id: +segmentID,
      audience_id: +audienceID,
      hold_out_groups: holdOutGroups.current,
      exclude_stores,
      generate_days: `${parseInt(generateDaysRadioButtonPosition, 0) - 1}`, // < 0 means create today
      contactability_filters: JSON.stringify(contactability_filters),
      udf_filters: JSON.stringify(udf_filters),
      exclusion_dates,
      ...payload
    }));
  }

  const isCreatingACampaign = !!campaignCreatedStatuses[parseInt(audienceID, 0) - 1];
  const { name } = useAudience(audience_summary.id);
  const hasError = campaignNameError || campaignCreatingError || campaignReDownloadError

  return (
    <ContainerStyled maxWidth="xl">
      <PageTitle>Audience</PageTitle>
      {isCreatingACampaign && (
        <NotificationContainer>
          <StyledInfoIcon />
          Currently creating a campaign, please check back again later.
        </NotificationContainer>
      )}
      <CardContainer>
        <AudienceCard
          {...audience_summary}
        />

        <AudiencesSegmentWrapper>
          <AudienceSummaryCard />
        </AudiencesSegmentWrapper>

        <CampaignFormWrapper>
          <CampaignForm
            created_campaign={created_campaign}
            is_data_requested={is_data_requested}
            onExportClick={createCampaign}
            audience_name={name}
            error={hasError}
            isCreatingACampaign={isCreatingACampaign}
            setRadioButtonPosition={setGenerateDaysRadioButtonPosition}
            radioButtonPosition={generateDaysRadioButtonPosition}
          />
        </CampaignFormWrapper>
      </CardContainer>
    </ContainerStyled>
  )
}

const CardContainer = styled(Grid)`
&& {
  box-shadow: ${theme.boxShadow};
  position: relative;
}
`

const NotificationContainer = styled(CardContainer)`
&& {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${theme.colorInfoBackground};
  margin-bottom: 24px;
  padding: 16px;
}
`

const StyledInfoIcon = styled(WarningIcon)`
&& {
  color: ${theme.colorInfo};
  margin-right: 8px;
}
`

const CampaignFormWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
  background-color: ${theme.colorWhite};
  padding: 20px;
`

const AudiencesSegmentWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: ${theme.colorWhite};
  padding: 20px;
`
