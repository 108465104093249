import * as React from 'react';
import { UserPlusIcon } from '../Icons';
import theme from '../../assets/css/theme';
import styled from 'styled-components';

interface IProps {
  value: number | string,
}

const CampaignLiveSizeValue = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 1.125rem;
text-align: left;
color: ${theme.colorDarkGray};
`;

const Label = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: normal;
font-size: 0.8125rem;
text-align: left;
color: ${theme.colorDarkGray};
`;

const CampaignLiveSizeWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
`;

const CampaignLiveSizeValueWrapper = styled.div`
padding-left: ${(props: ISpaceSizeProp) => props.spaceSize || '1.5rem'};
`;

interface ISpaceSizeProp {
  spaceSize?: string;
}

interface IProps extends ISpaceSizeProp {
  value: number | string;
}

export class CampaignLiveSize extends React.Component<IProps> {
  public render() {
    return (
      <CampaignLiveSizeWrapper>
        <UserPlusIcon />
        <CampaignLiveSizeValueWrapper spaceSize={this.props.spaceSize}>
          <CampaignLiveSizeValue>{this.props.value.toLocaleString('en')}</CampaignLiveSizeValue>
          <Label>Campaigns Live</Label>
        </CampaignLiveSizeValueWrapper>
      </CampaignLiveSizeWrapper>
    );
  }
}

export default CampaignLiveSize;
