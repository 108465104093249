import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../assets/css/theme';

const FooterWrapper = styled.div`
  background-color: ${theme.footerBackground};
  color: ${theme.titleColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 360px;
`;

const FooterMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TermLink = styled(Link)`
  position: relative;
  font-size: 14px;
  margin: 20px 0;
  color: ${theme.titleColor};
  padding-bottom: 10px;
  
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    border-bottom: 1px solid #555555;
    left: 0;
    bottom: 1px;
  }
`;

export const Footer = () => (
    <FooterWrapper>
        <FooterMenuWrapper>
            <TermLink to="/">
                This is a Footer
            </TermLink>
        </FooterMenuWrapper>
    </FooterWrapper>
);

export default Footer;
