import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import { Box } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { withHeader } from '../../components';
import ContainerStyled from '../../components/ContainerStyled';
import format from 'date-fns/format';
import {
  CampaignsIncrementalRevenueCard,
  CampaignsRevenueCard,
  CampaignTopLocationCard,
} from '../../components/BoxComponents';
import { InfoChip } from '../../components/Chip';
import { GRID_SIZE_12, GRID_SIZE_4, GRID_SIZE_6 } from '../../config';
import { PageTitle } from '../../components/Text';
import { useClientCustomization, useAudience } from '../../utils/hooks';
import { Button } from '../../components/BoxComponents';
import { BackLink } from '../../components/Navigation'
import { IStoreState } from '../../modules/types';
import { campaignRequest, activeCampaignDownloadDataRequest, campaignDownloadControlCSVRequest, retargetCampaignRequest } from '../../modules/actions';
import CampaignParticipantDistributionCard from './CampaignParticipantDistributionCard'
import AverageTransactionValue from './AverageTransactionValue';
import { isDataRequested } from '../../modules/selectors';
import LoadingCampaignPage from './LoadingCampaignPage';

const CONVERT_PERC = 100;
const NOTIFICATION_DURATION = 4000;
const NOTIFICATION_ELEVATION = 6;

interface IProps extends RouteComponentProps<{ campaign_id: string }>{}

interface ICampaignCardProps {
  totalRevenue: number;
  incrementalRevenue: number;
  topLocation: string;
  activePersonCount: number;
  controlPersonCount: number;
  testGroupValue: number;
  controlGroupValue: number;
}

interface ICampaignHeader {
  is_creation_complete: boolean;
  start_date: string;
  end_date: string;
  audience_id: number;
}

const CampaignPage = (props: IProps) => {
  const { match: { params: { campaign_id } } } = props;
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const dispatch = useDispatch()
  const { clientName } = useClientCustomization()
  const campaign = useSelector((state: IStoreState) => {
    return state.campaign.campaign
  })
 
  const isLoading = useSelector(isDataRequested);

  useEffect(() => {
    dispatch(campaignRequest({ campaign_id }))
    // eslint-disable-next-line
  }, [campaign_id])

  if (!clientName) { return null; }

  const handleDownloadCSV = () => {
    if (campaign?.id) {
      dispatch(activeCampaignDownloadDataRequest({
        campaign_id: campaign.id
      }))
    }
  }

  const handleDownloadControlCSV = () => {
    if (campaign?.id) {
      dispatch(campaignDownloadControlCSVRequest({
        campaign_id: campaign.id
      }))
    }
  }

  const handleRetarget = () => {
    if (campaign?.id) {
      dispatch(retargetCampaignRequest({
        campaign_id: campaign.id
      }))
      setNotificationOpen(true)
    }
  }

  const handleCloseNotification = () => {
    setNotificationOpen(false)
  }

  if (isLoading === true || !campaign) {
    return <LoadingCampaignPage />
  }

  return (
    <ContainerStyled maxWidth="xl">
      <BackLink />
      <HeaderContainer>
        <CampaignHeader
          audience_id={campaign.audience_id}
          start_date={campaign.start_date}
          end_date={campaign.end_date}
          is_creation_complete={campaign.is_creation_complete}
        />
        <ButtonContainer>
          {campaign.csv_url && <Button onClick={handleRetarget}>Retarget</Button>}
          {campaign.control_csv_url && <Button onClick={handleDownloadControlCSV}>Download Control CSV</Button>}
          {campaign.csv_url && <Button onClick={handleDownloadCSV}>Download Campaign CSV</Button>}
        </ButtonContainer>
      </HeaderContainer>
      <CampaignCardInfo
        totalRevenue={campaign.total_revenue}
        incrementalRevenue={campaign.incremental_revenue}
        topLocation={campaign.top_location}
        activePersonCount={campaign.active_person_count}
        controlPersonCount={campaign.control_person_count}
        controlGroupValue={campaign.avg_control_tx_value}
        testGroupValue={campaign.avg_active_tx_value}
      />
      <Snackbar open={isNotificationOpen} autoHideDuration={NOTIFICATION_DURATION} onClose={handleCloseNotification}>
        <Alert onClose={handleCloseNotification} severity="success">
          Creating not purchased list
        </Alert>
      </Snackbar>
      </ContainerStyled>   
    )
}

const CampaignHeader = ({
  audience_id,
  start_date,
  end_date,
  is_creation_complete,
} : ICampaignHeader) => {
  
  const audience = useAudience(audience_id as number);

  const getFormatedStartDate = () => {
    const formattedStartDate = format(new Date(start_date), "do LLLL yyyy");
    return formattedStartDate;
  }
    
  const getFormatedEndDate = () => {
    const formattedEndDate = format(new Date(end_date), "do LLLL yyyy");
    return formattedEndDate;
  }

  const shouldShowHasNotStarted = is_creation_complete && new Date(start_date) > new Date()

  return (
    <Box display="flex" flexDirection="column">
      <StyledPageTitle>  
      {`${audience.name} ${getFormatedStartDate()} - ${getFormatedEndDate()}`}
      </StyledPageTitle>
      {!is_creation_complete && (
        <ChipContainer>
          <InfoChip
            size="small"
            label="Creation in progress"
          />
        </ChipContainer>
      )}
      {!!shouldShowHasNotStarted && (
        <ChipContainer>
          <InfoChip
            size="small"
            label="Campaign has not started"
          />
        </ChipContainer>
      )}
    </Box>
  )
}

const CampaignCardInfo = ({
  totalRevenue,
  incrementalRevenue,
  topLocation,
  activePersonCount,
  controlPersonCount,
  testGroupValue,
  controlGroupValue

}: ICampaignCardProps) => {

  const displayIncrementalRevenue = incrementalRevenue > 0 ? incrementalRevenue : 0;
  const avgTXValTest = testGroupValue ? Math.round(testGroupValue * CONVERT_PERC) / CONVERT_PERC : 0;
  const avgTXValControl = controlGroupValue ? Math.round(controlGroupValue * CONVERT_PERC) / CONVERT_PERC : 0;

  return (
    <Grid container={true} item={true} spacing={GRID_SIZE_4}>
      <Grid item={true} lg={GRID_SIZE_4} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12}>
        <CampaignsRevenueCard value={totalRevenue} />
      </Grid>
      <Grid item={true} lg={GRID_SIZE_4} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12}>
        <CampaignsIncrementalRevenueCard value={displayIncrementalRevenue}/>
      </Grid>
      <Grid item={true} lg={GRID_SIZE_4} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12}>
        <CampaignTopLocationCard value={topLocation || "unknown"}/>
      </Grid>
      <Grid item={true} lg={GRID_SIZE_6} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12}>
      <CampaignParticipantDistributionCard activePersonCount={activePersonCount} controlPersonCount={controlPersonCount} />
      </Grid>
      <Grid item={true} lg={GRID_SIZE_6} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12}>
        <AverageTransactionValue testGroupValue={avgTXValTest} controlGroupValue={avgTXValControl} />
      </Grid>
    </Grid>
  )
}

export default withHeader(CampaignPage);

const Alert = (props: any) => <MuiAlert elevation={NOTIFICATION_ELEVATION} variant="filled" {...props} />

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledPageTitle = styled(PageTitle)`
  margin: 0 0 1rem 0;
`

const ChipContainer = styled.div`
  margin-bottom: 1rem;
`

const ButtonContainer = styled.div`
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: 10px;
  column-gap: 12px;
`
