import styled from 'styled-components';
import theme from '../assets/css/theme';

export const PageTitle = styled.h1`
  margin: 0 0 2rem 0;
  font-size: 1.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: ${theme.colorDarkBlue};
`;

export const Subtitle = styled.h2`
  font-size: 1.25rem;
  font-weight: normal;
  color: ${theme.subtitleColor};
`;

export const InputLabel = styled.div`
  font-size: 0.9375rem;
`

export const CardTitle = styled.div`
  font-family: ${theme.ffInterRegular};
  font-size: 1.125rem;
  line-height: 1.39;
  color: ${theme.titleColor};
`;