import React, { useState, useEffect, ReactElement } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import { isEmpty, map } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { PointOptionsObject } from 'highcharts';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { isInclusivelyBeforeDay } from 'react-dates';
import { getToday } from '@loypal/lib-growth'
import format from 'date-fns/format';
import { withHeader } from '../../components';
import { DATE_FNS_DATE_FORMAT } from '../../config/datetime';
import { ICampaignsPerformanceSeries, ICampaignsSegmentsCard, ICampaignSummary, IStoreState, } from '../../modules/types';
import { campaignsSegmentSummaryRequest, campaignsSummaryClean, } from '../../modules/actions/campaign';
import {
  activeCampaigns,
  campaignPerformanceCategories,
  campaignPerformanceData,
  campaignsSummary,
  segmentCards,
} from '../../modules/selectors/campaign';
import { GRID_SIZE_12, GRID_SIZE_3, GRID_SIZE_4, GRID_SIZE_6, } from '../../config';
import { PageTitle } from '../../components/Text';
import ContainerStyled from '../../components/ContainerStyled';
import CampaignsSegmentCard from './CampaignsSegmentCard';
import { StackedColumnChart } from '../../components/Charts';
import {
  CampaignsIncrementalRevenueCard,
  CampaignPercenatgeIncrementalCard,
  CampaignsRevenueCard,
  CampaignsSizeCard,
} from '../../components/BoxComponents';
import theme from '../../assets/css/theme';
import '../../assets/css/react_dates_overrides.css'
import { CampaignFilter } from '../../components/Campaigns/CampaignFilter'
import LoadingCampaignsSegmentCard from './LoadingCampaignsSegmentCard';
import { useSetQueryDates, useGetDateFnsQueryDates } from '../../utils/hooks';
import { useHistory } from 'react-router-dom';
import subDays from 'date-fns/subDays'
import { ParticipantsDonutChart } from './ParticipantsDonutChart';

const NUM_SEGMENTS = 5;

interface IProps {
  campaignsSegmentSummaryRequest: typeof campaignsSegmentSummaryRequest;
  campaignsSummaryClean: typeof campaignsSummaryClean;
  segment_cards: ICampaignsSegmentsCard[];
  campaigns_performance_data: ICampaignsPerformanceSeries[];
  campaigns_performance_categories: string[];
  active_campaigns: {
    total: number;
    data: PointOptionsObject[];
  };
  campaigns_summary: ICampaignSummary;
}

const Campaigns = (props: IProps) => {
  const history = useHistory()
  const [startDate, setStartDate] = useState<any>(undefined)
  const [endDate, setEndDate] = useState<any>(undefined)
  const [excludePartialCampaigns, setExcludePartialCampaigns] = useState<boolean>(false)
  const [excludeActiveCampaigns, setExcludeActiveCampaigns] = useState<boolean>(false)
  const [focusedDateInput, setFocusedDateInput] = useState<any>(null)
  const [didDatesChanged, setDidDatesChanged] = useState<boolean>(false)
  const [didExcludeChanged, setDidExcludeChanged] = useState<boolean>(false)
  const [urlParams, setUrlParams] = useState<any>(null);
  const queryDates = useGetDateFnsQueryDates();
  
  useEffect(() => {
    /* tslint:disable:one-variable-per-declaration */ 
    let start_date, end_date;
    
    if (queryDates) {
      start_date = queryDates.startDate
      end_date = queryDates.endDate
      setStartDate(start_date);
      setEndDate(end_date);  
    } else {
      const today = getToday();
      const last_days_count = 30;
      const last_month = subDays(today, last_days_count);
      
      end_date = format(today, DATE_FNS_DATE_FORMAT);
      start_date = format(last_month, DATE_FNS_DATE_FORMAT);
      setStartDate(last_month)
      setEndDate(today)
    }

    props.campaignsSegmentSummaryRequest({
      start_date,
      end_date,
      exclude_partial_campaigns: excludePartialCampaigns ? 1 : 0,
      exclude_active_campaigns: excludeActiveCampaigns ? 1 : 0,
    });

    return () => {
      props.campaignsSummaryClean();
    }
  // can't have `props` as a dependency otherwise an infinite loop will occur
  // eslint-disable-next-line
  }, [props.campaignsSegmentSummaryRequest, props.campaignsSummaryClean], )

  useSetQueryDates({startDate, endDate, setUrlParams});

  const checkAllowedDates = (day: any) => {
    return !isInclusivelyBeforeDay(day, moment());
  }
    
  const { segment_cards, campaigns_performance_data } = props;

  let cards: ReactElement[] = map(segment_cards, (card) => {
    return (
      <Grid
        key={card.segment_id}
        item={true}
        xl={GRID_SIZE_3}
        lg={GRID_SIZE_4}
        md={GRID_SIZE_6}
        sm={GRID_SIZE_6}
        xs={GRID_SIZE_12}
      >
        <CampaignsSegmentCard urlParams={urlParams} {...card}/>
      </Grid >
    )
  });
  
  if (segment_cards.length < 1) {
    cards = map(Array(NUM_SEGMENTS).fill(null), (_segment_cards, card) => {
      return (
        <Grid
          key={card}
          item={true}
          lg={GRID_SIZE_4}
          md={GRID_SIZE_6}
          sm={GRID_SIZE_6}
          xs={GRID_SIZE_12}>
          <LoadingCampaignsSegmentCard />
        </Grid>
     )})
   }

  const gotoCalenderPage = () => {
    history.push("/campaigns/calendar");
  }
  
  const incrementalRevenue = props.campaigns_summary.incremental_revenue || 0;
  const expectedRevenue = (props.campaigns_summary.revenue || 0) - incrementalRevenue;
  const percentageCount = 100;
  const percentage = !expectedRevenue ? 0 : ((incrementalRevenue / expectedRevenue) * percentageCount).toFixed(2);

  return (
    <ContainerStyled maxWidth="xl">
      <PageTitleWrapper>
        <PageTitle>Campaigns</PageTitle>
        <FilterContainer>
          <ReportingDateRangePickerWrapper>
            <Button onClick= {gotoCalenderPage}>View Calendar</Button>
            <CampaignFilter
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              focusedDateInput={focusedDateInput}
              setFocusedDateInput={setFocusedDateInput}
              didDatesChanged={didDatesChanged}
              setDidDatesChanged={setDidDatesChanged}
              excludePartialCampaigns={excludePartialCampaigns}
              setExcludePartialCampaigns={setExcludePartialCampaigns}
              excludeActiveCampaigns={excludeActiveCampaigns}
              setExcludeActiveCampaigns={setExcludeActiveCampaigns}
              didExcludeChanged={didExcludeChanged}
              setDidExcludeChanged={setDidExcludeChanged}
              checkAllowedDates={checkAllowedDates}
              fetchCampaigns={props.campaignsSegmentSummaryRequest}
            />
          </ReportingDateRangePickerWrapper>
        </FilterContainer>
      </PageTitleWrapper>
      <GridWrapper>
      <Grid container={true} item={true} spacing={GRID_SIZE_4} >
        <Grid item={true} lg={GRID_SIZE_3} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12} className='gridItem'>
          <CampaignsSizeCard value={props.campaigns_summary.campaigns} />
        </Grid>
          <Grid item={true} lg={GRID_SIZE_3} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12} className='gridItem'>
          <CampaignsRevenueCard value={props.campaigns_summary.revenue} />
        </Grid>
          <Grid item={true} lg={GRID_SIZE_3} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12} className='gridItem'>
          <CampaignsIncrementalRevenueCard value={props.campaigns_summary.incremental_revenue} />
        </Grid>
          <Grid item={true} lg={GRID_SIZE_3} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12} className='gridItem'>
          <CampaignPercenatgeIncrementalCard value={percentage} />
        </Grid>
        <Grid item={true} lg={GRID_SIZE_6} md={GRID_SIZE_12} sm={GRID_SIZE_6} xs={GRID_SIZE_12}>
          <ParticipantsDonutChart 
            startDate={startDate} 
            endDate={endDate} 
            excludeActiveCampaigns={excludeActiveCampaigns}
            excludePartialCampaigns={excludePartialCampaigns}
          />
        </Grid>
        <Grid item={true} lg={GRID_SIZE_6} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12}>
          {!isEmpty(campaigns_performance_data) && <StackedColumnChart
            title={`Campaign Performance`}
            data={campaigns_performance_data}
            categories={['']}
            colors={[theme.colorTurquoise, theme.colorMelrose]}
          /> }
        </Grid>
        {cards}
      </Grid>
     </GridWrapper>
    </ContainerStyled>
  )
}

const mapStateToProps = (state: IStoreState) => ({
  campaigns_summary: campaignsSummary(state),
  active_campaigns: activeCampaigns(state),
  campaigns_performance_data: campaignPerformanceData(state),
  campaigns_performance_categories: campaignPerformanceCategories(state),
  segment_cards: segmentCards(state),
  is_data_requested: state.campaign.is_data_requested,
});

const mapDispatchToProps = {
  campaignsSegmentSummaryRequest,
  campaignsSummaryClean,
};

export default withHeader(connect(mapStateToProps, mapDispatchToProps)(Campaigns));

const GridWrapper = styled.div`
.gridItem{
  @media (min-width: 1100px) and (max-width: 1641px) {
    max-width: 50%;
    flex-basis: 50%;
  }
}
`

const PageTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  @media ${theme.media.laptop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`

const ReportingDateRangePickerWrapper = styled.div`
  display: flex;
  flex: 1;
  border-radius: 4px;
`

const FilterContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 1.25rem;
  flex-grow: 1;
  flex-direction: column;

  @media ${theme.media.laptop} {
    flex-grow: 0;
  }
`

const Button = styled.div`
  margin-right: 2rem;
  display: flex;
  text-align: center;
  border-radius: 4px;
  background-color: #120645;
  border: none;
  color: #ffffff;
  font-size: 0.9375rem;
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 0.625rem 1.063rem;
  padding-right: 1rem;

`