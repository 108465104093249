import * as React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
min-width: 40px;
min-height: 40px;
width: ${(props: IDiameter) => props.diameter || '2.5rem'};
height: ${(props: IDiameter) => props.diameter || '2.5rem'};
border: solid 0.0625rem ${props => props.color || '#ff7285'};
border-radius: 50%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`;

interface IDiameter {
  diameter?: string;
}

interface IProps extends IDiameter {
  color?: string,
}

export class  TrendUpIcon extends React.Component<IProps>{
  public render() {
    const color = this.props.color || '#ff7285';
    return (
      <Circle color={color} diameter={this.props.diameter}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25.751" height="13.778" viewBox="0 0 25.751 13.778">
          <path data-name="Trend Up Icon" d="M14.57,7.28h5.9l-5.9,5.9L8.009,6.624.3,14.333l2.3,2.3,5.413-5.413,6.561,6.561,8.2-8.2v5.9h3.28V4H14.57Z" transform="translate(-0.3 -4)" fill={color}/>
        </svg>
      </Circle>
    );
  }
}

export default TrendUpIcon;
