import React, { useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { IStoreState } from '../modules/types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { userForgotPassword } from '../modules/actions/user';
import { isLoggedIn, clientName } from "../modules/selectors";
import { GRID_SIZE_12, GRID_SIZE_9, GRID_SIZE_6, GRID_SIZE_3, GRID_SIZE_0 } from '../config';
import Group4Login from '../assets/img/login/LOYPAL-HOME-IMAGE.png';
import LPLogo from '../assets/img/login/LOYPAL-LOGO-HORIZONTAL.png';
import { InputText, Button } from "../components/Form";
import theme from '../assets/css/theme';
import { CircularProgress } from '@material-ui/core';

interface IProps extends RouteComponentProps {
  readonly client_name: string;
  readonly username: string;
  readonly is_logged_in: string;
  readonly usernameError?: boolean;
  readonly is_data_requested?: boolean;
  handleUserForgotPassword: typeof userForgotPassword;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ForgotPassword = ({
  is_logged_in,
  client_name,
  handleUserForgotPassword,
  is_data_requested,
  usernameError
}: IProps) => {
  const [username, setUsername] = useState('')

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleUserForgotPassword({
      client_name,
      username,
    });
  }

  const onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.currentTarget.value)
  }

  if (is_logged_in) {
    return <Redirect to="/" />;
  }

  if (!client_name) {
    return <Redirect to="/login" />;
  }

  return (
    <GridContainer container={true} item={true} spacing={GRID_SIZE_3} xs={GRID_SIZE_12}>
      <Hidden xsDown={true}>
        <GridCellLeft item={true} lg={GRID_SIZE_6} sm={GRID_SIZE_3} xs={GRID_SIZE_12}/>
      </Hidden>
      <GridCellRight
        container={true}
        item={true}
        lg={GRID_SIZE_6}
        sm={GRID_SIZE_9}
        xs={GRID_SIZE_12}
        spacing={GRID_SIZE_0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        { is_data_requested && (
          <Overlay>
            <CircularProgress />
          </Overlay>
        )}
          <Logo src={LPLogo}/>
        <SubTitle>Enter your username and we send you link to reset your password.</SubTitle>

        <Grid container={true} item={true} xs={GRID_SIZE_6}>
          <Form onSubmit={onSubmit}>
            <GridUserName item={true} xs={GRID_SIZE_12}>
              <InputUsernameStyled
                error={!!usernameError}
                autoFocus={true}
                type="text"
                label="Username"
                onChange={onUsernameChange}
              />
            </GridUserName>

            <GridControl
              container={true}
              item={true}
              xs={GRID_SIZE_12}
              alignItems="center"
            >
              <GridButtonLeft item={true} container={true} xs={GRID_SIZE_12} md={GRID_SIZE_6} >
                <SendRequestButton type="submit">Send Request</SendRequestButton>
              </GridButtonLeft>
              <GridButtonRight item={true} container={true} xs={GRID_SIZE_12} md={GRID_SIZE_6} >
                <ReturnToLogin href="/login-user">Return to Log in</ReturnToLogin>
              </GridButtonRight>
            </GridControl>

          </Form>
        </Grid>

        <TermPolicyWrapper>
          <Terms href="#">Term of use</Terms>
          <TermsWrapper>
            <Terms href="#">Privacy policy</Terms>
          </TermsWrapper>
        </TermPolicyWrapper>

      </GridCellRight>
    </GridContainer>
  );
}

const mapStateToProps = (state: IStoreState) => ({
  is_logged_in: isLoggedIn(state),
  client_name: clientName(state),
  usernameError: state.user.usernameError,
  is_data_requested: state.user.is_data_requested,
});

const mapDispatchToProps = {
  handleUserForgotPassword: userForgotPassword
};

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const SendRequestButton = styled(Button)`
  width: 181px !important;
  text-align: center;
  padding: 10px;
  background-color: ${theme.colorRed} !important;
  font-size: 1rem !important;
  align-items: center;
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
`;

const GridContainer = styled(Grid)`
  && {
    font-family: ${theme.ffSansProRegular};
    font-weight: bold;
    margin: 0;
  }
`;

const GridUserName = styled(Grid)`
  && {
    font-family: ${theme.ffSansProRegular};
    font-weight: bold;
    flex-basis: 105px;
  }
`;

const GridCell = styled(Grid)`
  && {
    position: relative;
    min-height: 100vh;
  }
`;

const InputUsernameStyled = styled(InputText)`
  && {
    font-family: inherit;
    width: 100%;
    margin-bottom: 3.375rem;
  }
  .MuiInputLabel-formControl {
    font-family: ${theme.ffSansProRegular};
    font-weight: bold;
    opacity: 0.8;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: ${theme.colorWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const GridCellLeft = styled(GridCell)`
  background-image: url(${Group4Login});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 40% center;
  opacity: 0.5;
  @media (max-width: 1541px){
    background-position: 36% center;
    }
  @media (max-width: 1280px){
    display: none;
    }
`;

const GridCellRight = styled(GridCell)`
  background-color: ${theme.colorWhite};
  @media (max-width: 1280px) {
    max-width: 100%;
    flex-basis: 100%;
    }
`;

const GridControl = styled(GridContainer)`
  && {
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 2;
      flex-basis: auto;
      @media ${theme.media.tabletL} {
         justify-content: space-between;
      }
  }
`;

const GridButtonLeft = styled(Grid)`
&& {
  justify-content: center;
  padding-bottom: 15px;

  @media${theme.media.tabletL} {
    justify-content: flex-start;
    padding-bottom: 0;
    padding-right: 15px;
  }
}
`;

const GridButtonRight = styled(Grid)`
&& {
  justify-content: center;
  padding-top: 15px;
  
  @media ${theme.media.tabletL} {
  justify-content: flex-end;
  padding-top: 0;
  padding-left: 15px;
  }
}
`;

const ReturnToLogin = styled.a`
  position: relative;
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
  opacity: 0.8;
  text-decoration: none;
  text-align: center;
  padding: 10px;
  width: 181px;
  height: 50px;
  border-radius: 4px;
  background-color: ${theme.colorWhite};
  border: 1px solid ${theme.colorDarkBlue};
  color: ${theme.colorDarkGray};
  font-size: 1rem;
  cursor: pointer;
  align-items: center;
  @media${theme.media.tabletL} {
    padding-bottom: 10px;
  }
`;

const Logo = styled.img`
  height: 74px;
  margin-bottom: 24px;
`;

const TermPolicyWrapper = styled.div`
  display: flex;
  flex: 0.4;
  align-self: center;
  position: relative;
  opacity: 0.8;
`;

const Terms = styled.a`
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  top: 100%;
  font-size: 0.8375rem;
  text-align: center;
  color: ${theme.colorDarkGray};
  text-decoration: none;
  margin-top: 2.5rem;
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
  text-decoration-line: underline;
  transform: translate(-70%, -30%);
  @media ${theme.media.laptop} {
    && {
      position: absolute;
      bottom: 4.125rem;
    }
  }
`;

const SubTitle = styled.div`
  opacity: 0.5;
  font-size: 1rem;
  text-align: center;
  color: ${theme.colorDarkGray};
  margin-bottom: 3.125rem;
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
`;

const TermsWrapper = styled.div`
  display: flex;
  margin-left: 100px;
  white-space: nowrap;
  text-align: center;
`;

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
