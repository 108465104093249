import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import { ISagaAction, ICustomersOverTimePayload } from '../../types'
import * as actions from '../../actions';

export const customerDataOverTimeRequestSaga = function* (action: ISagaAction<ICustomersOverTimePayload>): SagaIterator {
  try {
    const res: any = yield call(Api.Customer.fetchCustomersDataOverTime, action.payload);
    yield put(actions.customerDataOverTimeSuccess(res));
  } catch (e) {
    yield put(actions.customerDataOverTimeFailed((e as Error).message));
  }
}
export const storesRequestSaga = function* (): SagaIterator {
  try {
    const res: any = yield call(Api.Customer.fetchStores);
    yield put(actions.storesSuccess(res));
  } catch (e) {
    yield put(actions.storesFailed((e as Error).message));
  }
}
export const currentLifetimeValueRequestSaga = function* (): SagaIterator {
  try {
    const res: any = yield call(Api.Customer.fetchLTV);
    yield put(actions.lifetimeValueSuccess(res));
  } catch (e) {
    yield put(actions.lifetimeValueFailed((e as Error).message));
  }
}

