import * as React from 'react';
import theme from '../../assets/css/theme';

interface IProps {
  color?: string,
}

export const CampaignsIcon = (props: IProps) => {
  const color = props.color || theme.colorInactive;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <rect width="16" height="16" fill="none"/>
      <path d="M2,5v9H14V5ZM13,2h2a.945.945,0,0,1,1,1V15a.945.945,0,0,1-1,1H1a.945.945,0,0,1-1-1V3A.945.945,0,0,1,1,2H3V1A.945.945,0,0,1,4,0,.945.945,0,0,1,5,1V2h6V1a1,1,0,0,1,2,0ZM12,12H10V10h2ZM9,12H7V10H9Zm3-3H10V7h2ZM9,9H7V7H9ZM6,12H4V10H6Z" fill={color} fillRule="evenodd"/>
    </svg>
  );
};

export default CampaignsIcon;
