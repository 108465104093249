import React from 'react';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import { Link } from '../../components/Form';
import { ArrowLeftIcon } from '../../components/Icons';

interface IProps {
  url?: string
  text?: string
}

export const BackLink = ({ url, text = 'Back' }: IProps) => {
  const history = useHistory()

  const handleGoBack = () => {
    if (url) {
      history.push(url)
    } else {
      history.goBack()
    }
  }
  
  return (
    <BackContainer>
      <Link onClick={handleGoBack}>
        <ArrowLeftIcon/>
        {text}
      </Link>
    </BackContainer>
  )
}

const BackContainer = styled.div`
  margin-bottom: 12px;
   a {
    display: flex;
    align-items: center;
   }
`;
