import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { DATETIME_FORMAT, DATE_FNS_DATE_FORMAT } from '../../config/datetime';
import format from 'date-fns/format';

export const useGetQueryDates = () => {
    const { search } = useLocation()
    return search;
}

/* This is deprecated, please use Date Fns */
export const useGetMomentQueryDates = () => {
    const { search } = useLocation()

    if (!search || !search.includes('start_date') || !search.includes('end_date')) {
      return null;
    }
    const queryParams = search.split('?')[1].split('&');
    const [startDate, endDate]: any[]  = queryParams.map((param) => {
      return param.replace(/([a-zA-Z])|_|=/g, '');
    })
    
    const momentQueryStartDate = moment(startDate, DATETIME_FORMAT);
    const momentQueryEndDate = moment(endDate, DATETIME_FORMAT);

    return { momentQueryStartDate, momentQueryEndDate };
}

export const useGetDateFnsQueryDates = () => {
    const { search } = useLocation()

    if (!search || !search.includes('start_date') || !search.includes('end_date')) {
      return null;
    }
    const queryParams = search.split('?')[1].split('&');
    const [queryStartDate, queryEndDate]: any[]  = queryParams.map((param) => {
      return param.replace(/([a-zA-Z])|_|=/g, '');
    })
    
    const startDate = format(new Date(queryStartDate), DATE_FNS_DATE_FORMAT);
    const endDate = format(new Date(queryEndDate), DATE_FNS_DATE_FORMAT);

    return { startDate, endDate };
}