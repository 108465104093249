import React, { useEffect } from 'react';
import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withHeader } from '../../components';
import { PageTitle, Subtitle } from '../../components/Text';
import ContainerStyled from '../../components/ContainerStyled';
import { Button } from '../../components/BoxComponents';
import { allIntegrationsRequest } from '../../modules/actions';
import { IStoreState } from '../../modules/types';
import { IntegrationCard, LoadingIntegrationCard } from './Integrations/IntegrationCard';

const Integrations = () => {
  const dispatch = useDispatch()
  const integrations = useSelector((state: IStoreState) => {
    return state.integration.integrations
  })
  const isDataLoading = useSelector((state: IStoreState) => {
    return state.integration.is_data_requested
  })

  useEffect(() => {
    dispatch(allIntegrationsRequest())
    // eslint-disable-next-line
  }, [])

  if (isDataLoading) {
    return <LoadingIntegrationCard />
  }

  if (!Object.keys(integrations).length) {
    return <div>You do not have any integrations.</div>
  }

  return (
    <>
      {Object.keys(integrations).map(platform => (
        <IntegrationCard key={platform} platform={platform} {...integrations[platform]} />
      ))}
    </>
  )
}


const Settings = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(allIntegrationsRequest())
    // eslint-disable-next-line
  }, [])

  const goToIntegrationsForm = () => {
    history.push("/settings/add-integration");
  }

  return (
    <ContainerStyled maxWidth="xl">
      <PageTitle>Settings</PageTitle>
      <Box mb="20px">
        <Subtitle>Integrations</Subtitle>
      </Box>
      <Box mb="28px">
        <Integrations />
      </Box>
      <Button onClick={goToIntegrationsForm}>Add an integration</Button>
    </ContainerStyled>
  )
}

export default withHeader(Settings);