import * as React from 'react';
import theme from '../../assets/css/theme';

interface IProps {
  color?: string,
}

export const OpportunityIcon = (props: IProps) => {
  const color = props.color || theme.colorInactive;
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
      <path d="M0,16a6.018,6.018,0,0,1,6-6H8a6.018,6.018,0,0,1,6,6Zm2.6-2h8.9A4.033,4.033,0,0,0,8,12H6.1A4.035,4.035,0,0,0,2.6,14ZM3,5V4a4,4,0,0,1,8,0V5A4,4,0,1,1,3,5ZM5,4V5A2,2,0,1,0,9,5V4A2,2,0,0,0,5,4Z" fill={color}/>
    </svg>

  );
};

export default OpportunityIcon;
