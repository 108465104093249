import { SagaIterator } from 'redux-saga';
import * as user from './user';
import * as segment from './segment';
import * as audience from './audience';
import * as campaign from './campaign';
import * as customers from './customers';
import * as integration from './integration';
import { takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';

/**
 * Root saga that connect sagas with actions.
 */
export default function* rootSaga(): SagaIterator {
  // fetch cognito url by client id
  yield takeLatest(actions.clientNameRequest, user.clientNameRequestSaga);
  // save bearer token to store
  yield takeLatest(actions.userLogin, user.loginSaga);
  yield takeLatest(actions.userLoginFailed, user.loginFailedSaga);
  yield takeLatest(actions.goToEditClientName, user.goToEditClientNameSaga);
  // forgot password
  yield takeLatest(actions.userForgotPassword, user.forgotPasswordSaga);
  yield takeLatest(actions.userConfirmForgotPassword, user.confirmForgotPasswordSaga);
  // logout
  yield takeLatest(actions.userLogout, user.userLogoutSaga);
  // fetch customers sizes
  yield takeLatest(actions.customersCountRequest, segment.customersCountRequestSaga);
  // fetch opportunity values
  yield takeLatest(actions.opportunityValueRequest, segment.opportunityValueRequestSaga);
  // fetch segments summary for cards
  yield takeLatest(actions.segmentCardRequest, segment.segmentCardRequestSaga);
  // fetch segments summary for lifecycle view
  yield takeLatest(actions.lifecycleRequest, segment.lifecycleRequestSaga);
  // fetch audience summary for Audience view
  yield takeLatest(actions.audienceCardRequest, audience.audienceRequestSaga);
  // create campaign and download campaign export file
  yield takeLatest(actions.campaignCreateRequest, campaign.createCampaignRequestSaga);
  // update campaign dates
  yield takeLatest(actions.campaignUpdateDatesRequest, campaign.updateCampaignDatesRequestSaga);
  // fetch Notification Info about in Campaign people and hold out group in Audience
  yield takeLatest(actions.getNotificationInfoRequest, audience.getNotificationInfoRequestSaga);
  // download campaign export file
  yield takeLatest(actions.campaignReDownloadDataRequest, campaign.reDownloadCampaignDataRequestSaga);
  yield takeLatest(actions.activeCampaignDownloadDataRequest, campaign.activeCampaignDownloadDataRequestSaga);
  yield takeLatest(actions.campaignDownloadControlCSVRequest, campaign.campaignDownloadControlCSVRequestSaga);
  yield takeLatest(actions.retargetCampaignRequest, campaign.retargetCampaignRequestSaga);
  // fetch campaigns segments summary info
  yield takeLatest(actions.campaignsSegmentSummaryRequest, campaign.campaignsSummarySegmentRequestSaga);
  // fetch Campaigns of certain Segment Actions
  yield takeLatest(actions.segmentCampaignsRequest, campaign.segmentCampaignsRequestSaga);
  // download person export file
  yield takeLatest(actions.exportCustomersRequest, user.exportCustomersRequestSaga);
  // fetch client profile data
  yield takeLatest(actions.userProfileRequest, user.userProfileRequestSaga);
  // update user profile actions
  yield takeLatest(actions.userUpdateProfileRequest, user.userUpdateProfileRequestSaga);
  // update user current password actions
  yield takeLatest(actions.userUpdatePasswordRequest, user.userUpdatePasswordRequestSaga);
  // update user profile picture actions
  yield takeLatest(actions.userUpdateProfilePictureRequest, user.userUpdateProfilePictureRequestSaga);
  // customer data overtime
  yield takeLatest(actions.customerDataOverTimeRequest, customers.customerDataOverTimeRequestSaga);
  // fetch campaign stores revenue data
  yield takeLatest(actions.campaignStoresRevenueRequest, campaign.campaignStoresRequestSaga);
  // fetch distinct stores
  yield takeLatest(actions.storesRequest, customers.storesRequestSaga);
  // fetch all lifetime values for all audiences
  yield takeLatest(actions.lifetimeValueRequest, customers.currentLifetimeValueRequestSaga);
  // fetch campaign creation statuses
  yield takeLatest(actions.campaignCreationStatusesRequest, campaign.campaignCreationStatusesRequestSaga);
  // fetch campaign creation statuses
  yield takeLatest(actions.contactabilityFiltersRequest, campaign.contactabilityFiltersRequestSaga);
  // fetch campaign
  yield takeLatest(actions.campaignRequest, campaign.campaignRequestSaga);
  // fetch calendar entries
  yield takeLatest(actions.calendarEntriesRequest, campaign.calendarEntriesRequestSaga);
  // fetch campaign participant 
  yield takeLatest(actions.campaignsParticipantLifecycleRequest, campaign.campaignsParticipantLifecycleRequestSaga);
  // fetch all integrations
  yield takeLatest(actions.allIntegrationsRequest, integration.allIntegrationsRequestSaga);
  // post create integration
  yield takeLatest(actions.toggleIntegrationsRequest, integration.toggleIntegrationsRequestSaga);
  // update or creating integration credentials
  yield takeLatest(actions.setIntegrationCredentialsRequest, integration.setIntegrationCredentialsRequestSaga);
   // fetch audience segment summary 
   yield takeLatest(actions.audiencesSummaryCardRequest, audience.audiencesSummaryCardRequestSaga);
}
