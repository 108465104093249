import * as React from 'react';
import { TearOffCalendarIcon } from '../Icons';
import theme from '../../assets/css/theme';
import styled from 'styled-components';

interface IProps {
  value: number | string,
}

const CampaignsSizeValue = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-stretch: normal;
font-style: normal;
font-size: 2.188rem;
text-align: left;
line-height: 1.26;
color: ${theme.colorDarkGray};
`;

const Label = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: normal;
font-stretch: normal;
font-style: normal;
font-size: 0.9375rem;
line-height: 1.67;
text-align: left;
color: ${theme.colorDarkBlue};
`;

const CampaignsSizeWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
background-color: ${theme.colorWhite};
box-shadow: ${theme.boxShadow};
padding: 1.25rem 1.438rem;
`;

const CampaignsSizeValueWrapper = styled.div`
padding-left: ${(props: ISpaceSizeProp) => props.spaceSize || '1.625rem'};
`;

interface ISpaceSizeProp {
  spaceSize?: string;
}

interface IProps extends ISpaceSizeProp {
  value: number | string;
}

export class CampaignsSizeCard extends React.Component<IProps> {
  public render() {
    return (
      <CampaignsSizeWrapper>
        <TearOffCalendarIcon />
        <CampaignsSizeValueWrapper spaceSize={this.props.spaceSize}>
          <CampaignsSizeValue>{this.props.value.toLocaleString('en')}</CampaignsSizeValue>
          <Label>Campaigns</Label>
        </CampaignsSizeValueWrapper>
      </CampaignsSizeWrapper>
    );
  }
}

export default CampaignsSizeCard;
