import React, { useMemo } from 'react';
import format from 'date-fns/format';
import theme from '../../assets/css/theme'
import { ICustomerDataDateRecord } from '../../modules/types'
import { Report } from '../../components/Report'
import { MultiTypeChart } from '../../components/Charts/MultiTypeChart'
import { 
  DateRangeType, 
  useDateFnsFormat,
  formatLargeNumber,
  getCurrentLowAndHighVal,
  getDateHeadings,
} from '../../utils/reports'

const DEFAULT_BOUNDARY = 0;

interface IProps {
  dateRange: DateRangeType,
  overTimeData: ICustomerDataDateRecord[]
}

interface IGrowthData {
  totalWon: null | number
  totalArchived: null | number
  netGrowth: null | number
}

interface IGrowthOvertimeData {
  date: string
  data: IGrowthData

}

const NUM_DECIMAL_POINTS = 0

const removeNullColumns = (overTimeData: IGrowthOvertimeData[]) => {
  return overTimeData.filter(dataSet => {
    return dataSet.data.totalWon !== null || dataSet.data.totalArchived !== null || dataSet.data.netGrowth !== null
  })
}

const getCSVData = (overTimeData: IGrowthOvertimeData[], dateFormat: string) => {
  const cleanedData = removeNullColumns(overTimeData)
  const dateHeadings = cleanedData.map(dataSet => {
    return format(new Date(dataSet.date), dateFormat)
  })

  const customersWon = cleanedData.map(dataSet => {
    return dataSet.data.totalWon === null ? 'n/a' : formatLargeNumber(dataSet.data.totalWon, NUM_DECIMAL_POINTS)
  })

  const customersArchived = cleanedData.map(dataSet => {
    return dataSet.data.totalArchived === null ? 'n/a' : formatLargeNumber(dataSet.data.totalArchived, NUM_DECIMAL_POINTS)
  })

  const nettGrowth = cleanedData.map(dataSet => {
    return dataSet.data.netGrowth === null ? 'n/a' : formatLargeNumber(dataSet.data.netGrowth, NUM_DECIMAL_POINTS)
  })

  const rows = [["Customers Won", ...customersWon], ["Customers Archived", ...customersArchived], ["Nett Growth", ...nettGrowth]]

  return { rows, headers: ['Contactability', ...dateHeadings], dateHeadings }
}

const getChartData = (overTimeData: IGrowthOvertimeData[]) => {
  const customersWonChartEntry = {
    name: 'Customers Won',
    type: 'column',
    color: theme.colorDarkestBlue,
    data: overTimeData.map(dataSet => dataSet.data.totalWon),
  }

  const customersArchivedChartEntry = {
    name: 'Customers Archived',
    type: 'column',
    color: theme.colorGray2,
    data: overTimeData.map(dataSet => dataSet.data.totalArchived === null ? null : -(dataSet.data.totalArchived)),
  }

  const netGrowthChartEntry = {
    name: 'Nett Difference',
    color: theme.colorRed,
    data: overTimeData.map(dataSet => dataSet.data.netGrowth),
  }

  return [customersWonChartEntry, customersArchivedChartEntry, netGrowthChartEntry]
}

const getGrowthData = (overTimeData: ICustomerDataDateRecord[]) => {
  const growthData: IGrowthOvertimeData[] = []
  for (const overTimeDataRecord of overTimeData) {
    const hasTotalWonData = overTimeDataRecord.data.some(data => data.numWon !== null)
    const hasTotalArchivedData = overTimeDataRecord.data.some(data => data.numArchived !== null)
    let totalWon = null;
    let totalArchived = null;
    let netGrowth = null;
    if (hasTotalWonData) {
      totalWon = overTimeDataRecord.data.reduce((total, val) => {
        if (val.numWon === null) {
          return total
        }

        return total + val.numWon
      }, 0)

      netGrowth = totalWon
    }

    if (hasTotalArchivedData) {
      totalArchived = overTimeDataRecord.data.reduce((total, val) => {
        if (val.numArchived === null) {
          return total
        }

        return total + val.numArchived
      }, 0)

      netGrowth = (netGrowth || 0) - totalArchived
    }
    growthData.push({ date: overTimeDataRecord.date, data: { totalWon, totalArchived, netGrowth } })
  }

  return growthData
}

const getMin = (growthDataOverTime: IGrowthOvertimeData[]) => {
  let min = 0;

  for (const { data } of growthDataOverTime) {
    if (data.totalArchived !== null) {
      // total archived gets converted to a negative value in the chart
      const newBoundaries = getCurrentLowAndHighVal(-(data.totalArchived), min, DEFAULT_BOUNDARY)
      
      min = newBoundaries.lowestVal
    }
  }

  return min
}

const getMax = (growthDataOverTime: IGrowthOvertimeData[]) => {
  let max = 0;

  for (const { data } of growthDataOverTime) {
    if (data.totalWon !== null) {
      const newBoundaries = getCurrentLowAndHighVal(data.totalWon, DEFAULT_BOUNDARY, max)
      
      max = newBoundaries.highestVal
    }
  }

  return max
}

export const NetCustomerGrowth = ({ overTimeData, dateRange }: IProps) => {
  const dateFormat = useDateFnsFormat(dateRange)
  const growthData = useMemo(() => {
    if (!overTimeData) {
      return null
    }
    
    return getGrowthData(overTimeData)
  }, [ overTimeData ])

  const tableInfo = useMemo(() => growthData ? getCSVData(growthData, dateFormat) : { rows: [], headers: [], dateHeadings: [] }, [growthData, dateFormat])
  const chartData = useMemo(() => growthData ? getChartData(growthData) : [], [growthData])
  const dateHeadings = getDateHeadings(overTimeData, dateFormat)
  const { rows, headers } = tableInfo
  const csvData = [
    headers,
    ...rows
  ];

  let chartMin = 0;
  let chartMax = 0;

  if (growthData !== null) {
    chartMin = getMin(growthData)
    chartMax = getMax(growthData)
  }

  return (
    <Report
      title="Nett Customer Growth"
      headers={headers}
      rows={rows}
      csvData={csvData}
      guideHeight="360px"
      guideSlides={
        [{
        id: 1, 
        body: "Monitor ‘Nett Customer Growth’ to track the volume trend of active customers within your customer base." 
      }, {
        id: 2, 
        body: "‘Customers Won’ represents new acquisitions into the customer base in the time period."
      }, {
        id: 3, 
        body: "‘Customers Archived' represents customers that have lapsed from the customer base in the time period - these are customers that have been inactive for so long, they can no longer be deemed active." 
      }, {
        id: 4, 
        body: "The objective is to see nett customer growth being positive." 
      }]
    }
    >
      <MultiTypeChart
        data={chartData}
        categories={dateHeadings}
        shouldShowDataLabels={false}
        height="300px"
        yAxisFloor={chartMin}
        yAxisCeiling={chartMax}
      /> 
    </Report>
  )
}