import * as React from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';

const SizeValueView = styled.div`
color: ${theme.colorDarkGray};
font-family: ${theme.ffInterMedium};
font-size: 2.5rem;
font-weight: 900;
line-height: 1.25;
`;

interface IProp {
  value: number,
}

export const SizeValue = (props: IProp) => {

    return (
      <SizeValueView>
        {props.value?.toLocaleString('en')}
      </SizeValueView>
    );
  }

export default SizeValue;
