import React from 'react'
import styled from 'styled-components';
import { variant } from 'styled-system'

import theme from '../../assets/css/theme';

interface IStyledProps {
  size: 'sm' | 'md' | 'lrg'
  children: string
  onClick?: () => void
}

interface IProps extends Omit<IStyledProps, 'size'> {
  size?: 'sm' | 'md' | 'lrg'
}

const TextButtonStyled = styled.button<IStyledProps>`
  && {
    border: none;
    background-color: transparent;
    font-family: inherit;
    color: ${theme.colorLightBlue};
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
    :disabled {
      color: ${theme.colorMediumGray};
      cursor: not-allowed;
    }
    :active {
      outline: none;
      border: none;
    }
    :focus {
      outline: 0;
    }

    ${() => variant({
      prop: 'size',
      variants: {
        sm: {
          fontSize: '0.9em',
        },
        md: {
          fontSize: '1em',
        },
        lrg: {
          fontSize: '1.24em',
        },
      }
    })}
  }
`;

export const TextButton = ({ size = 'md', ...props }: IProps) => (
  <TextButtonStyled size={size} {...props} />
);