import * as React from 'react';
import { withRouter } from 'react-router';

class ScrollToTopComponent extends React.Component<any> {
  /**
   * Scroll to top if current location and next location are different
   */
  public componentDidUpdate(prevProps: any): void {
    const { location} = this.props;

    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  /**
   * Just return children. HOC
   */
  public render(): React.ReactNode {
    return this.props.children;
  }
}

export const ScrollToTop = withRouter(ScrollToTopComponent);

export default ScrollToTop;
