import React from 'react'
import styled from 'styled-components';

import theme from '../../assets/css/theme';

const LinkStyled = styled.a`
  && {
    font-family: inherit;
    color: ${theme.colorBlue};
    font-size: 1rem;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
    :disabled {
      color: ${theme.colorMediumGray};
      cursor: not-allowed;
    }
  }
`;

export const Link = (props: any) => <LinkStyled {...props} />;