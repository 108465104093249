import { createAction } from 'redux-act';
import {
  ICampaignCreateRequestPayload,
  ICampaignCreateResponse,
  ICampaignReDownloadData,
  ICampaignsCard,
  ICampaignSummaryRequestPayload,
  ICampaignSummaryResponse,
  ISegmentCampaignRequestPayload,
  ICampaignUpdateDatesRequestPayload,
  ISegmentCampaignsResponse,
  ICampaignStoresRevenuePayload,
  ICampaignStoresRevenueResponse,
  ICampaignCreationStatusResponse,
  IContactabilityFiltersResponse,
  ICampaignResponse,
  ICampaignRequestPayload,
  ICampaignEntriesRequestPayload,
  ICampaignEntriesResponse,
  ICampaignsParticipantLifecycleResponse,
  ICampaignsParticipantLifecycleRequestPayload,
  INotPurchasedCampaignListRequest,
} from '../../types';

// Create Campaign actions
export const campaignCreateRequest = createAction<ICampaignCreateRequestPayload>('Create Campaign request');
export const campaignCreateSuccess = createAction<ICampaignCreateResponse>('Campaign created successfully');
export const campaignCreateFailed = createAction<string>('Campaign created failed');

export const campaignUpdateDatesRequest = createAction<ICampaignUpdateDatesRequestPayload>('Update Campaign dates request');
export const campaignUpdateDatesSuccess = createAction<ICampaignsCard>('Campaign updated successfully');
export const campaignUpdateDatesFailed = createAction<string>('Campaign update failed');
// Re-Download Campaign Data File Actions
export const campaignReDownloadDataRequest = createAction<ICampaignReDownloadData>('Re-Download Campaign Data File');
export const campaignReDownloadDataSuccess = createAction('Re-Downloaded Campaign Data File');
export const campaignReDownloadDataFailed = createAction<string>('Re-Downloading Campaign Data Failed');

// Campaigns Segments Summary actions
export const campaignsSegmentSummaryRequest = createAction<ICampaignSummaryRequestPayload>('Fetch campaigns summary for each segment')
export const campaignsSegmentSummarySuccess = createAction<ICampaignSummaryResponse>('Campaigns summary for each segment fetched successfully')
export const campaignsSegmentSummaryFailed = createAction<string>('Fetching campaigns summary for each segment failed')
export const campaignsSummaryClean = createAction('Set Campaigns summary data to default value')

// Campaigns of certain Segment Actions
export const segmentCampaignsRequest = createAction<ISegmentCampaignRequestPayload>('Fetch campaigns for certain segment');
export const segmentCampaignsSuccess = createAction<ISegmentCampaignsResponse>('Campaigns for certain segment fetched successfully');
export const segmentCampaignsFailed = createAction<string>('Fetching campaigns for certain segment failed');

// Download Data File Of Active Campaign Actions
export const activeCampaignDownloadDataRequest = createAction<ICampaignReDownloadData>('Download Data File Of Active Campaign');
export const activeCampaignDownloadDataSuccess = createAction('Successfully Downloaded Data File Of Active Campaign');
export const activeCampaignDownloadDataFailed = createAction<string>('Downloading Data File Of Active Campaign Failed');

// Download Control CSV of a Campaign
export const campaignDownloadControlCSVRequest = createAction<ICampaignReDownloadData>('Download campaign control CSV');
export const campaignDownloadControlCSVSuccess = createAction('Successfully Downloaded campaign control CSV');
export const campaignDownloadControlCSVFailed = createAction<string>('Downloading campaign control CSV failed');

// Generate not purchased list for campign
export const retargetCampaignRequest = createAction<INotPurchasedCampaignListRequest>('Generating not purchased list for Campaign');
export const retargetCampaignSuccess = createAction('Successfully generated not purchased list for Campaign');
export const retargetCampaignFailed = createAction<string>('Campaign not purchased list failed Failed');

// Fetch campaign store data actions
export const campaignStoresRevenueRequest = createAction<ICampaignStoresRevenuePayload>('Fetch campaign store data');
export const campaignStoresRevenueSuccess = createAction<ICampaignStoresRevenueResponse>('Store data for campaign fetched successfully');
export const campaignStoresRevenueFailed = createAction<string>('Store data for campaign fetch failed');

// Fetch campaign creation status actions
export const campaignCreationStatusesRequest = createAction('Fetch campaign creation statuses');
export const campaignCreationStatusesSuccess = createAction<ICampaignCreationStatusResponse>('Campaign creation statuses fetched successfully');
export const campaignCreationStatusesFailed = createAction<string>('Campaign creation statuses fetch failed');

// Fetch contactability filters
export const contactabilityFiltersRequest = createAction('Fetch contactability filters');
export const contactabilityFiltersSuccess = createAction<IContactabilityFiltersResponse>('Contactability filters fetched successfully');
export const contactabilityFiltersFailed = createAction<string>('Contactability filters fetch failed');

// Fetch campaign
export const campaignRequest = createAction<ICampaignRequestPayload>('Fetch campaign');
export const campaignSuccess = createAction<ICampaignResponse>('Campaign fetched successfully');
export const campaignFailed = createAction<string>('Campaign fetch failed');

// Fetch calendar entries
export const calendarEntriesRequest = createAction<ICampaignEntriesRequestPayload>('Fetch calendar entries');
export const calendarEntriesSuccess = createAction<ICampaignEntriesResponse>('Calendar entries fetched successfully');
export const calendarEntriesFailed = createAction<string>('Calendar entries fetch failed');

// Fetch camapigns participant lifecycle
export const campaignsParticipantLifecycleRequest = createAction<ICampaignsParticipantLifecycleRequestPayload>('Fetch campaigns participant of life cycle');
export const campaignsParticipantLifecycleSuccess = createAction<ICampaignsParticipantLifecycleResponse>('Campaigns participant of life cycle fetched successfully');
export const campaignsParticipantLifecycleFailed = createAction<string>('Campaigns participant of life cycle fetched failed');


