import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { storesRequest } from '../../../modules/actions';
import { IStoreState } from '../../../modules/types';
import { useClientCustomization, useFeatureFlag } from '../../../utils/hooks';
import { Checkbox, Autocomplete } from '../../../components/Form';
import theme from '../../../assets/css/theme';
interface IProps {
  stores?: string[]
  fetchStores?: typeof storesRequest
  handleOnChangeFilterStore(event: any, val: any): void
  resetFilterStores(): void
}

const CampaignStoreFilterInner = (
  { stores, handleOnChangeFilterStore, resetFilterStores, fetchStores }: IProps
) => {
  const [isFilteringStores, setIsFilteringStores] = useState(false)
  const { clientName } = useClientCustomization()
  const hasStoreFilteringEnabled = useFeatureFlag("campaignExcludeStores", clientName);

  useEffect(() => {
    if (fetchStores) {
      fetchStores();
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isFilteringStores) {
      resetFilterStores()
    }
    // eslint-disable-next-line
  }, [isFilteringStores])

  const toggleIsFilteringStores = useCallback(() => {
    setIsFilteringStores(!isFilteringStores)
  }, [isFilteringStores])

  if (!hasStoreFilteringEnabled) { return null }

  return (
    <Container>
      <CheckboxContainer>
        <StyledFormControlLabel
          control={
            <Checkbox
              checked={isFilteringStores}
              onChange={toggleIsFilteringStores}
            />
          }
          label="Exclude stores"
        />
      </CheckboxContainer>
      {isFilteringStores && (
        <AutocompleteContainer>
          <Autocomplete
            options={stores || []}
            onChange={handleOnChangeFilterStore}
            multiple={true}
            size="small"
            label='Select stores to exclude'
          />
        </AutocompleteContainer>
      )}
    </Container>
  )
}
const mapStateToProps = (state: IStoreState) => ({
  stores: state.customers.stores,
  is_data_requested: state.customers.is_data_requested,
})

const mapDispatchToProps = {
  fetchStores: storesRequest
}

export const CampaignStoreFilter = connect(mapStateToProps, mapDispatchToProps)(CampaignStoreFilterInner)

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;

  @media ${theme.media.tablet} {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
`

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  &&& {
    align-items: flex-start;
    margin-right: 0px;
    margin-left: -7px;
  }

  &&& > span.MuiTypography-root {
    font-size: 14px;
    margin-left: 4px;
    margin-top: 4px;
  }
`

const AutocompleteContainer = styled.div`
  width: 100%;

  @media ${theme.media.tablet} {
    width: 60%;
    margin-top; 0px;
  }
`