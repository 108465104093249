import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DATE_FNS_DATE_FORMAT } from '../../config/datetime';
import format from 'date-fns/format';

interface IProps {
  startDate: Date,
  endDate: Date,
  setUrlParams?: (p: string) => void,
}

export const useSetQueryDates = ({startDate, endDate, setUrlParams }: IProps) => {
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    if (startDate || endDate ) {
      const params = `?start_date=${format(new Date(startDate), DATE_FNS_DATE_FORMAT)}&end_date=${format(new Date(endDate), DATE_FNS_DATE_FORMAT)}`;
      
      if (setUrlParams) {
          setUrlParams(params)
      }
      
      history.push(params);
    }
  }, [startDate, endDate, history, setUrlParams, search ]);
}