import React from 'react';
import styled from 'styled-components';
import MUISelect, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

interface IOption {
  value: any
  label: string
}

interface IProps extends SelectProps {
  options: IOption[]
}

export const Select = ({options, ...props}: IProps) => {
  return (
    <StyledSelect {...props} variant="outlined">
      {options.map(({ label, value }, index) => (
        <MenuItem key={index} value={value}>{label}</MenuItem>
      ))}
    </StyledSelect>
  )
}

const StyledSelect = styled(MUISelect)`
  background-color: white;
  width: 100%;

  .MuiSelect-root {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`