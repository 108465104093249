import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { Grid } from '@material-ui/core';

export const CardContainer = styled(Grid)`
   && {
      background-color: ${theme.colorWhite};
      padding: 16px;
   }
 `;

export const CardColumn = styled(Grid)`
  && {
    word-break: break-word;
  }
`;

export const CustomerSizeCardColumn = styled(CardColumn)`
  && {
    text-align: center;

    @media ${theme.media.tablet} {
      text-align: left; 
    }
  }
`;

export const TargetedCardColumn = styled(CardColumn)`
  && {
    justify-content: flex-start;

    @media ${theme.media.tablet} {
      justify-content: center;
    }
  }
`;

export const TargetedSizeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media ${theme.media.tablet} {
    align-items: flex-start;
  }
  @media ${theme.media.laptop} {
    align-items: center; 
  }
`;

export const AvailableCardColumn = styled(CardColumn)`
  && {
    justify-content: center;

    @media ${theme.media.tablet} {
      justify-content: flex-end;
    }
  }
`;

export const AvailableSizeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  @media ${theme.media.tablet} {
    align-items: flex-end;
  }
  @media ${theme.media.laptop} {
    align-items: center;
  }
`;

export const OpportunityCardColumn = styled(CardColumn)`
  && {
    justify-content: flex-start;
    
    @media ${theme.media.tablet} {
      justify-content: center;
    }
  }
`;

export const UntargetedSizeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  @media ${theme.media.tablet} {
    align-items: flex-start;
    & > div {
      align-items: flex-start;
    }
  }
`;

export const ButtonCardColumn = styled(CardColumn)`
  && {
    justify-content: center;
    
    @media ${theme.media.tablet} {
     justify-content: flex-end;
     align-items: flex-start;
    }
  }
`;