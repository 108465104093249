import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { IAllIntegrationsResponse, IIntegrationState } from '../../types';

const defaultState: IIntegrationState = {
  integrations: {},
  should_refetch: true
};

/**
 * Get all integrations actions handlers
 */
 const allIntegrationsRequest = (state: IIntegrationState) => {
   return {
    ...state,
    is_data_requested: true,
  }
 };

const allIntegrationsSuccess = (state: IIntegrationState, payload: IAllIntegrationsResponse) => ({
  ...state,
  ...payload,
  is_data_requested: false,
  should_refetch: false,
});

const allIntegrationsFailed = (state: IIntegrationState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Toggle integration flags actions handlers
 */
 const toggleIntegrationsRequest = (state: IIntegrationState) => {
   return {
    ...state,
    is_data_requested: true,
  }
 };

const toggleIntegrationsSuccess = (state: IIntegrationState) => ({
  ...state,
  is_data_requested: false,
});

const toggleIntegrationsFailed = (state: IIntegrationState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Set integration credentials actions handlers
 */
 const setIntegrationCredentialsRequest = (state: IIntegrationState) => {
   return {
    ...state,
    is_data_requested: true,
  }
 };

const setIntegrationCredentialsSuccess = (state: IIntegrationState) => ({
  ...state,
  is_data_requested: false,
});

const setIntegrationCredentialsFailed = (state: IIntegrationState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

export const integration = createReducer<IIntegrationState>({ }, defaultState)
  // all integrations
  .on(actions.allIntegrationsRequest, allIntegrationsRequest)
  .on(actions.allIntegrationsSuccess, allIntegrationsSuccess)
  .on(actions.allIntegrationsFailed, allIntegrationsFailed)
  // create integration
  .on(actions.toggleIntegrationsRequest, toggleIntegrationsRequest)
  .on(actions.toggleIntegrationsSuccess, toggleIntegrationsSuccess)
  .on(actions.toggleIntegrationsFailed, toggleIntegrationsFailed)
  // set integration credentials
  .on(actions.setIntegrationCredentialsRequest, setIntegrationCredentialsRequest)
  .on(actions.setIntegrationCredentialsSuccess, setIntegrationCredentialsSuccess)
  .on(actions.setIntegrationCredentialsFailed, setIntegrationCredentialsFailed)
;

export default integration;