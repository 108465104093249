import {
  IUserModel,
  IUserMeta,
  IUserProfile,
  IUserModelLocalStorage,
  IUserMetaLocalStorage,
} from '../../types';
import moment, { HTML5_FMT } from 'moment';

interface IUserLocalStore {
  user?: IUserModel,
  meta?: IUserMeta,
  profile?: IUserProfile,
}

export const saveUserToStorage = ({ user, meta, profile }: IUserLocalStore): void => {
  const storedUser = getUserObjectFromStorage();
  localStorage.setItem('user', JSON.stringify({
    ...storedUser,
    ...user,
    ...meta,
    ...profile,
  }));
};

export const clearUserFromStorage = (): void => {
  localStorage.removeItem('user');
};

export const getUserObjectFromStorage = (): IUserModelLocalStorage & IUserMetaLocalStorage => {
  return JSON.parse(localStorage.getItem('user') || '{}');
};

export const isUserTokenExpired = () => {
  const { expires_at = '' } = getUserObjectFromStorage();
  const expired = moment(expires_at, HTML5_FMT.DATETIME_LOCAL_MS);
  const isExpired = !expired.isValid() || expired.isBefore(moment());
  // clear user info from storage if token is expired
  if (expired.isBefore(moment())) {
    clearUserFromStorage();
  }

  return isExpired;
};
