import * as React from 'react';
import { MoneyBagIcon } from '../Icons';
import theme from '../../assets/css/theme';
import styled from 'styled-components';

interface IProps {
  value: number | string,
}

const TargetedOpportunityValue = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 1.125rem;
text-align: left;
color: ${theme.colorDarkGray};
`;

const Label = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: normal;
font-size: 0.8125rem;
text-align: left;
color: ${theme.colorDarkGray};
`;

const TargetedOpportunityWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
`;

const TargetedOpportunityValueWrapper = styled.div`
padding-left: ${(props: ISpaceSizeProp) => props.spaceSize || '1.5rem'};
`;

interface ISpaceSizeProp {
  spaceSize?: string;
}

interface IProps extends ISpaceSizeProp {
  value: number | string;
}

export class CampaignTargetedOpportunitySize extends React.Component<IProps> {
  public render() {
    return (
      <TargetedOpportunityWrapper>
        <MoneyBagIcon />
        <TargetedOpportunityValueWrapper spaceSize={this.props.spaceSize}>
          <TargetedOpportunityValue>${this.props.value.toLocaleString('en')}</TargetedOpportunityValue>
          <Label>Targeted Opportunity</Label>
        </TargetedOpportunityValueWrapper>
      </TargetedOpportunityWrapper>
    );
  }
}

export default CampaignTargetedOpportunitySize;
