import * as React from 'react';

interface IProps {
  direction: string,
  color: string
}

export class Arrow extends React.Component<IProps>{

  public render () {
    const transform = (this.props.direction === 'high') ? "translate(8 10.073) rotate(180)" : "";

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10.073" viewBox="0 0 8 10.073">
        <path d="M5,6.044V1.007A.948.948,0,0,0,4,0H4A.948.948,0,0,0,3,1.007V6.044H0l4,4.029L8,6.044Z" transform={transform} fill={this.props.color}/>
      </svg>
    );
  }
}

export default Arrow;
