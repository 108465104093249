import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';
import theme from '../../assets/css/theme';
import styled from 'styled-components';

const TypographyStyled = styled(Typography)`
  && {
    display: inline-flex;
    cursor: pointer;
    flex-direction: row;
    align-content: center;
  }
`;

const useStyles = makeStyles(themeStyles => ({
  title: {
    flexGrow: 0,
    fontFamily: theme.ffInterRegular,
    fontSize: '0.9375rem',
    color: theme.titleColor,
    marginRight: 2,
  },
  expandMore: {
    color: '#a4afb7',
  },
}));

interface IProps {
  nickname: string
}

export const TopMenu: React.FC<IProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <TypographyStyled aria-controls="simple-menu" aria-haspopup="true" onClick={ handleClick } color="inherit" noWrap={true} className={classes.title}>
        { props.nickname }
        <ExpandMoreIcon className={classes.expandMore}/>
      </TypographyStyled>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        { props.children }
      </Menu>
    </React.Fragment>
  );
}

export default TopMenu;
