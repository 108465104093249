import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import {
  ICustomersCount,
  ILifeCicleSuccessPayload,
  IOpportunityValue,
  ISegmentCard,
  ISegmentRequestApi,
  ISegmentState,
} from '../../types';

const defaultState: ISegmentState = {
  customers: {
    segments: [],
    total: 0,
    contactableTotal: 0,
    emailTotal: 0,
    phoneTotal: 0,
  },
  opportunity_value: [],
  segment_cards: [],
  audiences_cards: [],
  current_segment: {
    segment_id: 0,
    avgOrderValue: 0,
    frequency: 0,
    recency: 0,
    spend: 0,
    size_total: 0,
    size_last_7: {
      value: 0,
      rate: ''
    },
    size_last_30: {
      value: 0,
      rate: ''
    },
    size_total_proportion: 0,
    untagged_opportunity: 0,
  }
};

/**
 * Customer sizes actions handlers
 */
const customersCountRequest = (state: ISegmentState) => ({
  ...state,
  is_data_requested: true,
});

const customersCountSuccess = (state: ISegmentState, payload: ICustomersCount) => ({
  ...state,
  customers: {
    ...payload,
  },
  is_data_requested: false,
});

const customersCountFailed = (state: ISegmentState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Opportunity Value actions handlers
 */
const opportunityValueRequest = (state: ISegmentState) => ({
  ...state,
  is_data_requested: true,
});

const opportunityValueSuccess = (state: ISegmentState, payload: IOpportunityValue[]) => ({
  ...state,
  opportunity_value: payload,
  is_data_requested: false,
});

const opportunityValueFailed = (state: ISegmentState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Segment cards actions handlers
 */
const segmentCardRequest = (state: ISegmentState) => ({
  ...state,
  is_data_requested: true,
});

const segmentCardSuccess = (state: ISegmentState, payload: ISegmentCard[]) => ({
  ...state,
  ...{
    segment_cards: payload,
  },
  is_data_requested: false,
});

const segmentCardFailed = (state: ISegmentState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

const segmentCardClean = () => ({
  ...defaultState,
});

/**
 * Lifecycle actions handlers
 */
const lifecycleRequest = (state: ISegmentState, payload: ISegmentRequestApi) => ({
  ...state,
  is_data_requested: true,
});

const lifecycleSuccess = (state: ISegmentState, payload: ILifeCicleSuccessPayload) => ({
  ...state,
  current_segment: payload.segment,
  audiences_cards: payload.audiences,
  is_data_requested: false,
});

const lifecycleFailed = (state: ISegmentState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

export const segment = createReducer<ISegmentState>({ }, defaultState)
  // customers sizes
  .on(actions.customersCountRequest, customersCountRequest)
  .on(actions.customersCountSuccess, customersCountSuccess)
  .on(actions.customersCountFailed, customersCountFailed)
  // opportunity value
  .on(actions.opportunityValueRequest, opportunityValueRequest)
  .on(actions.opportunityValueSuccess, opportunityValueSuccess)
  .on(actions.opportunityValueFailed, opportunityValueFailed)
  // segments cards
  .on(actions.segmentCardRequest, segmentCardRequest)
  .on(actions.segmentCardSuccess, segmentCardSuccess)
  .on(actions.segmentCardFailed, segmentCardFailed)
  .on(actions.segmentCardClean, segmentCardClean)
  // lifecycle view
  .on(actions.lifecycleRequest, lifecycleRequest)
  .on(actions.lifecycleSuccess, lifecycleSuccess)
  .on(actions.lifecycleFailed, lifecycleFailed)
;

export default segment;
