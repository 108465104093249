import React from 'react';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { GRID_SIZE_0, GRID_SIZE_12, GRID_SIZE_6 } from '../../config';
import { LoadingSkeleton } from '../../components/LoadingSkeleton'
import { OpportunitySegmentCardContainer, FullWidthTwoElements, FullWidthElement } from './common'

const LoadingSizeHasChangedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-start;
`;

const LoadingOpportunitySegmentCard = () => {
  return (
    <OpportunitySegmentCardContainer>
      <Box width="32%">
        <LoadingSkeleton variant="text" />
      </Box>
      <Box width="48%">
        <LoadingSkeleton variant="text" />
      </Box>
      <FullWidthTwoElements>
        <Box width="40%" height="28px">
          <LoadingSkeleton variant="rect" height="100%" />
        </Box>
        <Box width="40%" height="28px">
          <LoadingSkeleton variant="rect" height="100%" />
        </Box>
      </FullWidthTwoElements>
      <FullWidthElement>
        <LoadingSizeHasChangedContainer>
          <LoadingSkeleton variant="rect" width="20%" height="20px" />
          <LoadingSkeleton variant="rect" width="20%" height="20px" />
        </LoadingSizeHasChangedContainer>
      </FullWidthElement>
      <FullWidthElement>
        <LoadingSkeleton variant="rect" />
      </FullWidthElement>

      <Grid container={true} spacing={GRID_SIZE_0} alignItems="center">
        <Grid item={true} sm={GRID_SIZE_12} md={GRID_SIZE_6}>
        <LoadingSkeleton variant="rect" height="40px" />
        </Grid>
        <Grid container={true} item={true} sm={GRID_SIZE_12} md={GRID_SIZE_6} justifyContent="flex-end">
          <LoadingSkeleton variant="rect" width="100%" height="40px" />
        </Grid>
      </Grid>
    </OpportunitySegmentCardContainer>
  )
}
export default LoadingOpportunitySegmentCard;
