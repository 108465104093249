import React from 'react';
import { DateRangePicker, DateRangePickerShape } from 'react-dates';
import moment, { Moment } from 'moment'
import { NavNext } from './NavNext';
import { NavPrev } from './NavPrev';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../../assets/css/react_dates_overrides.css'

export type DateType = Date | number;

export interface IOnDatesChange {
  startDate: DateType | null;
  endDate: DateType | null;
}
export interface IMomentOnDatesChange {
  startDate: Moment | null;
  endDate: Moment | null;
}

interface IProps extends Omit<DateRangePickerShape, 'startDate' | 'endDate' | 'onDatesChange' | 'onClose'> {
  startDate: Date | number;
  endDate: Date | number;
  onDatesChange: (dates: IOnDatesChange) => void;
  onClose: (dates: IOnDatesChange) => void;
}

const convertToMoment = (startDate: DateType, endDate: DateType) => {
  // convert from moment to date fns as moment is deprecated throughout our platform
  const momentStartDate = moment(startDate)
  const momentEndDate = moment(endDate)
  return { momentStartDate, momentEndDate }
}

const convertFromMoment = (momentStartDate: Moment | null, momentEndDate: Moment | null) => {
  let startDate = null;
  let endDate = null;

  if (momentStartDate) {
    startDate = momentStartDate.toDate()
  }

  if (momentEndDate) {
    endDate = momentEndDate.toDate()
  }

  return { startDate, endDate }
}

export const ReportingDateRangePicker = ({startDate, endDate, ...props}: IProps) => {
  const onDatesChange = (changeProps: IMomentOnDatesChange) => {
    const convertedDates = convertFromMoment(changeProps.startDate, changeProps.endDate);
    props.onDatesChange({startDate: convertedDates.startDate, endDate: convertedDates.endDate})
  }

  const onDateRangePickerClose = (closeProps: IMomentOnDatesChange) => {
    const convertedDates = convertFromMoment(closeProps.startDate, closeProps.endDate);
    props.onClose({startDate: convertedDates.startDate, endDate: convertedDates.endDate})
  }

  const { momentStartDate, momentEndDate } = convertToMoment(startDate, endDate)
  return (
    <DateRangePicker
      {...props}
      startDate={momentStartDate}
      endDate={momentEndDate}
      onDatesChange={onDatesChange}
      onClose={onDateRangePickerClose}
      navNext={<NavNext />}
      navPrev={<NavPrev />}
    />
  )
}

export default ReportingDateRangePicker;
