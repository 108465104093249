import * as React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
min-width: 40px;
min-height: 40px;
width: 2.5rem;
height: 2.5rem;
border: none;
background-color: ${props => props.color || '#f0f0f7'};;
border-radius: 50%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`;

interface IProps {
  color?: string;
  bgColor?: string;
}

export class  CountDownIcon extends React.Component<IProps>{
  public render() {
    return (
      <Circle color={this.props.bgColor}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="15.999" viewBox="0 0 16.001 15.999">
          <path d="M0,9A6.957,6.957,0,0,1,7,2a6.845,6.845,0,0,1,4.2,1.4l.9-.9-.8-.8a.966.966,0,0,1,0-1.4.968.968,0,0,1,1.4,0l3,3a.967.967,0,0,1,0,1.4.968.968,0,0,1-1.4,0l-.8-.8-.9.9A6.847,6.847,0,0,1,14,9a6.957,6.957,0,0,1-7,7A6.957,6.957,0,0,1,0,9ZM2,9a4.952,4.952,0,0,0,5,5,4.953,4.953,0,0,0,5-5A4.951,4.951,0,0,0,7,4,4.951,4.951,0,0,0,2,9Zm4,1V6H8V8h2v2Z" transform="translate(0 0)" fill={this.props.color || '#b4b4c6'}/>
        </svg>
      </Circle>
    );
  }
}

export default CountDownIcon;
