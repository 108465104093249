import styled from 'styled-components'
import MUICheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const Checkbox = styled(MUICheckbox)`
  &&&.MuiButtonBase-root {
    padding: 4px;
  }
`;

export const CheckboxLabel = styled(FormControlLabel)`
  &&& {
    align-items: flex-start;
  }

  &&& > span.MuiTypography-root {
    font-size: 14px;
    margin-left: 4px;
    margin-top: 4px;
  }
`