import * as React from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { Typography } from '@material-ui/core';

interface IProp {
  title: string,
  subtitle: string,
}

const Title = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 1.125rem;
line-height: 1.39;
color: ${theme.colorDarkGray};
`;

const SubTitle = styled(Typography)`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 0.875rem;
line-height: 1.79;
color: ${theme.titleColor};
opacity: 0.5;
height: 3.25rem;
`;

export class BoxTitle extends React.Component<IProp>{
  public render() {
    return (
      <div>
        <Title>{this.props.title}</Title>
        <SubTitle>{this.props.subtitle}</SubTitle>
      </div>
    );
  }
}

export default BoxTitle;
