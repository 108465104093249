import { IStoreState } from '../types';
import { map } from 'lodash';
import segmentColors from '../../config/segmentColors';
import moment from 'moment';

export const campaignsSummary = ({ campaign }: IStoreState) => campaign.campaigns_summary;

export const activeCampaigns = ({ campaign }: IStoreState) => ({
  total: campaign.active_campaigns.total,
  data: map(campaign.active_campaigns.segments, item => ({
    name: item.segment,
    y: item.count,
    color: segmentColors[item.segment],
  }))
  });

export const campaignPerformanceData = ({ campaign: { campaigns_summary } }: IStoreState) => {
  let incrementalRevenue = 0
  let expectedRevenue = 0

  if (campaigns_summary) {
    incrementalRevenue = campaigns_summary.incremental_revenue
    const revenue = campaigns_summary.revenue
    expectedRevenue = revenue - incrementalRevenue
  }
  
  return [
    {
      name: 'Incremental Revenue',
      data: [incrementalRevenue],
    },
    {
      name: 'Expected Revenue',
      data: [expectedRevenue],
    },
  ]
};

export const campaignPerformanceCategories = ({ campaign: { campaigns_performance } }: IStoreState) => (campaigns_performance ? campaigns_performance.categories : ['0']);

export const segmentCards = ({ campaign }: IStoreState) => campaign.segment_cards;

export const campaignsCards = ({ campaign }: IStoreState) => (map(campaign.campaigns_cards, card => ({
  ...card,
  start_date: moment.utc(card.start_date).format('DD-MMMM-YY'),
  end_date: moment.utc(card.end_date).format('DD-MMMM-YY'),
  targeted_opportunity: (card.targeted_opportunity ? card.targeted_opportunity : 0)
})));

export const segmentCampaignsNumPages = ({ campaign }: IStoreState) => campaign.segment_campaigns_page_pagination.total_num_pages;

export const campaignsEngageCurrentPage = ({ campaign }: IStoreState) => campaign.segment_campaigns_page_pagination.current_page;

export const campaignsEngagePageLimit = ({ campaign }: IStoreState) => campaign.segment_campaigns_page_pagination.page_limit;

export const campaignStoresData = ({ campaign }: IStoreState) => campaign.campaign_store_revenues;
