import { createContext } from 'react';
import queryString from 'query-string'
import { Moment } from 'moment';
import {
  IContactabilityFilter,
} from '../../modules/types';
import { EMAIL_FILTER } from './CampaignForm/ContactabilityFilter';

interface IDateFilterValue {
  startDate: Moment | null,
  setStartDate: (val: Moment | null) => void,
  endDate: Moment | null,
  setEndDate: (val: Moment | null) => void,
  isStartDateNotToday: boolean | null,
  isStartDateTomorrow: boolean | null
}

export const DateFilterContext = createContext<IDateFilterValue>({
  startDate: null,
  setStartDate: () => {
    return
  },
  endDate: null, 
  setEndDate: () => {
    return
  },
  isStartDateNotToday: false,
  isStartDateTomorrow: false
});

const queryParamToArr = (param: string | string[]) => {
  if (typeof param === 'string') {
    return [param]
  }
  return param
}

/* tslint:disable-next-line */
export const getFilters = (
  search: string,
  contactabilityFiltersAvailable: IContactabilityFilter[]
) => {

  const parsedQueryString = queryString.parse(search) || {};

  let exclude_stores: string[] = [];
  if (parsedQueryString?.excludeStores) {
    exclude_stores = queryParamToArr(parsedQueryString.excludeStores)
  }

  const contactabilityEmailFilter = contactabilityFiltersAvailable.find(filter => filter.channel === EMAIL_FILTER)
  let contactability_filters: IContactabilityFilter[] = [];
  if (parsedQueryString?.contactabilityFilters && contactabilityEmailFilter) {
    const activeContactabilityFilters = queryParamToArr(parsedQueryString.contactabilityFilters)

    if (activeContactabilityFilters.includes(EMAIL_FILTER)) {
      contactability_filters = [contactabilityEmailFilter]
    }
  }

  let udf_filters  = [];
  if (parsedQueryString?.udfFilters) {
    udf_filters = JSON.parse(parsedQueryString.udfFilters) || [];
  }

  let exclusion_dates: string[] = [];
  if (parsedQueryString?.exclusionDates) {
    exclusion_dates =  queryParamToArr(parsedQueryString.exclusionDates)
  }
  return { exclude_stores, contactability_filters, udf_filters, exclusion_dates}
}
