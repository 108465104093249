import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import {
  ICampaignReDownloadData,
  ICampaignsCard,
  ICampaignState,
  ICampaignSummaryRequestPayload,
  ICampaignSummaryResponse,
  ISegmentCampaignRequestPayload,
  ICampaignUpdateDatesRequestPayload,
  ISegmentCampaignsResponse,
  ICampaignStoresRevenuePayload,
  ICampaignStoresRevenueResponse,
  ICampaignCreationStatusResponse,
  IContactabilityFiltersResponse,
  ICampaignResponse,
  ICampaignEntriesResponse,
  ICampaignsParticipantLifecycleResponse,
  INotPurchasedCampaignListRequest
} from '../../types';
import { isEmpty } from 'lodash';

const defaultState: ICampaignState = {
  start_date: '',
  end_date: '',
  campaigns_summary: {
    campaigns: 0,
    revenue: 0,
    incremental_revenue: 0,
  },
  active_campaigns: {
    segments: [],
    total: 0,
  },
  campaigns_performance: null,
  segment_cards: [],
  campaigns_cards: [],
  segment_campaigns_page_pagination: {
    current_page: 0,
    total_num_pages: 0,
    page_limit: 20,
  },
  campaign_store_revenues: [],
  campaign_creation_statuses: [],
  contactability_filters: [],
  campaign: null,
  calendar_entries: [],
  campaign_participant: []
};

/**
 * Campaign segments summary actions handlers
 */
const campaignsSegmentSummaryRequest = (state: ICampaignState, payload: ICampaignSummaryRequestPayload) => ({
  ...state,
  is_campaign_segment_data_requested: true,
});

const campaignsSegmentSummarySuccess = (state: ICampaignState, payload: ICampaignSummaryResponse) => ({
  ...state,
  campaigns_summary: payload.campaigns_summary,
  active_campaigns: payload.active_campaigns,
  campaigns_performance: payload.campaigns_performance,
  segment_cards: payload.segment_cards,
  is_campaign_segment_data_requested: false,
})

const campaignsSegmentSummaryFailed = (state: ICampaignState, payload: string) => ({
  ...state,
  is_campaign_segment_data_requested: false,
});

const campaignsSummaryClean = () => ({
  ...defaultState,
});

/**
 * Campaigns of certain Segment Actions
 */
const segmentCampaignsRequest = (state: ICampaignState, payload: ISegmentCampaignRequestPayload) => ({
  ...state,
  is_data_requested: true,
});

const segmentCampaignsSuccess = (state: ICampaignState, payload: ISegmentCampaignsResponse) => {
  let page: number;
  if (isEmpty(payload)) {
    page = state.segment_campaigns_page_pagination.current_page
  } else {
    page = payload.current_page
  }

  return {
    ...state,
    campaigns_cards: [
      ...payload.campaigns,
    ],
    segment_campaigns_page_pagination: {
      ...state.segment_campaigns_page_pagination,
      current_page: page,
      total_num_pages: payload.total_num_pages,
    },
    is_data_requested: false,
  };
}

const segmentCampaignsFailed = (state: ICampaignState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Download Data File Of Active Campaign Actions
 */
const activeCampaignDownloadDataRequest = (state: ICampaignState, payload: ICampaignReDownloadData) => ({
  ...state,
  is_data_requested: true,
});

const activeCampaignDownloadDataSuccess = (state: ICampaignState) => ({
  ...state,
  is_data_requested: false,
});

const activeCampaignDownloadDataFailed = (state: ICampaignState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Download campaign control group CSV
 */
const campaignDownloadControlCSVRequest = (state: ICampaignState, payload: ICampaignReDownloadData) => ({
  ...state,
  is_data_requested: true,
});

const campaignDownloadControlCSVSuccess = (state: ICampaignState) => ({
  ...state,
  is_data_requested: false,
});

const campaignDownloadControlCSVFailed = (state: ICampaignState, payload: string) => ({
  ...state,
  is_data_requested: false,
});


/**
 * Download Data File Of Active Campaign Actions
 */
const retargetCampaignRequest = (state: ICampaignState, payload: INotPurchasedCampaignListRequest) => ({
  ...state,
  is_data_requested: true,
});

const retargetCampaignSuccess = (state: ICampaignState) => ({
  ...state,
  is_data_requested: false,
});

const retargetCampaignFailed = (state: ICampaignState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Update campaign dates
 */
const campaignUpdateDatesRequest = (state: ICampaignState, payload: ICampaignUpdateDatesRequestPayload) => ({
  ...state,
  is_data_requested: true,
})

const campaignUpdateDatesSuccess = (state: ICampaignState, payload: ICampaignsCard) => {
  const campaign_cards = state.campaigns_cards.map(card => {
    if (card.campaign_id === payload.campaign_id) {
      return payload
    }

    return card
  })
  
  return {
    ...state,
    campaigns_cards: campaign_cards,
    is_data_requested: false,
  }
};

const campaignUpdateDatesFailed = (state: ICampaignState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Fetch campaign store revenues
 */
const campaignStoresRevenueRequest = (state: ICampaignState, payload: ICampaignStoresRevenuePayload) => ({
  ...state,
  is_data_requested: true,
})

const campaignStoresRevenueSuccess = (state: ICampaignState, payload: ICampaignStoresRevenueResponse) => {
  return {
    ...state,
    campaign_store_revenues: payload.campaignStoreRevenues,
    is_data_requested: false,
  }
};

const campaignStoresRevenueFailed = (state: ICampaignState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Fetch campaign creation statuses
 */
const campaignCreationStatusesRequest = (state: ICampaignState) => ({
  ...state,
  is_data_requested: true,
})

const campaignCreationStatusesSuccess = (state: ICampaignState, payload: ICampaignCreationStatusResponse) => {
  return {
    ...state,
    campaign_creation_statuses: payload.campaignCreationStatuses,
    is_data_requested: false,
  }
};

const campaignCreationStatusesFailed = (state: ICampaignState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Fetch contactability filters
 */
 const contactabilityFiltersRequest = (state: ICampaignState) => ({
  ...state,
  is_data_requested: true,
})

const contactabilityFiltersSuccess = (state: ICampaignState, payload: IContactabilityFiltersResponse) => {
  return {
    ...state,
    contactability_filters: payload.contactabilityFilters,
    is_data_requested: false,
  }
};

const contactabilityFiltersFailed = (state: ICampaignState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Fetch campaign
 */
const campaignRequest = (state: ICampaignState) => ({
  ...state,
  is_data_requested: true,
})

const campaignSuccess = (state: ICampaignState, payload: ICampaignResponse) => {
  return {
    ...state,
    campaign: payload,
    is_data_requested: false,
  }
};

const campaignFailed = (state: ICampaignState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Fetch calendar entries
 */

const calendarEntriesRequest = (state: ICampaignState) => {
  return {
    ...state,
    is_data_requested: true,
  }
};

const calendarEntriesSuccess = (state: ICampaignState, payload: ICampaignEntriesResponse) => {
  return {
    ...state,
    calendar_entries: payload.entries,
    is_data_requested: false,
  }
}

const calendarEntriesFailed = (state: ICampaignState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Fetch campaign participant
 */

const campaignsParticipantLifecycleRequest = (state: ICampaignState) => {
  return {
    ...state,
    is_data_requested: true,
  }
};

const campaignsParticipantLifecycleSuccess = (state: ICampaignState, payload: ICampaignsParticipantLifecycleResponse) => {
  return {
    ...state,
    campaign_participant: payload.campaign_participants,
    is_data_requested: false,
  }
}

const campaignsParticipantLifecycleFailed = (state: ICampaignState) => ({
  ...state,
  is_data_requested: false,
});




export const campaign = createReducer<ICampaignState>({ }, defaultState)
  // customers sizes
  .on(actions.campaignsSegmentSummaryRequest, campaignsSegmentSummaryRequest)
  .on(actions.campaignsSegmentSummarySuccess, campaignsSegmentSummarySuccess)
  .on(actions.campaignsSegmentSummaryFailed, campaignsSegmentSummaryFailed)
  .on(actions.campaignsSummaryClean, campaignsSummaryClean)
  // segment campaigns
  .on(actions.segmentCampaignsRequest, segmentCampaignsRequest)
  .on(actions.segmentCampaignsSuccess, segmentCampaignsSuccess)
  .on(actions.segmentCampaignsFailed, segmentCampaignsFailed)
  // data file of active campaign
  .on(actions.activeCampaignDownloadDataRequest, activeCampaignDownloadDataRequest)
  .on(actions.activeCampaignDownloadDataSuccess, activeCampaignDownloadDataSuccess)
  .on(actions.activeCampaignDownloadDataFailed, activeCampaignDownloadDataFailed)
  // download campaign control group csv
  .on(actions.campaignDownloadControlCSVRequest, campaignDownloadControlCSVRequest)
  .on(actions.campaignDownloadControlCSVSuccess, campaignDownloadControlCSVSuccess)
  .on(actions.campaignDownloadControlCSVFailed, campaignDownloadControlCSVFailed)
  // update campaign dates
  .on(actions.campaignUpdateDatesRequest, campaignUpdateDatesRequest)
  .on(actions.campaignUpdateDatesSuccess, campaignUpdateDatesSuccess)
  .on(actions.campaignUpdateDatesFailed, campaignUpdateDatesFailed)
  // fetch campaign store revenue
  .on(actions.campaignStoresRevenueRequest, campaignStoresRevenueRequest)
  .on(actions.campaignStoresRevenueSuccess, campaignStoresRevenueSuccess)
  .on(actions.campaignStoresRevenueFailed, campaignStoresRevenueFailed)
  // fetch campaign creation statuses
  .on(actions.campaignCreationStatusesRequest, campaignCreationStatusesRequest)
  .on(actions.campaignCreationStatusesSuccess, campaignCreationStatusesSuccess)
  .on(actions.campaignCreationStatusesFailed, campaignCreationStatusesFailed)
  // fetch contactability filters
  .on(actions.contactabilityFiltersRequest, contactabilityFiltersRequest)
  .on(actions.contactabilityFiltersSuccess, contactabilityFiltersSuccess)
  .on(actions.contactabilityFiltersFailed, contactabilityFiltersFailed)
  // fetch campaign
  .on(actions.campaignRequest, campaignRequest)
  .on(actions.campaignSuccess, campaignSuccess)
  .on(actions.campaignFailed, campaignFailed)
  // fetch calendar entries
  .on(actions.calendarEntriesRequest, calendarEntriesRequest)
  .on(actions.calendarEntriesSuccess, calendarEntriesSuccess)
  .on(actions.calendarEntriesFailed, calendarEntriesFailed)
  // fetch campaign participants
  .on(actions.campaignsParticipantLifecycleRequest, campaignsParticipantLifecycleRequest)
  .on(actions.campaignsParticipantLifecycleSuccess, campaignsParticipantLifecycleSuccess)
  .on(actions.campaignsParticipantLifecycleFailed, campaignsParticipantLifecycleFailed)
  // generate not purchased list for campaign
  .on(actions.retargetCampaignRequest, retargetCampaignRequest)
  .on(actions.retargetCampaignSuccess, retargetCampaignSuccess)
  .on(actions.retargetCampaignFailed, retargetCampaignFailed)
;

export default campaign;
