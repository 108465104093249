export const RADIO_SELECT_GROUP = [
    'Today',
    'On the first day of the campaign',
    '24 hours before the campaign starts',
    '48 hours before the campaign starts',
]

export const RADIO_SELECT_GROUP_ALT = [
    'Today',
    'On the first day of the campaign',
    '24 hours before the campaign starts',
]

export const RADIO_BUTTON_TITLE = 'Generate CSV:';

export const INFO_MESSAGES = [
    'today',
    'the day this campaign starts',
    'the day before this campaign starts',
    'two days before this campaign starts'
  ]
  