type Callback = (data?: object) => void;

// notify a component when password can be clean
export const EVENT_PASSWORD_CLEAN = 'user:password:clean';

/**
 * Event Emitter pattern
 */
export const EventEmitter = {
  events: {},
  dispatch (event: string, data: object) {
    if (!this.events[event]) {
      return;
    }
    this.events[event].forEach((callback: Callback) => callback(data))
  },
  subscribe (event: string, callback: Callback) {
    if (!this.events[event]) {
      this.events[event] = [];
    }

    this.events[event].push(callback);
  }
};
