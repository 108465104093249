import React, { useState, useCallback, useEffect } from 'react';
import { Link, LinkProps, withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import styled from 'styled-components'
import { variant } from 'styled-system'
import { ArrowUpIcon, ArrowDownIcon } from '../../components/Icons'
import theme from '../../assets/css/theme';
import { useWindowSize } from '../../utils/hooks'
import { getCombinedPathAndQuery } from '../../utils/queryParams'

const COLLAPSE_WIDTH_THRESHOLD = theme.size.tabletL;

interface ISecondaryMenuItem {
  label: string,
  to: string
}

interface IMenuItem {
  label: string,
  icon: JSX.Element,
  isSidebarOpen: boolean,
  secondaryMenuItems?: ISecondaryMenuItem[]
}

interface ISubmenuItem {
  secondaryMenuItems: ISecondaryMenuItem[]
}

interface IMenuItemProps extends RouteComponentProps, LinkProps, IMenuItem {
  active: string[],
}

interface IContainerProps {
  isActive: boolean
}

interface IArrowItem {
  hasSecondaryMenuItems: boolean,
  isSidebarOpen: boolean,
  toggleIsMenuExpanded: () => void,
  isMenuExpanded: boolean,
  isTablet: boolean,
}

export const MenuItem = ({active, isSidebarOpen, secondaryMenuItems, ...props}: IMenuItemProps) => {
  const location = useLocation()
  const combinedPathAndQuery = getCombinedPathAndQuery(location.pathname, location.search)
  const isActive = active.reduce((prev, item) => (prev || ((new RegExp(item)).test(combinedPathAndQuery))), false);
  const secondaryPaths = secondaryMenuItems?.map(menuItem => menuItem.to)
  const [isMenuExpanded, setIsMenuExpanded] = useState(false)
  
  const { width: windowWidth = window.innerWidth } = useWindowSize();
  const isTablet = windowWidth <= COLLAPSE_WIDTH_THRESHOLD;

  useEffect(() => {
    if (!isMenuExpanded && secondaryPaths?.includes(combinedPathAndQuery)) {
      setIsMenuExpanded(true)
    }

  }, [ combinedPathAndQuery, isMenuExpanded, secondaryPaths ])

  const toggleIsMenuExpanded = useCallback(() => setIsMenuExpanded(!isMenuExpanded), [isMenuExpanded])

  const useStyles = makeStyles(themeStyle => ({
    menuItemActive: {
      backgroundColor: theme.colorWhite,
      borderLeft: `5px solid ${theme.colorWhite}`,
      '& > div': {
        marginLeft: '-5px',
      }
    },
    listItemIconOpen: {
      minWidth: 27,
      transition: themeStyle.transitions.create('min-width', {
        easing: themeStyle.transitions.easing.sharp,
        duration: themeStyle.transitions.duration.leavingScreen,
      }),
    },
  }));

  const classes = useStyles();

  return (
    <Container isActive={isActive}>
      <MenuItemContainer isActive={combinedPathAndQuery === props.to}>
        <LinkStyled to={props.to}>
          <ListItemStyled button={true}>
            <ListItemIcon className={clsx(isSidebarOpen && classes.listItemIconOpen)}>
              {React.cloneElement(props.icon, {color: isActive ? theme.colorWhite : theme.colorWhite})}
            </ListItemIcon>
            <ListItemTextStyled primary={props.label} disableTypography={true}/>
          </ListItemStyled>
        </LinkStyled>
        <ArrowItem 
          hasSecondaryMenuItems={!!secondaryMenuItems} 
          isSidebarOpen={isSidebarOpen}
          toggleIsMenuExpanded={toggleIsMenuExpanded} 
          isMenuExpanded={isMenuExpanded}
          isTablet={isTablet}
        />
      </MenuItemContainer>
      {!!secondaryMenuItems && isMenuExpanded && <Submenu secondaryMenuItems={secondaryMenuItems} />}
    </Container>
  );
};

const ArrowItem = ({hasSecondaryMenuItems, isSidebarOpen, toggleIsMenuExpanded, isMenuExpanded, isTablet }: IArrowItem) => {
  return (
    <>
      {hasSecondaryMenuItems && isSidebarOpen && !isTablet && (
        /* tslint:disable-next-line */
        <ArrowIconContainer onClick={toggleIsMenuExpanded}>
          { isMenuExpanded ? <ArrowUpIcon color={theme.colorWhite} /> : <ArrowDownIcon color={theme.colorWhite} /> }
        </ArrowIconContainer>
      )}
    </>
  );
};

const Submenu = ({ secondaryMenuItems }: ISubmenuItem) => {
  const location = useLocation()
  const combinedPathAndQuery = getCombinedPathAndQuery(location.pathname, location.search)

  return (
    <>
      {secondaryMenuItems.map(({ label, to }) => (
        <MenuItemContainer key={to} isActive={combinedPathAndQuery === to}>
          <LinkStyled to={to}>
            <ListItemStyled button={true}>
              <SubmenuLabelContainer>
                <SubListItemTextStyled primary={label} disableTypography={true}/>
              </SubmenuLabelContainer>
            </ListItemStyled>
          </LinkStyled>
        </MenuItemContainer>
      ))}
    </>
  )
}

const Container = styled.div<IContainerProps>`
  padding-right: 12px;
  ${() => variant({
    prop: 'isActive',
    variants: {
      true: {
        backgroundColor: theme.colorBlack,
      },
    }
  })}
`

const MenuItemContainer = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  ${() => variant({
    prop: 'isActive',
    variants: {
      true: {
        borderLeft: `5px solid ${theme.colorWhite}`,
        '& > a > div': {
          marginLeft: '-5px',
        },
      },
    }
  })}
`

const SubmenuLabelContainer = styled.div`
  margin-left: 27px;
`

const ArrowIconContainer = styled.div`
  cursor: pointer;
`

const LinkStyled = styled(Link)`
  display: block;
  width: 100%;
  color: inherit;
  text-decoration: none;
`

const ListItemStyled = styled(ListItem)`
  padding: 11px 25px;
`

const ListItemTextStyled = styled(ListItemText)`
  font-size: 0.9475rem;
  font-family: ${theme.ffInterMedium};
`

const SubListItemTextStyled = styled(ListItemText)`
  font-size: 0.8375rem;
`

export default withRouter((props: IMenuItemProps) => {
  return <MenuItem {...props} />
});
