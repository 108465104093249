import React from 'react';
import { Box } from '@material-ui/core'
import { PLATFORM_CREDENTIALS } from '@loypal/lib-growth';
import { InputLabel } from '../../../components/Text';
import { InputText } from '../../../components/Form';
import { IIntegrationCreds } from './integration-options'; 

interface ICredsFormProps {
  platform: string
  setValue: (val: any) => void
  values: {[key: string]: string}
}

export const CredentialsInputs = ({ platform, setValue, values }: ICredsFormProps) => {
  const requiredCredentials: IIntegrationCreds[] = PLATFORM_CREDENTIALS[platform];

  const getCredValue = (key: string) => {
    if (!values.hasOwnProperty(key)) { return '' }

    return values[key]
  }

  return (
    <>
      <Box mb="12px" mt="24px">
        <InputLabel>Add required credentials</InputLabel>
      </Box>
      {
        requiredCredentials.map(({ key, label }) => {
          const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setValue({...values, [key]: event.target.value})
          }

          const value = getCredValue(key)

          return (
            <Box mb="12px" width="240px" key={key}>
              <InputText
                type="text"
                variant="outlined"
                label={label}
                margin="dense"
                value={value}
                fullWidth={true}
                name="name"
                onChange={handleChange}
              />
            </Box>
          )
        })
      }
    </>
  )
}