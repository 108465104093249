import * as React from 'react';
import { TrendUpIcon } from '../Icons';
import theme from '../../assets/css/theme';
import styled from 'styled-components';

interface IProps {
  value: number | string,
}

const IncrementalRevenueValue = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 1.125rem;
text-align: left;
color: ${theme.colorDarkGray};
`;

const Label = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: normal;
font-size: 0.8125rem;
text-align: left;
color: ${theme.colorDarkGray};
`;

const IncrementalRevenueWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
`;

const IncrementalRevenueValueWrapper = styled.div`
padding-left: ${(props: ISpaceSizeProp) => props.spaceSize || '1.5rem'};
`;

interface ISpaceSizeProp {
  spaceSize?: string;
}

interface IProps extends ISpaceSizeProp {
  value: number | string;
}

export class CampaignIncrementalRevenueSize extends React.Component<IProps> {
  public render() {
    return (
      <IncrementalRevenueWrapper>
        <TrendUpIcon />
        <IncrementalRevenueValueWrapper spaceSize={this.props.spaceSize}>
          <IncrementalRevenueValue>${this.props.value.toLocaleString('en')}</IncrementalRevenueValue>
          <Label>Incremental Revenue</Label>
        </IncrementalRevenueValueWrapper>
      </IncrementalRevenueWrapper>
    );
  }
}

export default CampaignIncrementalRevenueSize;
