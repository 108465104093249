import { useSegments } from './use-segment';

export interface IData {
  segmentID: number
  [key: string]: any
}

interface ISegmentedData {
  [key: number]: any
}

export interface IResult {
  segmentID: number
  segmentName: string
  [key: string]: any
}

const getAverage = (data: any, dataKey: string) => {
  // we should only account for numbers and treat null as null
  // null means we have not camptured this data, it is not the same as 0
  let total: null | number = null
  let countNotNull = 0
  for (const dataPoint of data) {
    if (dataPoint[dataKey] !== null) {
      countNotNull++
      if (total === null) {
        total = 0
      }

      total += dataPoint[dataKey]
    }
  }

  const avg = total === null ? null : total / countNotNull
  return avg
}

export const useAvgSegmentData = () => {
  const segments = useSegments()

  const getAvgSegmentData = (data: IData[], dataKey: string) => {
    const groupedData: ISegmentedData = {}

    for (const segmentData of data) {
      if (!segmentData.hasOwnProperty(dataKey)) {
        continue
      }
      
      if (!groupedData.hasOwnProperty(segmentData.segmentID)) {
        groupedData[segmentData.segmentID] = [segmentData]
      } else {
        groupedData[segmentData.segmentID].push(segmentData)
      }
    }

    const result: IResult[] = []

    for (const key of Object.keys(groupedData)) {
      const segment = groupedData[key]
      const segmentID = segment[0].segmentID

      
      const segmentDescription = segments.find(({ id }: any) => id === segmentID)
      const avg = getAverage(segment, dataKey)
      result.push({ segmentID, segmentName: segmentDescription.name, [dataKey]: avg })
    }

    return result
  }

   return getAvgSegmentData
}