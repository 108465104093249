import React, { useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { IStoreState } from '../modules/types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { userConfirmForgotPassword } from '../modules/actions/user';
import { isLoggedIn, clientName, clientUsername } from "../modules/selectors";
import { GRID_SIZE_12, GRID_SIZE_9, GRID_SIZE_6, GRID_SIZE_3, GRID_SIZE_0 } from '../config';
import Group4Login from '../assets/img/login/LOYPAL-HOME-IMAGE.png';
import LPLogo from '../assets/img/login/LOYPAL-LOGO-HORIZONTAL.png';
import { InputText, Button } from "../components/Form";
import theme from '../assets/css/theme';
import { CircularProgress } from '@material-ui/core';

interface IProps extends RouteComponentProps {
  readonly client_name: string;
  readonly username: string;
  readonly confirmationCode: string;
  readonly newPassword: string;
  readonly is_logged_in: string;
  readonly newPasswordError?: boolean;
  readonly confirmationCodeError?: string;
  readonly is_data_requested?: boolean;
  handleChangePassword: typeof userConfirmForgotPassword;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IFormProps {
  readonly client_name: string;
  readonly username: string;
  readonly confirmationCodeError?: string;
  handleChangePassword: typeof userConfirmForgotPassword;
}

const ConfirmForgotPassword = ({ 
  is_logged_in, 
  client_name, 
  username,
  is_data_requested,
  ...props
}: IProps) => {
  if (is_logged_in) {
    return <Redirect to="/" />;
  }

  if (!client_name) {
    return <Redirect to="/login" />;
  }

  if (!username) {
    return <Redirect to="/forgot-password" />
  }

  return (
    <GridContainer container={true} item={true} spacing={GRID_SIZE_3} xs={GRID_SIZE_12}>
      <Hidden xsDown={true}>
        <GridCellLeft item={true} lg={GRID_SIZE_6} sm={GRID_SIZE_3} xs={GRID_SIZE_12}/>
      </Hidden>
      <GridCellRight
        container={true}
        item={true}
        lg={GRID_SIZE_6}
        sm={GRID_SIZE_9}
        xs={GRID_SIZE_12}
        spacing={GRID_SIZE_0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        { is_data_requested &&
        <Overlay>
          <CircularProgress />
        </Overlay> }
        <LogoWrapper>
          <Logo src={LPLogo}/>
        </LogoWrapper>

        <SubTitle>Enter confirmation code and new password.</SubTitle>
        <ResetPasswordForm client_name={client_name} username={username} {...props} />
        <TermPolicyWrapper>
          <Terms href="#">Term of use</Terms>
          <TermsWrapper>
            <Terms href="#">Privacy policy</Terms>
          </TermsWrapper>
        </TermPolicyWrapper>
      </GridCellRight>
    </GridContainer>
  );
}

const ResetPasswordForm = ({
  client_name,
  username,
  confirmationCodeError,
  handleChangePassword
 }: IFormProps) => {
  const [confirmationCode, setConfirmationCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
  const [newPasswordError, setNewPasswordError] = useState('')

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleChangePassword({
      client_name,
      confirmationCode,
      username,
      newPassword,
    });
  }

  const onNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newPasswordErrorVal = '';
    if (newPasswordConfirm !== event.currentTarget.value) {
      newPasswordErrorVal = 'Confirmation password does not match to new password';
    }
    setNewPassword(event.currentTarget.value)
    setNewPasswordError(newPasswordErrorVal)
  }

  const onNewPasswordConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newPasswordErrorVal = '';
    if (newPassword !== event.currentTarget.value) {
      newPasswordErrorVal = 'Confirmation password does not match to new password';
    }
    setNewPasswordConfirm(event.currentTarget.value)
    setNewPasswordError(newPasswordErrorVal)
  }

  const onConfirmationCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationCode(event.currentTarget.value)
  }

  const hasPasswordValidationError = confirmationCodeError ? confirmationCodeError.toLowerCase().includes("password") : false

  return (
    <Grid container={true} item={true} xs={GRID_SIZE_6}>
      <Form onSubmit={onSubmit}>

        <GridConfirmationCode item={true} xs={GRID_SIZE_12} >
          <InputConfirmationCodeStyled
            error={!!confirmationCodeError}
            autoFocus={false}
            type="text"
            label="Confirmation Code"
            onChange={onConfirmationCodeChange}
          />
        </GridConfirmationCode>

        <GridNewPassword item={true} xs={GRID_SIZE_12} >
          <InputNewPasswordStyled
            error={!!newPasswordError || hasPasswordValidationError}
            autoFocus={false}
            type="password"
            label="New Password"
            onChange={onNewPasswordChange}
          />
        </GridNewPassword>

        <GridNewPassword item={true} xs={GRID_SIZE_12} >
          <InputNewPasswordStyled
            error={!!newPasswordError || hasPasswordValidationError}
            autoFocus={false}
            type="password"
            label="Confirm New Password"
            onChange={onNewPasswordConfirmChange}
          />
        </GridNewPassword>

        {hasPasswordValidationError && (
          <ErrorMessage>
            Your password must be at least 12 characters long and contain an uppercase letter,
            a lowercase letter, a special character and a number.
          </ErrorMessage>
        )}

        <GridControl
          container={true}
          item={true}
          xs={GRID_SIZE_12}
          alignItems="center"
        >
          <GridButtonLeft item={true} container={true} xs={GRID_SIZE_12} md={GRID_SIZE_6} >
            <RequestButton
              type="submit"
              disabled={!!newPasswordError || newPassword === ''}
            >Send request</RequestButton>
          </GridButtonLeft>
          <GridButtonRight item={true} container={true} xs={GRID_SIZE_12} md={GRID_SIZE_6} >
            <ReturnToLogin href="/login-user">Return to log in</ReturnToLogin>
          </GridButtonRight>
        </GridControl>

      </Form>
    </Grid>
  )
}

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;


const RequestButton = styled(Button)`
  width: 181px;
  text-align: center;
  padding: 10px;
  background-color: ${theme.colorRed} !important;
  font-size: 0.8rem;
  align-items: center;
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
`;

const Logo = styled.img`
  height: 74px;
  margin-bottom: 24px;
`;

const ErrorMessage = styled.p`
  margin-top: -2rem;
  margin-bottom: 2rem;
`

const GridContainer = styled(Grid)`
  && {
    font-family: ${theme.ffInterRegular};
    margin: 0;
  }
`;

const GridConfirmationCode = styled(Grid)`
  && {
    font-family: ${theme.ffInterRegular};
    flex-basis: 105px;
  }
`;

const GridNewPassword = styled(Grid)`
  && {
    font-family: ${theme.ffInterRegular};
    flex-basis: 105px;
  }
`;

const GridCell = styled(Grid)`
  && {
    position: relative;
    min-height: 100vh;
  }
`;

const InputConfirmationCodeStyled = styled(InputText)`
  && {
    font-family: inherit;
    width: 100%;
    margin-bottom: 3.375rem;
  }
  .MuiInputLabel-formControl {
    font-family: ${theme.ffSansProRegular};
    font-weight: bold;
    opacity: 0.8;
  }
`;

const InputNewPasswordStyled = styled(InputText)`
  && {
    font-family: inherit;
    width: 100%;
    margin-bottom: 3.375rem;
  }
  .MuiInputLabel-formControl {
    font-family: ${theme.ffSansProRegular};
    font-weight: bold;
    opacity: 0.8;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: ${theme.colorWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const GridCellLeft = styled(GridCell)`
  background-image: url(${Group4Login});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 40% center;
  opacity: 0.5;
  @media (max-width: 1541px){
    background-position: 36% center;
    }
  @media (max-width: 1280px){
    display: none;
    }
`;

const GridCellRight = styled(GridCell)`
  && {
    background-color: ${theme.colorWhite};
    flex-wrap: nowrap;
  }
  @media (max-width: 1280px) {
    max-width: 100%;
    flex-basis: 100%;
    }
`;

const GridControl = styled(GridContainer)`
  && {
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 2;
      flex-basis: auto;
      @media ${theme.media.tabletL} {
         justify-content: space-between;
      }
  }
`;

const GridButtonLeft = styled(Grid)`
&& {
  justify-content: center;
  padding-bottom: 15px;

  @media${theme.media.tabletL} {
    justify-content: flex-start;
    padding-bottom: 0;
    padding-right: 15px;
  }
}
`;

const GridButtonRight = styled(Grid)`
&& {
  justify-content: center;
  padding-top: 15px;

  @media ${theme.media.tabletL} {
  justify-content: flex-end;
  padding-top: 0;
  padding-left: 15px;
  }
}
`;

const ReturnToLogin = styled.a`
  font-family: ${theme.ffSansProRegular};
  border: 1px solid ${theme.colorDarkBlue};
  width: 229px;
  height: 50px;
  border-radius: 4px;
  font-weight: bold;
  opacity: 0.8;
  font-size: 1rem;
  text-align: center;
  color: ${theme.colorDarkBlue};
  text-decoration: none;
  padding-top: 10px;
  @media${theme.media.tabletL} {
    padding-bottom: 0;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const SubTitle = styled.div`
opacity: 0.5;
font-size: 1rem;
text-align: center;
color: ${theme.colorDarkGray};
margin-bottom: 3.125rem;
font-family: ${theme.ffSansProRegular};
font-weight: bold;
`;

const Terms = styled.a`
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  top: 100%;
  font-size: 0.8375rem;
  text-align: center;
  color: ${theme.colorDarkGray};
  text-decoration: none;
  margin-top: 2.5rem;
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
  text-decoration-line: underline;
  transform: translate(-70%, -30%);
  @media ${theme.media.laptop} {
    && {
      position: absolute;
      bottom: 4.125rem;
    }
  }
`;

const TermsWrapper = styled.div`
  display: flex;
  margin-left: 100px;
  white-space: nowrap;
  text-align: center;
`;

const TermPolicyWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 0.5;
  opacity: 0.8;
`;

const mapStateToProps = (state: IStoreState) => ({
  is_logged_in: isLoggedIn(state),
  client_name: clientName(state),
  username: clientUsername(state),
  confirmationCodeError: state.user.confirmationCodeError,
  is_data_requested: state.user.is_data_requested,
});

const mapDispatchToProps = {
  handleChangePassword: userConfirmForgotPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmForgotPassword);

