import * as React from 'react';
import styled from 'styled-components';
import NotificationsIcon from '../../components/Icons/NotificationsIcon';
import theme from '../../assets/css/theme';
import { ArrowDownIcon } from '../../components/Icons';
import { Button } from '../../components/BoxComponents';
import { variant } from 'styled-system';
import { INotificationInfo } from '../../modules/types';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

const NotificationsStyled = styled.div`
display: flex;
min-height: 35vh;
flex-direction: column;
padding: 20px 16px;
box-shadow: ${theme.boxShadowDarker};
`

const NotificationsHeader = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 1rem;
opacity: 0.9;
color: ${theme.colorDarkGray};
padding: 7px;
`

const NotificationsRow = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 0.85rem;
color: ${theme.colorDarkGray};
opacity: 0.7;
padding-left: 50px;
padding-right: 14px;
padding-bottom: 32px;
`

const TextBold = styled.span`
font-size: 0.9375rem;
font-family: ${theme.ffSansProRegular};
font-weight: bolder;
color: ${theme.colorDarkBlue};
`

const Label = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 0.85rem;
text-align: left;
color: ${theme.colorDarkGray};
opacity: 0.7;
`

const AvailableWrapper = styled.div`
padding-left: 0.425rem;
margin-bottom: 1.063rem;
`

const SizeWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-content: center;
`

const CardButton = styled(Button)`
display: flex;
align-items: center;
justify-content: space-between;
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 0.7rem;
min-width: 150px;
height: 30px;
background-color: ${props => (props.primary ? `${theme.colorRed}` : `${theme.colorWhite}`)};
@media ${theme.media.laptop} {
  margin-top: 0;
}
`

const ArrowWrapper = styled.div`
display: flex;
padding-left: 10px;
`

const ButtonCardColumn = styled.div`
position: relative;
width: 219px;
padding-left: 10px;
margin: 0 auto;
`

const OpportunityCardColumn = styled.div`
  margin: 0px 3px;
`

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
`

const PropValue = styled.div`
  padding-left: 16px;
  margin-bottom: 16px;
`

const Value = styled.div`
  font-family: ${theme.ffInterRegular};
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  ${() => variant({
    prop: 'greyedOut',
    variants: {
      true: {
        color: theme.colorMediumGray,
      },
      false: {
        color: theme.colorDarkGray,
      },
    }
  })}
`

interface IProps extends INotificationInfo {
  targeted: number;
  available: number;
  is_data_requested?: boolean;
}

interface IMetricProps {
  value: string;
  title: string;
  icon: React.ReactElement;
}

export const CustomerNotification = ({ in_campaign, in_control_group, targeted, available, is_data_requested }: IProps) => {
  if (is_data_requested) { return null }

  const hideActivateButton = false;

  return (
    <NotificationsStyled>
      <SizeWrapper>
        <NotificationsIcon />
        <AvailableWrapper>
          <NotificationsHeader>Customers in Campaign...</NotificationsHeader>
        </AvailableWrapper>
      </SizeWrapper>
      <SizeWrapper>
        <Metric value={`${targeted?.toLocaleString('en')}`}
        title="In Campaign" icon={<TrackChangesIcon />} />
      </SizeWrapper>
      <SizeWrapper>
        <Metric value={`${available?.toLocaleString('en')}`}
          title="Available Customers" icon={<PersonOutlinedIcon />}/>
      </SizeWrapper>
      <NotificationsRow>
        <TextBold>{in_campaign?.toLocaleString('en')}</TextBold> customers to be targeted in the<br/>campaign.<TextBold> {in_control_group?.toLocaleString('en')}</TextBold> customers will be <br/> quarantined in a control group.
      </NotificationsRow>
      {hideActivateButton && (
        <ButtonCardColumn>
        <CardButton primary={true}>
          Activate Audiences in Emarsys
          <ArrowWrapper> <ArrowDownIcon color={theme.colorWhite}/> </ArrowWrapper>
        </CardButton>
      </ButtonCardColumn>
      )}
    </NotificationsStyled>
  )
}

const Metric = ({ value, title, icon }: IMetricProps) => (
  <OpportunityCardColumn>
    <ValueWrapper>
      {icon}
      <PropValue>
        <Value >{value}</Value>
        <Label>{title}</Label>
      </PropValue>
    </ValueWrapper>
  </OpportunityCardColumn>
)

export default CustomerNotification;
