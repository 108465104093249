import React from 'react';
import styled from 'styled-components';
import { Button, ButtonGroup } from '@material-ui/core';
import { variant } from 'styled-system'
import theme from '../assets/css/theme';

interface IOption { name: string, isactive: boolean , onClick: () => void }

interface IProps {
  options: IOption[]
}

interface IButtonProps {
  isactive: string
}

export const ButtonGroups = ({options = []}: IProps) => {
  return (
    <>
      <ButtonGroupWrapper>
        <ButtonGroup>
          {options.map(({name, isactive, onClick}) => (
            <ButtonActiveStyle key={name} isactive={isactive.toString()} onClick={onClick}>{name}</ButtonActiveStyle>
          ))}
        </ButtonGroup>
      </ButtonGroupWrapper>
  </>
  )
}

const ButtonActiveStyle = styled(Button)<IButtonProps>`
  box-shadow: 0px 0px 3px 0px #dfdfdf;
  text-transform: initial;
  font-size: 0.735rem;
  font-family: ${theme.ffInterMedium};
  ${() => variant({
    prop: 'isactive',
    variants: {
      true: {
        color: theme.colorRed,
      },
    }
  })}
`

const ButtonGroupWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  margin-bottom: 17px;
`
