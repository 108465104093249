export const GRID_SIZE_12 = 12;
export const GRID_SIZE_11 = 11;
export const GRID_SIZE_10 = 10;
export const GRID_SIZE_9 = 9;
export const GRID_SIZE_8 = 8;
export const GRID_SIZE_7 = 7;
export const GRID_SIZE_6 = 6;
export const GRID_SIZE_5 = 5;
export const GRID_SIZE_4 = 4;
export const GRID_SIZE_3 = 3;
export const GRID_SIZE_2 = 2;
export const GRID_SIZE_1 = 1;
export const GRID_SIZE_0 = 0;
