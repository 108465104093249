import React from 'react';
import { getSegmentDefinitions } from '@loypal/lib-growth'
import { useClientCustomization } from '../../utils/hooks'
import MenuItem from './MenuItem';
import { activeConfig } from '../../config';
import { OpportunityIcon, CampaignsIcon, CustomersIcon, SettingsIcon } from '../Icons';
import { FlaggedFeature } from '../FlaggedFeature';

interface IProps {
  isSidebarOpen: boolean,
  queryDateParams: string,
}

interface ISegment {
  name: string,
  description: string,
  id: number
}

export const MainListItems = (props: IProps) => {
  const { industryGroup } = useClientCustomization()
  const segments = getSegmentDefinitions(industryGroup)
  const { queryDateParams } = props;

  const opportunitySubmenuItems = segments && segments.map(({ name, id }: ISegment) => ({
    label: name,
    to: `/segments/${id}`
  }))

  const campaignsSubmenuItems = segments && segments.map(({ name, id }: ISegment) => ({
    label: name,
    to: `/campaigns/segments/${id}${queryDateParams}`
  }))

  const customerSubmenuItems = [
    { label: 'Loyalty Measures', to: '/customers/loyalty' },
    { label: 'Commercial Performance', to: '/customers/commercial-performance' },
  ]

  return (
    <div>
      
      <MenuItem
        to="/opportunity"
        active={activeConfig.opportunity}
        icon={<OpportunityIcon/>}
        label="Opportunity"
        isSidebarOpen={props.isSidebarOpen}
        secondaryMenuItems={opportunitySubmenuItems}
      />
      
      <MenuItem
        to={`/campaigns${queryDateParams}`}
        active={activeConfig.campaigns}
        icon={<CampaignsIcon/>}
        label="Campaigns"
        isSidebarOpen={props.isSidebarOpen}
        secondaryMenuItems={campaignsSubmenuItems}
      />
    
      <MenuItem
        to="/customers"
        active={activeConfig.customers}
        icon={<CustomersIcon/>}
        label="Customers"
        isSidebarOpen={props.isSidebarOpen}
        secondaryMenuItems={customerSubmenuItems}
      />
     
      <FlaggedFeature featureName="settings">
        <MenuItem
          to="/settings"
          active={activeConfig.settings}
          icon={<SettingsIcon/>}
          label="Settings"
          isSidebarOpen={props.isSidebarOpen}
        />
      </FlaggedFeature>
    </div>
  )
};

export default MainListItems;
