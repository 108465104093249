import { createAction } from 'redux-act';
import {
  IAudienceCard,
  IAudienceRequestApi,
  IAudienceSummaryRequestApi,
  IAudiencesSummaryCard,
  INotificationInfo,
  INotificationRequestApi,

} from '../../types';
// Audience Summary
export const audienceCardRequest = createAction<IAudienceRequestApi>('Fetch summary data for a particular audience');
export const audienceCardSuccess = createAction<IAudienceCard>('Audience summary data fetched successfully');
export const audienceCardFailed = createAction<string>('Audience summary data fetching failed');
export const audienceCardClean = createAction('Set Audience summary data to default value');
// Exporting Campaign actions
export const getNotificationInfoRequest = createAction<INotificationRequestApi>('Get Notification info');
export const getNotificationInfoSuccess = createAction<INotificationInfo>('Notification info got successfully');
export const getNotificationInfoFailed = createAction<string>('Getting Notification info failed');
// Audiences segment summary
export const audiencesSummaryCardRequest = createAction<IAudienceSummaryRequestApi>('Fetch Audiences Segment summary data');
export const audiencesSummaryCardSuccess = createAction<IAudiencesSummaryCard>('Audiences Segment summary data fetched successfully');
export const audiencesSummaryCardFailed  = createAction<string>('Audiences Segment summary data fetching failed')
