import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  IAudienceCard,
} from '../../modules/types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import theme from '../../assets/css/theme';
import {
  BoxTitle,
  SizeValue
} from '../../components/BoxComponents';
import { useSegment } from '../../utils/hooks';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import PersonPinCircleOutlinedIcon from '@material-ui/icons/PersonPinCircleOutlined';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import { variant } from 'styled-system';

interface IMetricProps {
  value: string;
  title: string;
  greyedOut?: boolean;
  icon: React.ReactElement;
}

interface IValueProps {
  greyedOut: boolean;
}

const AudienceCard = (props: IAudienceCard) => {
  const {
    volume,
    targeted,
    available,
    untargeted_opportunity,
    total_marketable,
  } = props

  const { segment_id } = useParams<any>()
  const { name, description } = useSegment(segment_id || { name: '', description: '' })


  return (
    <CardContainer >
      <CardColumnWrapper>
        <CardColumn>
          <BoxTitle title={name} subtitle={description}/>
        </CardColumn>
        <TargetedWrapper>
          <TargetedCardColumn >
            <Metric value={`${total_marketable?.toLocaleString('en')}`}
              title="Marketable Customers" icon={<PersonPinCircleOutlinedIcon />}/>
          </TargetedCardColumn>
          <TargetedCardColumn  >
            <Metric value={`${untargeted_opportunity}`}
              title="Revenue Opportunity" icon={<MonetizationOnOutlinedIcon />}/>
          </TargetedCardColumn>
        </TargetedWrapper>
      </CardColumnWrapper>
      <CardColumnWrapper>
        <CustomerSizeCardColumn >
          <Grid >
            <SizeValue value={volume}/>
          </Grid>
        </CustomerSizeCardColumn>
        <TargetedWrapper>
          <MetricsContainer>
            <Metric value={`${targeted.toLocaleString('en')}`}
              title="In Campaign" greyedOut={true}
              icon={<TrackChangesIcon />}
              />
            <StackedMetric>
              <Metric value={`${available.toLocaleString('en')}`}
                title="Available Customers" icon={<PersonOutlinedIcon />}/>
            </StackedMetric>
              <Metric value={`${untargeted_opportunity}`}
                title="Revenue Opportunity" icon={<MonetizationOnOutlinedIcon />}/>
          </MetricsContainer>
        </TargetedWrapper>
      </CardColumnWrapper>
    </CardContainer>
  )
}

const Metric = ({ value, title, greyedOut = false, icon }: IMetricProps) => (
  <OpportunityCardColumn>
    <ValueWrapper>
      {icon}
      <PropValue>
        <Value greyedOut={greyedOut}>{value}</Value>
        <Label>{title}</Label>
      </PropValue>
    </ValueWrapper>
  </OpportunityCardColumn>
)

export default AudienceCard;

const CardContainer = styled(Grid)`
&& {
    background-color: ${theme.colorWhite};
  }
`

const CardColumn = styled(Grid)`
&& {
    word-break: break-word;
    padding: 1rem;
  }
`

const CustomerSizeCardColumn = styled(CardColumn)`
&& {
    text-align: center;
    @media ${theme.media.tablet} {
      text-align: left;
  }
}
`

const TargetedCardColumn = styled(CardColumn)`
&& {
  justify-content: flex-start;
    @media ${theme.media.tablet} {
      justify-content: center;
  }
}
`

const TargetedWrapper = styled.div`
display: flex;
justify-content: flex-end;
`

const CardColumnWrapper = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
align-items: self-end;
`

const OpportunityCardColumn = styled.div`
  margin: 0px 12px;
`

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
`

const PropValue = styled.div`
  padding-left: 16px;
  margin-bottom: 16px;
`

const Label = styled.div`
  font-family: ${theme.ffInterRegular};
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  color: ${theme.colorDarkGray};
`

const Value = styled.div<IValueProps>`
  font-family: ${theme.ffInterRegular};
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  ${() => variant({
    prop: 'greyedOut',
    variants: {
      true: {
        color: theme.colorMediumGray,
      },
      false: {
        color: theme.colorDarkGray,
      },
    }
  })}
`
const MetricsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-grow: 1;

  @media ${theme.media.laptopLLTE} {
    justify-content: center;
  }
`

const StackedMetric = styled.div`
  @media ${theme.media.laptopLLTE} {
    order: 3;
    display: flex;
  }
`
