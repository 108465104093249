import { Table } from '../../components/Charts'
import React, { useState, useCallback } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import { variant } from 'styled-system'
import styled from 'styled-components';
import { Guide, ISlide } from './Guide';
import theme from '../../assets/css/theme';
import { CSVLink  } from "react-csv";
import { ArrowDownIcon, ArrowUpIcon } from '../Icons';


interface IDataGroupOption { name: string, isActive: boolean, onClick: () => void }

interface IProps {
  title: string
  guideSlides?: ISlide[]
  headers: string[]
  rows: any
  csvData: any
  guideHeight?: string
  dataGroupOpts?: IDataGroupOption[]
  shouldBoldLastTableRow?: boolean
  children: JSX.Element
}

interface IDataGroupButtonProps {
  isactive: string
}

export const Report = ({ guideSlides = [], headers, rows, csvData, title, dataGroupOpts = [], guideHeight, shouldBoldLastTableRow = false, children}: IProps) => {

  const [isShowingGuide, setIsShowingGuide] = useState(true);
  const [viewTable, setViewTable] = useState(false);

  const handleClose = () => {
    setIsShowingGuide(!isShowingGuide)
  } 
    
  const ArrowView =  useCallback(() => setViewTable(!viewTable), [viewTable])
  return (
    <Container>
      <LeftPanelContainer>
        <HeaderSection>
          <StyledTitle>{title}</StyledTitle>
          {!!dataGroupOpts.length && (
            <ButtonGroupWrapper>
              <ButtonGroup>
                {dataGroupOpts.map(({name, isActive, onClick}) => (
                  <ButtonActiveStyle key={name} isactive={isActive.toString()} onClick={onClick}>{name}</ButtonActiveStyle>
                ))}
              </ButtonGroup>
            </ButtonGroupWrapper>
          )}
        </HeaderSection>
        {/* use key to force re-render chart, fixes bug where highcharts doesn't update width */}
        <div key={`chart-${isShowingGuide ? 1 : 0}`}>{ children }</div>
        <ViewButton onClick={ArrowView}>
          <ViewTablewrapper>
            { viewTable ? 'Hide Table' : 'View Table' }
          </ViewTablewrapper>
          <CloseButtonWrapper >  
            <ArrowIconContainer> 
              { viewTable ? <ArrowUpIcon/> : <ArrowDownIcon /> }
            </ArrowIconContainer>
          </CloseButtonWrapper>
        </ViewButton>
        {
        viewTable && (
        <>
          <TableContainer>
            <Table 
            headers={headers}
            rows={rows.slice(0)}
            shouldBoldLastRow={shouldBoldLastTableRow}
            />  
          </TableContainer> 
          <TitleContainer>
            <LinkWrapper>
              <CSVLink data={csvData} className='csvColor' filename={title}> 
                <DownloadButton>Download CSV</DownloadButton>
              </CSVLink>
            </LinkWrapper>
          </TitleContainer>   
        </>
      )}
      </LeftPanelContainer>
      {isShowingGuide && !!guideSlides.length && (
        <Guide 
          height={guideHeight}
          slides={guideSlides}
          onClick={handleClose}  
        />
      )}  
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
`

const TableContainer = styled.div`
  margin-top: 18px;
  width: 100%;
  overflow-x: auto;
`

const HeaderSection = styled.div`
  display: flex;    
  width: 100%;
  justify-content: space-between;
`

const LeftPanelContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  background-color: ${theme.colorWhite};
  padding: 16px;
  flex: 3 3 auto;
  overflow-x: auto;
`

const StyledTitle = styled.div`
  color: ${theme.colorDarkGray};
  font-size: 1.125rem;
  margin-bottom: 28px;
`

const LinkWrapper = styled.div`
  .csvColor{
    color: ${theme.colorWhite};
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 22px;
`

const DownloadButton = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${theme.colorWhite};
  color:${theme.colorDarkestGray};
  border: 1px solid ${theme.colorGray};
  padding: 8px 16px;
  border-radius: 4px;
  box-shadow: 0px 0px 3px 0px #dfdfdf;
  font-size: 0.8125rem;
`

const ViewButton = styled.div`
  display: flex;
  background-color: ${theme.colorWhite};
  color:${theme.colorDarkestGray};
  border: 1px solid ${theme.colorGray};
  position: relative;
  align-items: center;
  align-self: flex-end;
  padding-left: 0.625rem ;
  border-radius: 4px;
  padding: 8px 16px;
  box-shadow: 0px 0px 3px 0px #dfdfdf;
  padding-right: 3rem;
  font-size: 0.8125rem;
  cursor: pointer;
`

const ViewTablewrapper = styled.div`
  line-height: 24px;
`

const CloseButtonWrapper  = styled.div`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0.425rem;
  color: ${theme.colorGray};
  cursor: pointer; 
`

const ArrowIconContainer = styled.div`
  cursor: pointer;
  line-height: 0;
`

const ButtonActiveStyle = styled(Button)<IDataGroupButtonProps>`
  box-shadow: 0px 0px 3px 0px #dfdfdf;
  text-transform: initial;
  font-size: 0.735rem;
  ${() => variant({
    prop: 'isactive',
    variants: {
      true: {
        color: theme.colorRed,
      },
    }
  })} 
`

const ButtonGroupWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  margin-bottom: 17px;
`