import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { Grid } from '@material-ui/core';

export const CardContainer = styled(Grid)`
   && {
      background-color: ${theme.colorWhite};
      padding: 16px;
   }
 `;

export const CardColumn = styled.div`
  word-break: break-word;
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;

  @media ${theme.media.laptopMLTE} {
    max-width: 50%;
    flex-basis: 50%;
  }
`;

export const CustomerSizeCardColumn = styled(CardColumn)`
  && {
    text-align: center;

    @media ${theme.media.tablet} {
      text-align: left;
    }
  }
`;

export const ButtonCardColumn = styled(CardColumn)`
  && {
    justify-content: center;

    @media ${theme.media.tablet} {
      justify-content: flex-end;
    }
  }
`;

export const OpportunityCardColumn = styled(CardColumn)`
  && {
    justify-content: flex-start;
    
    @media ${theme.media.tablet} {
      justify-content: center;
    }
  }
`;