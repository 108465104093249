import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { InputText } from '../../components/Form';

export const InputLabel = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
color: ${theme.colorDarkGray};
font-size: 0.9375rem;
margin-top: 4px;
@media ${theme.media.laptopM} {
  flex-basis: 180px;
  flex-shrink: 0;
}
@media (max-width: 1280px) and (min-width: 600px) {
  padding-right: 70px;
}
@media (max-width: 660px) and (min-width: 420px) {
  margin-bottom: 15px;
}
`

export const TextInput = styled(InputText)`
&& {
  margin-top: 5px;
  font-family: inherit;
  & > .MuiInputBase-root.MuiInputBase-formControl {
    height: 38px;
    min-width: 400px;
    box-shadow: ${theme.boxShadow};
    padding: 10px 0 10px 5px;
    & .MuiOutlinedInput-notchedOutline {
      border: solid 1px ${theme.colorBorderControls};
      & legend span {
        display: none;
      }
    }
    &.Mui-error {
      border: solid 1px ${theme.colorRed};
    }
    & .MuiInputBase-input.MuiOutlinedInput-input {
      font-family: ${theme.ffSansProRegular};
      font-size: 0.8125rem;
      color: ${theme.colorDarkGray}
    }
  }
}
`