import React, { useState } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { IStoreState } from '../modules/types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { clientNameRequest } from '../modules/actions/user';
import { isLoggedIn, clientName } from "../modules/selectors";
import { GRID_SIZE_12, GRID_SIZE_9, GRID_SIZE_6, GRID_SIZE_3, GRID_SIZE_0 } from '../config';
import Group4Login from '../assets/img/login/LOYPAL-HOME-IMAGE.png';
import LPLogo from '../assets/img/login/LOYPAL-LOGO-HORIZONTAL.png';
import { InputText, Button } from "../components/Form";
import theme from '../assets/css/theme';
import { CircularProgress } from '@material-ui/core';

const PATH_POSITION: number = 2;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
`;

const GridContainer = styled(Grid)`
  && {
    font-family: ${theme.ffSansProRegular};
    font-weight: bold;
    margin: 0;
  }
`;

const GridCell = styled(Grid)`
  && {
    position: relative;
    min-height: 100vh;
  }
`;

const InputTextStyled = styled(InputText)`
  && {
    width: 55%;
    margin-bottom: 3.375rem;
  }
  .MuiInputLabel-formControl {
    font-family: ${theme.ffSansProRegular};
    font-weight: bold;
    opacity: 0.8;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: ${theme.colorWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const GridCellLeft = styled(GridCell)`
  background-image: url(${Group4Login});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 40% center;
  opacity: 0.5;
  @media (max-width: 1541px){
    background-position: 34% center;
    }
  @media (max-width: 1280px){
   display: none;
   }
`;

const GridCellRight = styled(GridCell)`
  background-color: ${theme.colorWhite};
  @media (max-width: 1280px) {
    max-width: 100%;
    flex-basis: 100%;
    }
`;

const Logo = styled.img`
  height: 74px;
  margin-bottom: 24px;
`;

const SubTitle = styled.div`
  opacity: 0.5;
  font-size: 1rem;
  text-align: center;
  color: ${theme.colorDarkGray};
  margin-bottom: 3.125rem;
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
`

const LoginButton = styled(Button)`
  width: 180px !important;
  text-align: center;
  padding: 10px;
  font-size: 1rem;
  background-color: ${theme.colorRed} !important;
  align-items: center;
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
`

const TermPolicyWrapper = styled.div`
  display: flex;
  flex: 0.5;
  align-self: center;
  position: relative;
  opacity: 0.8;
  `

const Terms = styled.a`
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  top: 120%;
  font-size: 0.8375rem;
  text-align: center;
  color: ${theme.colorDarkGray};
  text-decoration: none;
  margin-top: 2.5rem;
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
  text-decoration-line: underline;
  transform: translate(-70%, -30%);
  @media ${theme.media.laptop} {
    && {
      position: absolute;
      bottom: 4.125rem;
    }
  }
`;

const TermsWrapper = styled.div`
  display: flex;
  margin-left: 100px;
  white-space: nowrap;
  text-align: center;
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`

interface IProps extends RouteComponentProps {
  readonly is_logged_in: string;
  readonly reduxStoreClientName: string;
  readonly clientIdError?: boolean;
  readonly is_data_requested?: boolean;
  setClientName: typeof clientNameRequest;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const LoginComponent = ({
  is_logged_in,
  reduxStoreClientName,
  setClientName,
  clientIdError,
  is_data_requested
}: IProps) => {
  const [clientNameState, setClientNameState] = useState('');
  const { search } = useLocation();
  const parsedQueryString = queryString.parse(search);

  if (is_logged_in) {
    const toUrl = parsedQueryString.next ? `/${parsedQueryString.next}` : '/'
    return <Redirect to={toUrl} />;
  }

  if (reduxStoreClientName) {
    const toUrl = search ? `/login-user/${search}` : '/login-user'
    return <Redirect to={toUrl} />;
  }

  const onSubmit = (event: React.FormEvent) =>  {
    event.preventDefault();
    setClientName({ client_name: clientNameState });
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClientNameState(event.currentTarget.value)
  }

  const pathnameWithoutTrailingSlash = window.location.pathname.replace(/\/+$/, '');
  const arrayOfPaths: string[] = pathnameWithoutTrailingSlash.split('/');
  const clientValue: string | null = arrayOfPaths[PATH_POSITION];

  if (!!clientValue) {
    setClientName({ client_name: clientValue });
    const toUrl = search ? `/login-user/${search}` : '/login-user'
    return <Redirect to={toUrl} />;
  }

  return (
    <GridContainer container={true} item={true} spacing={GRID_SIZE_3} xs={GRID_SIZE_12}>
      <Hidden xsDown={true}>
        <GridCellLeft item={true} lg={GRID_SIZE_6} sm={GRID_SIZE_3} xs={GRID_SIZE_12}/>
      </Hidden>
      <GridCellRight
        container={true}
        item={true}
        lg={GRID_SIZE_6}
        sm={GRID_SIZE_9}
        xs={GRID_SIZE_12}
        spacing={GRID_SIZE_0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        { is_data_requested && (
          <Overlay>
            <CircularProgress />
          </Overlay>
        )}
          <LogoWrapper>
            <Logo src={LPLogo}/>
          </LogoWrapper>
          <SubTitle>Enter your Client Name.</SubTitle>
          <Form onSubmit={onSubmit}>
            <InputTextStyled
              error={!!clientIdError}
              autoFocus={true}
              type="text"
              label="Client Name"
              onChange={onChange}
            />
            <LoginButton type="submit">Login</LoginButton>
          </Form>
        <TermPolicyWrapper>
          <Terms href="#">Term of use</Terms>
          <TermsWrapper>
            <Terms href="#">Privacy policy</Terms>
          </TermsWrapper>
        </TermPolicyWrapper>
      </GridCellRight>
    </GridContainer>
  );
}

const mapStateToProps = (state: IStoreState) => ({
  is_logged_in: isLoggedIn(state),
  reduxStoreClientName: clientName(state),
  clientIdError: state.user.clientIdError,
  is_data_requested: state.user.is_data_requested,
});

const mapDispatchToProps = {
  setClientName: clientNameRequest
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginComponent));
