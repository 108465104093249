import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { getToday } from '@loypal/lib-growth'
import subDays from 'date-fns/subDays'
import format from 'date-fns/format';
import styled from 'styled-components';
import { DATE_FORMAT, DATE_FNS_DATE_FORMAT } from '../../config/datetime';
import { withHeader } from '../../components';
import { ICampaignReDownloadData, ICampaignsCard, IStoreState } from '../../modules/types';
import {
  segmentCampaignsRequest,
  activeCampaignDownloadDataRequest,
} from '../../modules/actions/campaign';
import {
  campaignsCards,
  campaignsEngageCurrentPage,
  campaignsEngagePageLimit,
  segmentCampaignsNumPages
} from '../../modules/selectors'
import CampaignCard from './CampaignCard';
import { PageTitle } from '../../components/Text';
import ContainerStyled from '../../components/ContainerStyled';
import {
  GRID_SIZE_4,
  GRID_SIZE_12,
} from '../../config';
import { Button } from '../../components/BoxComponents';
import { BackLink } from '../../components/Navigation';
import theme from '../../assets/css/theme';
import { useSegment } from '../../utils/hooks/use-segment'
import { CampaignFilter } from '../../components/Campaigns/CampaignFilter'
import LoadingCampaignCard from './LoadingCampaignCard';
import { useGetMomentQueryDates, useSetQueryDates, useGetQueryDates } from '../../utils/hooks';

const NUM_LOADING_CARDS = 4;
interface IProps extends RouteComponentProps<{ segment_id: string }> {
  fetchSegmentCampaigns: typeof segmentCampaignsRequest;
  handleDownloadData: typeof activeCampaignDownloadDataRequest;
  campaigns_cards: ICampaignsCard[];
  current_page: number;
  total_num_pages: number;
  page_limit: number;
  is_data_requested : any;
}

const LifecycleCampaigns = ({
  handleDownloadData,
  fetchSegmentCampaigns,
  current_page,
  page_limit,
  campaigns_cards,
  total_num_pages,

  ...props
}: IProps) => {
  const { match: { params: { segment_id } } }  = props;
  const [startDate, setStartDate] = useState<any>(undefined)
  const [endDate, setEndDate] = useState<any>(undefined)
  const [excludePartialCampaigns, setExcludePartialCampaigns] = useState<boolean>(false)
  const [excludeActiveCampaigns, setExcludeActiveCampaigns] = useState<boolean>(false)
  const [focusedDateInput, setFocusedDateInput] = useState<any>(null)
  const [didDatesChanged, setDidDatesChanged] = useState<boolean>(false)
  const [didExcludeChanged, setDidExcludeChanged] = useState<boolean>(false)
  const queryDates = useGetMomentQueryDates();
  const queryDatesParams = useGetQueryDates();

  useEffect(() => {
    /* tslint:disable:one-variable-per-declaration */
    let start_date, end_date;
    const first_page = 1;

    if (queryDates) {
      const { momentQueryStartDate, momentQueryEndDate } = queryDates;
      start_date = momentQueryStartDate.format(DATE_FORMAT);
      end_date = momentQueryEndDate.format(DATE_FORMAT);
      setStartDate(momentQueryStartDate);
      setEndDate(momentQueryEndDate);
    } else {
      const today = getToday();
      const last_days_count = 30;
      const past_day = subDays(today, last_days_count);
      end_date = format(today, DATE_FNS_DATE_FORMAT);
      start_date = format(past_day, DATE_FNS_DATE_FORMAT);
      setStartDate(past_day)
      setEndDate(today)
    }

    fetchSegmentCampaigns({
      segment_id,
      page: first_page,
      page_limit,
      start_date,
      end_date
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ segment_id, fetchSegmentCampaigns, page_limit ])

  useSetQueryDates({startDate, endDate});

  const downloadDataFile = (payload: ICampaignReDownloadData) => {
    handleDownloadData(payload);
  }

  const loadPreviousCampaigns = (event: React.MouseEvent) => {
    event.preventDefault();
    fetchSegmentCampaigns({
      segment_id,
      page: current_page - 1,
      page_limit,
      start_date: format(new Date(startDate), DATE_FNS_DATE_FORMAT),
      end_date: format(new Date(endDate), DATE_FNS_DATE_FORMAT),
      exclude_partial_campaigns: excludePartialCampaigns ? 1 : 0,
      exclude_active_campaigns: excludeActiveCampaigns ? 1 : 0,
    });
  }

  const loadMoreCampaigns = (event: React.MouseEvent) => {
    event.preventDefault();
    fetchSegmentCampaigns({
      segment_id,
      page: current_page + 1,
      page_limit,
      start_date: format(new Date(startDate), DATE_FNS_DATE_FORMAT),
      end_date: format(new Date(endDate), DATE_FNS_DATE_FORMAT),
      exclude_partial_campaigns: excludePartialCampaigns ? 1 : 0,
      exclude_active_campaigns: excludeActiveCampaigns ? 1 : 0,
    });
  }

  const fetchCampaignsWithFilters = (filters: any) => {
    fetchSegmentCampaigns({
      segment_id,
      page: current_page,
      page_limit,
      ...filters
    });
  }

  // allow all dates
  const checkAllowedDates = () => false;

  const segment = useSegment(segment_id);

  return (
    <ContainerStyled maxWidth="xl">
      <BackLink url={`/campaigns${queryDatesParams}`} />
      <TitleContainer>
        <PageTitle>{`${segment.name} Lifecycle Campaigns`}</PageTitle>
        <CampaignFilter
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          focusedDateInput={focusedDateInput}
          setFocusedDateInput={setFocusedDateInput}
          didDatesChanged={didDatesChanged}
          setDidDatesChanged={setDidDatesChanged}
          excludePartialCampaigns={excludePartialCampaigns}
          setExcludePartialCampaigns={setExcludePartialCampaigns}
          excludeActiveCampaigns={excludeActiveCampaigns}
          setExcludeActiveCampaigns={setExcludeActiveCampaigns}
          didExcludeChanged={didExcludeChanged}
          setDidExcludeChanged={setDidExcludeChanged}
          checkAllowedDates={checkAllowedDates}
          fetchCampaigns={fetchCampaignsWithFilters}
        />
      </TitleContainer>
      <Grid container={true} item={true} spacing={GRID_SIZE_4}>
        {props.is_data_requested === true ? (
          Array(NUM_LOADING_CARDS).fill(null).map((_campaign_id, card) => (
            <Grid
              key={card}
              item={true}
              xs={GRID_SIZE_12}
            >
              <LoadingCampaignCard />
            </Grid>
          ))
        ) : !campaigns_cards.length && (
          <NoResultsContainer>
            <p>No campaigns to show</p>
          </NoResultsContainer>
        )}

        {campaigns_cards.map(card => (
          <Grid
            key={card.campaign_id}
            item={true}
            xs={GRID_SIZE_12}>
            <CampaignCard onDownloadClick={downloadDataFile} {...card} />
          </Grid>
        ))}

        <Grid container={true} item={true} xs={GRID_SIZE_12} justifyContent="center" >
          { current_page > 1 && (
            <LoadButton to="" onClick={loadPreviousCampaigns}>Load previous</LoadButton>
          )}
          { current_page < total_num_pages && (
            <LoadButton to="" onClick={loadMoreCampaigns}>Load more</LoadButton>
          )}
        </Grid>
      </Grid>
    </ContainerStyled>
  )
}

const mapStateToProps = (state: IStoreState) => ({
  campaigns_cards: campaignsCards(state),
  current_page: campaignsEngageCurrentPage(state),
  total_num_pages: segmentCampaignsNumPages(state),
  page_limit: campaignsEngagePageLimit(state),
  is_data_requested: state.campaign.is_data_requested,
});

const mapDispatchToProps = {
  fetchSegmentCampaigns: segmentCampaignsRequest,
  handleDownloadData: activeCampaignDownloadDataRequest,
};

const LoadButton = styled(Button)`
  background-color: ${theme.colorDarkBlue};
  padding: 0.5625rem 4.563rem 0.5rem 4.625rem;
  font-family: ${theme.ffSansProRegular};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  margin: 0px 8px;
`;

const TitleContainer = styled.div`
   display: flex;
   justify-content: space-between;
`

const NoResultsContainer = styled.div`
   display: flex;
   justify-content: center;
   width: 100%;
   margin-top: 16px;
`

export default withHeader(connect(mapStateToProps, mapDispatchToProps)(LifecycleCampaigns))
