import * as React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
min-width: 40px;
min-height: 40px;
width: ${(props: IDiameter) => props.diameter || '2.5rem'};
height: ${(props: IDiameter) => props.diameter || '2.5rem'};
border: solid 0.0625rem ${props => props.color || '#a4a1fb'};
border-radius: 50%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`;

interface IDiameter {
  diameter?: string;
}

interface IProps extends IDiameter {
  color?: string;
}

export class  CartIcon extends React.Component<IProps>{
  public render() {
    const color = this.props.color || '#a4a1fb';
    return (
      <Circle color={color} diameter={this.props.diameter}>
        <svg xmlns="http://www.w3.org/2000/svg" width="22.354" height="22.355" viewBox="0 0 22.354 22.355">
          <path data-name="Cart Up Icon" d="M15.389,19.56a2.795,2.795,0,1,1,2.794,2.8A2.794,2.794,0,0,1,15.389,19.56Zm-13.971,0a2.794,2.794,0,1,1,2.794,2.8A2.794,2.794,0,0,1,1.417,19.56ZM0,15.369V12.574H17.13l1.994-6.986H2.814V2.794H.02V0H4.211a1.4,1.4,0,0,1,1.4,1.4v1.4h15.37a1.427,1.427,0,0,1,1.343,1.782l-2.794,9.779a1.4,1.4,0,0,1-1.344,1.014Zm1.4-4.191V9.78H6.986v1.4Zm1.4-2.8v-1.4H8.383v1.4Z" transform="translate(0 0)" fill={color}/>
        </svg>
      </Circle>
    );
  }
}

export default CartIcon;
