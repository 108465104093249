import { IStoreState } from '../types';
import stringifyUntargetedOpportunity from '../utils/helpers/stringifyUntargetedOpportunity';

export const audienceSummary = ({ audience }: IStoreState) => ({
  ...audience.current_audience,
  untargeted_opportunity: stringifyUntargetedOpportunity(audience.current_audience.untargeted_opportunity),
});

export const notificationInfo = ({ audience }: IStoreState) => audience.notification_info;

export const currentAudience = ({ audience }: IStoreState) => audience.current_audience;

export const createdCampaign = ({ audience }: IStoreState) => audience.created_campaign;