import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getSegmentDefinitions, AUDIENCE_SEGMENT_MAP } from '@loypal/lib-growth'
import { DonutChartCampaigns } from '../../components/Charts';
import { IStoreState, } from '../../modules/types';
import { useClientCustomization } from '../../utils/hooks';
import segmentColors from '../../config/segmentColors'
import { campaignsParticipantLifecycleRequest } from '../../modules/actions';

interface IProps {
  startDate: string;
  endDate: string;
  excludeActiveCampaigns: boolean;
  excludePartialCampaigns: boolean;
}

interface IDonutChartData {
  name: string;
  color: string;
  y: number;
}

interface IAudienceSegmentRelationship {
  audience_id: number;
  segment_id: number;
}

export const ParticipantsDonutChart = ({
  startDate, endDate, excludeActiveCampaigns, excludePartialCampaigns
}: IProps) => {
  const { industryGroup } = useClientCustomization()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(campaignsParticipantLifecycleRequest({
      start_date: startDate,
      end_date: endDate,
      exclude_partial_campaigns: excludePartialCampaigns ? 1 : 0,
      exclude_active_campaigns: excludeActiveCampaigns ? 1 : 0,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, excludeActiveCampaigns, excludePartialCampaigns])

  const campaignParticipants = useSelector((state: IStoreState) => {
    return state.campaign.campaign_participant
  })

  const donutChartData: IDonutChartData[] = []
  const segments = getSegmentDefinitions(industryGroup)
  let totalParticipants = 0;

  segments.forEach((segment: any, i: number) => {
    donutChartData.push({
      name: segment.name,
      color: segmentColors[segment.name],
      y: 0
    })
  })

  for (const campaignParticipantsRow of campaignParticipants) {
    const audienceSegment = AUDIENCE_SEGMENT_MAP.find((row: IAudienceSegmentRelationship) => row.audience_id === campaignParticipantsRow.audience_id);
    const totalCampaignParticpants = campaignParticipantsRow.control_person_count + campaignParticipantsRow.active_person_count
    if (donutChartData[audienceSegment.segment_id - 1]) {
      donutChartData[audienceSegment.segment_id - 1].y += totalCampaignParticpants;
      totalParticipants += totalCampaignParticpants;
    }
  }

  const donutSubtitle = (
    <Subtitle>
      <div><strong>{totalParticipants.toLocaleString('en')}</strong></div>
      <div>Participants</div>
    </Subtitle>
  );

  return (
    <DonutChartCampaigns
      title={`Campaign Participants by Lifecycle`}
      subtitle={donutSubtitle}
      data={donutChartData}
    />
  )
}

const Subtitle = styled.div`
  font-size: 1.125rem;
`