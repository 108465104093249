import styled from 'styled-components';

export const Circle = styled.div`
  min-width: 32px;
  min-height: 32px;
  width: 32px;
  height: 32px;
  border: solid 0.0625rem ${props => props.color || '#fff'};
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
