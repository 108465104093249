import { IStoreState } from '../types';

export * from './user';
export * from './segment';
export * from './audience';
export * from './campaign';

export const isDataRequested = (store: IStoreState) => (
  /* tslint:disable:cyclomatic-complexity */
  store.segment.is_data_requested
  || store.audience.is_data_requested
  || store.user.is_data_requested
  || store.campaign.is_data_requested
  || store.customers.is_data_requested
  || store.audience.is_audience_card_data_requested
  || store.audience.is_notification_data_requested
  || store.campaign.is_campaign_segment_data_requested
  || store.audience.is_campaign_info_data_requested
  || store.integration.is_data_requested
);
