
import React from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { LoadingSkeleton } from '../../components/LoadingSkeleton';
import Grid from '@material-ui/core/Grid';
import { GRID_SIZE_12, GRID_SIZE_4, GRID_SIZE_6 } from '../../config';
import { Box } from '@material-ui/core';

const PageTitleContainer = styled.div`
  padding: 1.25rem 2.95rem 0.9375rem 2.95rem;
  width: 100%;
`;

const RevenueCardWrapper = styled.div`
  display: flex;
  padding-top: 1.25rem;
  flex-wrap: wrap;  
`;

const FullWidthElement = styled.div`
  padding: 1.25rem 1.25rem 0.9375rem 1.25rem;
  display:flex;
  column-gap: 20px;
  box-shadow: ${theme.boxShadow};
  background-color: ${theme.colorWhite};
  margin-top: 10px;
`;

const HalfWidthDisributionwrapper = styled.div`
  padding-left: 2rem;
`;

const FirstLeftTransCardSize = styled.div`
  padding-left: 2.6rem;
  padding-top:1rem;
`;

const HalfWidth = styled.div`
  padding-left: 1rem;
`;

const LeftTransCardSize = styled.div`
  padding-left:9rem;
  padding-right: 1rem;
  padding-top: 1rem;
`;

const AverageValueSize = styled.div`
  display:flex;
`;

const AverageTransactionCardSize = styled.div`
padding-top: 5rem;
padding-left: 7rem;

@media (min-width: 1275px) and (max-width: 1550px){
  padding-left: 0.1rem;
}
`;

const AverageTransCardWrapper = styled.div`
  padding: 1.25rem 1.25rem 0.9375rem 1.25rem;
  box-shadow: ${theme.boxShadow};
  background-color: ${theme.colorWhite};
`;

const AverageTransactionCardWrapper = styled.div`
  display: flex;
`;

const CustomerAndTransactionWrapper = styled.div`
  display: flex;
  padding-top:2rem;
  width: 100%;
`;

const CompleteDistributionCardSize = styled.div`
  display: flex;
  column-gap: 30px;
  padding-top: 9rem;
  padding-left: 3rem;
  padding-right: 3rem;

  @media (min-width: 1280px) and (max-width: 1550px){
    padding-top: 1rem;
  }
`;

const CompleteDistributionContainer = styled.div`
  display: flex;

  @media (min-width: 1280px) and (max-width: 1550px){
    justify-content: center;
    flex-direction: column; 
  }
`;

const DistributionCardWrapper = styled.div`
  padding: 1.25rem 1.25rem 0.9375rem 1.25rem;
  box-shadow: ${theme.boxShadow};
  background-color: ${theme.colorWhite};
`;

const DistributionCardSize = styled.div`
  padding-top: 3.25rem;
  padding-left: 1.9rem;
  padding-bottom: 3.25rem;
`;

const LoadingCampaignPage = () => {

  return (
    <PageTitleContainer>
      <LoadingSkeleton variant="text"  width="700px" height="90px"/>
      <RevenueCardWrapper>
      <Grid container={true} item={true} spacing={GRID_SIZE_4}>
      <Grid item={true} lg={GRID_SIZE_4} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12}>
      <FullWidthElement>
        <LoadingSkeleton variant="rect" width="70px" height="60px" />
        <Box>
        <LoadingSkeleton variant="text" width="124px" height="35px" />
        <LoadingSkeleton variant="text"  width="124px" height="18px" />
        </Box>
      </FullWidthElement>
      </Grid>
      <Grid item={true} lg={GRID_SIZE_4} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12}>
         <FullWidthElement>
         <LoadingSkeleton variant="rect" width="70px" height="60px" />
        <Box>
        <LoadingSkeleton variant="text" width="124px" height="35px" />
        <LoadingSkeleton variant="text"  width="124px" height="18px" />
        </Box>
        </FullWidthElement>
      </Grid>
      <Grid item={true} lg={GRID_SIZE_4} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12}>
       <FullWidthElement>
      <LoadingSkeleton variant="rect" width="70px" height="60px" />
        <Box>
        <LoadingSkeleton variant="text" width="124px" height="35px" />
        <LoadingSkeleton variant="text"  width="124px" height="18px" />
        </Box>
      </FullWidthElement>
      </Grid>
      </Grid>
      </RevenueCardWrapper> 
      <CustomerAndTransactionWrapper>
        <Grid container={true} item={true} spacing={GRID_SIZE_4}>
        <Grid item={true} lg={GRID_SIZE_6} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12}>
      <DistributionCardWrapper>
      <LoadingSkeleton variant="text" width="292px" height="30px" />
      <CompleteDistributionContainer>
      <DistributionCardSize>
      <LoadingSkeleton variant="rect" width="190px" height="200px" />
      </DistributionCardSize>
      <CompleteDistributionCardSize>
      <Box>
       <LoadingSkeleton variant="text" width="130px" height="25px" />
       <LoadingSkeleton variant="text"  width="130px" height="25px" />
      </Box>
     <HalfWidthDisributionwrapper>
       <LoadingSkeleton variant="text" width="60px" height="25px" />
       <LoadingSkeleton variant="text"  width="60px" height="25px" />
     </HalfWidthDisributionwrapper>
     </CompleteDistributionCardSize>
     </CompleteDistributionContainer>
     </DistributionCardWrapper> 
     </Grid>
     <Grid item={true} lg={GRID_SIZE_6} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12}>
     <AverageTransCardWrapper>
      <LoadingSkeleton variant="text" width="292px" height="30px" />
     <AverageTransactionCardWrapper>
      <AverageTransactionCardSize>
        <LoadingSkeleton variant="rect" width="152px" height="162px" />
      <AverageValueSize>
      <FirstLeftTransCardSize>
       <LoadingSkeleton variant="text"  width="60px" height="25px" />
     </FirstLeftTransCardSize>
     <LeftTransCardSize>
       <LoadingSkeleton variant="text" width="90px" height="25px" />
       <HalfWidth>
       <LoadingSkeleton variant="text"  width="60px" height="25px" />
       </HalfWidth>
     </LeftTransCardSize>
     </AverageValueSize>
     </AverageTransactionCardSize>
     </AverageTransactionCardWrapper>
     </AverageTransCardWrapper> 
     </Grid>
     </Grid>
     </CustomerAndTransactionWrapper>
    </PageTitleContainer> 
  )
}

export default LoadingCampaignPage;
