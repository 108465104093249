import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  Dialog,
} from '@material-ui/core';
import theme from '../../assets/css/theme';
import { GRID_SIZE_12, GRID_SIZE_6 } from '../../config';
import { InputText } from '../../components/Form';

interface IProps {
  nickname: string;
  full_name: string;
  picture: string;
  short_name: string;
  email: string;
  preferred_username: string;
  onSave: (picture: string) => void;
}

const Details = styled.div`
  display: flex;
`;

const AVATAR_SIZE = 6.25;

const Container = styled(Grid)`
  && {
    text-align: center;
    @media ${theme.media.tablet} {
      text-align: left;
    }
  }
`;

const DialogCard = styled(Card)`
  && {
    @media ${theme.media.tablet} {
      min-width: 37.5rem;
    }
  }
`;

const InputTextStyled = styled(InputText)`
  && {
    font-family: inherit;
  }
`;

const AvatarStyled = styled(Avatar)`
  && {
    background-color: ${theme.colorDarkGray};
    color: ${theme.colorWhite};
    margin: 0 auto;
    height: ${AVATAR_SIZE}rem;
    width: ${AVATAR_SIZE}rem;
    flex-shrink: 0;
    flex-grow: 0;
    
    @media ${theme.media.tablet} {
      margin: 0 0 0 auto;
    }
  }
`;

const FullName = styled.h2`
  font-size: 1.75rem;
`;

const AccountProfile = (props: IProps) => {
  const avatar = props.picture ? <AvatarStyled src={props.picture}/> : <AvatarStyled>{props.short_name}</AvatarStyled>;
  const [ picture, setPicture ] = useState(props.picture || '');
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPicture(event.target.value);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };


  const handleSave = () => {
    props.onSave(picture);
    setIsDialogOpen(false);
  };

  const isImageValidUrl = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg)/.test(picture);

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Details>
            <Container wrap="wrap" container={true}>
              <Grid item={true} md={GRID_SIZE_6} xs={GRID_SIZE_12}>
                <FullName>
                  {props.full_name}
                </FullName>
              </Grid>
              <Grid item={true} md={GRID_SIZE_6} xs={GRID_SIZE_12}>
                {avatar}
              </Grid>
              <Grid item={true} xs={GRID_SIZE_12}>
                <Typography color="textSecondary" variant="body1">
                  {props.email}
                </Typography>
                <Typography color="textSecondary" variant="body1">
                  {props.nickname}
                </Typography>
              </Grid>
            </Container>
          </Details>
        </CardContent>
        <Divider />
        
      </Card>
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={isDialogOpen}>
        <DialogCard>
          <CardContent>
            <Details>
              <Container wrap="wrap" container={true}>
                <Grid item={true} xs={GRID_SIZE_12}>
                  <InputTextStyled
                    error={!isImageValidUrl}
                    type="text"
                    label="Picture"
                    margin="dense"
                    value={picture}
                    fullWidth={true}
                    onChange={handleChange}
                  />
                </Grid>
              </Container>
            </Details>
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" variant="text" onClick={handleSave} disabled={!isImageValidUrl}>OK</Button>
            <Button color="primary" variant="text" onClick={handleClose}>Cancel</Button>
          </CardActions>
        </DialogCard>
      </Dialog>
    </React.Fragment>
  );
};

export default AccountProfile;
