import React from 'react';
import { LoadingSkeleton } from '../../components/LoadingSkeleton';
import Grid from '@material-ui/core/Grid';
import {
  GRID_SIZE_12,
} from '../../config';
import {
  CardContainer,
  CardColumn,
  OpportunityCardColumn,
} from './CommonLifecycleAndLoadingSegment';

 const LoadingLifecycleSegmentCard = () => {
  return (
    <CardContainer item={true} container={true} xs={GRID_SIZE_12} alignItems="flex-end" justifyContent="center">
      <CardColumn>
        <Grid item={true} xs={GRID_SIZE_12}>
          <LoadingSkeleton variant="text" width = "30%"/>
          <LoadingSkeleton variant="text" width = "50%"/>
        </Grid>
      </CardColumn>
      <OpportunityCardColumn>
        <LoadingSkeleton variant="rect" width = "100%" height="40px"/>
      </OpportunityCardColumn>
    </CardContainer>
    )
}

export default LoadingLifecycleSegmentCard;
