const FLAGS = {
  settings: ['lpapp', 'loypal-local', 'loypal-dev', 'devclientfour', 'devclientfive', 'devclientsix'],
  campaignContactabilityFiltering: { isLive: true },
  campaignExcludeStores: ['lpapp', 'endota', 'loypal-local', 'loypal-dev', 'devclientfour', 'devclientfive', 'devclientsix'],
  campaignUDFFiltering: ['loypal-local', 'loypal-dev', 'devclientfour', 'mater'],
  integrationKlaviyo: ['lpapp', 'loypal-local', 'loypal-dev', 'devclientfour', 'devclientfive', 'devclientsix'],
}

export const useFeatureFlag = (featureName: string, clientName?: string) => {
  if (!FLAGS[featureName]) {
    return false;
  }

  if (Array.isArray(FLAGS[featureName]) && clientName) {
    return FLAGS[featureName].includes(clientName)
  }

  return FLAGS[featureName].isLive;
}

