import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import theme from '../../../assets/css/theme'
import { IIntegrationPlatformInfo } from '../../../modules/types';
import { getIntegrationTypeLabel, getIntegrationPlatformLabel, getIntegrationTypesForPlatform, IIntegrationType } from '../IntegrationsForm/integration-options';
import { CardTitle } from '../../../components/Text';
import { PasswordDisplay } from '../../../components/Form/InputText';
import { Checkbox, CheckboxLabel } from '../../../components/Form/Checkbox';
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'

const DUMMY_PASSWORD = 'abcdefghijklmnop'

interface IProps extends IIntegrationPlatformInfo {
  platform: string
}

export const IntegrationCard = ({ platform, flags, credentials}: IProps) => {
  const allIntegrationTypes: IIntegrationType[] = getIntegrationTypesForPlatform(platform)
  return (
    <CardContainer>
      <Box mb="14px" display="flex" justifyContent="space-between" alignItems="center">
        <CardTitle>{getIntegrationPlatformLabel(platform)}</CardTitle>
        <IconButton color="primary" aria-label="upload picture" component="span">
          <EditIcon />
        </IconButton>
      </Box>
      <Box display="flex" gridColumnGap="20px" mb="20px">
        {credentials.map((credentialLabel) => (
          // for security reasons, do not display the credential values
          <PasswordDisplay key={credentialLabel} label={credentialLabel} value={DUMMY_PASSWORD} />
        ))}
      </Box>
      <Box display="flex" gridColumnGap="20px">
        {allIntegrationTypes.map(integrationType => {
          const integration = flags[integrationType.value]
          const isActive = integration && integration.isActive;
          return (
            <CheckboxLabel
              key={integrationType.label}
              control={
                <Checkbox 
                  checked={isActive}
                />
              }
              label={getIntegrationTypeLabel(integrationType.label)}
            />
          )
        })}
      </Box>
    </CardContainer>
  )
}

export const LoadingIntegrationCard = () => (
  <CardContainer>
    <Box width="35%" mt="8px">
      <LoadingSkeleton variant="text" />
    </Box> 
    <Box width="20%" mt="20px">
      <LoadingSkeleton variant="text" />
    </Box> 
    <Box width="25%" height="36px" mt="12px">
      <LoadingSkeleton variant="rect" height="100%" />
    </Box>
    <Box display="flex" mt="20px" mb="8px">
      <Box width="30%">
        <LoadingSkeleton variant="text" />
      </Box> 
      <Box width="30%" ml="20px">
        <LoadingSkeleton variant="text" />
      </Box> 
    </Box>
  </CardContainer>
)
 
const CardContainer = styled.div`
  padding: 0.75rem 0.75rem 1.125rem 1.25rem;
  box-shadow: ${theme.boxShadow};
  background-color: ${theme.colorWhite};
  margin-bottom: 20px;
`;