import * as React from 'react';
import { withHeader } from '../components';

export const NotFound = () => (
  <div>
    <span>404</span>
  </div>
);

export default withHeader(NotFound);
