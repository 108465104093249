const colors = {
  blue: '#120645',
  darkBlue: '#43425d',
  lightBlue: '#3B86FF',
  turquoise: '#00E9CE',
  melrose: '#A4A1FB',
  green: '#4ad991',
  lightGreen: '#daf7e8',
  red: '#FE0056',
  gray: '#BFBFBF',
  gray2: '#CCCCCC',
  lightestGray: '#f8f7f8',
  dimGray: '#c0bfcc',
  lightGray: '#f0f0f7',
  lightGray1: '#EDF0F5',
  lightGray2: '#E8E9EC',
  darkGray: '#4d4f5c',
  darkestGray: '#433b4b',
  mediumGray: '#A5A4BF',
  lightOrange: '#ffc06a',
  purple: '#a3a0fb',
  darkPurple: '#3C3543',
  white: '#ffffff',
  infoBackground: '#FEFAE6',
  info: '#FF991F', 
  lightPink: '#F57DA8',
  darkestBlue: '#150B3D',
  lighestPurple: '#736594',
  lightWhite: '#efefef',
  darkRed: '#c6215b',
  black: '#000000'

};

const sizes = {
  mobile: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  tabletL: 960,
  laptop: 1024,
  laptopM: 1280,
  laptopL: 1440,
  desktop: 2560
};

const theme = {
  // plain colors
  colorBlack: colors.black,
  colorBlue: colors.blue,
  colorDarkBlue: colors.darkBlue,
  colorLightBlue: colors.lightBlue,
  colorTurquoise: colors.turquoise,
  colorMelrose: colors.melrose,
  colorGreen: colors.green,
  colorLightGreen: colors.lightGreen,
  colorRed: colors.red,
  colorDarkRed: colors.darkRed,
  colorGray: colors.gray,
  colorGray2: colors.gray2,
  colorLightestGray: colors.lightestGray,
  colorDimGray: colors.dimGray,
  colorLightGray: colors.lightGray,
  colorDarkGray: colors.darkGray,
  colorDarkestGray: colors.darkestGray,
  colorMediumGray: colors.mediumGray,
  colorPurple: colors.purple,
  colorDarkPurple: colors.darkPurple,
  colorWhite: colors.white,
  colorLightOrange: colors.lightOrange,
  colorInfoBackground: colors.infoBackground,
  colorInfo: colors.info,
  colorLightPink: colors.lightPink,
  colorDarkestBlue: colors.darkestBlue,
  colorLighestPurple: colors.lighestPurple,
  colorLightWhite: colors.lightWhite,


  // elements colors
  backgroundGradient: [
    '#242348',
    '#5A55AA'
  ],
  backgroundActive: colors.darkPurple,
  colorActive: colors.purple,
  colorInactive: colors.mediumGray,
  colorBorderControls: colors.lightGray2,
  titleColor: colors.darkBlue,
  subtitleColor: colors.darkGray,
  footerBackground: colors.gray,
  headerBackground: colors.gray,
  chartBackground: colors.white,
  mainBackground: colors.lightestGray,
  boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.04)',
  boxShadowDarker: '0 2px 10px 0 rgba(0, 0, 0, 0.15)',

  // fonts
  ffSansProRegular: 'SourceSansPro-Regular',
  ffInterRegular: 'Inter-Regular',
  ffInterMedium: 'Inter-Medium',
  ffInterLight: 'Inter-Light',
  ffInterExtraLight: 'Inter-Extra-Light',

  // font size
  htmlFontSize: 16,

  //
  negativeColor: colors.red,
  positiveColor: colors.green,

  // bar colors
  emptyBarColor: colors.lightGray1,
  filledBarColor: colors.blue,

  // media sizes
  media: {
    mobile: `(min-width: ${sizes.mobile}px)`,
    mobileM: `(min-width: ${sizes.mobileM}px)`,
    mobileL: `(min-width: ${sizes.mobileL}px)`,
    tablet: `(min-width: ${sizes.tablet}px)`,
    tabletL: `(min-width: ${sizes.tabletL}px)`,
    laptop: `(min-width: ${sizes.laptop}px)`,
    laptopLTE: `(max-width: ${sizes.laptop}px)`,
    laptopM: `(min-width: ${sizes.laptopM}px)`,
    laptopMLTE: `(max-width: ${sizes.laptopM}px)`,
    laptopL: `(min-width: ${sizes.laptopL}px)`,
    laptopLLTE: `(max-width: ${sizes.laptopL}px)`,
    desktop: `(min-width: ${sizes.desktop}px)`,
  },

  // raw sizes
  size: {
    ...sizes,
  }
};

export default theme;
