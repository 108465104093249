import React, { useState, useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import styled from 'styled-components';

interface IFormControl {
  component: string,
}

interface IFormLabel {
  component: string
}

interface IRadioGroup {
  'aria-label': string,
  name: string,
  value: string,
  onChange: any
}

interface IFormControlLabel {
  key: number,
  value: string,
  label: string,
  control: any,
}

const StyledFormControl = styled(FormControl)<IFormControl>`
  margin-bottom: 0.5rem;
`;

const StyledFormLabel = styled(FormLabel)<IFormLabel>`
  &&&.MuiFormLabel-root {
    font-size: 0.9375rem;
    color: inherit;
  }

  margin-bottom: 0.625rem
`;

const StyledRadioGroup = styled(RadioGroup)<IRadioGroup>`
  &&&.MuiFormGroup-root {
    padding-left: 1.75rem;
    font-size: 0.9375rem;
    color: inherit;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)<IFormControlLabel>`
  &&&.MuiFormControlLabel-root > .MuiTypography-body1  {
    font-size: 0.9375rem;
    color: inherit;
  }
`;

export const RadioButtonsGroup = (props: any) => {
  const { title, items, defaultValue, setHandle } = props;
  const [defaultPosition, setDefaultPosition] = useState(defaultValue.toString());

  useEffect(() => {
    setHandle(defaultPosition);
  });

  const handleChange = (event: any) => {
    setDefaultPosition(event.target.value);

    if (setHandle) {
      setHandle(event.target.value);
    }
  };

  return (
    <StyledFormControl component="fieldset">
      <StyledFormLabel component="legend">{title}</StyledFormLabel>
      <StyledRadioGroup aria-label={title} name={title} value={defaultPosition} onChange={handleChange}>
        {
            items.map((item: string, index: number) => {
                return (
                    <StyledFormControlLabel key={index} value={index.toString()} control={<Radio />} label={item} />
                )
            })
        }
      </StyledRadioGroup>
    </StyledFormControl>
  );
}