import * as React from 'react';
import Highcharts, { ColorString, Point, PointOptionsObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styled from 'styled-components';
import { renderToString } from 'react-dom/server';
import theme from '../../assets/css/theme';
import { isEmpty } from 'lodash';
import merge from 'lodash/merge';

const THOUSAND_DIVIDER = 1000;

const Container = styled.div`
  background-color: ${theme.colorWhite};
  box-shadow: ${theme.boxShadow};
`

const Title = styled.div`
  font-size: 1.125rem;
  font-family: ${theme.ffInterRegular};
  fill: ${theme.colorDarkPurple};
  color: ${theme.colorDarkPurple};
`

const Subtitle = styled.div`
  font-size: 0.8125rem;
  display: flex;
  flex-direction: row;
  font-family: ${theme.ffInterRegular};
  opacity: 0.8;
  color: ${theme.colorLightGray}
  
  :before {
    content: "";
    margin-right: 8px;
    height: 14.6px;
    width: 14.6px;
    border-radius: 50%;
    border: solid 3px ${props => props.color || ''};
    background-color: ${theme.colorDarkPurple};
  }
`

interface IState {
  options: Highcharts.Options,
}

interface IProps {
  title: string,
  data: PointOptionsObject[]
}

export class ColumnChart extends React.Component<IProps, IState> {

  public static getDerivedStateFromProps(nextProps: IProps, prevState: IProps) {
    if (prevState.data !== nextProps.data) {
      return merge(prevState, {
        options: {
          series: [{
            data: nextProps.data,
          }]
        },
      });
    }

    return null;
  }

  public state: IState = {
    options: {
      title: {
        text: renderToString(<Title>{this.props.title}</Title>),
        align: 'left',
        verticalAlign: 'top',
        margin: 88,
        x: 10,
        y: 21,
      },
      subtitle: {
        useHTML: true,
        text: renderToString(!isEmpty(this.props.data) ? <Subtitle
          color={this.props.data[0].color as string}>{`${this.props.data[0].name} - $${this.props.data[0].y}`}</Subtitle> : <div />),
        align: 'left',
        x: 10,
        y: 50,
      },
      chart: {
        height: 352,
        marginLeft: 55,
        marginRight: 55,
      },
      colors: [theme.colorDarkestBlue],
      xAxis: {
        type: 'category',
        labels: {
          style: {
            fontFamily: theme.ffInterRegular,
            color: theme.colorDarkBlue,
            fontWeight: 'bold',
          }
        }
      },
      yAxis: {
        labels: {
          formatter() {
            const formattedValue = typeof this.value === "string" ? parseInt(this.value, 0) : this.value
            return this.value >= THOUSAND_DIVIDER ? `$${formattedValue / THOUSAND_DIVIDER}k` : `$${this.value}`
          },
          style: {
            fontFamily: theme.ffInterRegular,
            color: theme.colorDarkBlue,
          }
        },
        title: {
          text: '',
        },
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        series: {
          point: {
            events: {
              mouseOver: e => this.updateSubtitle(e.target as unknown as Point),
            },
          },
        },
      },
      series: [{
        type: 'column',
        data: this.props.data,
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 550
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            },
            title: {
              align: 'left',
            },
            subtitle: {
              align: 'left',
              y: 80,
            }
          }
        }]
      }
    }
  };

  public updateSubtitle(data: Point) {
    const {series: {chart: {subtitle: {element}}}, name, color, y} = data;
    const label = `${name} - $${(y || 0).toLocaleString('en')}`;
    element.innerHTML = renderToString(<Subtitle color={color as ColorString}>{label}</Subtitle>);
  }

  public render() {
    return <Container>
      <HighchartsReact 
        highcharts={Highcharts}
        options={this.state.options}
        updateArgs={[true]}
      />
    </Container>
  }
}

export default ColumnChart;
