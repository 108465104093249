import * as React from 'react';
import { renderToString } from 'react-dom/server'
import HC_patternFill from "highcharts-pattern-fill";
import Highcharts, {
  ColorString,
  GradientColorStopObject,
  LinearGradientColorObject,
  SeriesColumnOptions,
} from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { map } from 'lodash';
import merge from 'lodash/merge';
import chroma from 'chroma-js';
import { CircularLoading } from '../../components/CircularLoading'

const Title = styled.div`
  color: ${theme.colorDarkGray};
  font-size: 1.125rem;
`;

const START_GRADIENT = 1;
const HALF_GRADIENT = 0.3;
const FINISH_GRADIENT = 0;
const DEFAULT_NUM_Y_AXIS_TICKS = 5
const DEFAULT_HEIGHT = 352

const defaultColors = [
  theme.colorMelrose,
  theme.colorTurquoise,
  theme.colorLightOrange,
  theme.colorBlue,
  theme.colorRed,
];

export interface IStackedColumnChartData {
  name: string
  color?: string
  data: any[]
  [key: string]: any
}

interface ISeries extends SeriesColumnOptions {
  color?: string;
  fillColor?: {
    linearGradient: LinearGradientColorObject,
    stops: GradientColorStopObject[]
  };
}

export interface IStackedColumnProps {
  title?: string;
  data: any[];
  categories: string[];
  colors?: string[];
  shouldShowDataLabels?: boolean
  isLoading?: boolean
  yAxisTickAmount?: number
  height?: string | number
  tooltipOpts?: any,
  yAxisCeiling?: number
  withColorGradient?: boolean
}

const getAlphaColor = (color: string, alpha: number): ColorString => {
  return chroma(color).alpha(alpha).css();
}

HC_patternFill(Highcharts);

export const StackedColumnChart = ({ 
  title, 
  categories, 
  data, 
  yAxisTickAmount = DEFAULT_NUM_Y_AXIS_TICKS, 
  shouldShowDataLabels = true, 
  isLoading = false,
  height = DEFAULT_HEIGHT,
  yAxisCeiling,
  tooltipOpts,
  withColorGradient = false,
  ...props
}: IStackedColumnProps) => {
  const prepareSeries = (seriesData : IStackedColumnChartData[]): ISeries[] => {
    if (!withColorGradient) {
      return seriesData.map(series => {
        return merge(series, {
          type: 'column' as 'column',
        })
      })
    }

    const HALF_STOP = 0.5;
    const colors = (props.colors || []).concat(defaultColors);
    return map(seriesData, (series, index): ISeries => {
      const color = colors[index];
      return merge(series, {
        type: 'column' as 'column',
        color,
        fillColor: {
          linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 0.8,
          },
          stops: [
            [0 , getAlphaColor(color, START_GRADIENT)] as GradientColorStopObject,
            [HALF_STOP , getAlphaColor(color, HALF_GRADIENT)] as GradientColorStopObject,
            [1 , getAlphaColor(color, FINISH_GRADIENT)] as GradientColorStopObject,
          ],
        },
      })
    });
  }

  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  });

  const options: Highcharts.Options = {
    defs: {
      // @ts-ignore
      patterns: [
        {
          id: "promising-pattern",
          path: {
            d: "M 0 10 L 10 0 M -1 1 L 1 -1 M 9 11 L 11 9",
            stroke: theme.colorDarkestBlue,
            strokeWidth: 4
          }
        },
        {
          id: "advocate-pattern",
          path: {
            d: "M 0 10 L 10 0 M -1 1 L 1 -1 M 9 11 L 11 9",
            stroke: theme.colorLighestPurple,
            strokeWidth: 4
          }
        },
        {
          id: "hero-pattern",
          path: {
            d: "M 2, 2.5 a 0.75,0.75 0 0,0 1.50,0 a 0.75,0.75 0 0,0 -1.50,0, M 6.5, 6.5 a 0.75,0.75 0 0,0 1.50,0 a 0.75,0.75 0 0,0 -1.50,0",
            stroke: theme.colorLighestPurple,
            strokeWidth: 2
          }
        },
        {
          id: "super-hero-pattern",
          path: {
            d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
            stroke: theme.colorLighestPurple,
            strokeWidth: 2
          }
        },
        {
          id: "last-chance-pattern",
          path: {
            d: "M 0 10 L 10 0 M -1 1 L 1 -1 M 9 11 L 11 9",
            stroke: theme.colorDimGray,
            strokeWidth: 4
          }
        },
        {
          id: "at-risk-advocate-pattern",
          path: {
            d: "M 2, 2.5 a 0.75,0.75 0 0,0 1.50,0 a 0.75,0.75 0 0,0 -1.50,0, M 6.5, 6.5 a 0.75,0.75 0 0,0 1.50,0 a 0.75,0.75 0 0,0 -1.50,0",
            stroke: theme.colorLighestPurple,
            strokeWidth: 2
          }
        },
        {
          id: "at-risk-hero-pattern",
          path: {
            d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
            stroke: theme.colorLighestPurple,
            strokeWidth: 2
          }
        },
      ]
    },
    title: {
      text: title ? renderToString(<Title>{title}</Title>) : undefined,
      align: 'left',
      verticalAlign: 'top',
      x: 10,
      y: 21,
    },
    xAxis: {
      categories: isLoading ? Array(categories.length).fill('') : categories,
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      stackLabels: {
        enabled: shouldShowDataLabels,
      },
      tickAmount: yAxisTickAmount,
      ceiling: yAxisCeiling,
    },
    chart: {
      type: 'colunm',
      animation: false,
      height,
      style: {
        fontFamily: theme.ffInterRegular
      },
    },
    credits: {
      enabled: false
    },
    legend: {
      align: 'left',
      layout: 'horizontal',
      verticalAlign: 'bottom'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: shouldShowDataLabels,
        }
      }
    },
    series: prepareSeries(data),
  }

  if (tooltipOpts) {
    options.tooltip = tooltipOpts
  }

  if (isLoading && options?.series) {
    data.forEach((dataItem, i) => {
      data[i] = {
        ...dataItem,
        data: Array(dataItem.data.length).fill(0)
      }
    })
    options.series = prepareSeries(data)
  }

  return (
    <Container>
      {isLoading && (
        <LoadingContainer>
          <CircularLoading />
        </LoadingContainer>
      )}
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        updateArgs={[true, true, true]}
      />
    </Container>
  )
}

export default StackedColumnChart;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 5;
`
