import * as actions from '../../actions';
import { createReducer } from 'redux-act';
import { ICustomersState, ICustomerDataOverTimeResponse, IStoresResponse, ICurrentLTVResponse } from '../../types'

const defaultState: ICustomersState = {
  dataOverTime: null,
  stores: [],
  lifetimeValues: []
}

const customersClean = () => ({
  ...defaultState,
});

const customerDataOverTimeRequest = (state: ICustomersState) => ({
  ...state,
  is_data_requested: true,
});

const customerDataOverTimeSuccess = (state: ICustomersState, payload: ICustomerDataOverTimeResponse) => ({
  ...state,
  ...payload,
  is_data_requested: false,
});

const customerDataOverTimeFailed = (state: ICustomersState) => ({
  ...state,
  is_data_requested: false,
});

const storesRequest = (state: ICustomersState) => ({
  ...state,
  is_data_requested: true,
});

const storesSuccess = (state: ICustomersState, payload: IStoresResponse) => ({
  ...state,
  ...payload,
  is_data_requested: false,
});

const storesFailed = (state: ICustomersState) => ({
  ...state,
  is_data_requested: false,
});

const lifetimeValueRequest = (state: ICustomersState) => ({
  ...state,
  is_data_requested: true,
});

const lifetimeValueSuccess = (state: ICustomersState, payload: ICurrentLTVResponse) => ({
  ...state,
  ...payload,
  is_data_requested: false,
});

const lifetimeValueFailed = (state: ICustomersState) => ({
  ...state,
  is_data_requested: false,
});

export const customers = createReducer({  }, defaultState)
.on(actions.customerDataOverTimeRequest, customerDataOverTimeRequest)
.on(actions.customerDataOverTimeSuccess, customerDataOverTimeSuccess)
.on(actions.customerDataOverTimeFailed, customerDataOverTimeFailed)
.on(actions.storesRequest, storesRequest)
.on(actions.storesSuccess, storesSuccess)
.on(actions.storesFailed, storesFailed)
.on(actions.lifetimeValueRequest, lifetimeValueRequest)
.on(actions.lifetimeValueSuccess, lifetimeValueSuccess)
.on(actions.lifetimeValueFailed, lifetimeValueFailed)
.on(actions.customersClean, customersClean)
