import './assets/css/fonts.css'
import './assets/css/index.css';
import './assets/css/big_calendar_overrides.css';
import theme from './assets/css/theme';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ClientCustomizationProvider } from './utils/context/client-customization';
import { FiltersProvider } from './utils/context/filters';
import history from './modules/utils/history';
import { GlobalErrors, PrivateRoute, ScrollToTop } from './components';
import store from './modules/store';
import Audience from './pages/Audience';
import Login from './pages/Login';
import LoginUser from './pages/LoginUser';
import ForgotPassword from './pages/ForgotPassword';
import ConfirmForgotPassword from './pages/ConfirmForgotPassword';
import NotFound from './pages/NotFound';
import Opportunity from './pages/Opportunity';
import Lifecycle from './pages/Lifecycle';
import * as serviceWorker from './serviceWorker';
import { CssBaseline, createTheme, MuiThemeProvider } from "@material-ui/core";
import MyProfile from './pages/MyProfile';
import Campaigns from './pages/Campaigns';
import Campaign from './pages/Campaign';
import { CustomersPage } from './pages/Customers';
import { LoyaltyPage } from './pages/Loyalty';
import { CommercialPerformancePage } from './pages/CommercialPerformance';
import CalendarPage  from './pages/CampaignsCalendar';
import LifecycleCampaigns from './pages/LifecycleCampaigns';
import Settings from './pages/Settings';
import IntegrationsForm from './pages/Settings/IntegrationsForm';

// Override default theming for material-ui
const themeUI = createTheme({
  palette: {
    primary: {
      main: theme.colorBlue,
    },
    background: {
      default: theme.mainBackground,
    }
  },
  typography: {
    fontFamily: theme.ffInterRegular,
  }
});
const MainPageRedirect = () => <Redirect to="/opportunity"/>;


ReactDOM.render(
  <MuiThemeProvider theme={themeUI}>
    <Provider store={store}>
      <ClientCustomizationProvider>
        <FiltersProvider>
          <style type="text/css">{`html { font-size: ${theme.htmlFontSize}px; }`}</style>
          <>
            <CssBaseline/>
            <Router history={history}>
              <ScrollToTop>
                <Switch>
                  <Route exact={true} path="/" render={MainPageRedirect}/>
                  <Route path="/login" component={Login}/>
                  <Route path="/login-user" component={LoginUser}/>
                  <Route path="/forgot-password" component={ForgotPassword}/>
                  <Route path="/confirm-forgot-password" component={ConfirmForgotPassword}/>
                  <PrivateRoute exact={true} path="/opportunity" component={Opportunity}/>
                  <PrivateRoute exact={true} path="/segments/:segment_id" component={Lifecycle}/>
                  <PrivateRoute exact={true} path="/segments/:segment_id/audiences/:audience_id" component={Audience}/>
                  <PrivateRoute exact={true} path="/user/profile" component={MyProfile}/>
                  <PrivateRoute exact={true} path="/campaigns" component={Campaigns}/>
                  <PrivateRoute exact={true} path="/campaigns/segments/:segment_id" component={LifecycleCampaigns}/>
                  <PrivateRoute exact={true} path="/campaign/:campaign_id" component={Campaign}/>
                  <PrivateRoute exact={true} path="/customers" component={CustomersPage}/>
                  <PrivateRoute exact={true} path="/customers/loyalty" component={LoyaltyPage}/>
                  <PrivateRoute exact={true} path="/customers/commercial-performance" component={CommercialPerformancePage}/>
                  <PrivateRoute exact={true} path="/campaigns/calendar" component={CalendarPage} />
                  <PrivateRoute exact={true} path="/settings" component={Settings} />
                  <PrivateRoute exact={true} path="/settings/add-integration" component={IntegrationsForm} />
                  <Redirect from="*" to="/"/>
                  <Route component={NotFound}/>
                </Switch>
                <GlobalErrors/>
              </ScrollToTop>
            </Router>
          </>
        </FiltersProvider>
      </ClientCustomizationProvider>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
