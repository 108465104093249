import * as React from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

const PRELOADER_SIZE = 20;

const CircularProgressWrapper = styled.div`
 && {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.7);
 }
`;

const CircularProgressStyled = styled(CircularProgress)`
  && {
    color: ${theme.colorGreen}
  }
`;

const baseStyles = (props: any) => `
  position: relative;
  text-align: center;
  border-radius: 4px;
  background-color: ${theme.colorBlue};
  border: none;
  color: ${theme.colorWhite};
  font-size: 0.9375rem;
  cursor: pointer;
  text-decoration: none;
  padding: 0.625rem 1.063rem;
  width: 100%;
  ${ props.is_loading ? `
      pointer-events: none;
    ` : '' }
  @media ${theme.media.laptop} {
    width: auto;
  }
`;

const ExternalLink = styled.a`
  && {
    ${baseStyles};
  }
`;

const ButtonStyled = styled(Link)`
  ${baseStyles};
`;

const NonLinkButton = styled.span`
  ${baseStyles};
`;

export const Button = (props: any) => {
  if (props.external) {
    return (
      <ExternalLink {...props} target="_blank" href={props.to}>
        { props.children }
        { props.is_loading &&  <CircularProgressWrapper>
          <CircularProgressStyled size={PRELOADER_SIZE} />
        </CircularProgressWrapper> }
      </ExternalLink>
    );
  }

  if (props.to) {
    return (
      <ButtonStyled {...props} disabled={props.is_loading}>
        { props.children }
        { props.is_loading &&  <CircularProgressWrapper>
         <CircularProgressStyled size={PRELOADER_SIZE} />
        </CircularProgressWrapper> }
      </ButtonStyled>
    )
  }

  return (
    <NonLinkButton {...props} disabled={props.is_loading}>
      { props.children }
      { props.is_loading &&  <CircularProgressWrapper>
       <CircularProgressStyled size={PRELOADER_SIZE} />
      </CircularProgressWrapper> }
    </NonLinkButton>
  )
}

export const SubmitButton = styled(Button)`
  &&.isDisabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
  &&.isHidden {
    display: none;
  }
`;
