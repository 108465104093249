import { createAction } from 'redux-act';
import { IAllIntegrationsResponse, IIntegrationFlagsRequestPayload, ISetIntegrationCredentialsPayload } from '../../types';

// All integrations
export const allIntegrationsRequest = createAction('Fetch all integrations');
export const allIntegrationsSuccess = createAction<IAllIntegrationsResponse>('All integrations fetched successfully');
export const allIntegrationsFailed = createAction<string>('All integrations fetching failed');

// Toggle integration flags
export const toggleIntegrationsRequest = createAction<IIntegrationFlagsRequestPayload>('Toggle integration flags');
export const toggleIntegrationsSuccess = createAction('Toggled integration flags successfully');
export const toggleIntegrationsFailed = createAction<string>('Toggling integration flags failed');

// Create or update integration credentials
export const setIntegrationCredentialsRequest = createAction<ISetIntegrationCredentialsPayload>('Update integration credentials');
export const setIntegrationCredentialsSuccess = createAction('Updated integration credentials successfully');
export const setIntegrationCredentialsFailed = createAction<string>('Updating integration credentials failed');
