import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';

const Root = styled.div``;

interface IProps {
  children?: React.ReactNode
}

const GridRoot = (props: IProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexGrow: 1,
      },
    }),
  );
  const classes = useStyles();
  return <Root className={classes.root}>{props.children}</Root>
}

export default GridRoot;
