export const activeConfig = {
  opportunity: [
    '/opportunity',
    '^/segments/?.*',
  ],
  campaigns: [
    '/campaigns',
    '/campaign',
  ],
  customers: [
    '/customers',
    '/customers/?.*',
  ],
  settings: [
    '/settings',
  ],
};