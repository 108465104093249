import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ISagaAction,
  IAudienceRequestApi,
  INotificationRequestApi,
  IAudienceSummaryRequestApi
} from '../../types';

export const audienceRequestSaga = function* (action: ISagaAction<IAudienceRequestApi>): SagaIterator {
  try {
    const audience: any = yield call(Api.Audience.fetchAudienceSummary, {
      segment_id: action.payload.segment_id,
      audience_id: action.payload.audience_id,
      exclude_stores: action.payload.exclude_stores,
      contactability_filters: action.payload.contactability_filters,
      udf_filters: action.payload.udf_filters,
    });
    yield put(actions.audienceCardSuccess(audience));
  } catch (e) {
    yield put(actions.audienceCardFailed((e as Error).message));
  }
};

export const getNotificationInfoRequestSaga = function* (action: ISagaAction<INotificationRequestApi>): SagaIterator {
  try {
    const data: any = yield call(Api.Campaign.exportInformation, action.payload);
    yield put(actions.getNotificationInfoSuccess(data));
  } catch (e) {
    yield put(actions.getNotificationInfoFailed((e as Error).message));
  }
};

export const audiencesSummaryCardRequestSaga = function* (action: ISagaAction<IAudienceSummaryRequestApi>): SagaIterator {
  try {
    const audienceSummary: any = yield call(Api.AudienceSummary.fetchAudienceSummary, {
      audience_id: action.payload.audience_id,
    });
    yield put(actions.audiencesSummaryCardSuccess(audienceSummary));
  } catch (e) {
    yield put(actions.audiencesSummaryCardFailed((e as Error).message));
  }
}
