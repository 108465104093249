import * as React from 'react';
import theme from '../../assets/css/theme';
import { useState, useMemo } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
} from '@material-ui/core';
import { GRID_SIZE_12, GRID_SIZE_3 } from '../../config';
import styled from 'styled-components';
import { InputText } from '../../components/Form';
import { pick } from 'lodash';
import { EventEmitter, EVENT_PASSWORD_CLEAN } from '../../modules/utils/events';

export interface IPassword {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

interface IProps {
  onSave: (data: Omit<IPassword, 'confirm_password'>) => void;
  error?: string | boolean;
}

const InputTextStyled = styled(InputText)`
  && {
    font-family: inherit;
  }
`;

const ButtonWrapper = styled.div`
  display: flex; 
  flex-wrap: wrap;
  flex-direction: column;
`;

const MessageWrapper = styled.div`
  padding-bottom: 15px;
  color: ${theme.negativeColor};
  font-weight: bold;
  align-self: flex-start;
`;

const initialState = {
  new_password: '',
  old_password: '',
  confirm_password: '',
  error : {confirm_password : ''}
};

const AccountChangePassword = (props: IProps) => {
  const [ values, setValues ] = useState(initialState);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
      
    });
  };

  const changePassword = () => {
    setValues({...values, error : { confirm_password : ""}})
    props.onSave(pick(values, [
      'new_password',
      'old_password',
    ]));
    EventEmitter.subscribe(EVENT_PASSWORD_CLEAN, () => setValues(initialState));
  };

  const hasError = () =>
    values.confirm_password !== values.new_password ||
    !values.old_password ||
    !values.new_password ||
    !values.confirm_password
  ;
  
  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== values.new_password) {
      setValues({...values, [event.target.name]: event.target.value, error : {...values.error, confirm_password : "Password does not match"}})
      
    } else {
      setValues({...values, [event.target.name]: event.target.value, error : {...values.error, confirm_password : ""}})
    }
  }

  const generatePasswordError = (error: string) => {
    if (error.includes('Attempt limit exceeded')) {
      return 'Too many attempts, please try again later.';
    }

    if (error.includes('Incorrect username or password')) {
      return 'Your current password is incorrect.'
    }

    if (error.includes('Cannot update password: Password did not conform with policy: Password not long enough')) {
      return 'Your password must be at least 12 characters long.'
    }

    if (error.includes('Password did not conform with policy')) {
      const policy = error.split(": ")
      return policy[policy.length - 1]
    }

    return 'Could not update password, please try again later.'
  }

  const errorMessage = useMemo(() => {
    if (values.error.confirm_password) { return values.error.confirm_password }
    
    if (!props.error) { return null; }

    if (props.error && typeof props.error !== 'string') {
      return 'Could not update password, please try again later.';
    }
    
    return generatePasswordError(props.error);
  }, [props.error, values.error.confirm_password])

  return (
    <Card>
      <form autoComplete="off" noValidate={true}>
        <CardHeader
          title="Change Password"
        />
        <Divider />
        <CardContent>
          <Grid container={true} spacing={GRID_SIZE_3}>
            <Grid item={true} xs={GRID_SIZE_12}>
              <InputTextStyled
                autoComplete="off"
                error={!!props.error}
                type="password"
                label="Current Password"
                margin="dense"
                value={values.old_password}
                fullWidth={true}
                name="old_password"
                onChange={handleChange}
              />
            </Grid>
            <Grid item={true} xs={GRID_SIZE_12}>
              <InputTextStyled
                autoComplete="off"
                error={values.confirm_password !== values.new_password || !!props.error}
                type="password"
                label="New Password"
                margin="dense"
                value={values.new_password}
                fullWidth={true}
                name="new_password"
                onChange={handleChange}
              />
            </Grid>
            <Grid item={true} xs={GRID_SIZE_12}>
              <InputTextStyled
                autoComplete="off"
                error={values.confirm_password !== values.new_password || !!props.error}
                type="password"
                label="Confirm Password"
                margin="dense"
                value={values.confirm_password}
                fullWidth={true}
                name="confirm_password"
                onChange={handleConfirmPasswordChange}
              /> 
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
        <ButtonWrapper>
          {errorMessage && (
            <MessageWrapper>
              {errorMessage}
            </MessageWrapper>
          )}
           <Button
            color="primary"
            variant="contained"
            onClick={changePassword}
            disabled={hasError()}
          >
            Change Password
          </Button>
          </ButtonWrapper>
        </CardActions>
      </form>
    </Card>
  );
};

export default AccountChangePassword;
