import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { ISagaAction, IIntegrationFlagsRequestPayload, ISetIntegrationCredentialsPayload } from '../../types';

export const allIntegrationsRequestSaga = function* (): SagaIterator {
  try {
    const response: any = yield call(Api.Integration.fetchAllIntegrations);
    yield put(actions.allIntegrationsSuccess(response));
  } catch (e) {
    yield put(actions.allIntegrationsFailed((e as Error).message));
  }
};

export const toggleIntegrationsRequestSaga = function* (action: ISagaAction<IIntegrationFlagsRequestPayload>): SagaIterator {
  try {
    yield call(Api.Integration.toggleIntegrations, action.payload);
    yield put(actions.toggleIntegrationsSuccess());
  } catch (e) {
    yield put(actions.toggleIntegrationsFailed((e as Error).message));
  }
};

export const setIntegrationCredentialsRequestSaga = function* (action: ISagaAction<ISetIntegrationCredentialsPayload>): SagaIterator {
  try {
    yield call(Api.Integration.setIntegrationCredentials, action.payload);
    yield put(actions.setIntegrationCredentialsSuccess());
  } catch (e) {
    yield put(actions.setIntegrationCredentialsFailed((e as Error).message));
  }
};
