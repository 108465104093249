import * as React from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';

const DateValueView = styled.div`
color: ${theme.colorDarkGray};
`;

const DateTitle = styled.div`
color: ${theme.colorDarkGray};
font-family: ${theme.ffSansProRegular};
font-size: 1.125rem;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: 0.72;
text-align: left;
padding-bottom: 0.125rem;
`;

const DateLabel = styled.div`
color: ${theme.colorDarkGray};
font-family: ${theme.ffSansProRegular};
font-size: 0.8125rem;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: 1.54;
text-align: left;
`;

interface IProp {
  date: string;
  label: string;
}

export class DateValue extends React.Component<IProp> {
  public render() {
    return (
      <DateValueView>
        <DateTitle>{this.props.date}</DateTitle>
        <DateLabel>{this.props.label}</DateLabel>
      </DateValueView>
    );
  }
}

export default DateValue;
