import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Moment } from 'moment';
import { getToday } from '@loypal/lib-growth';
import { RouteComponentProps, useLocation, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { DATE_FORMAT, DATE_FNS_DATE_FORMAT } from '../../config/datetime';
import { withHeader } from '../../components';
import {
  IAudienceCard,
  ICampaignCreateResponse,
  INotificationInfo,
  IStoreState,
  IUserMeta,
  AppDispatch,
  IContactabilityFilter,
} from '../../modules/types';
import {
  audienceSummary,
  isDataRequested,
} from '../../modules/selectors';
import {
  audienceCardRequest,
  audienceCardClean,
} from '../../modules/actions';
import { getFilters, DateFilterContext } from './utils';
import { CampaignCreationPage } from './CampaignCreationPage';

interface IRouteParams {
  segment_id: string;
  audience_id: string;
}

interface IProps extends RouteComponentProps<IRouteParams>, IUserMeta {
  cleanAudienceCardData: typeof audienceCardClean;
  handleFetchAudienceCardData: typeof audienceCardRequest;
  audience_summary: IAudienceCard;
  notifications_info: INotificationInfo;
  created_campaign: ICampaignCreateResponse;
  is_data_requested?: boolean;
  targeted: number;
  available: number;
  in_control_group: number;
  in_campaign: number;
  segmentID: string;
}

const fetchAudienceCardData = (
  audienceID: string,
  segmentID: string,
  search: string,
  dispatchCallback: AppDispatch,
  contactabilityFiltersAvailable: IContactabilityFilter[]
) => {
  const {
    exclude_stores,
    contactability_filters,
    udf_filters
  } = getFilters(search, contactabilityFiltersAvailable)

  dispatchCallback(audienceCardRequest({
    segment_id: segmentID,
    audience_id: audienceID,
    exclude_stores,
    contactability_filters,
    udf_filters
  }))
}

const Audience = ({
  audience_summary,
  is_data_requested
}: IProps) => {
  const [startDate, setStartDate] = useState<Moment | null>(null)
  const [endDate, setEndDate] = useState<Moment | null>(null)
  const { search } = useLocation();
  const dispatch = useDispatch();
  const searchRef = useRef('');
  const { audience_id, segment_id } = useParams<any>()
  const contactabilityFiltersAvailable: IContactabilityFilter[] = useSelector((state: IStoreState) => {
    return state.campaign.contactability_filters
  })

  useEffect(() => {
    if (!is_data_requested || searchRef.current !== search) {
      fetchAudienceCardData(audience_id, segment_id, search, dispatch, contactabilityFiltersAvailable)
    }
    searchRef.current = search
    // eslint-disable-next-line
  }, [search])

  useEffect(() => {
    if (!is_data_requested) {
      fetchAudienceCardData(audience_id, segment_id, search, dispatch, contactabilityFiltersAvailable)
    }
    return () => {
      dispatch(audienceCardClean())
    }
  // eslint-disable-next-line
  }, [])

  const today = getToday();
  const formattedToday = format(today, DATE_FNS_DATE_FORMAT)
  const isStartDateNotToday = startDate && startDate?.format(DATE_FORMAT) !== formattedToday;
  const isStartDateTomorrow = startDate?.diff(formattedToday, 'days') === 1;

  return (
    <DateFilterContext.Provider
      value={{
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        isStartDateNotToday,
        isStartDateTomorrow
      }}
    >
      <CampaignCreationPage
        audience_summary={audience_summary}
        is_data_requested={is_data_requested}
      />
    </DateFilterContext.Provider>
  )
}

const mapStateToProps = (state: IStoreState) => ({
  is_data_requested: isDataRequested(state),
  audience_summary: audienceSummary(state),
});

export default withHeader(connect(mapStateToProps, {})(Audience));
