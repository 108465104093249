import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import React from 'react';
import {
  useLocation,
  useHistory,
  useParams
}
from 'react-router-dom';
import {
  OpportunityIcon,
  CampaignsIcon,
  CustomersIcon,
  SettingsIcon
} from '../Icons';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { IStoreState, } from '../../modules/types';
import { useSelector } from 'react-redux';
 import format from 'date-fns/format';

 const MAGIC_NUM = -18

const ROUTE_TABLE = (name: any, url: any) => {

  return {
  '/opportunity': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/opportunity', name: 'Opportunity'}]
  },
  '/segments/1/audiences/1': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/segments/1/audiences/1', name: 'Create campaign' }]
  },
  '/segments/2/audiences/2': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/segments/2/audiences/2', name: 'Create campaign' }]
  },
  '/segments/3/audiences/3': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/segments/3/audiences/3', name: 'Create campaign' }]
  },
  '/segments/3/audiences/4': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/segments/3/audiences/4', name: 'Create campaign' }]
  },
  '/segments/4/audiences/5': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/segments/4/audiences/5', name: 'Create campaign' }]
  },
  '/segments/4/audiences/6': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/segments/4/audiences/6', name: 'Create campaign' }]
  },
  '/segments/4/audiences/7': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/segments/4/audiences/7', name: 'Create campaign' }]
  },
  '/segments/4/audiences/8': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/segments/4/audiences/8', name: 'Create campaign' }]
  },
  '/segments/5/audiences/9': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/segments/5/audiences/9', name: 'Create campaign' }]
  },
  '/segments/5/audiences/10': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/segments/5/audiences/10', name: 'Create campaign' }]
  },
  '/segments/5/audiences/11': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/segments/5/audiences/11', name: 'Create campaign' }]
  },
  '/segments/5/audiences/12': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/segments/5/audiences/12', name: 'Create campaign' }]
  },
  '/segments/6/audiences/13': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/segments/6/audiences/13', name: 'Create campaign' }]
  },
  '/segments/1': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/opportunity', name: 'Opportunity'}, {url: '/segments/1', name: 'Prospect' }]
  },
  '/segments/2': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/opportunity', name: 'Opportunity'}, {url: '/segments/2', name: 'Welcome' }]
  },
  '/segments/3': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/opportunity', name: 'Opportunity'}, {url: '/segments/3', name: 'Engage' }]
  },
  '/segments/4': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/opportunity', name: 'Opportunity'}, {url: '/segments/4', name: 'Nurture' }]
  },
  '/segments/5': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/opportunity', name: 'Opportunity'}, {url: '/segments/5', name: 'Revive' }]
  },
  '/segments/6': {
    logo: OpportunityIcon,
    breadcrumbs: [{ url: '/opportunity', name: 'Opportunity'}, {url: '/segments/6', name: 'Archive' }]
  },
  '/campaigns': {
    logo: CampaignsIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}]
  },
  [`/campaign/${url}`]: {
    logo: CampaignsIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: `/campaign/${url}`, name: `${name}` }]
  },
  '/campaigns/segments/1': {
    logo: CampaignsIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/campaigns/segments/1', name: 'Prospect' }]
  },
  '/campaigns/segments/2': {
    logo: CampaignsIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/campaigns/segments/2', name: 'Welcome' }]
  },
  '/campaigns/segments/3': {
    logo: CampaignsIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/campaigns/segments/3', name: 'Engage' }]
  },
  '/campaigns/segments/4': {
    logo: CampaignsIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/campaigns/segments/4', name: 'Nurture' }]
  },
  '/campaigns/segments/5': {
    logo: CampaignsIcon,
    breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/campaigns/segments/5', name: 'Revive' }]
  },
  '/campaigns/segments/6': {
    logo: CampaignsIcon,
  breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, {url: '/campaigns/segments/5', name: 'Archive' }]
  },
  '/campaigns/calendar': {
    logo: CampaignsIcon,
  breadcrumbs: [{ url: '/campaigns', name: 'Campaigns'}, { url: '/campaigns/calendar', name: 'Calendar'}, ]
  },
  '/customers': {
    logo: CustomersIcon,
    breadcrumbs: [{ url: '/customers', name: 'Customers'}]
  },
  '/customers/loyalty': {
    logo: CustomersIcon,
    breadcrumbs: [{ url: '/customers', name: 'Customers'}, { url: '/customers/loyalty', name: 'Loyalty Measures'}]
  },
  '/customers/commercial-performance': {
    logo: CustomersIcon,
    breadcrumbs: [{ url: '/customers', name: 'Customers'}, { url: '/customers/commercial-performance', name: 'Commercial Performance'}]
  },
  '/settings': {
    logo: SettingsIcon,
    breadcrumbs: [{ url: '/settings', name: 'Settings'}]
  },
  '/settings/add-integration': {
    logo: SettingsIcon,
    breadcrumbs: [{ url: '/settings', name: 'Settings'}, { url: '/settings/add-integration', name: 'Integration'}]
  },
  '/user/profile': {
    logo: SettingsIcon,
    breadcrumbs: [{ url: '/user/profile', name: 'Profile'}]
  }
}
}

export const BreadCrumbs = () => {
  const history = useHistory()
  const location = useLocation();
  const params = useParams<{campaign_id : any}>();
  const pathname = location.pathname;

  const campaignId = params.campaign_id;

  const campaignName = useSelector((state: IStoreState) => {
    return state.campaign.campaign?.name
  })

  const campaignNameChar = campaignName?.slice(0, MAGIC_NUM).replaceAll('_', ' ')

  const startDate = useSelector((state: IStoreState) => {
    return state.campaign.campaign?.start_date && format(new Date(state.campaign.campaign?.start_date), "do LLLL yyyy")
  })

  const endDate = useSelector((state: IStoreState) => {
    return state.campaign.campaign?.end_date && format(new Date(state.campaign.campaign?.end_date), "do LLLL yyyy")
  })

  const campaignBreadcrumbTitle = ` ${campaignNameChar} ${startDate} - ${endDate}`

  if (!campaignName && campaignId ) {return null}

  const routePath = ROUTE_TABLE(campaignBreadcrumbTitle, campaignId)[pathname];

  if (!routePath) { return null }
  const MenuIcon = routePath.logo;

  return (
    <Breadcrumbs aria-label="breadcrumb">
        <Typography  >
          <NavWrapper>
             <MenuIcon color= {`${theme.colorDarkRed}`} key="icon"/>
            <PathWrapper>
              { routePath.breadcrumbs.map((breadcrumb, index) => {
                const goToPage = () => {
                  history.push(breadcrumb.url)
                }
                return (
                  <React.Fragment key={index}>
                    { index !== 0 && <HashWrapper>/</HashWrapper> }
                    <Link key={breadcrumb.url} onClick={goToPage}>
                      { breadcrumb.name }
                    </Link>
                  </React.Fragment>
                )
              })}
            </PathWrapper>
          </NavWrapper>
        </Typography>
    </Breadcrumbs>
  )
}

const HashWrapper = styled.span`
  color: ${theme.colorBlue};
  font-weight: bold;
  padding-left: 8px;
  padding-right: 8px;
`

const NavWrapper = styled.span`
  display: flex;
  position: absolute;
  left: 0;
  top: 22px;
  margin-left: 70px;
  align-items: center;
  cursor: pointer;
`

const PathWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  font-family: ${theme.ffInterMedium};
`
