import React from 'react'
import theme from '../../assets/css/theme';

const DEFAULT_DIMENSION = 24

interface IProps {
  color?: string,
  height?: string | number,
  width?: string | number
}

export const ArrowUpIcon = ({
  color = theme.colorDarkBlue,
  height = DEFAULT_DIMENSION,
  width = DEFAULT_DIMENSION
}: IProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox={`0 0 ${height} ${width}`} width={width}>
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" fill={color}/>
    </svg>
  )
}