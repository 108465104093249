import styled from 'styled-components';
import theme from '../assets/css/theme';
import Container from '@material-ui/core/Container';

export default styled(Container)`
  && {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
    @media ${theme.media.laptop} {
      padding-right: 3.438rem;
      padding-left: 3.438rem;
    }
  }
`;
