import * as React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
min-width: 40px;
min-height: 40px;
width: 2.938rem;
height: 2.938rem;
border: solid 0.0625rem ${props => props.color || '#a3a1fb'};
border-radius: 50%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`;

interface IProps {
  color?: string,
}

export class  TearOffCalendarIcon extends React.Component<IProps>{
  public render() {
    const color = this.props.color || '#a3a1fb';
    return (
      <Circle color={color}>
        <svg xmlns="http://www.w3.org/2000/svg" width="17.413" height="19.9" viewBox="0 0 17.413 19.9">
          <path id="Path_258" data-name="Path 258" d="M17.169,0H2.244A1.175,1.175,0,0,0,1,1.244V19.9l3.731-2.488L7.219,19.9l2.488-2.488L12.194,19.9l2.488-2.488L18.413,19.9V1.244A1.175,1.175,0,0,0,17.169,0ZM14.681,12.438H4.731V9.95h9.95Zm0-4.975H4.731V4.975h9.95Z" transform="translate(-1 0)" fill={color}/>
        </svg>
      </Circle>
    );
  }
}

export default TearOffCalendarIcon;
