import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '../../components/BoxComponents';

export interface ISlide {
  id: any;
  title?: string;
  body: string;
}
export interface IContainer {
  height: string
}

interface IProps {
  slides: ISlide[],
  onClick: any,
  height?: string
}

export const Guide = ({ slides, onClick, height = '300px' }: IProps) => {

  const [slideIndex, setSlideIndex] = useState(1);

  const nextSlide = () => {
    if (slideIndex !== slides.length) {
      setSlideIndex(slideIndex + 1)
    }
  }

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1)
    }
  }

  return (
    <Container height={height}>
      <CloseButtonWrapper onClick={onClick}>
        <CloseIcon  fontSize="small"/>
      </CloseButtonWrapper>
      <ContainerSlider>
        {slides.map((obj, index) => {
          return(
            <div key={obj.id} className={slideIndex === index + 1 ? "active" : 'inactive'}>
              { obj.title && (
                <TitleWrapper>
                  <Title>{obj.title}</Title>
                </TitleWrapper>
              )}
              <Body>{obj.body}</Body>
            </div>
          )
        })}
      </ContainerSlider>
      {slides.length !== 1 && (
        <FooterContainer>
          <AllDots>
            {slides.map((item, index) => {
            const handleSlide = () => setSlideIndex(index + 1)
            return (
              <div
              key={index}
              onClick={handleSlide}
              className = {`${slideIndex === index + 1 ? "dot active" : "dot"}`}/>
              )
            })}
          </AllDots>
          <ButtonWrapper>
            <PreviousButton className={(slideIndex === 1) && 'isDisabled slide-button' } onClick={prevSlide}>Previous</PreviousButton>
            <NextButton className={(slideIndex === slides.length) && 'isDisabled slide-button slide-next'} onClick={nextSlide}>Next</NextButton>
          </ButtonWrapper>
        </FooterContainer>
      )}
    </Container>
  )
}

const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 150px;
  padding: 1.563rem 1.25rem 1.125rem 1.25rem;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 4%);
  background-color: ${theme.colorWhite};
  height: ${({ height }) => height};
  border-radius: 4px;
  align-self: flex-start;
`

const AllDots = styled.div`
  display: flex;
  margin: 0 auto 32px;
  border-radius: 3px;
  border-radius: 3px;
  .dot {
    cursor: pointer;
    height: .3rem;
    width: 1.7rem;
    margin: 0px 3px;
    border-radius: 3px;
    background-color: ${theme.colorLightGreen};
    &:hover{
      background-color:${theme.colorGreen}!important;
    } 
  }
  .dot.active {
    background: ${theme.colorGreen};
    cursor: pointer;
  }
`

const ContainerSlider = styled.div`
  display: flex;
  max-width: 700px;
  .active {
    display: inline-block;
  }
  .inactive {
    display: none;
  }
`

const CloseButtonWrapper  = styled.div`
  top: 18px;
  right: 18px;
  color: ${theme.colorGray};
  cursor: pointer;
  position: absolute;
  z-index: 5;
`

const PreviousButton = styled(Button)`
  color: ${theme.colorWhite};
  padding: 6px 16px;
  font-size: 0.875rem;
  font-family: ${theme.ffInterRegular};
  font-weight: 700;
  line-height: 2.75;
  cursor: pointer;
  border-radius: 0 0 0 5px;
  width: 40%;
  bottom: 0;
  &&.isDisabled {
    background-color: ${theme.colorGray};
    cursor: not-allowed;
    pointer-events: none;
  }
`

const NextButton = styled(Button)`
  color: ${theme.colorWhite};
  border-radius: 0 0 4px 0;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 94px;
  font-family: ${theme.ffInterRegular};
  font-weight: 700;
  line-height: 2.75;
  background-color: ${theme.colorRed};
  text-align: center;
  cursor: pointer;
  width: 40%;
  &&.isDisabled {
    background-color: ${theme.colorGray};
    cursor: not-allowed;
    pointer-events: none;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
`

const TitleWrapper = styled.div`
  display: flex;
  padding-bottom: 12px;
`

const FooterContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
`

const Title = styled.div`
  font-family: ${theme.ffInterRegular};
  font-size: 1.125rem;
  color: ${theme.titleColor};
`

const Body = styled.div`
  font-family: ${theme.ffInterRegular};
  font-size: 0.875rem;
  line-height: 1.79;
  font-weight: 700;
  color: ${theme.titleColor};
  opacity: 0.5;
  inline-size: 200px;
  padding-top: 20px;
  white-space: pre-line;
`