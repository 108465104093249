import * as React from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { createStyles, makeStyles } from "@material-ui/core";

const InputTextStyled = styled(TextField)`
  && {
    * {
      font-family: inherit;
      outline: 0 !important;
    }

    .MuiInput-underline {
      .MuiInputBase-input {
        padding: 0.75rem 0;
      }
    }

    .MuiInputLabel-formControl {
      font-size: 0.9375rem;
      color: ${theme.colorDarkBlue};
      &.Mui-error {
        color: ${theme.colorRed};
      }
    }
    .MuiInputBase-formControl {
      :after, :before {
        border-bottom-color: ${theme.colorLightGray};
        border-bottom-width: 2px;
      }
      &.Mui-error {
        :after, :before {
          border-bottom-color: ${theme.colorRed};
        }
      }
    }
  }
`;

const InputTextUI = (props: any) => {
  const useStyles = makeStyles(() =>
    createStyles(props.styles),
  );
  const classes = useStyles();
  return <InputTextStyled
    classnames={classes.textField}
    { ...props }
  />;
};

export const InputText = (props : TextFieldProps) => (
  <InputTextUI {...props} />
);

export const PasswordInput = (props : TextFieldProps) => (
  <InputTextUI {...props} type="password" />
);

export const PasswordDisplay = (props : TextFieldProps) => (
  <InputTextUI {...props} type="password" disabled={true} />
);
