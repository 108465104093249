import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import {
  ISagaAction,
  ISegmentRequestApi,
  IUserMeta,
  IUserLoginMeta,
  IUserForgotMeta,
  IUserConfirmForgotMeta, IUserProfile
} from '../../types';
import { clearUserFromStorage, saveUserToStorage } from '../../utils/user';
import { Api } from '../../utils';
import * as actions from '../../actions';
import moment, { HTML5_FMT } from 'moment';
import FileSaver from 'file-saver';
import history from '../../utils/history';
import { UserLoginServersErrors } from '../../types/user.enums';
import { IPassword } from '../../../pages/MyProfile/AccountChangePassword';
import { EventEmitter, EVENT_PASSWORD_CLEAN } from '../../utils';

export const clientNameRequestSaga = function* (action: ISagaAction<IUserMeta>): SagaIterator {
  try {
    const { client_name } = action.payload;
      yield put(actions.clientNameSuccess({ client_name: action.payload.client_name }));
      saveUserToStorage({ meta: { client_name } });
  } catch (e) {
    yield put(actions.clientNameFailed((e as Error).message));
  }
};

export const loginSaga = function* (action: ISagaAction<IUserLoginMeta>): SagaIterator {

  try {
    // clean state on login and not logout to capture expired sessions
    yield put(actions.campaignsSummaryClean())
    yield put(actions.audienceCardClean())
    yield put(actions.customersClean())
    yield put(actions.segmentCardClean())
    const { id_token, access_token, expires_in, token_type, message }: any = yield call(Api.Auth.loginUser,  action.payload );

    if (id_token && access_token) {
      const expires_at = moment().add(expires_in, 'seconds').format(HTML5_FMT.DATETIME_LOCAL_MS);
      saveUserToStorage({ user: { id_token, expires_at, expires_in, access_token, token_type } });
      yield put(actions.userLoginSuccess({
        id_token,
        expires_at,
        expires_in,
        access_token,
        token_type,
      }));
      yield put(actions.userProfileRequest());
    } else {
      yield put(actions.userLoginFailed(message));
    }

  } catch (e) {
    yield put(actions.userLoginFailed((e as Error).message));
  }

};

export const goToEditClientNameSaga = function* () : SagaIterator {
  yield call(clearUserFromStorage);
};

export const loginFailedSaga = function* (action: ISagaAction<string>): SagaIterator {
  const error = action.payload;
  if (error === UserLoginServersErrors.ClientName) {
    yield call(clearUserFromStorage);
  }
};

export const forgotPasswordSaga = function* (action: ISagaAction<IUserForgotMeta>): SagaIterator {
  const errorCodeLimit = 300;
  try {
    const { message, code }: any = yield call(Api.Auth.forgotPassword, action.payload);
    if (code && code > errorCodeLimit) {
      yield put(actions.userForgotPasswordFailed(message));
    } else {
      yield put(actions.userForgotPasswordSuccess(action.payload));
      history.push('/confirm-forgot-password');
    }

  } catch (e) {
    yield put(actions.userForgotPasswordFailed((e as Error).message));
  }
};

export const confirmForgotPasswordSaga = function* (action: ISagaAction<IUserConfirmForgotMeta>): SagaIterator {
  const errorCodeLimit = 300;
  try {
    const { message, code }: any = yield call(Api.Auth.confirmForgotPassword, action.payload);
    if ( code && code > errorCodeLimit) {
      yield put(actions.userConfirmForgotPasswordFailed(message));
    } else {
      yield put(actions.userConfirmForgotPasswordSuccess());
      history.push('/login');
    }
  } catch (e) {
    yield put(actions.userConfirmForgotPasswordFailed((e as Error).message));
  }
};

export const exportCustomersRequestSaga = function* (action: ISagaAction<ISegmentRequestApi>): SagaIterator {
  try {
    const data: any = yield call(Api.Segment.exportCustomersData, { segment_id: action.payload.segment_id });
    FileSaver.saveAs(data.signedURL);
    yield put(actions.exportCustomersSuccess());
  } catch (e) {
    yield put(actions.exportCustomersFailed((e as Error).message));
  }
};

export const userLogoutSaga = function* (): SagaIterator {
  yield call(clearUserFromStorage);
};

export const userProfileRequestSaga = function* (): SagaIterator {
  try {
    const profile: any = yield call(Api.Client.fetchUserProfile);
    // save to localstorage
    saveUserToStorage({ profile });
    yield put(actions.userProfileSuccess(profile));
  } catch (e) {
    yield put(actions.userProfileFailed((e as Error).message));
  }
};

export const userUpdateProfileRequestSaga = function* (action: ISagaAction<Omit<IUserProfile, 'picture'>>): SagaIterator {
  try {
    const profile: any = yield call(Api.Client.updateProfile, action.payload);
    // save to localstorage
    saveUserToStorage({ profile });
    yield put(actions.userUpdateProfileSuccess(profile));
  } catch (e) {
    yield put(actions.userUpdateProfileFailed((e as Error).message));
  }
};

export const userUpdatePasswordRequestSaga = function* (action: ISagaAction<Omit<IPassword, 'confirm_password'>>): SagaIterator {
  try {
    yield call(Api.Client.changePassword, action.payload);
    yield put(actions.userUpdatePasswordSuccess());
    // TODO: might be refactored in the future not to use event emitter
    yield call({ context: EventEmitter, fn: EventEmitter.dispatch }, EVENT_PASSWORD_CLEAN, {});
  } catch (e) {
    yield put(actions.userUpdatePasswordFailed((e as Error).message));
  }
};

export const userUpdateProfilePictureRequestSaga = function* (action: ISagaAction<Pick<IUserProfile, 'picture'>>): SagaIterator {
  try {
    const profile: any = yield call(Api.Client.updatePicture, action.payload);
    // save to localstorage
    saveUserToStorage({ profile });
    yield put(actions.userUpdateProfilePictureSuccess(profile.picture));
  } catch (e) {
    yield put(actions.userUpdateProfilePictureFailed((e as Error).message));
  }
};
