import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import {
  IAudienceCard,
  IAudienceState,
  IAudienceRequestApi,
  INotificationInfo,
  INotificationRequestApi,
  ICampaignCreateRequestPayload,
  ICampaignCreateResponse,
  ICampaignReDownloadData,
  IAudiencesSummaryCard,
  IAudienceSummaryRequestApi,
} from '../../types';
import { CampaignCreateErrors } from '../../types/campaign.enums';

const defaultState: IAudienceState = {
  current_audience: {
    id: 0,
    volume: 0,
    proportion: 0,
    targeted: 0,
    available: 0,
    untargeted_opportunity: 0,
    total_marketable: 0,
    total_marketable_avail: 0,
  },
  notification_info: {
    in_campaign: 0,
    in_control_group: 0,
    isEstimation: false,
  },
  created_campaign: {
    id: null,
    audience_id: null,
    name: '',
    start_date: '',
    end_date: '',
  },
  audiences_summary: {
    audience_id: 0,
    avgOrderValue: 0,
    frequency: 0,
    recency: 0,
    spend: 0,
    proportion_total: 0,
  },
};

/**
 * Audience Card actions handlers
 */
const audienceCardRequest = (state: IAudienceState, payload: IAudienceRequestApi) => ({
  ...state,
  is_audience_card_data_requested: true,
});

const audienceCardSuccess = (state: IAudienceState, payload: IAudienceCard) => ({
  ...state,
  current_audience: payload,
  is_audience_card_data_requested: false,
});

const audienceCardFailed = (state: IAudienceState, payload: string) => ({
  ...state,
  is_audience_card_data_requested: false,
});

const audienceCardClean = () => ({
  ...defaultState,
});

/**
 *  Exporting actions
 */
const createCampaignRequest = (state: IAudienceState, payload: ICampaignCreateRequestPayload) => ({
  ...state,
  is_data_requested: true,
});

const createCampaignSuccess = (state: IAudienceState, payload: ICampaignCreateResponse) => ({
  ...state,
  created_campaign: payload,
  campaignNameError: undefined,
  campaignCreatingError: undefined,
  is_data_requested: false,
});

const createCampaignFailed = (state: IAudienceState, payload: string) => {
  let partOfState: {} = {};
  if (payload === CampaignCreateErrors.CampaignNameDuplicated) {
    partOfState = {
      campaignNameError: CampaignCreateErrors.CampaignNameDuplicatedProbably,
      campaignCreatingError: payload,
    };
  } else {
    partOfState = {
      campaignCreatingError: payload,
    };
  }
  return {
    ...state,
    ...partOfState,
  is_data_requested: false,
  };
};

const reDownloadCampaignDataRequest = (state: IAudienceState, payload: ICampaignReDownloadData) => ({
  ...state,
  is_data_requested: true,
});

const reDownloadCampaignDataSuccess = (state: IAudienceState) => ({
  ...state,
  campaignReDownloadError: undefined,
  is_data_requested: false,
});
const reDownloadCampaignDataFailed = (state: IAudienceState, payload: string) => ({
  ...state,
    campaignReDownloadError: payload,
    is_data_requested: false,
});

/**
 *  Notification actions
 */
const getNotificationInfoRequest = (state: IAudienceState, payload: INotificationRequestApi) => ({
  ...state,
  is_notification_data_requested: true,
});

const getNotificationInfoSuccess = (state: IAudienceState, payload: INotificationInfo) => ({
  ...state,
  notification_info: payload,
  is_notification_data_requested: false,
});

const getNotificationInfoFailed = (state: IAudienceState, payload: string) => ({
  ...state,
  is_notification_data_requested: false,
});

/**
 * Audiences summary action
 */
 const audiencesSummaryCardRequest = (state: IAudienceState, payload: IAudienceSummaryRequestApi) => ({
  ...state,
  is_audience_summary_card_data_requested: true,
});

const audiencesSummaryCardSuccess = (state: IAudienceState, payload: IAudiencesSummaryCard) => ({
  ...state,
  audiences_summary: payload,
  is_audience_summary_card_data_requested: false,
});

const audiencesSummaryCardFailed = (state: IAudienceState, payload: string) => ({
  ...state,
  is_audience_summary_card_data_requested: false,
});



export const audience = createReducer<IAudienceState>({}, defaultState)
  // audience view
  .on(actions.audienceCardRequest, audienceCardRequest)
  .on(actions.audienceCardSuccess, audienceCardSuccess)
  .on(actions.audienceCardFailed, audienceCardFailed)
  .on(actions.audienceCardClean, audienceCardClean)
  // Exporting actions
  .on(actions.campaignCreateRequest, createCampaignRequest)
  .on(actions.campaignCreateSuccess, createCampaignSuccess)
  .on(actions.campaignCreateFailed, createCampaignFailed)
  .on(actions.campaignReDownloadDataRequest, reDownloadCampaignDataRequest)
  .on(actions.campaignReDownloadDataSuccess, reDownloadCampaignDataSuccess)
  .on(actions.campaignReDownloadDataFailed, reDownloadCampaignDataFailed)
  // Notification actions
  .on(actions.getNotificationInfoRequest, getNotificationInfoRequest)
  .on(actions.getNotificationInfoSuccess, getNotificationInfoSuccess)
  .on(actions.getNotificationInfoFailed, getNotificationInfoFailed)
  // Audiences Summary actions
  .on(actions.audiencesSummaryCardRequest, audiencesSummaryCardRequest)
  .on(actions.audiencesSummaryCardSuccess, audiencesSummaryCardSuccess)
  .on(actions.audiencesSummaryCardFailed, audiencesSummaryCardFailed)
;