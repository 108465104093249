import React, { useContext } from 'react';
import styled from 'styled-components';
import { DATE_FORMAT } from '../../../config/datetime';
import theme from '../../../assets/css/theme';
import { Modal, TextButton } from '../../../components';
import { DateFilterContext } from '../utils';

interface IProps {
  isShowingCreatedMsg: boolean;
  closeCreatedMessage: any;
  radioButtonPosition: string;
}

export const CampaignCreationModal = ({
  isShowingCreatedMsg,
  closeCreatedMessage,
  radioButtonPosition
}: IProps) => {
  const {
    startDate,
    isStartDateNotToday
  } = useContext(DateFilterContext);

  const generate_csv_date = startDate?.clone().subtract(radioButtonPosition, 'days').format(DATE_FORMAT);

  return (
    <Modal isOpen={isShowingCreatedMsg} handleClose={closeCreatedMessage}>
      <ModalContentsContainer>
        {
          !isStartDateNotToday ?
          <>
            <ModalTitle>Successfully began campaign creation</ModalTitle>
            <p>
              An average campaign takes an hour to create.
              <br />
              We will send you an email once the CSV is ready to download.
            </p>
          </>
          :
          <>
            <ModalTitle>Successfully scheduled campaign creation</ModalTitle>
            <p>
            We will allocate customers into this campaign on {generate_csv_date}.
            <br />
            We will send you an email once the CSV is ready to download.
            </p>
          </>
        }
        <TextButtonContainer>
          <TextButton onClick={closeCreatedMessage}>OK</TextButton>
        </TextButtonContainer>
      </ModalContentsContainer>
    </Modal>
  )
}

const ModalTitle = styled.div`
  font-family: ${theme.ffInterRegular};
  font-size: 1.125rem;
  line-height: 1.39;
  color: ${theme.titleColor};
  margin-bottom: 18px;
`

const TextButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ModalContentsContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 4%);
  padding: 1.25rem 1.688rem 1.313rem 1.063rem;
  border-radius: 4px;
  width: 540px;
`
