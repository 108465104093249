import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { ISagaAction, ISegmentRequestApi } from '../../types';

export const customersCountRequestSaga = function* (): SagaIterator {
  try {
    const response: any = yield call(Api.Segment.fetchSegmentsCustomers);
    yield put(actions.customersCountSuccess(response));
  } catch (e) {
    yield put(actions.customersCountFailed((e as Error).message));
  }
};

export const opportunityValueRequestSaga = function* (): SagaIterator {
  try {
    const response: any = yield call(Api.Segment.fetchOpportunityValue);
    yield put(actions.opportunityValueSuccess(response));
  } catch (e) {
    yield put(actions.opportunityValueFailed((e as Error).message));
  }
};

export const segmentCardRequestSaga = function* (): SagaIterator {
  try {
    const response: any = yield call(Api.Segment.fetchSegmentsSummary);
    yield put(actions.segmentCardSuccess(response));
  } catch (e) {
    yield put(actions.segmentCardFailed((e as Error).message));
  }
};

export const lifecycleRequestSaga = function* (action: ISagaAction<ISegmentRequestApi>): SagaIterator {
  try {
    const [segment, audiences]: any = yield all([
      call(Api.Segment.fetchSegmentSummary, {
        segment_id: action.payload.segment_id
      }),
      call(Api.Segment.fetchSegmentAllAudiencesSummary, {
        segment_id: action.payload.segment_id
      }),
    ]);
    yield put(actions.lifecycleSuccess({segment, audiences}));
  } catch (e) {
    yield put(actions.lifecycleFailed((e as Error).message));
  }
};
