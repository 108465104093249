import { createAction } from 'redux-act';

import { 
  ICustomerDataOverTimeResponse, 
  ICustomersOverTimePayload,
  IStoresResponse, 
  ICurrentLTVResponse,
} from '../../types'

export const customerDataOverTimeRequest = createAction<ICustomersOverTimePayload>('Fetching customer data over time')
export const customerDataOverTimeSuccess = createAction<ICustomerDataOverTimeResponse>('Customer data over time successfully fetched')
export const customerDataOverTimeFailed = createAction<string>('Fetch customer data over time failed')
export const storesRequest = createAction('Fetching stores')
export const storesSuccess = createAction<IStoresResponse>('Stores successfully fetched')
export const storesFailed = createAction<string>('Fetch stores failed')
export const lifetimeValueRequest = createAction('Fetching current LTV')
export const lifetimeValueSuccess = createAction<ICurrentLTVResponse>('LTV successfully fetched')
export const lifetimeValueFailed = createAction<string>('Fetch LTV failed')
export const customersClean = createAction('Set Customer data to default value');