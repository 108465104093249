import theme from '../assets/css/theme';

const colors = {
  'Prospect': theme.colorLightPink,
  'Welcome': theme.colorRed,
  'Engage': theme.colorDarkestBlue,
  'Nurture': theme.colorLighestPurple,
  'Revive': theme.colorDimGray,
  'Archive': theme.colorGray2,
};

export const colorsBySegmentID = {
  1: theme.colorLightPink,
  2: theme.colorRed,
  3: theme.colorDarkestBlue,
  4: theme.colorLighestPurple,
  5: theme.colorDimGray,
  6: theme.colorGray2,
};

export default colors;