import React, { useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { IStoreState } from '../modules/types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { userLogin, goToEditClientName } from '../modules/actions/user';
import { isLoggedIn, clientName } from "../modules/selectors";
import {
  GRID_SIZE_0,
  GRID_SIZE_3,
  GRID_SIZE_6,
  GRID_SIZE_8,
  GRID_SIZE_9,
  GRID_SIZE_12,
} from '../config';
import Group4Login from '../assets/img/login/LOYPAL-HOME-IMAGE.png';
import LPLogo from '../assets/img/login/LOYPAL-LOGO-HORIZONTAL.png';
import { InputText, ButtonInversed, Button } from "../components/Form";
import theme from '../assets/css/theme';
import { CircularProgress } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography';

interface IProps extends RouteComponentProps {
  readonly client_name: string;
  readonly username: string;
  readonly is_logged_in: string;
  readonly clientIdError?: string;
  readonly usernameError?: string;
  readonly passwordError?: string;
  readonly is_data_requested?: boolean;
  handleLoginUser: typeof userLogin;
  handleGoToEditClientName: typeof goToEditClientName;
}

const LoginComponent = ({
  client_name,
  is_logged_in,
  clientIdError,
  usernameError,
  passwordError,
  is_data_requested,
  handleLoginUser,
  handleGoToEditClientName
}: IProps) => {
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const { search } = useLocation();
  const parsedQueryString = queryString.parse(search);

  if (is_logged_in) {
    const toUrl = parsedQueryString.next ? `/${parsedQueryString.next}` : '/'
    return <Redirect to={toUrl} />;
  }

  if (!client_name || clientIdError) {
    const toUrl = search ? `/login/${search}` : '/login'
    return <Redirect to={toUrl} />;
  }

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleLoginUser({
      client_name,
      username,
      password,
    });
  }

  const onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.currentTarget.value)
  }

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value)
  }

  const onRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(!!event.currentTarget.value)
  }

  return (
    <GridContainer container={true} item={true} spacing={GRID_SIZE_3} xs={GRID_SIZE_12}>
      <Hidden xsDown={true}>
        <GridCellLeft item={true} lg={GRID_SIZE_6} sm={GRID_SIZE_3} xs={GRID_SIZE_12}/>
      </Hidden>
      <GridCellRight
        container={true}
        item={true}
        lg={GRID_SIZE_6}
        sm={GRID_SIZE_9}
        xs={GRID_SIZE_12}
        spacing={GRID_SIZE_0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        { is_data_requested && (
          <Overlay>
            <CircularProgress />
          </Overlay>
        )}
        <Logo src={LPLogo} />

        <SubTitle>Welcome back, please login to your account.</SubTitle>

        <FormGrid container={true} item={true} sm={GRID_SIZE_6} xs={GRID_SIZE_8}>
          <Form onSubmit={onSubmit}>
            <GridUserName item={true} xs={GRID_SIZE_12}>
              <InputUsernameStyled
                error={!!usernameError}
                autoFocus={true}
                type="text"
                label="Username"
                onChange={onUsernameChange}
              />
            </GridUserName>

            <GridPassword item={true} xs={GRID_SIZE_12} >
              <InputPasswordStyled
                error={!!passwordError}
                autoFocus={false}
                type="password"
                label="Password"
                onChange={onPasswordChange}
              />
            </GridPassword>

            <GridControl
              container={true}
              item={true}
              xs={GRID_SIZE_12}
              alignItems="center"
            >
              <GridButtonLeft item={true} container={true} xs={GRID_SIZE_12} md={GRID_SIZE_6} >
              <FormControlLabel
                control={<Checkbox color="primary" value={rememberMe} onChange={onRememberMeChange}/>}
                label={<RememberMeStyled>Remember me</RememberMeStyled>}
              />
              </GridButtonLeft>
              <GridButtonRight item={true} container={true} xs={GRID_SIZE_12} md={GRID_SIZE_6} >
                <ForgotPassword href="/forgot-password">Forgot Password</ForgotPassword>
              </GridButtonRight>
            </GridControl>

            <GridControlButtons
              container={true}
              item={true}
              xs={GRID_SIZE_12}
              alignItems="center"
            >
              <GridButtonLeft item={true} container={true} xs={GRID_SIZE_12} md={GRID_SIZE_6} >
                <LoginButton type="submit" primary={true}>Login</LoginButton>
              </GridButtonLeft>
              <GridButtonRight item={true} container={true} xs={GRID_SIZE_12} md={GRID_SIZE_6} >
                <ButtonInversedWrapper
                  type="button"
                  onClick={handleGoToEditClientName}
                >Change Client Name</ButtonInversedWrapper>
              </GridButtonRight>
            </GridControlButtons>
          </Form>
        </FormGrid>
        <TermPolicyWrapper>
          <Terms href="#">Term of use</Terms>
          <TermsWrapper>
            <Terms href="#">Privacy policy</Terms>
          </TermsWrapper>
        </TermPolicyWrapper>
      </GridCellRight>
    </GridContainer>
  );
}

const mapStateToProps = (state: IStoreState) => ({
  is_logged_in: isLoggedIn(state),
  client_name: clientName(state),
  clientIdError: state.user.clientIdError,
  usernameError: state.user.usernameError,
  passwordError: state.user.passwordError,
  is_data_requested: state.user.is_data_requested,
});

const mapDispatchToProps = {
  handleLoginUser: userLogin,
  handleGoToEditClientName: goToEditClientName,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const ButtonInversedWrapper = styled(ButtonInversed)`
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
  opacity: 0.8;
  font-size: 1rem;
`

const LoginButton = styled(Button)`
  width: 181px;
  text-align: center;
  padding: 10px;
  background-color: ${theme.colorRed} !important;
  font-size: 0.8rem;
  align-items: center;
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
`;

const GridContainer = styled(Grid)`
  && {
    font-family: ${theme.ffSansProRegular};
    font-weight: bold;
    margin: 0;
  }
`;

const GridUserName = styled(Grid)`
  && {
    font-family: ${theme.ffSansProRegular};
    font-weight: bold;
    flex-basis: 105px;
  }
`;

const GridPassword = styled(Grid)`
  && {
    flex-basis: 105px;
  }
`;

const GridCell = styled(Grid)`
  && {
    position: relative;
    min-height: 100vh;
  }
`;

const InputUsernameStyled = styled(InputText)`
  && {
    font-family: inherit;
    width: 100%;
    margin-bottom: 3.375rem;
  }
  .MuiInputLabel-formControl {
    font-family: ${theme.ffSansProRegular};
    font-weight: bold;
    opacity: 0.8;
  }
`;

const InputPasswordStyled = styled(InputText)`
  && {
    font-family: inherit;
    width: 100%;
    max-height: 60px;
  }
  .MuiInputLabel-formControl {
    font-family: ${theme.ffSansProRegular};
    font-weight: bold;
    opacity: 0.8;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: ${theme.colorWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const GridCellLeft = styled(GridCell)`
  background-image: url(${Group4Login});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 40% center;
  opacity: 0.5;
  @media (max-width: 1541px){
    background-position: 36% center;
    }
  @media (max-width: 1280px){
    display: none;
    }
`;

const GridCellRight = styled(GridCell)`
  && {
    background-color: ${theme.colorWhite};
    flex-wrap: nowrap;
  }
  @media (max-width: 1280px) {
    max-width: 100%;
    flex-basis: 100%;
    }
`;

const GridControl = styled(GridContainer)`
  && {
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 2;
      flex-basis: auto;
      @media ${theme.media.tabletL} {
         justify-content: space-between;
      }
  }
`;

const GridControlButtons = styled(GridContainer)`
  && {
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 2;
      flex-basis: auto;
      padding-top: 63px;
      @media ${theme.media.tabletL} {
         justify-content: space-between;
      }
  }
`;

const GridButtonLeft = styled(Grid)`
&& {
  justify-content: center;
  padding-bottom: 15px;

  @media${theme.media.tabletL} {
    justify-content: flex-start;
    padding-bottom: 0;
    padding-right: 15px;
  }
}
`;

const GridButtonRight = styled(Grid)`
&& {
  justify-content: center;
  padding-top: 15px;

  @media ${theme.media.tabletL} {
  justify-content: flex-end;
  padding-top: 0;
  padding-left: 15px;
  }
}
`;

const Logo = styled.img`
  height: 74px;
  margin-bottom: 24px;
`;

const ForgotPassword = styled.a`
  font-size: 0.9375rem;
  font-family: inherit;
  text-align: center;
  color: ${theme.colorDarkBlue};
  text-decoration: none;
  padding-bottom: 30px;
  text-decoration-line: underline;
  opacity: 0.8;

  @media${theme.media.tabletL} {
    padding-bottom: 0;
  }
`;

const SubTitle = styled.div`
  opacity: 0.5;
  font-size: 1rem;
  text-align: center;
  color: ${theme.colorDarkGray};
  margin-bottom: 3.125rem;
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
`;

const RememberMeStyled = styled(Typography)`
&& {
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
  opacity: 0.7;
  font-size: 0.9375rem;
}
`;

const FormGrid = styled(Grid)`
  && {
    margin-bottom: 0.9375rem;
  }
`;

const Terms = styled.a`
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  top: 100%;
  font-size: 0.8375rem;
  text-align: center;
  color: ${theme.colorDarkGray};
  text-decoration: none;
  margin-top: 2.5rem;
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
  text-decoration-line: underline;
  transform: translate(-70%, -30%);
  @media ${theme.media.laptop} {
    && {
      position: absolute;
      bottom: 4.125rem;
    }
  }
`;

const TermsWrapper = styled.div`
  display: flex;
  margin-left: 100px;
  white-space: nowrap;
  text-align: center;
`;

const TermPolicyWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 0.5;
  opacity: 0.8;
`;
