import * as React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import {
  BoxTitle,
  Button,
  SizeValue,
  UntargetedOpportunitySize
} from '../../components/BoxComponents';
import {
  SizeHasChanged,
  ProportionTotal
} from '../../components/Charts';
import theme from '../../assets/css/theme';
import { GRID_SIZE_0, GRID_SIZE_12, GRID_SIZE_6 } from '../../config';
import { useSegment } from '../../utils/hooks';
import { FullWidthTwoElements, FullWidthElement } from './common'

const CardButton = styled(Button)`
  background-color: ${theme.colorRed};
  font-size: 0.7375rem;

  @media (min-width: 1280px) and (max-width: 1406px) {
    height: 50px;
    width: 85px;
  }

  @media (min-width: 960px) and (max-width: 1080px) {
    height: 50px;
    width: 85px;
    margin-top: -22px;
  }

  @media ${theme.media.laptop} {
    margin-top: -22px;
  }
`;

const OpportunitySegmentCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.25rem 0.9375rem 1.25rem;
  box-shadow: ${theme.boxShadow};
  background-color: ${theme.colorWhite};
`;

interface ISize {
  days: number;
  size: number;
  sign: string;
}

export interface IProps {
  segment_id: number;
  sizeValue: number;
  sizeHasChangedData: {
    sizeFirst: ISize;
    sizeSecond: ISize;
  };
  onClick?: (segment_id: number) => void;
  proportionTotal: number;
  untargetedOpportunitySize: number | string;
}

const OpportunitySegmentCard = (props: IProps) => {
  const { sizeValue, sizeHasChangedData, proportionTotal, untargetedOpportunitySize, segment_id} = props
  const { name, description } = useSegment(segment_id)

  return (
    <OpportunitySegmentCardContainer>
      <BoxTitle
        title={name}
        subtitle={description}
      />

      <FullWidthTwoElements>
        <SizeValue value={sizeValue}/>
        <FullWidthElement>
          <SizeHasChanged
            sizeFirst={sizeHasChangedData.sizeFirst}
            sizeSecond={sizeHasChangedData.sizeSecond}
          />
        </FullWidthElement>
      </FullWidthTwoElements>

      <FullWidthElement>
        <ProportionTotal
          label={`${proportionTotal}% of all customers`}
          value={proportionTotal}
        />
      </FullWidthElement>

      <Grid container={true} spacing={GRID_SIZE_0} alignItems="center">
        <Grid item={true} sm={GRID_SIZE_12} md={GRID_SIZE_6}>
          <UntargetedOpportunitySize value={untargetedOpportunitySize}/>
        </Grid>
        <Grid container={true} item={true} sm={GRID_SIZE_12} md={GRID_SIZE_6} justifyContent="flex-end">
          <CardButton to={`/segments/${segment_id}`}>View Audiences</CardButton>
        </Grid>
      </Grid>
    </OpportunitySegmentCardContainer>
  );
}

export default OpportunitySegmentCard;
