import * as React from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';

const ButtonStyled = styled.button`
  && {
    font-family: inherit;
    width: 229px;
    height: 50px;
    border-radius: 4px;
    background-color: ${theme.colorWhite};
    border: 1px solid ${theme.colorDarkBlue};
    color: ${theme.colorDarkGray};
    font-size: 1.125rem;
    cursor: pointer;
    :disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;

export const ButtonInversed = (props: any) => <ButtonStyled {...props} />;
