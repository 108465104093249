import { IStoreState } from "../types";
import { pick, reduce } from 'lodash';

export const isLoggedIn = ({ user }: IStoreState) => user.is_logged_in;
export const idToken = ({ user }: IStoreState) => user.id_token;
export const loginUrl = ({ user }: IStoreState) => user.loginUrl;
export const clientName = ({ user }: IStoreState) => user.client_name;
export const clientUsername = ({ user }: IStoreState) => user.username;
export const clientNickname = ({ user }: IStoreState) => user.nickname;
export const clientPreferredUsernameName = ({ user }: IStoreState) => user.preferred_username;
export const clientPicture = ({ user }: IStoreState) => user.picture;
export const userFullName = ({ user }: IStoreState) =>
  [
    user.name,
    user.family_name
  ].join(' '); // we use such a way to simplify handling whitespace between name and family_name if family_name is null
export const userShortName = ({ user }: IStoreState) =>
  [
    user.name || '', // name can be null so make it an empty string by default
    user.family_name || '',  // family_name can be null so make it an empty string by default
  ].map(n =>
      n.charAt(0) // get the first letter from name/family_name
      .toUpperCase() // convert it to upper case
    )
    .join('') // join letters together
;

export const getProfile = ({ user }: IStoreState) =>
  reduce(
    pick(user, ['nickname', 'name', 'family_name', 'picture', 'email', 'preferred_username']),
    (acc, cur, key) => {
      acc[key] = cur || ''; // value can be null, but react uncontrolled components don't accept null as a default value rather than we should use empty strings
      return acc;
    }, {}
  )
;
