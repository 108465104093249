import HTTPClientCore, { IRequestConfig } from '../HTTPClient';
import * as qs from 'qs';
import axios from 'axios';
import { CANCEL } from 'redux-saga';
import {
  IAudienceRequestApi,
  ICampaignCreateRequestApi,
  ICampaignCreateResponseApi,
  ICampaignReDownloadData,
  INotificationRequestApi,
  INotificationInfo,
  ISegmentRequestApi,
  IUserLoginMeta,
  IUserModel,
  IUserProfile,
  IUserForgotMeta,
  IUserConfirmForgotMeta,
  ICampaignSummaryRequestPayload,
  ICampaignSummaryResponse,
  ISegmentCampaignRequestPayload,
  ICampaignsCard,
  ICampaignUpdateDatesRequestPayload,
  ICustomersOverTimePayload,
  ICampaignStoresRevenuePayload,
  ICampaignStoresRevenueResponse,
  ICampaignCreationStatusResponse,
  IContactabilityFiltersResponse,
  ICampaignRequestPayload,
  ICampaignResponse,
  ICampaignEntriesRequestPayload,
  ICampaignEntriesResponse,
  ICampaignsParticipantLifecycleResponse,
  ICampaignsParticipantLifecycleRequestPayload,
  IIntegrationFlagsRequestPayload,
  ISetIntegrationCredentialsPayload,
  IAudienceSummaryRequestApi,
  INotPurchasedCampaignListRequest,
} from "../../types";
import { getUserObjectFromStorage } from '../user';
import { IPassword } from '../../../pages/MyProfile/AccountChangePassword';

class HTTPClient extends HTTPClientCore {
  /**
   * Overridden method adds CancelToken symbol, that allow redux-saga'
   * "takeLatest" function to cancel any requests automatically.
   */
  public makeRequest(config: IRequestConfig): Promise<any> {
    const source = axios.CancelToken.source();
    config.headers = config.headers || {};

    const { id_token, client_name, access_token } = getUserObjectFromStorage();
    if (id_token) {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${id_token}`,
        'X-Client-Name': client_name,
        'X-Access-Token': access_token,
      };
    }

    const request = super.makeRequest({
      ...config,
      params: {
        ...config.params,
      },
      cancelToken: source.token,
    });

    request[CANCEL] = () => source.cancel();

    return request;
  }
}

const APIClient = new HTTPClient({
  baseURL: process.env.REACT_APP_API_URL || '',
  withCredentials: false,
  // transformRequest: [data => qs.stringify(data)],
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  onCatchNetworkError: err => { throw Error(err.response!.data.message || err.message) }
});

export const Api = {
  Auth: {
    loginUser: (params: IUserLoginMeta): Promise<IUserModel> => APIClient.post(`/user/create-token`, params),
    forgotPassword: (params: IUserForgotMeta): Promise<string> => APIClient.post(`/user/forgot-password`, params),
    confirmForgotPassword: (params: IUserConfirmForgotMeta): Promise<string> => APIClient.post(`/user/confirm-forgot-password`, params),
  },
  Audience: {
    fetchAudienceSummary: (payload: IAudienceRequestApi) => {
      const {
        audience_id,
        segment_id,
        contactability_filters = [],
        udf_filters = [],
        ...otherPayload
      } = payload;
      // must stringify contactability_filters and udf_filters before it gets
      // to the handler due to a limitation with OpenAPI
      // see the getSegmentsAudienceSummary handler for more info
      return APIClient.get(`segments/${segment_id}/audiences/${audience_id}/summary`, {
        ...otherPayload,
        contactability_filters: JSON.stringify(contactability_filters),
        udf_filters: JSON.stringify(udf_filters)
      })
    },
  },
  AudienceSummary: {
    fetchAudienceSummary: (payload: IAudienceSummaryRequestApi): Promise<IAudienceSummaryRequestApi> => {
      const { audience_id } = payload;
      return APIClient.get(`audiences/${audience_id}/summary`, payload)
    }
  },

  Campaign: {
    createCampaign: (payload: ICampaignCreateRequestApi): Promise<ICampaignCreateResponseApi> => {
      return APIClient.post(`/campaigns`, payload);
    },
    updateCampaignDates: (payload: ICampaignUpdateDatesRequestPayload): Promise<ICampaignsCard> => {
      return APIClient.post(`/campaigns/${payload.campaign_id}/update_dates`, payload);
    },
    exportInformation: (payload: INotificationRequestApi): Promise<INotificationInfo> => {
      const {
        contactability_filters = [],
        udf_filters = [],
        ...otherParams
      } = payload;
      // must stringify contactability_filters and udf before it gets to the
      // handler due to a limitation with OpenAPI

      // see the getCampaignsExportInformation handler for more info
      return APIClient.get(`/campaigns/export-information`, {
        ...otherParams,
        contactability_filters: JSON.stringify(contactability_filters),
        udf_filters: JSON.stringify(udf_filters)
      });
    },
    reDownloadCampaignDataFile: (payload: ICampaignReDownloadData) => {
      return APIClient.get(`/campaigns/${payload.campaign_id}/person_ids`);
    },
    downloadCampaignControlCSV: (payload: ICampaignReDownloadData) => {
      return APIClient.get(`/campaigns/${payload.campaign_id}/control/person_ids`);
    },
    fetchSegmentsSummary: (payload: ICampaignSummaryRequestPayload): Promise<ICampaignSummaryResponse> => {
      return APIClient.get(`/campaigns/segments/summary`, payload);
    },
    fetchSegmentsCampaigns: (payload: ISegmentCampaignRequestPayload): Promise<ICampaignsCard[]> => {
      const { segment_id, ...params } = payload;
      return APIClient.get(`/campaigns/segments/${segment_id}/items`, params)
    },
    fetchCampaignStoresData: (payload: ICampaignStoresRevenuePayload): Promise<ICampaignStoresRevenueResponse> => {
      const { campaign_id, ...params } = payload;
      return APIClient.get(`/campaigns/${campaign_id}/stores`, params)
    },
    fetchCampaignCreationStatuses: (): Promise<ICampaignCreationStatusResponse> => {
      return APIClient.get(`/campaigns/creation-statuses`)
    },
    fetchContactabilityFilters: (): Promise<IContactabilityFiltersResponse> => {
      return APIClient.get(`/campaigns/contactability-filters`)
    },
    fetchCampaign: (payload: ICampaignRequestPayload): Promise<ICampaignResponse> => {
      return APIClient.get(`/campaign/${payload.campaign_id}`)
    },
    fetchCalendarEntries: (payload: ICampaignEntriesRequestPayload): Promise<ICampaignEntriesResponse> => {
      return APIClient.get(`/campaigns/calendar`, payload)
    },
    fetchCampaignParticipant: (payload: ICampaignsParticipantLifecycleRequestPayload): Promise<ICampaignsParticipantLifecycleResponse> => {
      return APIClient.get(`/campaigns/participants`, payload)
    },
    retargetCampaign: (payload: INotPurchasedCampaignListRequest): Promise<any> => {
      return APIClient.post(`/campaign-retarget/${payload.campaign_id}`)
    }
  },
  Segment: {
    fetchSegmentsCustomers: () => APIClient.get(`/segments/customers`),
    fetchOpportunityValue: () => APIClient.get(`/segments/opportunity-value`),
    fetchSegmentsSummary: () => APIClient.get(`/segments/summary`),
    fetchSegmentSummary: (payload: ISegmentRequestApi) => APIClient.get(`/segments/${payload.segment_id}/summary`),
    fetchSegmentAllAudiencesSummary: (payload: ISegmentRequestApi) => APIClient.get(`/segments/${payload.segment_id}/audiences/summary`),
    exportCustomersData: (payload: ISegmentRequestApi) => APIClient.get(`/export/person_ids`, { segment_id: payload.segment_id }),
  },
  Client: {
    fetchUserProfile: () => APIClient.get(`/user/profile`),
    updateProfile: (params: Omit<IUserProfile, 'picture'>) => APIClient.patch(`/user/profile`, params),
    updatePicture: (params: Pick<IUserProfile, 'picture'>) => APIClient.patch(`/user/profile`, params),
    changePassword: (params: Omit<IPassword, 'confirm_password'>) => APIClient.patch(`/user/change-password`, params),
    fetchClientConfig: () => APIClient.get(`/client/config`),
  },
  Customer: {
    fetchCustomersDataOverTime: (params: ICustomersOverTimePayload) => APIClient.get('/customers', { dates: JSON.stringify(params.dates) }),
    fetchStores: () => APIClient.get(`/stores`),
    fetchLTV: () => APIClient.get(`/customers/ltv`),
  },
  Integration: {
    fetchAllIntegrations: () => APIClient.get(`/integrations`),
    toggleIntegrations: (payload: IIntegrationFlagsRequestPayload): Promise<string> => {
      return APIClient.post(`/integration/flags`, payload);
    },
    setIntegrationCredentials: (payload: ISetIntegrationCredentialsPayload): Promise<string> => {
      return APIClient.post(`/integration/credentials`, payload);
    },
  }
};

export * from './ApiErrors';

export default Api;
