import * as React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Grid, } from '@material-ui/core';
import theme from '../../assets/css/theme';
import { BoxTitle } from '../../components/BoxComponents';
import { useAudience } from '../../utils/hooks';
import { IStoreState } from '../../modules/types';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import LoopIcon from '@material-ui/icons/Loop';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import TimerIcon from '@material-ui/icons/Timer';

const RECOMMENDATION_CONTENT = [
  {
    description: "Are on the database but have never made a purchase.",
    objective: "Demand generation",
    recommendation: "Develop intent through compelling brand and product content and stimulate first purchase activity through a first purchase offer.",
    suggestionOne: "First purchase discount",
    suggestionTwo: "Spend and get (e.g. get free shipping, free gift)"
  },
  {
    description: "Our newest customers that have purchased recently, but only once",
    objective: "Education and engagement",
    recommendation: "Build connection to the brand and encourage brand interaction.",
    suggestionOne: "Brand story content",
    suggestionTwo: "Review $ get"
  },
  {
    description: "Have made only one purchase, and have not made a second purchase within expected timeframes.",
    objective: "Drive 2nd purchase",
    recommendation: "Present alternative content to stimulate intent. Utilise an offer with a mid investment and mid hurdle to capture that intent.",
    suggestionOne: "Spend $x get $y (mid reinvestment, mid hurdle)",
    suggestionTwo: "Time defined discount (mid reinvestment)"
  },
  {
    description: "Have made 2 purchases, with the second purchase being not very recent",
    objective: "Drive 3rd purchase",
    recommendation: "Present alternative content to stimulate intent. Utilise an offer with a mid investment and a higher hurdle to capture that intent.",
    suggestionOne: "Spend $x get $y (mid reinvestment, higher hurdle)",
    suggestionTwo: "Time defined discount (mid reinvestment)"
  },
  {
    description: "Have made 2 purchases, with the second purchase being recently.",
    objective: "Drive 3rd purchase",
    recommendation: "These customers are showing early signs of becoming loyal customers, reward this with an offer (lower reinvestment).",
    suggestionOne: "Discount (lower reinvestment)",
    suggestionTwo: "Spend $x get $y (mid reinvestment, mid hurdle)"
  },
  {
    description: "Has made more than 2 purchases, but not enough purchases to be in the highest frequency customer groups.",
    objective: "Build frequency",
    recommendation: "Build frequency without cannibalising revenue that would have been captured anyway.",
    suggestionOne: "Early access to new styles or sales",
    suggestionTwo: "Spend and get (e.g. get free shipping, free gift)"
  },
  {
    description: "A high frequency customer that has not made a recent purchase within expected timeframes.",
    objective: "Build recency",
    recommendation: "Move the customer back into Super Hero by encouraging a purchase, without cannibalising revenue that would have been captured anyway.",
    suggestionOne: "Early access to new styles or sales",
    suggestionTwo: "Gift with Purchase"
  },
  {
    description: "Our best customers, they purchase often and have purchased recently.",
    objective: "Maintain",
    recommendation: "Make customers feel special without eroding margin or revenue..",
    suggestionOne: "Early new season access",
    suggestionTwo: "Gift with purchase"
  },
  {
    description: "A customer (typically low value) that has purchased only once and is a churn risk.",
    objective: "Win Back",
    recommendation: "Low hurdle, low reinvestment offer to encourage win back.",
    suggestionOne: "$ off (low hurdle, low reinvestment)",
    suggestionTwo: "% Discount"
  },
  {
    description: "A customer that has only purchased once more recently or twice less recently, and is a churn risk.",
    objective: "Win Back",
    recommendation: "Low hurdle, mid reinvestment offer to encourage win back.",
    suggestionOne: "$ off (low hurdle, mid reinvestment)",
    suggestionTwo: "% Discount"
  },
  {
    description: "Previously top customers with a moderate frequency of spending, but they have not purchased recently and may therefore churn.",
    objective: "Win Back",
    recommendation: "Mid hurdle, high reinvestment offer to encourage win back.",
    suggestionOne: "$ off (mid hurdle, high reinvestment)",
    suggestionTwo: "% Discount"
  },
  {
    description: "Previously top customers with a high frequency of spending, but they have not purchased recently and may therefore churn.",
    objective: "Win Back",
    recommendation: "Low hurdle, high reinvestment offer to encourage win back.",
    suggestionOne: "Time defined gift card (low hurdle, high reinvestment",
    suggestionTwo: "% Discount"
  },
  {
    description: "Customers that have not made purchase activity in a considerable time and are therefore considered dormant",
    objective: "Demand generation",
    recommendation: "Treat these customers like a prospect.",
    suggestionOne: "Marketing Plan",
    suggestionTwo: "Spend and get (e.g. get free shipping, free gift)"
  },
]

interface IMetricProps {
  value: string;
  title: string;
  icon: React.ReactElement;
}

const AudienceSummaryCard = () => {
  const audiencesSummary = useSelector((state: IStoreState) => {
    return state.audience.audiences_summary
  })
  const { audience_id, proportion_total, avgOrderValue, frequency, recency, spend } = audiencesSummary;
  const { name } = useAudience(audience_id);
  const content = RECOMMENDATION_CONTENT[audience_id - 1];

  return (
    <ContainerWrapper>
      <CardContainer container={true}>
        <CardColumn>
          <BoxTitle title={name} subtitle={content?.description} />
        </CardColumn>
        { name === 'Prospect' ?
          <MetricsContainer>
            <Metric value={`${proportion_total}%`} title="Customer Base"
              icon={<DataUsageIcon />} />
          </MetricsContainer>
        :
          <MetricsContainer>
            <Metric value={`${proportion_total}%`} title="Customer Base"
              icon={<DataUsageIcon />} />
            { name !== 'Welcome' &&
              <Metric value={`${(frequency)?.toFixed(1)} Orders`}
                title="Frequency" icon={<LoopIcon />} />
            }
            { name !== 'Archive' &&
              <Metric value={`${(recency)?.toFixed(0)} Days`}
                title="Recency" icon={<TimerIcon />} />
            }
            <Metric value={`$${(spend)?.toFixed(2)}`} title="Spend To Date"
              icon={<MonetizationOnOutlinedIcon />}/>
            <Metric value={`$${(avgOrderValue)?.toFixed(2)}`}
              title="Avg. Order Value" icon={<ShoppingBasketOutlinedIcon />}/>
          </MetricsContainer>
        }
      </CardContainer>
      <RecommendationContainer>
        <Wrapper>
        <Title>Core Objective</Title>
        <ObjectiveDesc>
         {content?.objective}
        </ObjectiveDesc>
        <RecommandCardColumn>
          <Title>Recommendation</Title>
          <RecommandSubTitle>
            {content?.recommendation}
          </RecommandSubTitle>
        </RecommandCardColumn>
        </Wrapper>
        <TitleWrapper>
          <TitleSuggest>Suggest doing this...</TitleSuggest>
          <SubTitle>{content?.suggestionOne}</SubTitle>
          <TitleSuggest>Then this..</TitleSuggest>
          <SubTitle>{content?.suggestionTwo}</SubTitle>
        </TitleWrapper>
      </RecommendationContainer>
  </ContainerWrapper>
  )
}

const Metric = ({ value, title, icon }: IMetricProps) => (
  <OpportunityCardColumn>
    <ValueWrapper>
      {icon}
      <PropValue>
        <Value>{value}</Value>
        <Label>{title}</Label>
      </PropValue>
    </ValueWrapper>
  </OpportunityCardColumn>
)

export default AudienceSummaryCard

const ContainerWrapper = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
width: 100%;
`

const Title = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 1.125rem;
line-height: 1.39;
color: ${theme.colorDarkGray};
`

const SubTitle = styled.div`
font-family: ${theme.ffSansProRegular};
font-size: 0.875rem;
font-weight: bold;
color: ${theme.colorDarkGray};
opacity: 0.8;
`

const RecommandSubTitle = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 0.875rem;
opacity: 0.4;
color: ${theme.titleColor};
min-height: 3rem;
`

const ObjectiveDesc = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 0.875rem;
opacity: 0.4;
color: ${theme.titleColor};
padding-bottom: 20px;
`

const Wrapper = styled.div`
display: flex;
flex-wrap: wrap;
flex-flow: column wrap;
width: 50%;
`

const TitleWrapper = styled.div`
display: flex;
flex-wrap: wrap;
align-items: end;
flex-flow: column wrap;
padding-top: 30px;
width: 50%;
`

const TitleSuggest = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: 900;
font-size: 1rem;
color: ${theme.colorDarkestGray};
`

const CardColumn = styled(Grid)`
padding-right: 1rem;
&& {
  word-break: break-word;
}
@media (max-width: 1541px) and (min-width: 1304px) {
  padding-right: 4rem;
}
`

const RecommendationContainer = styled.div`
display: flex;
justify-content: space-between;
padding-top: 40px;
width: 100%;
`

const OpportunityCardColumn = styled.div`
  margin: 0px 12px;

  @media ${theme.media.laptopLLTE} {
    margin: 0px 4px;
  }

  @media ${theme.media.laptopMLTE} {
    margin: 0px 12px;
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
  }
`

const MetricsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;

  @media ${theme.media.laptopMLTE} {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  @media (max-width: 1541px) and (min-width: 1280px) {
    justify-content: flex-start;
  }
`

const Label = styled.div`
  font-family: ${theme.ffInterRegular};
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  color: ${theme.colorDarkGray};
`

const Value = styled.div`
  font-family: ${theme.ffInterRegular};
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: ${theme.colorDarkGray};
`

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
`

const PropValue = styled.div`
  padding-left: 16px;
  margin-bottom: 16px;
`

const RecommandCardColumn = styled(CardColumn)`
&& {
  justify-content: flex-start;
  
  @media ${theme.media.tablet} {
    justify-content: flex-start;
  }
}
`

const CardContainer = styled(Grid)`
   && {
      background-color: ${theme.colorWhite};
   }
 `;
