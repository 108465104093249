import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../assets/css/theme';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '../../../components/Form';
import { DayPickerSingleDateController } from 'react-dates';
import { DateFilterContext } from '../utils';
import moment, { Moment } from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DATE_FORMAT } from '../../../config/datetime';
import { isEmpty } from 'lodash';

const NUM_OF_MONTHS = 2;


interface IFocusedDateInput {
  focused: boolean | null
}

interface IProps {
  setExclusionDatesApplied(exclusionDates: string[]): void
}

export const ExclusionDateFilter = ({ setExclusionDatesApplied }: IProps) => {
  const {
    startDate,
    endDate,
    setEndDate,
    setStartDate,
  } = useContext(DateFilterContext);

  const [isFilteringExlusionDates, setIsFilteringExlusionDates] = useState(false);
  const [exclusionDates, setExclusionDates] = useState<Moment[]>([]);
  const [focusedDateInput, setFocusedDateInput] = useState<any>(null);

  const toggleIsFilteringExlusionDates = useCallback(() => {
    setIsFilteringExlusionDates(!isFilteringExlusionDates);
    setExclusionDates([]);
  }, [isFilteringExlusionDates, setExclusionDates]);

  const handleExclusionDateChange = (date: any) => {
    if (date.isAfter(endDate) || date.isBefore(startDate)) {
      return;
    }

    if (exclusionDates.find(d => d === date)) {
      return setExclusionDates(previousExclusionDates => previousExclusionDates.filter(d => !d.isSame(date)))
    }
    return setExclusionDates(previousExclusionDates => [...previousExclusionDates, date])
  }

const handeExcludedDateDehighlight = (day: any | boolean) => {
  const start = moment(startDate);
  const end = moment(endDate);
  
  const dates = Array.from({length: end.diff(start, 'days') + 1}, (_, index) => {
    return moment(start).add(index, 'days');
  })

  if (start.isSame(end)) {
    return false;
  }
  
  if (exclusionDates.length > 0)  {
    const removedDates = dates.filter((item) => {
      return !exclusionDates.some(d => moment(item).isSame(d, 'day')) 
    });
    return removedDates.some(date => day.isSame(date, "day"))
  }
  return dates.some(date => day.isSame(date, "day"))
}

  const handleFocusChange = ({focused} : IFocusedDateInput) => {
    setFocusedDateInput(focused);
  }

  const handleCampaignStartDateUpdate = () => {
    if (startDate && startDate.isSame(endDate)) {
      return;
    }

    const matchingStartDate = exclusionDates.find(date => date.isSame(startDate))
    if (startDate && exclusionDates.length && !isEmpty(matchingStartDate)) {
      setStartDate(startDate.add(1, 'days'))
      setExclusionDates(previousDate => previousDate.filter((date) => date !== matchingStartDate ))
    }
  }

  const handleCampaignEndDateUpdate = () => {
    if (endDate && endDate.isSame(startDate)) {
      return;
    }

    const matchingEndDate = exclusionDates.find(date => date.isSame(endDate))
    if (endDate && exclusionDates.length && !isEmpty(matchingEndDate)) {
      setEndDate(endDate.subtract(1, 'days'))
      setExclusionDates(previousDate => previousDate.filter((date) => date !== matchingEndDate ))
    }
  }
  
  useEffect(() => {
    handleCampaignStartDateUpdate();
    handleCampaignEndDateUpdate();
    setExclusionDatesApplied(exclusionDates.map((date) => (date.utc().format(DATE_FORMAT))));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exclusionDates, endDate, startDate, exclusionDates]);

  return (
    <Container>
      <CheckboxContainer>
        <StyledFormControlLabel
          control={
            <Checkbox 
            checked={isFilteringExlusionDates}
            onChange={toggleIsFilteringExlusionDates}
            inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Exclude dates from campaign"
        />
      </CheckboxContainer>
      {isFilteringExlusionDates && (
      <ExclusionDatePickerContainer>
        <p>Click the dates within the selected range to exclude the date from the campaign.</p>
        <DayPickerSingleDateController
          numberOfMonths={NUM_OF_MONTHS}
          date={null}
          onDateChange={handleExclusionDateChange} // PropTypes.func.isRequired
          focused={focusedDateInput} // PropTypes.bool
          onFocusChange={handleFocusChange} // PropTypes.func.isRequired
          isDayHighlighted={handeExcludedDateDehighlight}
          keepOpenOnDateSelect={false}
        />
      </ExclusionDatePickerContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;

  @media ${theme.media.tablet} {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
  }
`
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  &&& {
    align-items: flex-start;
    margin-right: 0px;
    margin-left: -7px;
  }

  &&& > span.MuiTypography-root {
    font-size: 14px;
    margin-left: 4px;
    margin-top: 4px;
  }
`
const ExclusionDatePickerContainer = styled.div`
  margin-top: 10px;

  /* this is needed to override styling the current day */
  .CalendarDay__today {
    font-weight: unset !important;
    color: inherit !important;
    border-radius: 0 !important;
    background-color: unset !important;
  }
  
  .CalendarDay__highlighted_calendar {
    background: #ffe8bc !important;
    color: #484848 !important;
  }
` 