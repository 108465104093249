import * as React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
min-width: 40px;
min-height: 40px;
width: 2.5rem;
height: 2.5rem;
border: none;
background-color: ${props => props.color || '#daf7e8'};;
border-radius: 50%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`;

interface IProps {
  color?: string;
  bgColor?: string;
}

export class  ProcessIcon extends React.Component<IProps>{
  public render() {
    return (
      <Circle color={this.props.bgColor}>
        <svg xmlns="http://www.w3.org/2000/svg" width="15.6" height="14.201" viewBox="0 0 15.6 14.201">
          <path d="M12.6,12a6.973,6.973,0,0,1-4.9,2,6.721,6.721,0,0,1-4.9-2A6.583,6.583,0,0,1,.9,8.2l2-.3a5.405,5.405,0,0,0,1.4,2.7,5.012,5.012,0,0,0,7,0L9.2,8.5l6.4-.7-.8,6.4ZM.7,0,2.9,2.2a7.063,7.063,0,0,1,9.9,0A6.592,6.592,0,0,1,14.7,6l-2,.3a5.4,5.4,0,0,0-1.4-2.7,5.012,5.012,0,0,0-7,0L6.4,5.7,0,6.4Z" fill={this.props.color || '#4ad991'}/>
        </svg>
      </Circle>
    );
  }
}

export default ProcessIcon;
