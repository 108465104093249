import { useSegments } from './use-segment';

export interface IData {
  segmentID: number
  [key: string]: any
}

interface ISegmentedData {
  [key: number]: any
}

export interface IResult {
  segmentID: number | null
  segmentName: string
  [key: string]: any
}

export const useTotalSegmentData = () => {
  const segments = useSegments()

  const getTotalSegmentData = (data: IData[], dataKey: string) => {
    const groupedData: ISegmentedData = {}

    for (const segmentData of data) {
      if (!segmentData.hasOwnProperty(dataKey)) {
        continue
      }
      
      if (!groupedData.hasOwnProperty(segmentData.segmentID)) {
        groupedData[segmentData.segmentID] = [segmentData]
      } else {
        groupedData[segmentData.segmentID].push(segmentData)
      }
    }

    const result: IResult[] = []

    for (const key of Object.keys(groupedData)) {
      const segment = groupedData[key]
      const segmentID = segment[0].segmentID
      const total = segment.reduce(
        (runningTotal: number, dataPoint: IData) => runningTotal + dataPoint[dataKey],
        0
      );

      const segmentDescription = segments.find(({ id }: any) => id === segmentID)
      result.push({ segmentID, segmentName: segmentDescription.name, [dataKey]: total })
    }

    return result
  }

   return getTotalSegmentData
}