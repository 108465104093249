import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useClientCustomization, useFeatureFlag } from '../../../utils/hooks';
import { Checkbox, Autocomplete } from '../../../components/Form';
import theme from '../../../assets/css/theme';

type Operation = 'eq' | 'ne' | 'lt' | 'lte' | 'gt' | 'gte';

export interface IFilter {
  fieldname: string,
  op: Operation,
  value: string | boolean
};

interface IProps {
  setUDFFilters(filters: IFilter[]): void
}

const MaterUDFFilter = ({
  setUDFFilters,
}: IProps) => {

  // these are hard coded for the moment to account for mater
  const excludeStates = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
  const excludeVIP = ['Exclude VIPs', 'Exclude non VIPs'];
  // state variables to keep track of the fields
  const [isFilteringUDFs, setIsFilteringUDFs] = useState(false);
  const [stateFilters, setStateFilters] = useState<IFilter[]>([]);
  const [VIPFilters, setVIPFilters] = useState<IFilter[]>([]);

  // work out if we should be using this or not
  const { clientName } = useClientCustomization()
  const hasUDFFilteringEnabled = useFeatureFlag("campaignUDFFiltering", clientName);

  const toggleIsFilteringUDFs = useCallback(() => {
    if (isFilteringUDFs) {
      // need to just reset the UDF values back to nothing if we're turning off
      setStateFilters([]);
      setVIPFilters([]);
    }
    setIsFilteringUDFs(!isFilteringUDFs)
  }, [isFilteringUDFs]);

  useEffect(() => {
    // this fires after the state or vip filter change and basically
    // is the thing that propogates the UDF state back to the campaign Form
    const filters = stateFilters.concat(...VIPFilters);
    setUDFFilters(filters);

    // This disable is in place because there's an indirect useEffect in place.
    // Specifically, the setUDFFilters is happening at the higher level but it's
    // called as a result of the changes on the state and VIP filters locally which
    // are the individual components of the filter.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateFilters, VIPFilters]);

  if (!hasUDFFilteringEnabled) { return null }

  const handleStateFilterChange = (_event: any, value: any) => {
    if (value.length === 0) {
      setStateFilters([]);
    } else {
      setStateFilters(value.map((stateValue: string) => {
        return {
          fieldname: 'udf_3',
          op: 'eq',
          value: stateValue
        }
      }));
    }
  };

  const handleVIPFilterChange = (_event: any, value: any) => {
    // take the values from the VIP selector and make the relevant
    // object from it.
    if (value === null) {
      setVIPFilters([]);
    } else {
      setVIPFilters([{
        fieldname: 'udf_1',
        op: 'eq',
        value: value === 'Exclude VIPs' ? true : false
      }]);
    }
  }

  return(
    <Container>
      <CheckboxContainer>
        <StyledFormControlLabel
          control={
            <Checkbox
              checked={isFilteringUDFs}
              onChange={toggleIsFilteringUDFs}
            />
          }
          label="Exclude people from campaign using attributes"
        />
      </CheckboxContainer>
      {isFilteringUDFs && (
        <MaterExclusionContainer>
          <AutocompleteContainer>
            <Autocomplete
              options={excludeStates || []}
              multiple={true}
              size="small"
              label='Select state(s) to exclude'
              onChange={handleStateFilterChange}
            />
          </AutocompleteContainer>
          <AutocompleteContainer>
            <Autocomplete
              options={excludeVIP || []}
              multiple={false}
              size="small"
              label='VIP status to exclude'
              onChange={handleVIPFilterChange}
            />
          </AutocompleteContainer>
        </MaterExclusionContainer>
      )}
    </Container>
  )
};

export const DefaultUDFFilter = (() => {
  return (
    <Container>
      <CheckboxContainer>
        <StyledFormControlLabel
          control={
            <Checkbox
            />
          }
          label="Filter by attribute"
        />
      </CheckboxContainer>
        <AutocompleteContainer>
          <Autocomplete
            options={['NSW', 'VIC', 'WA', 'TAS']}
            multiple={false}
            size="small"
            label='Select states to exclude'
          />
        </AutocompleteContainer>
    </Container>
  )
});

export const UDFFilter = ({setUDFFilters}: IProps) => {
  // doing this like this because we want to do something
  // specific for Mater in the first instance and will rewrite this
  // entirely for more generalised approach.
  return (
    <MaterUDFFilter
      setUDFFilters={setUDFFilters}
    />
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;

  @media ${theme.media.tablet} {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
  }
`

const CheckboxContainer = styled.div`
  flex-grow: 1;
  flex-basis: 50%

//  display: flex;
//  align-items: center;
//  justify-content: flex-start;
//  flex: 1;
`

const MaterExclusionContainer = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
//  display: flex;
//  align-items: center;
//  justify-content: flex-start;
//  flex: 1;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  &&& {
    align-items: flex-start;
    margin-right: 0px;
    margin-left: -7px;
  }

  &&& > span.MuiTypography-root {
    font-size: 14px;
    margin-left: 4px;
    margin-top: 4px;
  }
`

const AutocompleteContainer = styled.div`
  margin-top: 12px;
  width: 100%;

  @media ${theme.media.tablet} {
    margin-top; 0px;
  }
`
