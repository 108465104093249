import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useFilters } from '../../utils/hooks';
import { AppWithHeader } from '../../components/Header';
import { PageTitle } from '../../components/Text';
import ContainerStyled from '../../components/ContainerStyled';
import { DateRangeSelector, DateRangeType } from '../../components/Filters/DateRangeSelector';
import { customerDataOverTimeRequest } from '../../modules/actions';
import { IStoreState, ICustomerDataDateRecord } from '../../modules/types'
import { isDataRequested } from '../../modules/selectors';
import { AvgSpendToDate } from './AvgSpendToDate';
import { AvgTransactionValue } from './AvgTransactionValue';

export const CommercialPerformancePage = () => {
  const { dateRange } = useFilters()
  const dispatch = useDispatch();
  const isLoading = useSelector(isDataRequested);

  const dataOverTime: ICustomerDataDateRecord[] = useSelector((state: IStoreState) => {
    return state.customers.dataOverTime || []
  })

  const handleChangeDateRange = (_newDateRange: DateRangeType, dates: string[]) => {
    dispatch(customerDataOverTimeRequest({ dates }))
  }

  return (
    <AppWithHeader>
      <PageContainer maxWidth="xl">
        <HeaderContainer>
          <PageTitle>Commercial Performance</PageTitle>
          <SelectContainer>
            <DateRangeSelector currentDateRange={dateRange} handleDateRangeChange={handleChangeDateRange} />
          </SelectContainer>
        </HeaderContainer>
        { dataOverTime.length > 0 && !isLoading && (
          <>
            <AvgSpendToDate overTimeData={dataOverTime} dateRange={dateRange} />
            <AvgTransactionValue overTimeData={dataOverTime} dateRange={dateRange} />
          </>
        )}
      </PageContainer>
    </AppWithHeader>
  )
}

const PageContainer = styled(ContainerStyled)`
  margin-bottom: 32px;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const SelectContainer = styled.div`
  width: 200px;
`