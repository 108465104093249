import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { map } from 'lodash';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { withHeader } from '../../components';
import { useSegment } from '../../utils/hooks';
import { campaignCreationStatusesRequest } from '../../modules/actions';
import { IAudienceCard, ISegmentRequestApi, IStoreState, IUserMeta } from '../../modules/types';
import { currentSegmentSummary, audiencesSummary as fetchAudiencesSummary } from '../../modules/selectors/segment';
import { lifecycleRequest, exportCustomersRequest, segmentCardClean } from '../../modules/actions';
import LifecycleSegmentCard from './LifecycleSegmentCard';
import LifecycleAudienceCard from './LifecycleAudienceCard';
import { PageTitle } from '../../components/Text';
import ContainerStyled from '../../components/ContainerStyled';
import theme from '../../assets/css/theme';
import { clientName } from '../../modules/selectors';
import { BackLink } from '../../components/Navigation';
import LoadingLifecycleSegmentCard from './LoadingLifecycleSegmentCard';
import LoadingLifecycleAudienceCard from './LoadingLifecycleAudienceCard';

export interface IPropsLifecycle {
  segment_id: number;
  avgOrderValue: number;
  frequency: number;
  recency: number;
  spend: number;
  sizeValue: number;
  onClick?: (segment_id: number) => void;
  proportionTotal: number;
  untargetedOpportunitySize: number | string;
}

interface IProps extends RouteComponentProps<{ segment_id: string }>, IUserMeta {
  fetchLifecycleInfo: typeof lifecycleRequest;
  handleExportCustomers: typeof exportCustomersRequest;
  segmentSummary: IPropsLifecycle;
  audiencesSummary: IAudienceCard[];
  isSegmentsDataRequested?: boolean;
  isUserDataRequested?: boolean;
  cleanSegmentCard: typeof segmentCardClean;
}

const Lifecycle = ({
  fetchLifecycleInfo,
  cleanSegmentCard,
  handleExportCustomers,
  segmentSummary: data,
  audiencesSummary,
  isSegmentsDataRequested,
  isUserDataRequested,
  client_name,
  ...props
}: IProps) => {
  const { match: { params: { segment_id } } }  = props;
  const { name } = useSegment(segment_id)
  const dispatch = useDispatch()
  useEffect(() => {
    fetchLifecycleInfo({ segment_id });
    dispatch(campaignCreationStatusesRequest())
    // wrap in an anonymous function to avoid return type error
    return () => {
      cleanSegmentCard()
    }
    // eslint-disable-next-line
  }, [ segment_id, cleanSegmentCard, fetchLifecycleInfo ])

  const downloadFile = (payload: ISegmentRequestApi) => {
    handleExportCustomers({ segment_id: payload.segment_id });
  }

  return (
    <ContainerStyled maxWidth="xl">
      <BackLink url="/opportunity" />
      <PageTitle>{ name || "Customer Lifecycle" }</PageTitle>
      <CardContainer container={true}>
        {/* segments data has finished fetching */}
        { isSegmentsDataRequested !== undefined && !isSegmentsDataRequested ? (
          <LifecycleSegmentCard
            {...data}

            is_data_requested={isUserDataRequested}
            client_name={client_name}
            onClick={downloadFile}
          />
        ) : (
          <LoadingLifecycleSegmentCard />
        )}
      </CardContainer>
      <AudiencesTitle>Audiences</AudiencesTitle>
        {audiencesSummary.length === 0 && (
          <CardContainerAudience container={true}>
            <LoadingLifecycleAudienceCard />
        </CardContainerAudience>
      )}
      {map(audiencesSummary, card => (
        <CardContainerAudience container={true} key={card.id}>
          <LifecycleAudienceCard {...card} segment_id={segment_id} />
        </CardContainerAudience>
      ))}
    </ContainerStyled>
  )
}

const mapStateToProps = (state: IStoreState) => ({
  isSegmentsDataRequested: state.segment.is_data_requested,
  isUserDataRequested: state.user.is_data_requested,
  client_name: clientName(state),
  segmentSummary: currentSegmentSummary(state),
  audiencesSummary: fetchAudiencesSummary(state),
});

const mapDispatchToProps = {
  fetchLifecycleInfo: lifecycleRequest,
  handleExportCustomers: exportCustomersRequest,
  cleanSegmentCard: segmentCardClean,
}

const CardContainer = styled(Grid)`
  && {
    box-shadow: ${theme.boxShadow};
  }
`;

const CardContainerAudience = styled(CardContainer)`
  && {
    margin-bottom: 1.875rem;
  }
`;

const AudiencesTitle = styled(PageTitle)`
  margin: 2rem 0;
`;

export default withHeader(connect(mapStateToProps, mapDispatchToProps)(Lifecycle));
