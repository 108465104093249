import React, { useMemo } from 'react';
import format from 'date-fns/format';
import theme from '../../assets/css/theme'
import { ICustomerDataDateRecord } from '../../modules/types'
import { Report } from '../../components/Report'
import { MultiTypeChart } from '../../components/Charts/MultiTypeChart'
import { 
  DateRangeType, 
  useDateFnsFormat,
  formatLargeNumber,
  getDateHeadings,
} from '../../utils/reports'

interface IProps {
  dateRange: DateRangeType,
  overTimeData: ICustomerDataDateRecord[]
}

interface IContactablityData {
  totalCount: null | number
  totalContactable: null | number
  totalNotContactable: null | number
  totalContactableViaPhone: null | number
  totalContactableViaEmail: null | number
}

interface IContactabilityOvertimeData {
  date: string
  data: IContactablityData

}

const NUM_DECIMAL_POINTS = 0

const getCurrentContactabilityMetric = (runningTotal: null | number, newVal: null | number) => {
  let newTotal = runningTotal;
  if (newVal !== null) {
    if (newTotal === null) {
      // switch to int before adding values
      newTotal = 0
    }

    newTotal += newVal
  }

  return newTotal
}

const getContactabilitySplit = (overTimeDataRecord: ICustomerDataDateRecord) => {
  // if all records have null for the metric, retain the null value
  let totalCount = null
  let totalContactable = null
  let totalNotContactable = null
  let totalContactableViaPhone = null
  let totalContactableViaEmail = null

  for (const overTimeRecord of overTimeDataRecord.data) {
    totalCount = getCurrentContactabilityMetric(totalCount, overTimeRecord.count)
    totalContactable = getCurrentContactabilityMetric(totalContactable, overTimeRecord.totalContactable)
    totalContactableViaPhone = getCurrentContactabilityMetric(totalContactableViaPhone, overTimeRecord.totalContactableViaPhone)
    totalContactableViaEmail = getCurrentContactabilityMetric(totalContactableViaEmail, overTimeRecord.totalContactableViaEmail)

    if (totalContactable !== null && totalCount !== null) { 
      totalNotContactable = totalCount - totalContactable
    }
  }

  return { totalCount, totalContactable, totalNotContactable, totalContactableViaEmail, totalContactableViaPhone }
}

const removeNullColumns = (overTimeData: IContactabilityOvertimeData[]) => {
  return overTimeData.filter(dataSet => {
    return dataSet.data.totalContactable !== null || dataSet.data.totalNotContactable !== null
  })
}

const getCSVData = (overTimeData: IContactabilityOvertimeData[], dateFormat: string) => {
  const cleanedData = removeNullColumns(overTimeData)
  const dateHeadings = cleanedData.map(dataSet => {
    return format(new Date(dataSet.date), dateFormat)
  })

  const contactableData = cleanedData.map(dataSet => {
    return dataSet.data.totalContactable === null ? 'n/a' : formatLargeNumber(dataSet.data.totalContactable, NUM_DECIMAL_POINTS)
  })

  const notContactableData = cleanedData.map(dataSet => {
    return dataSet.data.totalNotContactable === null ? 'n/a' : formatLargeNumber(dataSet.data.totalNotContactable, NUM_DECIMAL_POINTS)
  })

  const totalData = cleanedData.map(dataSet => {
    if (dataSet.data.totalNotContactable === null) {
      return 'n/a'
    }

    if (dataSet.data.totalContactable === null) {
      return formatLargeNumber(dataSet.data.totalNotContactable, NUM_DECIMAL_POINTS)
    }

    const total = dataSet.data.totalNotContactable + dataSet.data.totalContactable

    return formatLargeNumber(total, NUM_DECIMAL_POINTS)
  })

  const rows = [["Contactable", ...contactableData], ["Not Contactable", ...notContactableData], ["Total", ...totalData]]

  return { rows, headers: ['Contactability', ...dateHeadings], dateHeadings }
}

const getChartData = (overTimeData: IContactabilityOvertimeData[]) => {
  const contactableChartEntry = {
    name: 'Contactable',
    type: 'column',
    color: theme.colorDarkestBlue,
    data: overTimeData.map(dataSet => dataSet.data.totalContactable),
  }

  const notContactableChartEntry = {
    name: 'Not Contactable',
    type: 'column',
    color: theme.colorGray2,
    data: overTimeData.map(dataSet => dataSet.data.totalNotContactable),
  }

  const emailContactableChartEntry = {
    name: 'Email',
    color: theme.colorRed,
    data: overTimeData.map(dataSet => dataSet.data.totalContactableViaEmail),
  }

  const phoneContactableChartEntry = {
    name: 'Phone',
    color: theme.colorLightPink,
    data: overTimeData.map(dataSet => dataSet.data.totalContactableViaPhone),
  }

  return [notContactableChartEntry, contactableChartEntry, emailContactableChartEntry, phoneContactableChartEntry]
}

export const CustomerContactability = ({ overTimeData, dateRange }: IProps) => {
  const dateFormat = useDateFnsFormat(dateRange)
  const contactabilityData = useMemo(() => {
    if (!overTimeData) {
      return null
    }
    const contactabilityDataRes: IContactabilityOvertimeData[] = []
    for (const overTimeDataRecord of overTimeData) {
      const data = getContactabilitySplit(overTimeDataRecord)
      contactabilityDataRes.push({ date: overTimeDataRecord.date, data })
    }

    return contactabilityDataRes
  }, [ overTimeData ])

  const tableInfo = useMemo(() => contactabilityData ? getCSVData(contactabilityData, dateFormat) : { rows: [], headers: [], dateHeadings: [] }, [contactabilityData, dateFormat])
  const chartData = useMemo(() => contactabilityData ? getChartData(contactabilityData) : [], [contactabilityData])
  const dateHeadings = getDateHeadings(overTimeData, dateFormat)
  const { rows, headers } = tableInfo
  const csvData = [
    headers,
    ...rows
  ];

  return (
    <Report
      title="Customer Contactability"
      headers={headers}
      rows={rows}
      csvData={csvData}
      shouldBoldLastTableRow={true}
      guideHeight="320px"
      guideSlides={
        [{
        id: 1, 
        body: "Monitor ‘Customer Contactability’ to understand the volume and proportion of customers that can be communicated to via direct marketing." 
      }, {
        id: 2, 
        body: "Furthermore, understand the volume and proportion of those customers that can be contacted via Email and via Phone."
      }, {
        id: 3, 
        body: "The objective is to see overall contactabilty increase. Decreasing contractibility suggests more customers are opting out than are opting in over a given time period." 
      }]
    }
    >
      <MultiTypeChart
        data={chartData}
        categories={dateHeadings}
        shouldShowDataLabels={false}
        height="300px"
      /> 
    </Report>
  )
}