import * as React from 'react';
import { Circle } from './index';

interface IProps {
  color?: string,
}

export class  MoneyBagIcon extends React.Component<IProps>{
  public render() {
    const color = this.props.color || '#00E9CE';
    return (
      <Circle color={color}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
          <path data-name="Money Bag Icon" d="M-227.408-1011a3.359,3.359,0,0,1-2.768-1.5,3.019,3.019,0,0,1,0-3l3.514-6a1.135,1.135,0,0,1,.958-.5h4.259a1.138,1.138,0,0,1,.958.5l3.513,6a3.013,3.013,0,0,1,0,3,3.356,3.356,0,0,1-2.768,1.5Zm1.7-13a1,1,0,0,1-.745-.3l-1.065-1a.975.975,0,0,1,.746-1.7h6.387a.993.993,0,0,1,.745,1.7l-1.065,1a1,1,0,0,1-.745.3Z" transform="translate(230.575 1027)" fill={color}/>
        </svg>
      </Circle>
    );
  }
}

export default MoneyBagIcon;
