import capitalize from 'lodash/capitalize';
import { 
  KLAVIYO_OPT, 
  DOWNSTREAM_CAMPAIGN_CREATION_OPT, 
  DOWNSTREAM_AUDIENCE_OPT,
  INTEGRATION_TYPES,
  REQUIRED_INTEGRATION_INFO
} from '@loypal/lib-growth';
import { useFeatureFlag, useClientCustomization } from "../../../utils/hooks"

export interface IIntegrationType {
  value: string,
  label: string,
  description: string
}

export interface IIntegrationCreds {
  key: string,
  label: string,
}

export interface IOtherIntegrationInfo {
  key: string,
  label: string,
}

export const useIntegrationOptions = () => {
  const { clientName } = useClientCustomization()

  const options = []
  const isKlaviyoEnabled = useFeatureFlag('integrationKlaviyo', clientName);
  if (isKlaviyoEnabled) {
    options.push(KLAVIYO_OPT)
  }

  return options
}

export const valueToLabel = (value: string) => {
  return capitalize(value.replaceAll('_', ' '))
}

export const getIntegrationTypeLabel = (integrationValue: string) => {
  const integrationTypes = [DOWNSTREAM_CAMPAIGN_CREATION_OPT, DOWNSTREAM_AUDIENCE_OPT];
  const integrationType = integrationTypes.find(({ value }) => value === integrationValue);
  return integrationType?.label || valueToLabel(integrationValue);
}

export const getIntegrationPlatformLabel = (platformValue: string) => {
  const integrationPlatforms = [KLAVIYO_OPT];
  const integrationType = integrationPlatforms.find(({ value }) => value === platformValue);
  return integrationType?.label || valueToLabel(platformValue);
}

export const getIntegrationTypesForPlatform = (platform: string) => {
  if (INTEGRATION_TYPES.hasOwnProperty(platform)) {
    return INTEGRATION_TYPES[platform]
  }

  const allIntegrationTypes = [DOWNSTREAM_AUDIENCE_OPT, DOWNSTREAM_CAMPAIGN_CREATION_OPT];
  return allIntegrationTypes
}

export const getRequiredInfoFields = (platform: string, type: string) => {
  if (!REQUIRED_INTEGRATION_INFO.hasOwnProperty(platform)) {
    return []
  }

  const integrationTypes = REQUIRED_INTEGRATION_INFO[platform];
  if (!integrationTypes.hasOwnProperty(type)) {
    return []
  }

  return integrationTypes[type]
}