import * as React from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import Arrow from './Arrow';

interface IProps {
  sizeFirst: {
    days: number,
    size: number,
    sign: string,
  },
  sizeSecond: {
    days: number,
    size: number,
    sign: string,
  },
}

const SizeHasChangedContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-content: stretch;
align-items: flex-start;
`;

const Size = styled.div`
flex-grow: 0;
flex-shrink: 0;
flex-basis: auto;
margin-left: 1.563rem;
`;

const Label = styled.div`
height: 0.875rem;
font-family: ${theme.ffInterRegular};
font-size: 0.6875rem;
font-weight: normal;
line-height: 1.1;
opacity: 0.5;
color: ${theme.titleColor};
`;

const LabelRight = styled(Label)`
text-align: right;
`;

const Percentage = styled.div`
height: 1.063rem;
font-family: ${theme.ffInterMedium};
font-size: 0.8725rem;
font-weight: bold;
color: ${(props: { positive?: boolean }) => props.positive ? theme.colorDarkPurple : theme.negativeColor}
`;

const PercentageRight = styled(Percentage)`
text-align: right;
`;

export class SizeHasChanged extends React.Component<IProps> {
  private static isPositive(sign: string): boolean {
    return (sign === 'high');
  }

  public render() {
    return (
      <SizeHasChangedContainer>

        <Size>
          <Label>{this.props.sizeFirst.days + ' Days'}</Label>
          <Percentage positive={SizeHasChanged.isPositive(this.props.sizeFirst.sign)}>
            <Arrow
              color={SizeHasChanged.isPositive(this.props.sizeFirst.sign) ? theme.colorDarkPurple : theme.negativeColor}
              direction={this.props.sizeFirst.sign}/> {
            this.props.sizeFirst.size + '%'
          }</Percentage>
        </Size>

        <Size>
          <LabelRight>{this.props.sizeSecond.days + ' Days'}</LabelRight>
          <PercentageRight positive={SizeHasChanged.isPositive(this.props.sizeSecond.sign)} >
            <Arrow
              color={SizeHasChanged.isPositive(this.props.sizeSecond.sign) ? theme.colorDarkPurple : theme.negativeColor}
              direction={this.props.sizeSecond.sign}/> {
            this.props.sizeSecond.size + '%'
          }</PercentageRight>
        </Size>

      </SizeHasChangedContainer>
    );
  }
}

export default SizeHasChanged;
