import { getSegmentDefinition, getSegmentDefinitions } from '@loypal/lib-growth';

import { useClientCustomization } from './use-client-customization';

export const useSegment = (id: number | string) => {
  if (typeof id === 'string') {
    id = parseInt(id, 0)
  }
  
  const { industryGroup } = useClientCustomization();
  return getSegmentDefinition(industryGroup, id)
}

export const useSegments = () => {
  const { industryGroup } = useClientCustomization();

  return getSegmentDefinitions(industryGroup)
}