import React, { useMemo, useState } from 'react';
import { ICustomerDataDateRecord } from '../../modules/types'
import { useAvgData } from '../../utils/hooks/use-avg-data'
import { useAvgSegmentData } from '../../utils/hooks/use-avg-segment-data'
import { Report } from '../../components/Report'
import { LineChart } from '../../components/Charts/LineChart'
import { useClientCustomization } from '../../utils/hooks/use-client-customization'
import {
  DateRangeType,
  removeProspectData,
  nullifyZeros,
  useDateFnsFormat,
  getYAxisCeil,
  getYAxisFloor,
  getSegmentsLineChartData,
  getAllChartData,
  getAudienceCSVData,
  getAudienceLineChartData,
  getSegementCSVData,
  getAllCSVData,
  formatLargeNumber,
  getMinAndMax,
  getDateHeadings
} from '../../utils/reports'

const ALL = "All"
const LIFESTAGES = "Lifestages"
const AUDIENCES = "Audiences"
const LIFESTAGES_INDEX_OFFSET = 2
const AUDIENCES_INDEX_OFFSET = 1
const NUM_DECIMAL_POINTS = 2

interface IProps {
  dateRange: DateRangeType,
  overTimeData: ICustomerDataDateRecord[]
}


export const AvgDaysBetweenTX = ({ overTimeData, dateRange }: IProps) => {
  const relevantOvertimeData = removeProspectData(overTimeData)
  const cleanedOvertimeData = nullifyZeros(relevantOvertimeData, 'avgDaysBetweenTX')
  const [dataGrouping, setDataGrouping] = useState(ALL)
  const { industryGroup } = useClientCustomization()
  const dateFormat = useDateFnsFormat(dateRange)

  const getAvgData = useAvgData();
  const getAvgSegmentData = useAvgSegmentData()

  const formatData = (data: any) => {
    const twoDecimalNum = 2;
    return data === null ? 'n/a' : formatLargeNumber(data, twoDecimalNum)
  }

  const avgDataOverTime = useMemo(() => {
    if (!cleanedOvertimeData) {
      return null
    }
    return cleanedOvertimeData.map(audienceData => {
      const avgData = getAvgData(audienceData.data, 'avgDaysBetweenTX')
      return { ...audienceData, data: avgData }
    })
    // eslint-disable-next-line
  }, [cleanedOvertimeData])

  const avgSegmentDataOverTime = useMemo(() => {
    if (!cleanedOvertimeData) {
      return null
    }
    return cleanedOvertimeData.map(audienceData => {
      const avgSegmentData = getAvgSegmentData(audienceData.data, 'avgDaysBetweenTX');
      return { ...audienceData, data: avgSegmentData }
    })
    // eslint-disable-next-line
  }, [cleanedOvertimeData])

  const chartInfo = useMemo(() => {
    const defaultChartinfo = { data: [], lowestVal: 0, highestVal: 0 }
    if (dataGrouping === LIFESTAGES) {
      return avgSegmentDataOverTime ? getSegmentsLineChartData(avgSegmentDataOverTime, "avgDaysBetweenTX", LIFESTAGES_INDEX_OFFSET) : defaultChartinfo
    }

    if (dataGrouping === AUDIENCES) {
      return cleanedOvertimeData ? getAudienceLineChartData(cleanedOvertimeData, "avgDaysBetweenTX", industryGroup, AUDIENCES_INDEX_OFFSET) : defaultChartinfo
    }

    return avgDataOverTime ? getAllChartData(avgDataOverTime || [], dateFormat) : defaultChartinfo
    // eslint-disable-next-line
  }, [dataGrouping, cleanedOvertimeData])

  const tableInfo = useMemo(() => {
    const defaultTableInfo = { rows: [], headers: [], dateHeadings: [] }
    if (dataGrouping === LIFESTAGES) {
      return avgSegmentDataOverTime ? getSegementCSVData(avgSegmentDataOverTime, dateFormat, "avgDaysBetweenTX", formatData) : defaultTableInfo
    }

    if (dataGrouping === AUDIENCES) {
      return cleanedOvertimeData ? getAudienceCSVData(cleanedOvertimeData, industryGroup, dateFormat, "avgDaysBetweenTX", formatData, false, true) : defaultTableInfo
    }

    return avgDataOverTime ? getAllCSVData(avgDataOverTime, dateFormat, formatData) : defaultTableInfo
    // eslint-disable-next-line
  }, [dataGrouping, cleanedOvertimeData])

  const { min, max } = useMemo(() => getMinAndMax(cleanedOvertimeData, "avgDaysBetweenTX"), [ cleanedOvertimeData ])
  const dateHeadings = getDateHeadings(overTimeData, dateFormat)
  const { rows, headers } = tableInfo
  const csvData = [
    headers,
    ...rows
  ];

  const { data: chartData } = chartInfo
  const yAxisFloor = getYAxisFloor(min)
  const yAxisCeil = getYAxisCeil(max)

  const showAllData = () => {
    setDataGrouping(ALL)
  }

  const showSegmentsData = () => {
    setDataGrouping(LIFESTAGES)
  }
  const showAudiencesData = () => {
    setDataGrouping(AUDIENCES)
  }

  const yAxisLabelFormat = (label: number) => {
    return formatLargeNumber(label, NUM_DECIMAL_POINTS)
  }

  return (
    <Report
      title="Average Days Between Transactions"
      headers={headers}
      rows={rows}
      csvData={csvData}
      dataGroupOpts={[
        {
          name: ALL,
          isActive: dataGrouping === ALL,
          onClick: showAllData
        },
        {
          name: LIFESTAGES,
          isActive: dataGrouping === LIFESTAGES,
          onClick: showSegmentsData
        },
        {
          name: AUDIENCES,
          isActive: dataGrouping === AUDIENCES,
          onClick: showAudiencesData
        },
      ]}
      guideSlides={
        [{
        id: 1,
        body: "Monitor ‘Average Days Between Transaction’ to understand how quickly (on average) a customer is making a subsequent purchase"
      }, {
        id: 2,
        body: "The objective is to see this time reducing, indicating that customers are purchasing more frequently."
      }, {
        id: 3,
        body: "Observing measures by lifestage or audience can help identify areas within the customer base that are relatively over and under performing in this metric."
       }]}
    >
      <LineChart
        // re-render when data grouping changes
        // fixes a bug with highcharts when data changes when already mounted
        key={`avg-days-between-tx-${dataGrouping}`}
        height="300px"
        data={chartData}
        yAxisFloor={yAxisFloor}
        yAxisCeil={yAxisCeil}
        xAxisCategories={dateHeadings}
        dataLabel="Average Days Between Transactions"
        isMultiLine={dataGrouping !== ALL}
        yAxisLabelFormat={yAxisLabelFormat}
        tooltipOpts={{
          valueDecimals: NUM_DECIMAL_POINTS
        }}
      />
    </Report>
  )
}
