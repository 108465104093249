import format from 'date-fns/format';
import { getAudienceDefinitions } from '@loypal/lib-growth'
import segmentColors, { colorsBySegmentID } from '../config/segmentColors'
import { ICustomerDataDateRecord } from '../modules/types'
import theme from '../assets/css/theme'

const BOUNDARY_PADDING_PERC = 10
const DEFAULT_FLOOR = 0
const NUM_DECIMAL_FIGURES = 1
const PROSPECT = 1;
const INDEX_OFFSET = 1;                                               

const RELATIONSHIP_COLOURS = {
  "Prospect": theme.colorRed,
  "Active": theme.colorDarkestBlue,
  "Archive": theme.colorGray2
}

const LINE_DASH_STYLES_BY_AUDIENCE_ID = {
  1: "Solid",
  2: "Solid",
  3: "Solid",
  4: "Dash",
  5: "Solid",
  6: "Dash",
  7: "Dot",
  8: "DashDot",
  9: "Solid",
  10: "Dash",
  11: "Dot",
  12: "DashDot",
  13: "Solid",
}

const BAR_CHART_FILL_BY_AUDIENCE_ID = {
  1: theme.colorLightPink,
  2: theme.colorRed,
  3: theme.colorDarkestBlue,
  4: "url(#promising-pattern)",
  5: theme.colorLighestPurple,
  6: "url(#advocate-pattern)",
  7: "url(#hero-pattern)",
  8: "url(#super-hero-pattern)",
  9: theme.colorDimGray,
  10: "url(#last-chance-pattern)",
  11: "url(#at-risk-advocate-pattern)",
  12: "url(#at-risk-hero-pattern)",
  13: theme.colorGray2,
}

export const TWO_YEARS_FORMAT = 'MMM-YY'
export const TWO_YEARS_DATE_FNS_FORMAT = 'do MMM yy'
export const ONE_YEAR_FORMAT = 'MMM-YY'
export const ONE_YEAR_DATE_FNS_FORMAT = 'do MMM yy'
export const SIX_MONTHS_FORMAT = 'MMM'
export const SIX_MONTHS_DATE_FNS_FORMAT = 'do MMM'
export const THREE_MONTHS_FORMAT = 'Do MMM'
export const THREE_MONTHS_DATE_FNS_FORMAT = 'do MMM'
export const LAST_3_MONTHS = 'Last 3 months'
export const LAST_6_MONTHS = 'Last 6 months'
export const LAST_1_YEAR = 'Last 1 year'
export const LAST_2_YEARS = 'Last 2 years'
export const DEFAULT_NUM_CELLS = 9
export const COLLAPSE_MAX_SHOW = 4
export const NUM_AUDIENCES = 13

export type FormatData = (data: any) => string | number

interface ILineChartData {
  name: string
  color: string
  data: any[]
  marker: any
  dashStyle?: string
}

interface IStackedColumnChartData {
  name: string
  color: any
  data: any[]
}

export type DateRangeType = typeof LAST_3_MONTHS | typeof LAST_6_MONTHS | typeof LAST_1_YEAR | typeof LAST_2_YEARS

export const removeProspectData = (overTimeData: ICustomerDataDateRecord[]) => {
  return overTimeData.map(({ data, ...other}) => {
    return {
      data: data.filter(audienceData => audienceData.audienceID !== PROSPECT),
      ...other
    }
  })
}

export const nullifyZeros = (overTimeData: ICustomerDataDateRecord[], dataKey: string) => {
  return overTimeData.map(({ data, ...other}) => {
    return {
      data: data.map(dataPoint => ({
        ...dataPoint,
        [dataKey]: dataPoint[dataKey] === 0 ? null : dataPoint[dataKey]
      })),
      ...other
    }
  })
}

export const useDateFormat = (dateRange: DateRangeType | null) => {
  const formats = {
    [LAST_2_YEARS]: TWO_YEARS_FORMAT,
    [LAST_1_YEAR]: ONE_YEAR_FORMAT,
    [LAST_6_MONTHS]: SIX_MONTHS_FORMAT,
    [LAST_3_MONTHS]: THREE_MONTHS_FORMAT,
  }

  return dateRange ? formats[dateRange] : THREE_MONTHS_FORMAT
}

export const useDateFnsFormat = (dateRange: DateRangeType | null) => {
  const formats = {
    [LAST_2_YEARS]: TWO_YEARS_DATE_FNS_FORMAT,
    [LAST_1_YEAR]: ONE_YEAR_DATE_FNS_FORMAT,
    [LAST_6_MONTHS]: SIX_MONTHS_DATE_FNS_FORMAT,
    [LAST_3_MONTHS]: THREE_MONTHS_DATE_FNS_FORMAT,
  }

  return dateRange ? formats[dateRange] : THREE_MONTHS_DATE_FNS_FORMAT
}

export const getCurrentLowAndHighVal = (data: number, lowestVal: number | undefined, highestVal: number | undefined) => {
  let newLowestVal = lowestVal
  let newHighestVal = highestVal

  if (newLowestVal === undefined || data < newLowestVal) {
    newLowestVal = data
  }

  if (newHighestVal === undefined || data > newHighestVal) {
    newHighestVal = data
  }

  return { lowestVal: newLowestVal, highestVal: newHighestVal}
}

export const getMinAndMax = (overTimeData: ICustomerDataDateRecord[], dataKey: string) => {
  let min = 0;
  let max = 0;

  for (const dataSet of overTimeData) {
    for (const audienceData of dataSet.data) {
      const newBoundaries = getCurrentLowAndHighVal(audienceData[dataKey], min, max)
      
      min = newBoundaries.lowestVal
      max = newBoundaries.highestVal
    }
  }

  return { min, max }
}

export const getYAxisFloor = (lowestVal: number | undefined) => {
 return lowestVal ? lowestVal - (lowestVal / BOUNDARY_PADDING_PERC) : DEFAULT_FLOOR
}

export const getYAxisCeil = (highestVal: number | undefined) => {
  return highestVal ? highestVal + (highestVal / BOUNDARY_PADDING_PERC) : DEFAULT_FLOOR
}

export const formatLargeNumber = (num: number, numDecimalFigures = NUM_DECIMAL_FIGURES) => {
  if (numDecimalFigures <= 0) {
    const formattedNumber = num.toString().split('.')[0]
    return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  }

  const float = num.toFixed(numDecimalFigures)
  const arr = float.toString().split('.')

  const formattedNum = arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return `${formattedNum}.${arr[1]}`
}

export const getRowsBase = (industryGroup: string) => {
  const audiences = getAudienceDefinitions(industryGroup)
  return audiences.map(({ name }: { name: string }) => [name])
}

export const getDateHeadings = (overTimeData: any[], dateFormat: string) => {
  const dateHeadings: string[] = []
  for (const dateData of overTimeData) {
    dateHeadings.push(format(new Date(dateData.date), dateFormat))
  }

  return dateHeadings
}

const removeNullColumns = (overTimeData: any[], dataKey: string) => {
  return overTimeData.filter(timeData => timeData.data.some((data: any) => {
    return data[dataKey] !== null && data[dataKey] !== 0
  }))
}

export const getAllCSVData = (overTimeData: any[], dateFormat: string, formatData?: FormatData) => {
  const cleanedOvertimeDate = overTimeData.filter(dataSet => dataSet.data !== null)
  const dateHeadings: string[] = []
  const rows: any[] = [['All customers']]
  for (const dateData of cleanedOvertimeDate) {
    let formattedData = dateData.data
    if (formatData) {
      formattedData = formatData(dateData.data)
    }
    rows[0].push(formattedData)
    dateHeadings.push(format(new Date(dateData.date), dateFormat))
  }
  
  return { rows, headers: [ '', ...dateHeadings] }
}

export const getRelationshipCSVData = (overTimeData: any[], dateFormat: string, dataKey: string, formatData?: FormatData) => {
  const cleanedData = removeNullColumns(overTimeData, dataKey)
  const dateHeadings: string[] = []
  const rows = cleanedData[0] ? cleanedData[0].data.map((relationshipData: any) => [relationshipData.relationship]) : []
  const totals: (string | number)[] = []
  cleanedData.forEach((dataSet, i) => {
    dateHeadings.push(format(new Date(dataSet.date), dateFormat))
    let total = 0

    for (const index of dataSet.data.keys()) {
      const data = dataSet.data[index][dataKey]
      total += data
      let formattedData = data
      if (formatData) {
        formattedData = formatData(data)
      }

      rows[index].push(formattedData)
    }

    let formattedTotal: number | string = total
    if (formatData) {
      formattedTotal = formatData(total)
    }
    totals.push(formattedTotal)
  })

  rows.push(["Total", ...totals])

  return { rows, headers: ['Relationship', ...dateHeadings] }
}

export const getAudienceCSVData = (overTimeData: ICustomerDataDateRecord[], industryGroup: string, dateFormat: string, dataKey: string, formatData?: FormatData, includeTotal = false, shouldRemoveProspect = false) => {
  const cleanedData = removeNullColumns(overTimeData, dataKey)
  const dateHeadings: string[] = []
  const rows = getRowsBase(industryGroup)
  if (shouldRemoveProspect) {
    rows.shift()
  }

  if (includeTotal) {
    rows.push(["Total"])
  }

  cleanedData.forEach((dataSet, i) => {
    dateHeadings.push(format(new Date(dataSet.date), dateFormat))
    let total = 0
    for (const audienceData of dataSet.data) {
      total += audienceData[dataKey]
      let formattedData = audienceData[dataKey]
      if (formatData) {
        formattedData = formatData(audienceData[dataKey])
      }

      const indexOffset = shouldRemoveProspect ? INDEX_OFFSET + 1 : INDEX_OFFSET
      rows[audienceData.audienceID - indexOffset].push(formattedData)
    }

    if (includeTotal) {
      let formattedTotal: number | string = total
      if (formatData) {
        formattedTotal = formatData(total)
      }
      rows[rows.length - 1].push(formattedTotal)
    }
  })

  return { rows, headers: ['Audience', ...dateHeadings] }
}

export const getSegementCSVData = (overTimeData: any[], dateFormat: string, dataKey: string, formatData?: FormatData, includeTotal = false) => {
  const cleanedData = removeNullColumns(overTimeData, dataKey)
  const dateHeadings: string[] = []
  const rows = []
  const totals = []
  for (const index of cleanedData.keys()) {
    const data = cleanedData[index].data
    dateHeadings.push(format(new Date(cleanedData[index].date), dateFormat))
    let total = 0

    for (const dataIndex of data.keys()) {
      if (index === 0) {
        rows.push([data[dataIndex].segmentName])
      }

      total += data[dataIndex][dataKey]
      let formattedData = data[dataIndex][dataKey]
      if (formatData) {
        formattedData = formatData(data[dataIndex][dataKey])
      }

      rows[dataIndex].push(formattedData)
    }

    let formattedTotal: number | string = total
    if (formatData) {
      formattedTotal = formatData(total)
    }
    totals.push(formattedTotal)
  }

  if (includeTotal) {
    rows.push(["Total", ...totals])
  }

  return { rows, headers: ['Lifestage', ...dateHeadings] }
}

export const getAudienceLineChartData = (overTimeData: any[], dataKey: string, industryGroup: string, indexOffset = 0) => {
  const audiences = getAudienceDefinitions(industryGroup)
  const audienceNames = audiences.map(({ name }: { name: string }) => [name])
  const audienceData: ILineChartData[] = []
  let lowestVal;
  let highestVal;
  for (const dataSet of overTimeData) {
    for (const datePointData of dataSet.data) {
      const index = datePointData.audienceID - (1 + indexOffset)
      if (!audienceData[index]) {
        audienceData[index] = { 
          "name": audienceNames[datePointData.audienceID - 1], 
          color: colorsBySegmentID[datePointData.segmentID],
          dashStyle: LINE_DASH_STYLES_BY_AUDIENCE_ID[datePointData.audienceID],
          data: [],
          marker: {
            symbol: "circle"
          }
        }
      }

      audienceData[index].data.push(datePointData[dataKey])

      const newBoundaries = getCurrentLowAndHighVal(datePointData[dataKey], lowestVal, highestVal)
      lowestVal = newBoundaries.lowestVal
      highestVal = newBoundaries.highestVal
    }
  }

  return { data: audienceData, lowestVal, highestVal }
}

export const getSegmentsLineChartData = (overTimeData: any[], dataKey: string, indexOffset = 1) => {
  const segmentData: ILineChartData[] = []
  let lowestVal;
  let highestVal;
  for (const dataSet of overTimeData) {
    for (const datePointData of dataSet.data) {
      const index = datePointData.segmentID - indexOffset
      if (!segmentData[index]) {
        segmentData[index] = { 
          "name": datePointData.segmentName, 
          color: segmentColors[datePointData.segmentName],
          // specify dashStyle to remove bug that occurs when switching
          // to segment view from audience view
          dashStyle: "Solid",
          data: [],
          marker: {
            symbol: "circle"
          }
        }
      }

      segmentData[index].data.push(datePointData[dataKey])

      const newBoundaries = getCurrentLowAndHighVal(datePointData[dataKey], lowestVal, highestVal)
      lowestVal = newBoundaries.lowestVal
      highestVal = newBoundaries.highestVal
    }
  }

  return { data: segmentData, lowestVal, highestVal }
}

export const getAllChartData = (overTimeData: any[], dateFormat: string) => {
  const data = []
  let lowestVal;
  let highestVal;
  for (const dataSet of overTimeData) {
    const dataItem = {
      name: format(new Date(dataSet.date), dateFormat),
      y: dataSet.data
    }

    const newBoundaries = getCurrentLowAndHighVal(dataSet.data, lowestVal, highestVal)
    lowestVal = newBoundaries.lowestVal
    highestVal = newBoundaries.highestVal

    data.push(dataItem)
  }

  return { data, lowestVal, highestVal }
}

export const getRelationshipBarChartData = (overTimeData: any[], dataKey: string) => {
  const data: IStackedColumnChartData[] = []
  for (const dataSet of overTimeData) {
    for (const datePointData of dataSet.data) {
      const index = data.findIndex(({ name }: any) => name === datePointData.relationship)
      
      if (index === -1) {
        data.push({ 
          "name": datePointData.relationship, 
          color: RELATIONSHIP_COLOURS[datePointData.relationship] || theme.colorRed,
          data: [datePointData[dataKey]] as any[],
        })
      } else {
        data[index].data.push(datePointData[dataKey])
      }
    }
  }

  return { data: data.reverse(), colors: [RELATIONSHIP_COLOURS.Archive, RELATIONSHIP_COLOURS.Active, RELATIONSHIP_COLOURS.Prospect] }
}

export const getSegmentsBarChartData = (overTimeData: any[], dataKey: string) => {
  const segmentData: IStackedColumnChartData[] = []
  const colors: string[] = []
  let lowestVal;
  let highestVal;
  for (const dataSet of overTimeData) {
    for (const datePointData of dataSet.data) {
      const index = datePointData.segmentID - 1
      if (!segmentData[index]) {
        segmentData[index] = { 
          "name": datePointData.segmentName, 
          color: segmentColors[datePointData.segmentName],
          data: [],
        }
        colors.push(segmentColors[datePointData.segmentName])
      }

      segmentData[index].data.push(datePointData[dataKey])

      const newBoundaries = getCurrentLowAndHighVal(datePointData[dataKey], lowestVal, highestVal)
      lowestVal = newBoundaries.lowestVal
      highestVal = newBoundaries.highestVal
    }
  }

  return { data: segmentData.reverse(), colors: colors.reverse() }
}

export const getAudienceBarChartData = (overTimeData: any[], dataKey: string, industryGroup: string) => {
  const audiences = getAudienceDefinitions(industryGroup)
  const audienceNames = audiences.map(({ name }: { name: string }) => [name])
  const audienceData: IStackedColumnChartData[] = []

  for (const dataSet of overTimeData) {
    for (const datePointData of dataSet.data) {
      const index = datePointData.audienceID - 1
      if (!audienceData[index]) {
        audienceData[index] = { 
          "name": audienceNames[index], 
          color: BAR_CHART_FILL_BY_AUDIENCE_ID[datePointData.audienceID],
          data: [],
        }
      }

      audienceData[index].data.push(datePointData[dataKey])
    }
  }

  return { data: audienceData.reverse()  }
}