import { IStoreState } from "../types";
import { map } from 'lodash';
import segmentColors from '../../config/segmentColors';
import stringifyUntargetedOpportunity from '../utils/helpers/stringifyUntargetedOpportunity';

const NUM_DECIMAL_PLACES = 2

export const isLoading = ({ segment}: IStoreState) => !!segment.is_data_requested;

export const customerLifecycleDistribution = ({ segment}: IStoreState) => ({
  total: segment.customers.total,
  contactableTotal: segment.customers.contactableTotal,
  emailTotal: segment.customers.emailTotal,
  phoneTotal: segment.customers.phoneTotal,
  allCustomerData: map(segment.customers.segments, item => ({
    name: `${item.segment}: ${item.count.toLocaleString('en')} (${Math.round(item.percentage)}%)`,
    y: item.count,
    color: segmentColors[item.segment],
  })),
  marketableEmail: map(segment.customers.segments, item => ({
    name: `${item.segment}: ${item.contactableEmailCount.toLocaleString('en')} (${Math.round(item.emailPercentage)}%)`,
    y: item.contactableEmailCount,
    color: segmentColors[item.segment],
  })),
  marketablePhone: map(segment.customers.segments, item => ({
    name: `${item.segment}: ${item.contactablePhoneCount.toLocaleString('en')} (${Math.round(item.phonePercentage)}%)`,
    y: item.contactablePhoneCount,
    color: segmentColors[item.segment],
  }))
});

export const opportunityValue = ({ segment }: IStoreState) => map(segment.opportunity_value, item => ({
  name: item.segment,
  y: item.value < 0 ? 0 : parseFloat((item.value / item.count).toFixed(NUM_DECIMAL_PLACES)),
  color: segmentColors[item.segment],
}));

export const cardData = ({ segment }: IStoreState) => map(segment.segment_cards, card => ({
  segment_id: card.segment_id,
  sizeHasChangedData: {
    sizeFirst: {
      days: 7,
      size: card.size_last_7.value,
      sign: card.size_last_7.rate,
    },
    sizeSecond: {
      days: 30,
      size: card.size_last_30.value,
      sign: card.size_last_30.rate,
    }
  },
  sizeValue: card.size_total,
  proportionTotal: card.size_total_proportion,
  untargetedOpportunitySize: stringifyUntargetedOpportunity(card.untagged_opportunity ),
}));

export const currentSegmentSummary = ({ segment }: IStoreState) => ({
  segment_id: segment.current_segment.segment_id,
  sizeHasChangedData: {
    sizeFirst: {
      days: 7,
      size: segment.current_segment.size_last_7.value,
      sign: segment.current_segment.size_last_7.rate,
    },
    sizeSecond: {
      days: 30,
      size: segment.current_segment.size_last_30.value,
      sign: segment.current_segment.size_last_30.rate,
    }
  },
  sizeValue: segment.current_segment.size_total,
  avgOrderValue: segment.current_segment.avgOrderValue,
  frequency: segment.current_segment.frequency,
  recency: segment.current_segment.recency,
  spend: segment.current_segment.spend,
  proportionTotal: segment.current_segment.size_total_proportion,
  untargetedOpportunitySize: stringifyUntargetedOpportunity(segment.current_segment.untagged_opportunity ),
});

export const audiencesSummary = ({ segment }: IStoreState) => map(segment.audiences_cards, card => ({
  ...card,
  untargeted_opportunity: stringifyUntargetedOpportunity(card.untargeted_opportunity),
}));
