import * as React from 'react';
import theme from '../../assets/css/theme';
import styled from 'styled-components';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';

interface IProps {
  value: number | string,
}

const MoneyValue = styled.div`
  font-family: ${theme.ffSansProRegular};
  font-size: 1.1rem;
  font-weight: 900;
  text-align: left;
  color: ${theme.colorDarkestGray};
`;

const Label = styled.div`
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
  font-size: 0.85rem;
  text-align: left;
  color: ${theme.colorDarkGray};
  opacity: 0.7;
`;

const OpportunityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
`;

const MoneyWrapper = styled.div`
  padding-left: 0.625rem;
  margin-bottom: 1.063rem;
`;

export class UntargetedOpportunitySize extends React.Component<IProps> {
  public render() {
    return (
      <OpportunityWrapper>
          <MonetizationOnOutlinedIcon />
        <MoneyWrapper>
          <MoneyValue>{this.props.value}</MoneyValue>
          <Label>Revenue Opportunity</Label>
        </MoneyWrapper>
      </OpportunityWrapper>
    );
  }
}

export default UntargetedOpportunitySize;
