import React, { createContext, useState } from 'react'

export type DateRangeType = typeof LAST_3_MONTHS | typeof LAST_6_MONTHS | typeof LAST_1_YEAR | typeof LAST_2_YEARS

export const LAST_3_MONTHS = 'Last 3 months'
export const LAST_6_MONTHS = 'Last 6 months'
export const LAST_1_YEAR = 'Last 1 year'
export const LAST_2_YEARS = 'Last 2 years'

export interface  IFilterType {
  dateRange: DateRangeType
  setDateRange: (value: DateRangeType) => void;
}

const DEFAULT_CONFIG = {
  dateRange: LAST_6_MONTHS as typeof LAST_6_MONTHS,
  setDateRange: () => {
    return
  }
}


export const FilterContext = createContext<IFilterType>(DEFAULT_CONFIG)

export const FiltersProvider = ({ children }: { children: React.ReactNode }) => {
  const [dateRange, setDateRange] = useState<DateRangeType>(LAST_6_MONTHS)

  return (
    <FilterContext.Provider value={{ dateRange, setDateRange }}>
      {children}
    </FilterContext.Provider>
  )
}