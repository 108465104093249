import React from 'react'
import styled from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import format from 'date-fns/format';
import subMonths from 'date-fns/subMonths';
import subWeeks from 'date-fns/subWeeks';
import isSameDay from 'date-fns/isSameDay';
import { getToday } from '@loypal/lib-growth';

import theme from '../../assets/css/theme';
import { ReportingDateRangePicker, IOnDatesChange, ButtonSmall, Checkbox } from '../Form';
import { DATE_FNS_DATE_FORMAT } from '../../config/datetime';

const SIX_MONTHS = 6;
const THREE_MONTHS = 3;
const ONE_MONTHS = 1;
const ONE_WEEK = 1;

type focusedInputShape = 'startDate' | 'endDate' | null;

interface IDateRangePreset {
  text: string;
  start: Date;
  end: Date;
}

interface ICampaignFilterProps {
  startDate: any;
  endDate: any;
  focusedDateInput: any;
  didDatesChanged: boolean;
  excludePartialCampaigns: boolean;
  excludeActiveCampaigns: boolean;
  didExcludeChanged: boolean;
  setStartDate(val: any): void;
  setEndDate(val: any): void;
  setFocusedDateInput(val: any): void;
  setDidDatesChanged(val: boolean): void;
  setExcludePartialCampaigns(val: boolean): void;
  setExcludeActiveCampaigns(val: boolean): void;
  setDidExcludeChanged(val: boolean): void;
  fetchCampaigns(opt: any): void;
  checkAllowedDates(day: any): boolean;
}

export const CampaignFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  focusedDateInput,
  setFocusedDateInput,
  didDatesChanged,
  setDidDatesChanged,
  excludePartialCampaigns,
  setExcludePartialCampaigns,
  excludeActiveCampaigns,
  setExcludeActiveCampaigns,
  didExcludeChanged,
  setDidExcludeChanged,
  fetchCampaigns,
  checkAllowedDates,
}: ICampaignFilterProps) => {

  const getDatepickerOrientation = () => {
    const widthSizeForVertical = 1024;
    return (window.innerWidth < widthSizeForVertical) ? 'vertical' : 'horizontal';
  }

  const checkDidStartDateChange = (dates: IOnDatesChange) => {
    const { startDate: selectedStartDate } = dates;
    if (!selectedStartDate || !startDate) {
      return false;
    }

    return !isSameDay(startDate, selectedStartDate)
  }

  const checkDidEndDateChange = (dates: IOnDatesChange) => {
    const { endDate: selectedEndDate } = dates;
    if (!endDate || !selectedEndDate) {
      return false;
    }

    return !isSameDay(endDate, selectedEndDate)
  }

  const checkDidDatesChanged = (dates: IOnDatesChange) => {
    const { startDate: selectedStartDate, endDate: selectedEndDate } = dates;
    if (startDate && endDate && selectedStartDate && selectedEndDate) {
      return (checkDidStartDateChange(dates) || checkDidEndDateChange(dates) || didDatesChanged);
    }

    return false;
  }

  const onDatesChange = (dates: IOnDatesChange) => {
    if (checkDidDatesChanged(dates) && dates.startDate && dates.endDate) {
      setStartDate(format(dates.startDate, DATE_FNS_DATE_FORMAT))
      setEndDate(format(dates.endDate, DATE_FNS_DATE_FORMAT))
      setDidDatesChanged(true)
      return
    }

    if (checkDidStartDateChange(dates) && dates.startDate) {
      setStartDate(format(dates.startDate, DATE_FNS_DATE_FORMAT))
      setDidDatesChanged(true)
    }

    if (checkDidEndDateChange(dates) && dates.endDate) {
      setEndDate(format(dates.endDate, DATE_FNS_DATE_FORMAT))
      setDidDatesChanged(true)
    }
  }

  const onFocusChange = (focusedInput: focusedInputShape) => {
    setFocusedDateInput(focusedInput)
  }

  const onDateRangePickerClose = (dates: IOnDatesChange) => {
    if (checkDidDatesChanged(dates) || didExcludeChanged) {
      fetchCampaigns({
        // @ts-ignore - we've checked value is not null above
        start_date: format(dates.startDate, DATE_FNS_DATE_FORMAT),
        // @ts-ignore - we've checked value is not null above
        end_date: format(dates.endDate, DATE_FNS_DATE_FORMAT),
        exclude_partial_campaigns: excludePartialCampaigns ? 1 : 0,
        exclude_active_campaigns: excludeActiveCampaigns ? 1 : 0,
      });
      setDidDatesChanged(false)
      setDidExcludeChanged(false)
    }
  }

  const presetButtonClick = (dates: IOnDatesChange) => {
    setFocusedDateInput(null)
    onDateRangePickerClose(dates);
    onDatesChange(dates);
    setDidDatesChanged(false)
  }

  const toggleExcludePartialCampaigns = (event: any) => {
    const isChecked = event.target.checked
    if (excludePartialCampaigns !== isChecked) {
      setExcludePartialCampaigns(isChecked)
      setDidExcludeChanged(true)
    }
  }

  const toggleExcludeActiveCampaigns = (event: any) => {
    const isChecked = event.target.checked
    if (excludeActiveCampaigns !== isChecked) {
      setExcludeActiveCampaigns(isChecked)
      setDidExcludeChanged(true)
    }
  }

  const renderCalendarInfo = () => {
    const today = getToday();
    const datesPresets: IDateRangePreset[] = [
      {
        text: 'Last 6 month',
        start: subMonths(today, SIX_MONTHS),
        end: today,
      },
      {
        text: 'Last 3 month',
        start: subMonths(today, THREE_MONTHS),
        end: today,
      },
      {
        text: 'Last month',
        start: subMonths(today, ONE_MONTHS),
        end: today,
      },
      {
        text: 'Last week',
        start: subWeeks(today, ONE_WEEK),
        end: today,
      },

    ];

    return (
      <>
        <DayPickerTitle>Filter Campaigns</DayPickerTitle>
        <CheckBoxesContainer>
          <CheckboxContainer>
          <StyledFormControlLabel
              control={
                <Checkbox 
                  checked={excludePartialCampaigns}
                  onChange={toggleExcludePartialCampaigns}
                />
              }
              label="Exclude campaigns that start or end outside of date range"
            />
          </CheckboxContainer>
          <CheckboxContainer>
            <StyledFormControlLabel
              control={
                <Checkbox 
                checked={excludeActiveCampaigns}
                onChange={toggleExcludeActiveCampaigns}
                />
              }
              label="Exclude active campaigns"
            />
          </CheckboxContainer>
        </CheckBoxesContainer>
        <PresetDateRangePickerPanel>
          {datesPresets.map(({ text, start, end }) => {
            const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate);
            return (
              <PresetButton
                key={text}
                className={isSelected && 'isSelected'}
                /* tslint:disable-next-line */
                onClick={() => presetButtonClick({ startDate: start, endDate: end })}
              >
                {text}
              </PresetButton>
            );
          })}
        </PresetDateRangePickerPanel>
      </>
    );
  }

  return (
    <ReportingDateRangePicker
      startDate={startDate}
      startDateId="campaign_start_date_id"
      endDate={endDate}
      endDateId="campaign_end_date_id"
      onDatesChange={onDatesChange}
      focusedInput={focusedDateInput}
      onFocusChange={onFocusChange}
      onClose={onDateRangePickerClose}
      customArrowIcon={<CustomArrowIcon>to</CustomArrowIcon>}
      orientation={getDatepickerOrientation()}
      renderCalendarInfo={renderCalendarInfo}
      displayFormat="DD-MMM-YY"
      anchorDirection="right"
      hideKeyboardShortcutsPanel={true}
      verticalSpacing={0}
      isOutsideRange={checkAllowedDates}
    />
  )
}

const CustomArrowIcon = styled.span`
  font-family: ${theme.ffSansProRegular};
  font-size: 0.8125rem;
  font-weight: normal;
  color: ${theme.colorDarkGray};
  padding: 8px 11px 6px;
`;

const DayPickerTitle = styled.div`
  font-family: ${theme.ffSansProRegular};
  font-size: 1.125rem;
  color: ${theme.colorDarkGray};
  padding: 20px 20px 0 20px;
`;

const PresetButton = styled(ButtonSmall)`
  &&.isSelected {
        background-color: ${theme.colorWhite};
    border: 1px solid ${theme.colorLightBlue};
    color: ${theme.colorDarkGray};
  }
`;

const CheckBoxesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 12px 12px 12px 24px;
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`

const PresetDateRangePickerPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 1.375rem 0.6875rem 1.375rem;
  && > button:not(:first-child) {
    margin-top: 10px;
  }
  
  @media ${theme.media.laptop} {
    flex-direction: row;
    && > button:not(:first-child) {
      margin-top: 0;
      margin-left: 10px;
    }
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &&& {
    align-items: flex-start;
  }

  &&& > span.MuiTypography-root {
    font-size: 14px;
    margin-left: 4px;
    margin-top: 4px;
  }
`