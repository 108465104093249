import React, { useMemo, useState } from 'react';
import { ICustomerDataDateRecord } from '../../modules/types'
import { useTotalRelationshipData } from '../../utils/hooks/use-total-relationship-data'
import { useTotalSegmentData } from '../../utils/hooks/use-total-segment-data'
import { Report } from '../../components/Report'
import { useClientCustomization } from '../../utils/hooks/use-client-customization' 
import { StackedColumnChart } from '../../components/Charts/StackedColumnChart'
import { 
  DateRangeType, 
  useDateFnsFormat,
  getSegmentsBarChartData, 
  getRelationshipBarChartData, 
  getAudienceCSVData,
  getAudienceBarChartData,
  getSegementCSVData,
  formatLargeNumber,
  getRelationshipCSVData,
  getDateHeadings
} from '../../utils/reports'

const RELATIONSHIP = "Relationship"
const LIFESTAGES = "Lifestages"
const AUDIENCES = "Audiences"
const NUM_DECIMAL_POINTS = 0

interface IProps {
  dateRange: DateRangeType,
  overTimeData: ICustomerDataDateRecord[]
}

export const CustomerComposition = ({ overTimeData, dateRange }: IProps) => {
  const [dataGrouping, setDataGrouping] = useState(RELATIONSHIP)
  const { industryGroup } = useClientCustomization()
  const dateFormat = useDateFnsFormat(dateRange)

  const getTotalRelationshipData = useTotalRelationshipData();
  const getTotalSegmentData = useTotalSegmentData()

  const formatData = (data: any) => {
    return data === 0 ? 'n/a' : `${formatLargeNumber(data, NUM_DECIMAL_POINTS)}`
  }
  
  const totalRelationshipDataOverTime = useMemo(() => {
    if (!overTimeData) {
      return null
    }
    return overTimeData.map(audienceData => {
      const avgData = getTotalRelationshipData(audienceData.data, 'count')
      return { ...audienceData, data: avgData }
    })
    // eslint-disable-next-line
  }, [overTimeData])
  
  const totalSegmentDataOverTime = useMemo(() => {
    if (!overTimeData) {
      return null
    }
    return overTimeData.map(audienceData => {
      const totalSegmentData = getTotalSegmentData(audienceData.data, 'count');
      return { ...audienceData, data: totalSegmentData }
    })
    // eslint-disable-next-line
  }, [overTimeData])

  const chartInfo = useMemo(() => {
    const defaultChartinfo = { data: [], colors: [] }
    if (dataGrouping === LIFESTAGES) {
      return totalSegmentDataOverTime ? getSegmentsBarChartData(totalSegmentDataOverTime, "count") : defaultChartinfo
    }

    if (dataGrouping === AUDIENCES) {
      return overTimeData ? getAudienceBarChartData(overTimeData, "count", industryGroup) : defaultChartinfo
    }

    return totalRelationshipDataOverTime ? getRelationshipBarChartData(totalRelationshipDataOverTime || [], "count") : defaultChartinfo
    // eslint-disable-next-line
  }, [dataGrouping, overTimeData])

  const tableInfo = useMemo(() => {
    const defaultTableInfo = { rows: [], headers: [], dateHeadings: [] }
    if (dataGrouping === LIFESTAGES) {
      return totalSegmentDataOverTime ? getSegementCSVData(totalSegmentDataOverTime, dateFormat, "count", formatData, true) : defaultTableInfo
    }

    if (dataGrouping === AUDIENCES) {
      return overTimeData ? getAudienceCSVData(overTimeData, industryGroup, dateFormat, "count", formatData, true) : defaultTableInfo
    }

    return totalRelationshipDataOverTime ? getRelationshipCSVData(totalRelationshipDataOverTime, dateFormat, "count", formatData) : defaultTableInfo
    // eslint-disable-next-line
  }, [dataGrouping, overTimeData])

  const dateHeadings = getDateHeadings(overTimeData, dateFormat)
  const { rows, headers } = tableInfo
  const csvData = [
    headers,
    ...rows
  ];

  const { data: chartData } = chartInfo

  const showRelationshipData = () => {
    setDataGrouping(RELATIONSHIP)
  }

  const showSegmentsData = () => {
    setDataGrouping(LIFESTAGES)
  }

  const showAudienceData = () => {
    setDataGrouping(AUDIENCES)
  }

  return (
    <Report
      title="Customer Composition"
      headers={headers}
      rows={rows}
      csvData={csvData}
      shouldBoldLastTableRow={true}
      dataGroupOpts={[
        {
          name: RELATIONSHIP,
          isActive: dataGrouping === RELATIONSHIP,
          onClick: showRelationshipData
        },
        {
          name: LIFESTAGES,
          isActive: dataGrouping === LIFESTAGES,
          onClick: showSegmentsData
        },
        {
          name: AUDIENCES,
          isActive: dataGrouping === AUDIENCES,
          onClick: showAudienceData
        },
      ]}
      guideSlides={
        [{
        id: 1, 
        body: "Monitor ‘Customer Composition’ to understand the distribution of customers across the three categories of Prospect, Active and Archive." 
      }, {
        id: 2, 
        body: "Prospects are customers that are on the base, but have never made a purchase."
      }, {
        id: 3, 
        body: "Actives are customers that have made a purchase, and will fall into a Lifestage of either Welcome, Engage, Nurture or Revive." 
      }, {
        id: 4, 
        body: "Archives are customers that have previously purchased, but have not purchased in so long that they are no longer deemed active." 
      }, {
        id: 5, 
        body: "The objective is to see ‘Active’ customers becoming a bigger proportion of the total customer base." 
      }]
    }
    >
      <StackedColumnChart
        // re-render when data grouping changes
        // fixes a bug with highcharts when data changes when already mounted
        key={`customer-composition-${dataGrouping}`}
        data={chartData}
        categories={dateHeadings}
        shouldShowDataLabels={false}
        height="300px"
      /> 
    </Report>
  )
}