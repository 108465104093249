export interface IData {
  [key: string]: any
}


export const useAvgData = () => {
  const getAvgData = (data: IData[], dataKey: string) => {
    const cleanedData = data.filter(audienceData => {
      return audienceData.hasOwnProperty(dataKey)
    })
    
    let total: null | number = null
    let countNotNull = 0
    for (const dataPoint of cleanedData) {
      if (dataPoint[dataKey] !== null) {
        countNotNull++
        if (total === null) {
          total = 0
        }

        total += dataPoint[dataKey]
      }
    }

    const avg = total === null ? null : total / countNotNull

    return avg
  }

  return getAvgData
}