import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { DateRangePicker } from 'react-dates';
import { Moment } from 'moment';
import { InputLabel } from '../components';
import theme from '../../../assets/css/theme';
import { DateFilterContext } from '../utils';

const VERTICAL_WIDTH_SIZE = 1024;

export type focusedInputShape = 'startDate' | 'endDate';

interface IOnDatesChange {
  startDate: Moment | null;
  endDate: Moment | null;
}

interface IProps {
  audience_name: string;
  isNameCreatedManually: boolean;
  focusedDateInput: focusedInputShape | null;
  setFocusedDateInput: React.Dispatch<React.SetStateAction<focusedInputShape | null>>;
  setCampaignName: React.Dispatch<React.SetStateAction<string>>
}

export const DateInput = ({
  audience_name,
  isNameCreatedManually,
  focusedDateInput,
  setFocusedDateInput,
  setCampaignName
}: IProps) => {
  const [windowWidth, setWindowWidth] = useState(0)
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = useContext(DateFilterContext);

  useEffect(() => {
    updateWindowWidth();
    window.addEventListener('resize', updateWindowWidth)
    return cleanUp
    // eslint-disable-next-line
  }, [])

  const cleanUp = () => {
    window.removeEventListener('resize', updateWindowWidth)
  }

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }

  const renderCalendarTitle = () => {
    return <DayPickerTitle>Select Campaign Date Range</DayPickerTitle>
  }

  const onFocusChange = (newFocusedDateInput: focusedInputShape | null) => {
    setFocusedDateInput(newFocusedDateInput)
  }

  const onDatesChange = (arg: IOnDatesChange) => {
    const { startDate: newStartDate, endDate: newEndDate } = arg;
    if (!isNameCreatedManually) {
      const generatedCampaignName = generateCampaignName(audience_name, newStartDate, newEndDate);
      setCampaignName(generatedCampaignName)
    }
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const getDatepickerOrientation = () => {
    return (windowWidth < VERTICAL_WIDTH_SIZE) ? 'vertical' : 'horizontal';
  }

  const generateCampaignName = (audienceName: string, finalStartDate?: Moment | null, finalEndDate?: Moment | null): string => {
    const dateFormat = 'YYYYMMDD';
    let formattedCampaignName = audienceName.replace(/\s/g, '_');
    if (finalStartDate && finalEndDate) {
      formattedCampaignName = formattedCampaignName + '_' + finalStartDate.format(dateFormat) + '_' + finalEndDate.format(dateFormat);
    }
    return formattedCampaignName;
  }

  return (
    <DatePickerContainer>
      <InputLabel>Campaigns Dates</InputLabel>
    <CampaignDateRangePicker>
        <DateRangePicker
          startDate={startDate}
          startDateId="campaign_start_date_id"
          endDate={endDate}
          endDateId="campaign_end_date_id"
          onDatesChange={onDatesChange}
          focusedInput={focusedDateInput}
          onFocusChange={onFocusChange}
          customArrowIcon={<CustomArrowIcon>to</CustomArrowIcon>}
          orientation={getDatepickerOrientation()}
          renderCalendarInfo={renderCalendarTitle}
          displayFormat="DD-MMM-YY"
        />
      </CampaignDateRangePicker>
    </DatePickerContainer>
  )
}

const DatePickerContainer = styled.div`
display: flex;
margin-bottom: 20px;
@media (max-width: 660px) and (min-width: 420px) {
  flex-direction: column;
}
`

const CampaignDateRangePicker = styled.div`
display: flex;
flex-wrap: wrap;
`

const CustomArrowIcon = styled.span`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
color: ${theme.colorDarkGray};
font-size: 0.9375rem;
color: ${theme.colorDarkGray};
`

const DayPickerTitle = styled.div`
font-family: ${theme.ffSansProRegular};
font-size: 1.125rem;
color: ${theme.colorDarkGray};
padding: 20px;
`
