import * as React from 'react';
import { MoneyBagIcon } from '../Icons';
import theme from '../../assets/css/theme';
import styled from 'styled-components';

type text_sizes = 'small' | 'big' | undefined;

interface ITextSize {
  text_size?: text_sizes;
}

interface IProps extends ITextSize {
  value: number,
}

const SizeValue = styled.div`
font-family: ${theme.ffInterRegular};
font-weight: bold;
font-size: 1.125rem;
text-align: left;
color: ${theme.colorDarkGray};
`;

const Label = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 0.85rem;
text-align: left;
color: ${theme.colorDarkGray};
opacity: 0.7;
`;

const AvailableWrapper = styled.div`
padding-left: 0.425rem;
margin-bottom: 1.063rem;
`;

const SizeWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-content: center;
`;

export class AvailableSize extends React.Component<IProps> {
  public render() {
    return (
      <SizeWrapper>
          <MoneyBagIcon color={theme.colorDarkestGray}/>
        <AvailableWrapper>
          <SizeValue>{this.props.value.toLocaleString('en')}</SizeValue>
            <Label>Available Customers</Label>
        </AvailableWrapper>
      </SizeWrapper>
    );
  }
}

export default AvailableSize;
