import * as React from 'react';
import { renderToString } from 'react-dom/server'
import Highcharts, { Chart, PointOptionsObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { IChartPoint } from './types';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import merge from 'lodash/merge';

const chartSize = 230;
const CHART_SIZE_DIVIDER = 2;
const MARGIN_LEFT = 23;
const MARGIN_LEFT_RATE = 2;

const Container = styled.div`
  background-color: ${theme.colorWhite};
  box-shadow: ${theme.boxShadow};
  @media (min-width: 1280px) and (max-width: 1400px) {
    display: flex;
    flex-direction: column;
    }
`;

const LegendItem = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.titleColor};

  @media ${theme.media.mobileL} {
    flex-direction: row;
    min-width: 320px;
  }

`;

const LegendItemTitle = styled.div`
  font-size: 0.8125rem;
  align-self: flex-start;
  margin-left: 120px;
  font-family: ${theme.ffInterRegular};

  :before {
    display: inline-flex;
    content: "";
    margin-right: 8px;
    height: 14.6px;
    width: 14.6px;
    border-radius: 50%;
    border: solid 3px ${(props: { symbolColor: string }) => props.symbolColor || ''};
    background-color: ${theme.chartBackground};
    align-self: flex-start;
  }
`;

const Title = styled.div`
  color: ${theme.colorDarkGray};
  font-size: 1.125rem;
`;

const Subtitle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1rem;
  color: ${theme.subtitleColor}
`;

interface IState {
  options: Highcharts.Options,
}

interface IProps {
  title: string,
  subtitle: string | JSX.Element,
  data: PointOptionsObject[]
}

interface IPieChart extends Chart {
  options: {
    responsive: {
      rules: Array<{
        condition: {
          maxWidth: number
        }
      }>
    }
  },
  clipBox: {
    width: number
  },
  spacingBox: {
    x: number,
    y: number,
    width: number
    height: number
  }
}

export class DonutChart extends React.Component<IProps, IState> {

  public static getDerivedStateFromProps(nextProps: IProps, prevState: IProps) {
    if (prevState.data !== nextProps.data) {
      return merge(prevState, {
        options: {
          series: [{
            type: 'pie',
            data: nextProps.data,
          }],
          subtitle: {
            text: renderToString(<Subtitle>{nextProps.subtitle}</Subtitle>),
          }
        },
      });
    }
    return null;
  }

  public state: IState = {
    options: {
      title: {
        text: renderToString(<Title>{this.props.title}</Title>),
        align: 'left',
        verticalAlign: 'top',
        x: 10,
        y: 21,
      },
      subtitle: {
        useHTML: true,
        text: renderToString(<Subtitle>{this.props.subtitle}</Subtitle>),
      },
      chart: {
        height: 352,
        marginLeft: MARGIN_LEFT,
        style: {
          fontFamily: theme.ffInterRegular
        },
        events: {
          redraw: e => this.redraw(e.target as unknown as IPieChart),
          load: e => this.redraw(e.target as unknown as IPieChart),
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        symbolPadding: 0,
        symbolWidth: 0,
        symbolRadius: 0,
        useHTML: true,
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        itemMarginTop: 10,
        itemMarginBottom: 10,
        x: -32,
        labelFormatter() {
          const {name, color, legendItem} = this as IChartPoint;
          if (legendItem.symbol) {
            legendItem.symbol.element.style.display = 'none';
          }

          return renderToString(<LegendItem>
            <LegendItemTitle symbolColor={color as string}>{name}</LegendItemTitle>
          </LegendItem>)
        }
      },
      plotOptions: {
        pie: {
          center: [chartSize / CHART_SIZE_DIVIDER, null],
          innerSize: '70%',
          size: chartSize ,
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          borderWidth: 0
        },
        series: {
          allowPointSelect: false,
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              enabled: false
            },
            select: {
              enabled: false
            }
          }
        }
      },
      series: [{
        type: 'pie',
        data: this.props.data,
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal',
              x: 0,
            },
            title: {
              align: 'left',
            },
            chart: {
              height: 600,
              spacingLeft: 0,
            },
            plotOptions: {
              pie: {
                center: [null, null],
                size: chartSize
              }
            }
          }
        }]
      }
    }
  };

  public redraw(chart: IPieChart) {

    let left: string;

    const CLIP_BOX_WIDTH_DIVIDER = 2;
    const SPACING_BOX_EXP = 2;
    const TOP_DIVIDER = 2;

    // @ts-ignore
    const { plotBox, plotTop, clipBox, chartWidth, options: {responsive: {rules: [rule]}}, spacingBox, subtitle: {element: subtitleEl} } = chart;

    if (chartWidth <= rule.condition.maxWidth) {
      left = `${(clipBox.width + MARGIN_LEFT * MARGIN_LEFT_RATE ) / CLIP_BOX_WIDTH_DIVIDER / theme.htmlFontSize}rem`;
    } else {
      left = `${((spacingBox.x * SPACING_BOX_EXP) + (chartSize / CHART_SIZE_DIVIDER) + MARGIN_LEFT) / theme.htmlFontSize}rem`;
    }

    const dy = (plotBox.height - chartSize) / TOP_DIVIDER;

    const top: string = `${ (((chartSize / TOP_DIVIDER) + plotTop) + dy) / theme.htmlFontSize}rem`;

    subtitleEl.style.left = left;
    subtitleEl.style.top = top;
  }

  public componentDidMount() {
    window.dispatchEvent(new Event('resize'));
  }

  public render() {
    return (<Container>
      <HighchartsReact
        highcharts={Highcharts}
        options={this.state.options}
        updateArgs={[true, true, true]}
      />
    </Container>);
  }
}

export default DonutChart;
