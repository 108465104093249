import * as React from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';

const ButtonStyled = styled.button`
  && {
    font-family: inherit;
    min-width: 130px;
    height: 36px;
    border-radius: 4px;
    background-color: ${theme.colorLightBlue};
    border: none;
    color: ${theme.colorWhite};
    font-size: 1.125rem;
    cursor: pointer;
    :disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;

export const ButtonSmall = (props: any) => <ButtonStyled {...props} />;
