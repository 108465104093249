// @ts-ignore: 'DATE_FORMAT' is declared but its value is never read. Due to computed values below
import { DATE_FORMAT } from '../../config/datetime';

export enum CampaignCreateErrors {
  CampaignNameDuplicated = 'Cannot create campaign: Validation error',
  CampaignNameDuplicatedProbably = 'Duplicated name probably',
  CampaignNoPersonToInclude = 'There are not any persons to be included to a campaign',
  // @ts-ignore: Computed values are not permitted in an enum with string valued members.
  StartDateHasNotValidFormat = `start_date is not a valid date. It should be ${DATE_FORMAT}`,
  // @ts-ignore: Computed values are not permitted in an enum with string valued members.
  EndDateHasNotValidFormat = `end_date is not a valid date. It should be ${DATE_FORMAT}`,
  StartDateInThePast = 'date_from should be a future date',
  DatesAreNotConsistent = 'end_date should be after start_date',
}
