import * as React from 'react';
import { TrendUpIcon } from '../Icons';
import theme from '../../assets/css/theme';
import styled from 'styled-components';

interface IProps {
  value: number | string,
}

const CampaignsPercenatgeIncrementalRevenueValue = styled.div`
  font-family: ${theme.ffSansProRegular};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  font-size: 2.188rem;
  text-align: left;
  line-height: 1.26;
  color: ${theme.colorDarkGray};
`

const Label = styled.div`
  font-family: ${theme.ffSansProRegular};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-size: 0.9375rem;
  line-height: 1.67;
  text-align: left;
  color: ${theme.colorDarkBlue};
`

const CampaignsPercenatgeIncrementalRevenueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${theme.colorWhite};
  box-shadow: ${theme.boxShadow};
  padding: 1.25rem 1.438rem;
`

const CampaignsPercenatgeIncrementalRevenueValueWrapper = styled.div`
  padding-left: ${(props: ISpaceSizeProp) => props.spaceSize || '1.625rem'};
`

interface ISpaceSizeProp {
  spaceSize?: string;
}

interface IProps extends ISpaceSizeProp {
  value: number | string;
}

export class CampaignPercenatgeIncrementalCard extends React.Component<IProps> {
  public render() {
    return (
      <CampaignsPercenatgeIncrementalRevenueWrapper>
        <TrendUpIcon diameter="2.938rem" />
        <CampaignsPercenatgeIncrementalRevenueValueWrapper spaceSize={this.props.spaceSize}>
          <CampaignsPercenatgeIncrementalRevenueValue>{this.props.value.toLocaleString('en')}%</CampaignsPercenatgeIncrementalRevenueValue>
          <Label>% Incremental Revenue</Label>
        </CampaignsPercenatgeIncrementalRevenueValueWrapper>
      </CampaignsPercenatgeIncrementalRevenueWrapper>
    );
  }
}

export default CampaignPercenatgeIncrementalCard;
