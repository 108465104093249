import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import styled from 'styled-components';
import theme from '../../assets/css/theme';

interface IProps {
  label: string,
  value: number,
}

const Label = styled.div`
font-family: ${theme.ffInterRegular};
font-size: 0.8125rem;
line-height: 1.54;
text-align: left;
opacity: 0.5;
margin-bottom: 0.4375rem;
`;

// material-ui linear process has an issue in chrome.
// it's a fix for chrome
const StyledBorderLinearProgress = styled(LinearProgress)`
  && {
    .MuiLinearProgress-bar {
      ${ (props: { value: number }) => props.value <= 0 ? 'transform: translateX(-101%) !important;' : '' } 
    }
  }
`;

const BorderLinearProgress = withStyles({
  root: {
    height: 6,
    borderRadius: 8,
    backgroundColor: theme.mainBackground,
  },
  bar: {
    borderRadius: 8,
    backgroundColor: theme.colorGray,
  },
})(StyledBorderLinearProgress);

export class ProportionTotal extends React.Component<IProps>{
  public render() {
    return (
      <div>
        <Label>{this.props.label}</Label>
        <BorderLinearProgress
          variant="determinate"
          value={this.props.value}
        />
      </div>

    );
  }
}

export default ProportionTotal
