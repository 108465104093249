import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import styled from 'styled-components'
import theme from '../../assets/css/theme';
import { CircularLoading } from '../../components/CircularLoading'

const DEFAULT_NUM_Y_AXIS_TICKS = 5

export interface IDataPoint {
  y?: number
  name: string
  data?: number[]
}

export interface ILineChartProps {
  title?: string
  data: any[]
  dataLabel?: string
  yAxisTickAmount?: number
  height?: string | number
  isLoading?: boolean
  yAxisFloor?: number
  yAxisCeil?: number
  xAxisCategories?: string[]
  isMultiLine?: boolean
  tooltipOpts?: any,
  yAxisLabelFormat?(label: string | number): string,
}

const createChartOptions = (props: ILineChartProps) => {
  const {
    data, 
    title, 
    dataLabel, 
    yAxisTickAmount = DEFAULT_NUM_Y_AXIS_TICKS, 
    height, 
    tooltipOpts, 
    yAxisFloor = 0, 
    yAxisCeil = 0, 
    yAxisLabelFormat,
    xAxisCategories,
    isMultiLine,
    isLoading = false
  } = props

  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  });

  const chartOptions: Highcharts.Options = {
    chart: {
      height,
      style: {
        fontFamily: theme.ffInterRegular
      },
    },
    title: {
      text: title,
      align: 'left',
      verticalAlign: 'top'
    },
    xAxis: {
      type: 'category',
      labels: {
        style: {
          fontFamily: theme.ffInterRegular
        }
      }
    },
    yAxis: {
      title: {
        text: '',
      },
      tickAmount: yAxisTickAmount,
      floor: yAxisFloor,
      softMin: yAxisFloor,
      ceiling: yAxisCeil,
      softMax: yAxisCeil,
      labels: {
        formatter() {
          if (yAxisLabelFormat) {
            return yAxisLabelFormat(this.value)
          }
  
          return `${this.value}`
        }
      }
    },
    series: [{
      type: 'line',
      data,
      name: dataLabel,
      color: theme.colorRed,
      events: {
        legendItemClick: (e: any) => {
          e.preventDefault()
        }
      },
    }],
    plotOptions: {
      line: {
        marker: {
          enabled: false
        }
      }
    },
    credits: {
      enabled: false
    },
    legend: {
      align: 'center',
      layout: 'horizontal',
      verticalAlign: 'bottom',
    },
  }

  if (xAxisCategories) {
    chartOptions.xAxis = {
      type: 'category',
      categories: xAxisCategories,
      labels: {
        style: {
          fontFamily: theme.ffInterRegular
        }
      }
    }
  }

  if (isLoading && chartOptions?.series) {
    chartOptions.series.forEach((seriesOpt, i) => {
      // @ts-ignore
      chartOptions.series[i] = {
        ...seriesOpt,
        // @ts-ignore
        data: [{name: '', y: 0}]
      }
    })
  }

  if (tooltipOpts) {
    chartOptions.tooltip = tooltipOpts
  }

  if (isMultiLine && chartOptions) {
    const currentSeriesValues = chartOptions.series ? chartOptions.series[0] : {}
    chartOptions.series = data.map(dataPoint => {
      return {
        ...currentSeriesValues,
        ...dataPoint
      }
    })
  }

  return chartOptions
}

export const LineChart = (props: ILineChartProps) => {
  const chartOptions = createChartOptions(props);
  const { data, isLoading = false} = props;

  if (!data || !data.length) {
    return null
  }

  return (
    <Container>
      {isLoading && (
        <LoadingContainer>
          <CircularLoading />
        </LoadingContainer>
      )}
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%
`

const LoadingContainer = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 5;
`