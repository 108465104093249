import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { IAuthState, IUserForgotMeta, IUserMeta, IUserProfile, IUserLoginErrors } from '../../types';
import { IUserModel } from '../../types';
import { getUserObjectFromStorage } from '../../utils/user';
import { UserLoginServersErrors } from '../../types/user.enums'

interface IUserLoginFailedPartOfState extends IUserLoginErrors {
  client_name?: string | number;
}

const { id_token } = getUserObjectFromStorage();

const defaultState: IAuthState = {
  is_logged_in: !!id_token,
  id_token: '',
  client_name: '',
  username: '',
  nickname: '',
  picture: '',
  name: '',
  family_name: '',
  email: '',
  preferred_username: '',
  ...getUserObjectFromStorage(),
};

/**
 * Cognito actions
 */
const clientNameRequest = (state: IAuthState, payload: IUserMeta) => ({
  ...state,
  ...payload,
  clientIdError: false,
  is_data_requested: true,
});

const clientNameSuccess = (state: IAuthState, payload: IUserMeta) => ({
  ...state,
  ...payload,
  is_data_requested: false,
});

const clientNameFailed = (state: IAuthState, payload: string) => ({
  ...state,
  clientIdError: payload,
  is_data_requested: false,
});

/**
 * Logout actions
 */
const userLogout = (state: IAuthState) => ({
  ...state,
  is_logged_in: false,
  id_token: '',
  client_name: '',
  nickname: '',
  picture: '',
});

/**
 *  Saving bearer token to local store
 */
const userLogin = (state: IAuthState) => ({
  ...state,
  is_data_requested: true,
});

const userLoginSuccess = (state: IAuthState, payload: IUserModel) => ({
  ...state,
  ...payload,
  clientIdError: undefined,
  usernameError: undefined,
  passwordError: undefined,
  is_logged_in: true,
  is_data_requested: false,
});

const userLoginFailed = (state: IAuthState, payload: string) => {
  let partOfState: IUserLoginFailedPartOfState = {};
  if (payload === UserLoginServersErrors.ClientName) {
    partOfState = {
      clientIdError: payload,
      client_name: ''
    };
  } else { // Login or Password error
    partOfState = {
      usernameError: payload,
      passwordError: payload,
    }
  }
  return {
    ...state,
    ...partOfState,
    is_logged_in: false,
    is_data_requested: false,
  };
};

const goToEditClientName = (state: IAuthState) => ({
  ...state,
  client_name: '',
  clientIdError: undefined,
  usernameError: undefined,
  passwordError: undefined,
});

/**
 * Forgot Password Actions
 */
const userForgotPassword = (state: IAuthState) => ({
  ...state,
  is_data_requested: true,
});

const userForgotPasswordSuccess = (state: IAuthState, payload: IUserForgotMeta) => ({
  ...state,
  username: payload.username,
  usernameError: undefined,
  is_data_requested: false,
});

const userForgotPasswordFailed = (state: IAuthState, payload: string) => ({
  ...state,
  usernameError: payload,
  is_data_requested: false,
});

const userConfirmForgotPassword = (state: IAuthState) => ({
  ...state,
  is_data_requested: true,
});

const userConfirmForgotPasswordSuccess = (state: IAuthState) => ({
  ...state,
  confirmationCodeError: undefined,
  is_data_requested: false,
});

const userConfirmForgotPasswordFailed = (state: IAuthState, payload: string) => ({
  ...state,
  confirmationCodeError: payload,
  is_data_requested: false,
});


/**
 *  Exporting actions
 */
const exportCustomersRequest = (state: IAuthState, payload: { segment_id: string | number }) => ({
  ...state,
  is_data_requested: true,
});

const exportCustomersSuccess = (state: IAuthState) => ({
  ...state,
  is_data_requested: false,
});

const exportCustomersFailed = (state: IAuthState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

/**
 *  Profile actions
 */
const userProfileRequest = (state: IAuthState) => ({
  ...state,
  is_data_requested: true,
});

const userProfileSuccess = (state: IAuthState, payload: IUserProfile) => ({
  ...state,
  ...payload,
  is_data_requested: false,
});

const userProfileFailed = (state: IAuthState, payload: string) => ({
  ...state,
  is_data_requested: false,
});

/**
 *  Update user profile actions
 */
const userUpdateProfileRequest = (state: IAuthState) => ({
  ...state,
  is_data_requested: true,
  updateProfileError: undefined,
});

const userUpdateProfileSuccess = (state: IAuthState, payload: IUserProfile) => ({
  ...state,
  ...payload,
  is_data_requested: false,
  updateProfileError: undefined,
});

const userUpdateProfileFailed = (state: IAuthState, payload: string) => ({
  ...state,
  is_data_requested: false,
  updateProfileError: payload,
});

/**
 *  Update user current password actions
 */
const userUpdatePasswordRequest = (state: IAuthState) => ({
  ...state,
  is_data_requested: true,
  changePasswordError: undefined,
});

const userUpdatePasswordSuccess = (state: IAuthState) => ({
  ...state,
  is_data_requested: false,
  changePasswordError: false,
});

const userUpdatePasswordFailed = (state: IAuthState, payload: string) => ({
  ...state,
  is_data_requested: false,
  changePasswordError: payload,
});

/**
 *  Update user profile picture actions
 */
const userUpdateProfilePictureRequest = (state: IAuthState) => ({
  ...state,
  is_data_requested: true,
  updateProfilePictureError: undefined,
});

const userUpdateProfilePictureSuccess = (state: IAuthState, payload: string) => ({
  ...state,
  picture: payload,
  is_data_requested: false,
  updateProfilePictureError: undefined,
});

const userUpdateProfilePictureFailed = (state: IAuthState, payload: string) => ({
  ...state,
  is_data_requested: false,
  updateProfilePictureError: payload,
});

export const user = createReducer<IAuthState>({ }, defaultState)
  // Cognito actions
  .on(actions.clientNameRequest, clientNameRequest)
  .on(actions.clientNameSuccess, clientNameSuccess)
  .on(actions.clientNameFailed, clientNameFailed)
  // logout actions
  .on(actions.userLogout, userLogout)
  // Saving bearer token to local store
  .on(actions.userLogin, userLogin)
  .on(actions.userLoginSuccess, userLoginSuccess)
  .on(actions.userLoginFailed, userLoginFailed)
  .on(actions.goToEditClientName, goToEditClientName)
  // Restoring forgotten password
  .on(actions.userForgotPassword, userForgotPassword)
  .on(actions.userForgotPasswordSuccess, userForgotPasswordSuccess)
  .on(actions.userForgotPasswordFailed, userForgotPasswordFailed)
  .on(actions.userConfirmForgotPassword, userConfirmForgotPassword)
  .on(actions.userConfirmForgotPasswordSuccess, userConfirmForgotPasswordSuccess)
  .on(actions.userConfirmForgotPasswordFailed, userConfirmForgotPasswordFailed)
  // Exporting actions
  .on(actions.exportCustomersRequest, exportCustomersRequest)
  .on(actions.exportCustomersSuccess, exportCustomersSuccess)
  .on(actions.exportCustomersFailed, exportCustomersFailed)
  // Profile actions
  .on(actions.userProfileRequest, userProfileRequest)
  .on(actions.userProfileSuccess, userProfileSuccess)
  .on(actions.userProfileFailed, userProfileFailed)
  // Update user profile actions
  .on(actions.userUpdateProfileRequest, userUpdateProfileRequest)
  .on(actions.userUpdateProfileSuccess, userUpdateProfileSuccess)
  .on(actions.userUpdateProfileFailed, userUpdateProfileFailed)
  // Update user current password actions
  .on(actions.userUpdatePasswordRequest, userUpdatePasswordRequest)
  .on(actions.userUpdatePasswordSuccess, userUpdatePasswordSuccess)
  .on(actions.userUpdatePasswordFailed, userUpdatePasswordFailed)
  // Update user profile picture actions
  .on(actions.userUpdateProfilePictureRequest, userUpdateProfilePictureRequest)
  .on(actions.userUpdateProfilePictureSuccess, userUpdateProfilePictureSuccess)
  .on(actions.userUpdateProfilePictureFailed, userUpdateProfilePictureFailed)
;

export default user;
