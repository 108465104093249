import styled from 'styled-components';
import theme from '../../assets/css/theme';

export const OpportunitySegmentCardContainer = styled.div`
  padding: 1.25rem 1.25rem 0.9375rem 1.25rem;
  box-shadow: ${theme.boxShadow};
  background-color: ${theme.colorWhite};
`;

export const FullWidthTwoElements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 0;
@media (max-width: 700px){
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 1280px) and (max-width: 1335px) {
  flex-direction: column;
  justify-content: center;
}
`;

export const FullWidthElement = styled.div`
  padding: 0.625rem 0;
`;