import * as React from 'react';
import {
  BoxTitle,
  SizeValue,
} from '../../components/BoxComponents';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { ISegmentRequestApi, IUserMeta } from '../../modules/types';
import { useSegment } from '../../utils/hooks';
import {
  CardContainer,
  CardColumn,
} from './CommonLifecycleAndLoadingSegment';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import LoopIcon from '@material-ui/icons/Loop';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import TimerIcon from '@material-ui/icons/Timer';

export interface IProps extends IUserMeta {
  segment_id: number;
  avgOrderValue: number;
  frequency: number;
  recency: number;
  spend: number;
  sizeValue: number;
  proportionTotal: number;
  untargetedOpportunitySize: number | string;
  onClick: (payload: ISegmentRequestApi) => void;
  is_data_requested?: boolean;
}

interface IMetricProps {
  value: string;
  title: string;
  icon: React.ReactElement;
}

const LifecycleSegmentCard = (props: IProps) => {
  const { sizeValue, proportionTotal, avgOrderValue, frequency, recency, spend, segment_id } = props
  const { name, description } = useSegment(segment_id) || { name: '', description: '' }

return (
  <CardContainer container={true}>
    <CardColumn>
      <BoxTitle title={name} subtitle={description} />
    </CardColumn>
    <CustomerSizeCardColumn>
      <Population value={sizeValue}/>
    </CustomerSizeCardColumn>
    { name === 'Prospect' ?
      <MetricsContainer>
        <Metric value={`${proportionTotal}%`} title="Customer Base"
          icon={<DataUsageIcon />} />
      </MetricsContainer>
    :
      <MetricsContainer>
        <Metric value={`${proportionTotal}%`} title="Customer Base"
          icon={<DataUsageIcon />} />
        { name !== 'Welcome' &&
          <Metric value={`${(frequency)?.toFixed(1)} Orders`}
            title="Frequency" icon={<LoopIcon />} />
        }
        { name !== 'Archive' &&
          <Metric value={`${(recency)?.toFixed(0)} Days`}
            title="Recency" icon={<TimerIcon />} />
        }
        <Metric value={`$${(spend)?.toFixed(2)}`} title="Spend To Date"
          icon={<MonetizationOnOutlinedIcon />}/>
        <Metric value={`$${(avgOrderValue)?.toFixed(2)}`}
          title="Avg. Order Value" icon={<ShoppingBasketOutlinedIcon />}/>
      </MetricsContainer>
    }
  </CardContainer>
  )
}

const Metric = ({ value, title, icon }: IMetricProps) => (
  <OpportunityCardColumn>
    <ValueWrapper>
      {icon}
      <PropValue>
        <Value>{value}</Value>
        <Label>{title}</Label>
      </PropValue>
    </ValueWrapper>
  </OpportunityCardColumn>
)

const OpportunityCardColumn = styled.div`
  margin: 0px 12px;

  @media ${theme.media.laptopLLTE} {
    margin: 0px 4px;
  }

  @media ${theme.media.laptopMLTE} {
    margin: 0px 12px;
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
  }
`

const CustomerSizeCardColumn = styled.div`
&& {
  text-align: flex-start;

  @media ${theme.media.laptopMLTE} {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
    display: flex;
    justify-content: flex-end;
  }
}
`

const Population = styled(SizeValue)`
  font-size: 2rem;
`

const MetricsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;

  @media ${theme.media.laptopMLTE} {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`

const Label = styled.div`
  font-family: ${theme.ffInterRegular};
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  color: ${theme.colorDarkGray};
`

const Value = styled.div`
  font-family: ${theme.ffInterRegular};
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: ${theme.colorDarkGray};
`

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
`

const PropValue = styled.div`
  padding-left: 16px;
  margin-bottom: 16px;
`

export default LifecycleSegmentCard;
