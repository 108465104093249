import * as React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
min-width: 40px;
min-height: 40px;
width: 2.5rem;
height: 2.5rem;
border: none;
background-color: ${props => props.color || '#fff4e5'};;
border-radius: 50%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`;

interface IProps {
  color?: string;
  bgColor?: string;
}

export const NotificationsIcon = (props: IProps) => {
  const color = props.color || '#ffca83';
  const bgColor = props.bgColor || '#fff4e5'
  return (
    <Circle color={bgColor}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path d="M8,0a8,8,0,1,0,8,8A8.024,8.024,0,0,0,8,0ZM8,12a.945.945,0,0,1-1-1,.945.945,0,0,1,1-1,.945.945,0,0,1,1,1A.945.945,0,0,1,8,12ZM9,9H7V4H9Z" transform="translate(0 0)" fill={color}/>
      </svg>
    </Circle>
  );
};

export default NotificationsIcon;
