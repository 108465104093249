import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '../../components/Form';
import { DayPickerSingleDateController } from 'react-dates';
import moment, { Moment } from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import { DATE_FORMAT } from '../../config/datetime';
import { isEmpty } from 'lodash';

const NUM_OF_MONTHS = 2;

interface IFocusedDateInput {
  focused: boolean | null
}

interface IProps {
  startDate: Moment | null
  endDate: Moment | null
  existingExclusionDates: Moment[]
  setExclusionDatesApplied(exclusionDates: string[]): void
  setStartDate(date: Moment | null): void
  setEndDate(date: Moment | null): void
}

export const ExclusionDateFilter = ({
  setExclusionDatesApplied,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  existingExclusionDates,
} : IProps) => {

  const [isFilteringExlusionDates, setIsFilteringExlusionDates] = useState(false);
  const [exclusionDates, setExclusionDates] = useState<Moment[]>([]);
  const [focusedDateInput, setFocusedDateInput] = useState<boolean | null>(false);

  const toggleIsFilteringExlusionDates = useCallback(() => {
    setIsFilteringExlusionDates(!isFilteringExlusionDates);
    setExclusionDates(existingExclusionDates)
  }, [isFilteringExlusionDates, existingExclusionDates]);

    const handleCampaignStartDateUpdate = (arrayOfDates: Moment[]) => {
    if (startDate?.isSame(endDate, 'days')) {
      return;
    }

    const matchingOuterDate = arrayOfDates.find((date: Moment) => date.isSame(startDate, 'days'))

    if (!isEmpty(matchingOuterDate)) {
      const orderedExclusionDates = arrayOfDates.sort((a: Moment, b: Moment) => a.diff(b));
      let nextDays = 1;

      for (let i = 0; i < orderedExclusionDates.length; i++) {
        if (i === 0) {
          continue;
        }

        const diff = orderedExclusionDates[i]?.diff(orderedExclusionDates[i - 1], 'days')

        if (diff === 1) {
           nextDays++;
         }

         if (diff > 1) {
          break;
         }
      }
      const newDate = startDate?.clone().add(nextDays, 'days') || null;
      setStartDate(newDate)
      setExclusionDates(previousDate => previousDate.filter((date) => date !== matchingOuterDate && date.isSameOrAfter(newDate, 'days')))
    }
  }

  const handleCampaignEndDateUpdate = (arrayOfDates: Moment[]) => {
    if (endDate?.isSame(startDate, 'days')) {
      return;
    }

    const matchingOuterDate = arrayOfDates.find((date: Moment) => date.isSame(endDate, 'days'))
    if (!isEmpty(matchingOuterDate)) {
      const deduplicatedDates = removeDuplicateDates(arrayOfDates)
      const orderedExclusionDates = deduplicatedDates.sort((a: Moment, b: Moment) => b.diff(a)).filter((d: Moment) => d.isSameOrBefore(endDate));
      let nextDays = 1;

      for (let i = 0; i < orderedExclusionDates.length; i++) {  
        if (i === 0) {
          continue;
        }

        const differenceInDays = orderedExclusionDates[i - 1].startOf('day').diff(orderedExclusionDates[i].startOf('day'), 'days')
        if (differenceInDays === 1) {
           nextDays++;
         }

         if (differenceInDays > 1) {
          break;
         }
      }
      const newDate = endDate?.clone().subtract(nextDays, 'days') || null;
      setEndDate(newDate)
      setExclusionDates(previousDate => previousDate.filter((date) => date.isSameOrBefore(newDate, 'days')))
    }
  }

 const removeDuplicateDates = (dateArray: Moment[]) => {
   return dateArray.reduce((accumulator: Moment[], date: Moment) => {
    const dateString = date.format(DATE_FORMAT);
  
    const isDuplicate = accumulator.some((d: Moment) => d.format(DATE_FORMAT) === dateString);

    if (!isDuplicate) {
      accumulator = [...accumulator, date]
    }
  
    return accumulator;
  }, []);
 }
 const handleExclusionDateChange = (date: Moment | null) => {
 const selectedDate = moment(date).local().clone().startOf('day');

    if (selectedDate.isAfter(endDate?.startOf('day'), 'days') || selectedDate.isBefore(startDate?.startOf('day'), 'days')) {
      return;
    }

    if (exclusionDates.find(d => d.isSame(selectedDate, 'days'))) {
      return setExclusionDates(previousExclusionDates => [...previousExclusionDates.filter(d => !moment(d).isSame(selectedDate, 'day'))])
    }

    setExclusionDates(previousExclusionDates => [...previousExclusionDates, selectedDate])
    handleCampaignStartDateUpdate([...exclusionDates, selectedDate]);
    handleCampaignEndDateUpdate([...exclusionDates, selectedDate]);  
  }

  const handeExcludedDateDehighlight = (day: Moment | null) => {
    const start = moment(startDate).startOf('day')
    const end = moment(endDate).startOf('day')
    
    const dates = Array.from({length: end.diff(start, 'days') + 1}, (_, index) => {
      return moment(start).add(index, 'days');
    })
    
    if (start.isSame(end, 'days')) {
      return false;
    }

    if (!exclusionDates.length) {
      return dates.some(date => day?.isSame(date, 'days'))
    }

    if (!exclusionDates.length && existingExclusionDates.length) {
      const combinedDates = [...exclusionDates, ...existingExclusionDates];
      const removedDates = dates.filter((item) => {
        return !combinedDates.some(d => moment(item).isSame(d, 'days'));
      });  
      return removedDates.some(date => day?.isSame(date, 'days'));
    }

    if (exclusionDates.length )  {
      const removedDates = dates.filter((item) => {
        return !exclusionDates.some(d => moment(item).isSame(d, 'days'));
      });  
      return removedDates.some(date => day?.isSame(date, 'days'));
    }

    return dates.some(date => day?.isSame(date, 'days'));
  }
  
  const handleFocusChange = ({focused} : IFocusedDateInput) => {
    setFocusedDateInput(focused);
  }
  
  useEffect(() => {
    setExclusionDatesApplied(exclusionDates.map((date) => (date.format(DATE_FORMAT))))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exclusionDates, endDate, startDate]);

  return (
    <Container>
      <CheckboxContainer>
        <StyledFormControlLabel
          control={
            <Checkbox 
            checked={isFilteringExlusionDates}
            onChange={toggleIsFilteringExlusionDates}
            />
          }
          label='Exclude dates from campaign'
        />
      </CheckboxContainer>
      {isFilteringExlusionDates && (
      <ExclusionDatePickerContainer>
        <p>Click the dates within the selected range to exclude the date from the campaign.</p>
        <DayPickerSingleDateController
          numberOfMonths={NUM_OF_MONTHS}
          date={null}
          onDateChange={handleExclusionDateChange}
          focused={focusedDateInput || false}
          onFocusChange={handleFocusChange}
          isDayHighlighted={handeExcludedDateDehighlight}
          keepOpenOnDateSelect={false}
        />
      </ExclusionDatePickerContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;

  @media  ${theme.media.tablet} {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
  }
`
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  &&& {
    align-items: flex-start;
    margin-right: 0px;
    margin-left: -7px;
  }

  &&& > span.MuiTypography-root {
    font-size: 14px;
    margin-left: 4px;
    margin-top: 4px;
  }
`
const ExclusionDatePickerContainer = styled.div`
  margin-top: 10px;

  /* this is needed to override styling the current day */
  .CalendarDay__today {
    font-weight: unset !important;
    color: inherit !important;
    border-radius: 0 !important;
    background-color: unset !important;
  }
  
  .CalendarDay__highlighted_calendar {
    background: #ffe8bc !important;
    color: #484848 !important;
  }
` 