import { createAction } from 'redux-act';
import {
  ICustomersCount, ILifeCicleSuccessPayload, IOpportunityValue,
  ISegmentCard, ISegmentRequestApi,
  IUserMeta,
} from '../../types';
// Customer sizes
export const customersCountRequest = createAction<IUserMeta>('Fetch count of persons for each segment');
export const customersCountSuccess = createAction<ICustomersCount>('Count of persons fetched successfully');
export const customersCountFailed = createAction<string>('Count of persons fetching failed');
// Opportunity value
export const opportunityValueRequest = createAction<IUserMeta>('Fetch opportunity values for each segment');
export const opportunityValueSuccess = createAction<IOpportunityValue[]>('Opportunity values fetched successfully');
export const opportunityValueFailed = createAction<string>('Opportunity values fetching failed');
// Segment cards
export const segmentCardRequest = createAction<IUserMeta>('Fetch card data for each segment');
export const segmentCardSuccess = createAction<ISegmentCard[]>('Segment card data fetched successfully');
export const segmentCardFailed = createAction<string>('Segment card data fetching failed');
export const segmentCardClean = createAction('Set Segment cards data to default value');
// Lifecycle view
export const lifecycleRequest = createAction<ISegmentRequestApi>('Fetch summary data for a particular segment');
export const lifecycleSuccess = createAction<ILifeCicleSuccessPayload>('Segment summary data fetched successfully');
export const lifecycleFailed = createAction<string>('Segment summary data fetching failed');
