import React, { useState, useEffect, useMemo, useContext } from 'react';
import styled from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { DATE_FORMAT } from '../../../config/datetime';
import { InputLabel, TextInput } from '../components';
import queryString from 'query-string'
import {
  ICampaignCreate,
  ICampaignCreateResponse,
  IContactabilityFilter,
  IStoreState,
} from '../../../modules/types';
import '../../../assets/css/react_dates_overrides.css';
import { CampaignCreateErrors } from '../../../modules/types/campaign.enums';
import CustomerNotification from '../CustomerNotification';
import { DateFilterContext } from '../utils';
import { RADIO_BUTTON_TITLE, RADIO_SELECT_GROUP, RADIO_SELECT_GROUP_ALT } from '../constants';
import { ContactabilityFilter, EMAIL_FILTER } from './ContactabilityFilter';
import { RadioButtonsGroup } from '../../../components/Form/RadioButtonsGroup';
import { CampaignStoreFilter } from './CampaignStoreFilter';
import { UDFFilter, IFilter } from './UDFFilter';
import { ExclusionDateFilter } from './ExclusionDateFilter';
import { Button } from '../../../components/BoxComponents';
import { CampaignCreationModal } from './CampaignCreationModal';
import { CampaignMechanic } from './CampaignMechanic';
import { focusedInputShape, DateInput } from './DatesInput';
import theme from '../../../assets/css/theme';

const TWO_SECONDS = 2000

interface IProps {
  is_data_requested?: boolean;
  error?: string;
  isCreatingACampaign: boolean;
  audience_name: string;
  created_campaign: ICampaignCreateResponse;
  onExportClick : (payload: ICampaignCreate) => void;
  setRadioButtonPosition: any;
  radioButtonPosition: string;
}

type IContactabilityFilters = IContactabilityFilter[]

interface IRadioButtonsProps {
  isStartDateTomorrow: boolean | null;
  isStartDateNotToday: boolean | null;
  setRadioButtonPosition: void;
}

const RadioButtons = ({isStartDateTomorrow, isStartDateNotToday, setRadioButtonPosition}: IRadioButtonsProps) => {
  if (isStartDateTomorrow) {
    return <RadioButtonsGroup title={RADIO_BUTTON_TITLE} items={RADIO_SELECT_GROUP_ALT} defaultValue={1} setHandle={setRadioButtonPosition}/>
  }

  if (isStartDateNotToday) {
    return <RadioButtonsGroup title={RADIO_BUTTON_TITLE} items={RADIO_SELECT_GROUP} defaultValue={1} setHandle={setRadioButtonPosition}/>
  }

  return null;
}

export const CampaignForm = ({
  audience_name,
  error,
  is_data_requested,
  created_campaign,
  isCreatingACampaign,
  onExportClick,
  setRadioButtonPosition,
  radioButtonPosition
}: IProps) => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isStartDateNotToday,
    isStartDateTomorrow
  } = useContext(DateFilterContext);
  const [isShowingCreatedMsg, setIsShowingCreatedMsg] = useState(false)
  const [campaignMechanic, setCampaignMechanic] = useState<string>("");
  const [metaData, setMetaData] = useState({})
  const [focusedDateInput, setFocusedDateInput] = useState<focusedInputShape | null>(null)
  const [isNameCreatedManually, setIsNameCreatedManually] = useState(false)
  const [campaignName, setCampaignName] = useState("")
  const [filterOutStores, setFilterOutStore] = useState([])
  const [contactabilityFiltersApplied, setContactabilityFiltersApplied] = useState<IContactabilityFilters>([]);
  const [UDFFiltersApplied, setUDFFiltersApplied] = useState<IFilter[]>([]);
  const [exclusionDatesApplied, setExclusionDatesApplied] = useState<string[]>([]);
  const { pathname } = useLocation();
  const history = useHistory();
  const userEmail = useSelector((state: IStoreState) => {
    return state.user.email
  })

  const notificationsInfo = useSelector((state: IStoreState) => {
    return state.audience.notification_info
  })
  const { in_campaign, in_control_group } = notificationsInfo;

  const audiencesNotifyCard = useSelector((state: IStoreState) => {
    return state.audience.current_audience
  })
  const { available, targeted } = audiencesNotifyCard;

  useEffect(() => {
    const isFilteringByEmailContactability = (
      contactabilityFiltersApplied.some((filter) => filter.channel === EMAIL_FILTER)
    )

    const searchParam = queryString.stringify({
      excludeStores: filterOutStores,
      contactabilityFilters: isFilteringByEmailContactability ? [EMAIL_FILTER] : [],
      udfFilters: JSON.stringify(UDFFiltersApplied),
      exclusionDates: exclusionDatesApplied,
    });

    history.replace(`${pathname}?${searchParam}`)
    // eslint-disable-next-line
  }, [filterOutStores, contactabilityFiltersApplied, UDFFiltersApplied, exclusionDatesApplied])

  // measure today and compare diff between the selected start date,
  // if it equals 1 day dont show third option
  useEffect(() => {
    setStartDate(null)
    setEndDate(null)
    setFocusedDateInput(null)
    setIsNameCreatedManually(false)
    setCampaignName(audience_name)
    // eslint-disable-next-line
  }, [audience_name])

  const isValid = useMemo(() => startDate && endDate, [startDate, endDate])

  const onCampaignNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNameCreatedManually) {
      setIsNameCreatedManually(true)
    }
    setCampaignName(event.currentTarget.value)
  }

  const showCreatedMessage = () => {
    setIsShowingCreatedMsg(true)
  }

  const closeCreatedMessage = () => {
    setIsShowingCreatedMsg(false)
  }

  const handleOnExportClick = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (!!startDate && !!endDate) {
      const payload: ICampaignCreate = {
        name: campaignName,
        start_date: startDate ? startDate.utc().format(DATE_FORMAT) : '',
        end_date: endDate ? endDate.utc().format(DATE_FORMAT) : '',
        created_by: userEmail,
      };

      if (campaignMechanic) {
        payload.campaign_type = campaignMechanic
      }

      if (Object.keys(metaData).length > 0) {
        payload.metadata = Object.keys(metaData).map((key: string) => ({ key, value: metaData[key]}));
      } else {
        payload.metadata = [];
      }


      onExportClick(payload);

      // wait a few seconds to ensure the creation request is sent before the
      // user navigates away from the page
      // NOTE: this is an interim solution until we build centralized alerts and notifications
      await new Promise(resolve => setTimeout(resolve, TWO_SECONDS))
      showCreatedMessage()
    }
  }

  const onCancelClick = (event: React.MouseEvent) => {
    event.preventDefault();
    history.goBack();
  }

  const handleSetFilteredStores = (_event: any, value: any) => {
    setFilterOutStore(value)
  }

  const handleResetFilteredStores = () => {
    setFilterOutStore([])
  }

  const handleSetContactabilityFilter = (value: IContactabilityFilters) => {
    setContactabilityFiltersApplied(value)
  }

  return (
    <CardContainer>
      <FormStyled>
        <TitleWrapper>
          <Title>Create Campaign</Title>
          <ObjectiveDesc> Create a new campaign by filling in the details below. Different campaign mechanics will prompt you for additional information depending on the campaign type.</ObjectiveDesc>
        </TitleWrapper>
        <CampaignNameWrapper>
          <CampaignNameLabel>Campaign Name</CampaignNameLabel>
            <TextInput
              variant="outlined"
              autoFocus={false}
              type="text"
              label={false}
              value={campaignName}
              error={(error === CampaignCreateErrors.CampaignNameDuplicatedProbably)}
              onChange={onCampaignNameChange}
            />
        </CampaignNameWrapper>

        <DateInput
          audience_name={audience_name}
          isNameCreatedManually={isNameCreatedManually}
          focusedDateInput={focusedDateInput}
          setFocusedDateInput={setFocusedDateInput}
          setCampaignName={setCampaignName}
        />

        <CampaignMechanic
          campaignMechanic={campaignMechanic}
          setCampaignMechanic={setCampaignMechanic}
          metaData={metaData}
          setMetaData={setMetaData}
        />
        <ContactabilityFilter
          contactabilityFiltersApplied={contactabilityFiltersApplied}
          handleOnChangeContactabilityFilter={handleSetContactabilityFilter}
        />
        <CampaignStoreFilter
          handleOnChangeFilterStore={handleSetFilteredStores}
          resetFilterStores={handleResetFilteredStores}
        />
        <UDFFilter
          setUDFFilters={setUDFFiltersApplied}
        />
        <ExclusionDateFilter 
          setExclusionDatesApplied={setExclusionDatesApplied}
        />
        <RadioButtons isStartDateNotToday={isStartDateNotToday} isStartDateTomorrow={isStartDateTomorrow} setRadioButtonPosition={setRadioButtonPosition} />
        <ButtonsWrapper>
        <SubmitButton
          onClick={handleOnExportClick}
          is_loading={is_data_requested}
          className={[
            (!isValid || isCreatingACampaign) && 'isDisabled',
            (!!created_campaign.id) && 'isHidden',
          ]}
          external={true}>Save</SubmitButton>
        <CancelButton
          onClick={onCancelClick}
          is_loading={is_data_requested}
          className={(is_data_requested) && 'isDisabled'}
          external={true}>Cancel</CancelButton>
        </ButtonsWrapper>
      </FormStyled>
      <NotificationCardColumn>
        <CustomerNotification
          in_campaign={in_campaign}
          in_control_group={in_control_group}
          targeted={targeted}
          available={available}
          is_data_requested={is_data_requested}
        />
      </NotificationCardColumn>
      <CampaignCreationModal
        isShowingCreatedMsg={isShowingCreatedMsg}
        closeCreatedMessage={closeCreatedMessage}
        radioButtonPosition={radioButtonPosition}
      />
    </CardContainer>
  )
}

export default CampaignForm;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const SubmitButton = styled(Button)`
  margin-right: 24px;
  &&.isDisabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
  &&.isHidden {
    display: none;
  }
`

const CancelButton = styled(Button)`
  &&.isDisabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
`

const ObjectiveDesc = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 0.875rem;
opacity: 0.4;
color: ${theme.titleColor};
`

const CardContainer = styled.div`
display: flex;
flex-wrap: wrap;
align-items: flex-start;
justify-content: space-between;
min-width: 100%;
@media (max-width: 1500px) and (min-width: 500px) {
  display: flex;
  flex-direction: column;
}
`

const FormStyled = styled.div`
display: flex;
flex-direction: column;
justify-content: space between;
padding-bottom: 30px;
max-width: 60%;
@media (max-width: 1480px) and (min-width: 420px) {
  min-width: 100%;
}
`

const NotificationCardColumn = styled.div`
display: flex;
flex-wrap: wrap;
max-width: 50%;
`

const CampaignNameWrapper = styled.div`
display: flex;
margin-bottom: 1.25rem;
@media (max-width: 660px) and (min-width: 420px) {
  flex-direction: column;
}
`

const CampaignNameLabel = styled(InputLabel)`
margin-top: 2px;
@media ${theme.media.laptopM} {
  flex-shrink: 1;
}
@media (max-width: 1280px) and (min-width: 600px) {
  padding-right: 75px;
}
`

const Title = styled.div`
font-family: ${theme.ffSansProRegular};
font-weight: bold;
font-size: 1.125rem;
line-height: 1.39;
color: ${theme.titleColor};
`

const TitleWrapper = styled.div`
display: flex;
flex-flow: column wrap;
margin-bottom: 50px;
`
