import * as React from 'react';
import { withHeader } from '../../components';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { IStoreState, IUserProfile } from '../../modules/types';
import { bindAll, pick } from 'lodash';
import { getProfile, userFullName, userShortName } from '../../modules/selectors';
import { GRID_SIZE_12, GRID_SIZE_4, GRID_SIZE_6, GRID_SIZE_8 } from '../../config';
import AccountProfile from './AccountProfile';
import AccountDetails from './AccountDetails';
import AccountChangePassword, { IPassword } from './AccountChangePassword';
import styled from 'styled-components';
import { userUpdateProfileRequest, userUpdatePasswordRequest, userUpdateProfilePictureRequest } from '../../modules/actions';

interface IProps extends IUserProfile {
  short_name: string;
  full_name: string;
  old_password: string;
  new_password: string;
  userUpdateProfileRequest: typeof userUpdateProfileRequest;
  userUpdatePasswordRequest: typeof userUpdatePasswordRequest;
  userUpdateProfilePictureRequest: typeof userUpdateProfilePictureRequest;
  changePasswordError?: string;
  updateProfileError?: string;
}

const Root = styled.div`
  padding: 30px;
`;

const AccountChangePasswordWrapper = styled.div`
  padding-top: 1.875rem;
`;

class MyProfile extends React.Component<IProps> {

  constructor(props: IProps) {
    super(props);
    bindAll(this, [
      'saveProfileData',
      'savePictureData',
      'changePassword'
    ]);
  }

  public render() {
    return (
      <React.Fragment>
        <Root>
          <Grid container={true} spacing={GRID_SIZE_4}>
            <Grid item={true} lg={GRID_SIZE_4} md={GRID_SIZE_6} xl={GRID_SIZE_4} xs={GRID_SIZE_12}>
              <AccountProfile {...pick(this.props, ['nickname', 'full_name', 'picture', 'short_name', 'email', 'preferred_username' ])} onSave={this.savePictureData} />
              <AccountChangePasswordWrapper>
                <AccountChangePassword onSave={this.changePassword} error={this.props.changePasswordError} />
              </AccountChangePasswordWrapper>
            </Grid>
            <Grid item={true} lg={GRID_SIZE_8} md={GRID_SIZE_6} xl={GRID_SIZE_8} xs={GRID_SIZE_12}>
              <AccountDetails onSave={this.saveProfileData} {...pick(this.props, ['nickname', 'picture', 'name', 'family_name', 'email', 'preferred_username' ])} error={this.props.updateProfileError} />
            </Grid>
          </Grid>
        </Root>
      </React.Fragment>
    );
  }

  private saveProfileData (data: Omit<IUserProfile, 'picture'>) {
    this.props.userUpdateProfileRequest(data);
  }

  private changePassword (data: Omit<IPassword, 'confirm_password'>) {
    this.props.userUpdatePasswordRequest(data);
  }

  private savePictureData (picture: string) {
    this.props.userUpdateProfilePictureRequest({
      picture,
    });
  }
}

const mapStateToProps = (state: IStoreState) => ({
  ...getProfile(state),
  short_name: userShortName(state),
  full_name: userFullName(state),
  changePasswordError: state.user.changePasswordError,
  updateProfileError: state.user.updateProfileError,
});

const mapDispatchToProps = {
  userUpdateProfileRequest,
  userUpdatePasswordRequest,
  userUpdateProfilePictureRequest,

};

export default withHeader(connect(mapStateToProps, mapDispatchToProps)(MyProfile));
