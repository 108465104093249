import React, { useMemo } from 'react';
import { ICustomerDataDateRecord } from '../../modules/types'
import { useAvgData } from '../../utils/hooks/use-avg-data'
import { Report } from '../../components/Report'
import { LineChart } from '../../components/Charts/LineChart'
import {
  DateRangeType, 
  useDateFnsFormat,
  getAllChartData, 
  getAllCSVData,
  formatLargeNumber,
  getDateHeadings,
  getCurrentLowAndHighVal,
} from '../../utils/reports'

const NUM_DECIMAL_POINTS = 1;
const MIN_FLOOR = 0;
const MAX_CEILING = 100;

interface IProps {
  dateRange: DateRangeType,
  overTimeData: ICustomerDataDateRecord[]
}

interface IAvgData {
  data: number | null;
  date: string
}

export const getMinAndMax = (overTimeData: IAvgData[]) => {
  let min;
  let max;

  for (const dataSet of overTimeData) {
    if (dataSet.data === null) {
      continue;
    }

    const newBoundaries = getCurrentLowAndHighVal(dataSet.data, min, max)
    
    min = newBoundaries.lowestVal
    max = newBoundaries.highestVal
  }

  return { min, max }
}

export const OneTimeOnlyCustomers = ({ overTimeData, dateRange }: IProps) => {
  const dateFormat = useDateFnsFormat(dateRange)

  const getAvgData = useAvgData();
  const formatData = (data: any) => {
    return data === null ? 'n/a' : `${formatLargeNumber(data, NUM_DECIMAL_POINTS)}%`
  }
  
  const avgDataOverTime: IAvgData[] | null = useMemo(() => {
    if (!overTimeData) {
      return null
    }
    return overTimeData.map(audienceData => {
      const avgData = getAvgData(audienceData.data, 'percOneTimeCustomers')
      return { ...audienceData, data: avgData === null ? null : parseFloat(formatLargeNumber(avgData, NUM_DECIMAL_POINTS)) }
    })
    // eslint-disable-next-line
  }, [overTimeData])

  const chartInfo = useMemo(() => {
    const defaultChartinfo = { data: [], lowestVal: 0, highestVal: 0 }
    return avgDataOverTime ? getAllChartData(avgDataOverTime || [], dateFormat) : defaultChartinfo
    // eslint-disable-next-line
  }, [overTimeData])

  const tableInfo = useMemo(() => {
    const defaultTableInfo = { rows: [], headers: [], dateHeadings: [] }
    return avgDataOverTime ? getAllCSVData(avgDataOverTime, dateFormat, formatData) : defaultTableInfo
    // eslint-disable-next-line
  }, [ overTimeData])


  const { min, max } = useMemo(() => {
    if (avgDataOverTime) {
      return getMinAndMax(avgDataOverTime)
    }

    return { min: MIN_FLOOR, max: MAX_CEILING }
  }, [ avgDataOverTime ])

  const dateHeadings = getDateHeadings(overTimeData, dateFormat)
  const { rows, headers } = tableInfo
  const csvData = [
    headers,
    ...rows
  ];

  const yAxisLabelFormat = (label: number) => {
    return `${label}%`
  }

  return (
    <Report
      title="One Time Only Customers"
      headers={headers}
      rows={rows}
      csvData={csvData}
      guideHeight="380px"
      guideSlides={
        [{
        id: 1, 
        body: "Monitor ‘One Time Only Customers’ to identify the proportion of the customer base that has only ever made a single purchase." 
      }, {
        id: 2, 
        body: "If a time period has a large increase in customer acquisition, this measure may momentarily increase, but, over time, the objective is to see this metric decreasing, indicating that a larger proportion of customers are purchasing multiple times."
      }]
    }
    >
      <LineChart
        // re-render when data grouping changes
        // fixes a bug with highcharts when data changes when already mounted
        key={`one-time-only-cx`}
        height="300px"
        dataLabel="Percentage of One Time Only Customers"
        data={chartInfo.data}
        yAxisFloor={min}
        yAxisCeil={max}
        xAxisCategories={dateHeadings}
        tooltipOpts={{
          valueDecimals: NUM_DECIMAL_POINTS,
          valueSuffix: '%'
        }}
        yAxisLabelFormat={yAxisLabelFormat}
      />
    </Report>
  )
}