import clsx from 'clsx';
// import IconButton from '@material-ui/core/IconButton';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import * as React from 'react';
import { Hidden, makeStyles, Divider, List, Drawer } from '@material-ui/core';
import { MainListItems } from './listItems';
import { LoyPalIcon } from '../Icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import theme from '../../assets/css/theme';
import { useGetQueryDates } from '../../utils/hooks';

interface IProps {
  isOpen: boolean,
  maxWidth: number,
  minWidth: number,

  onIconToggle(): void,
}

export default function SideBar(props: IProps) {
  const queryDateParams = useGetQueryDates(); 
  const useStyles = makeStyles(themeStyle => ({
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: props.maxWidth,
      transition: themeStyle.transitions.create('width', {
        easing: themeStyle.transitions.easing.sharp,
        duration: themeStyle.transitions.duration.enteringScreen,
      }),
      backgroundColor: theme.colorBlack,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom left',
      backgroundSize: '100% auto',
      border: 'none',
      color: 'white',
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: themeStyle.transitions.create('width', {
        easing: themeStyle.transitions.easing.sharp,
        duration: themeStyle.transitions.duration.leavingScreen,
      }),
      width: props.minWidth,
      [themeStyle.breakpoints.up('sm')]: {
        width: props.minWidth,
      },
      backgroundImage: theme.colorBlack,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom left',
      backgroundSize: '100% auto',
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '0 25px',
      // ...themeStyle.mixins.toolbar,
      minHeight: 70,
      transition: themeStyle.transitions.create('padding', {
        easing: themeStyle.transitions.easing.sharp,
        duration: themeStyle.transitions.duration.enteringScreen,
      }),
    },
    toolbarIconClose: {
      padding: '0 15px',
    },
    list: {
      padding: 0,
      transition: themeStyle.transitions.create('padding', {
        easing: themeStyle.transitions.easing.sharp,
        duration: themeStyle.transitions.duration.leavingScreen,
      }),
    },
    menuIcon: {
      fontSize: '0.8125rem'
    }
  }));
  const classes = useStyles();

  const links = <React.Fragment>
    <div>
      <ListItem
        button={true}
        onClick={props.onIconToggle}
        className={clsx(classes.toolbarIcon, !props.isOpen && classes.toolbarIconClose)}>
        <ListItemIcon>
          <LoyPalIcon/>
        </ListItemIcon>
        <div>
          <ListItemText primary="LoyPal" className={classes.menuIcon}/>
          <ListItemText secondary="Applied Intelligence" disableTypography={true} className={classes.menuIcon}/>
        </div>
      </ListItem>
    </div>
    <Divider/>
    <List className={classes.list}>
      <MainListItems isSidebarOpen={props.isOpen} queryDateParams={queryDateParams} />
    </List>
  </React.Fragment>

  return (
    <React.Fragment>
      <Hidden mdUp={true}>
        <Drawer
          variant="temporary"
          anchor="left"
          open={!props.isOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          { links }
        </Drawer>
      </Hidden>
      <Hidden smDown={true}>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !props.isOpen && classes.drawerPaperClose),
          }}
          open={props.isOpen}
        >
          { links }
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
}
