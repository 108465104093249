import React, { useEffect, useState, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { isEmpty, map } from 'lodash';
import { PointOptionsObject } from 'highcharts';
import { ReactElement } from 'react';
import { getSegmentDefinitions } from '@loypal/lib-growth'
import { isDataRequested } from '../../modules/selectors';
import { withHeader } from '../../components';
import { ColumnChart, DonutChart } from '../../components/Charts';
import { ISegmentReducer, IStoreState, IUserMeta } from '../../modules/types';
import { customersCountRequest, segmentCardRequest, opportunityValueRequest, lifetimeValueRequest } from '../../modules/actions';
import { GRID_SIZE_12, GRID_SIZE_3, GRID_SIZE_4, GRID_SIZE_6 } from '../../config';
import { cardData, customerLifecycleDistribution, opportunityValue } from '../../modules/selectors/segment';
import OpportunitySegmentCard from './OpportunitySegmentCard';
import { IProps as ICardProps } from './OpportunitySegmentCard';
import { PageTitle } from '../../components/Text';
import ContainerStyled from '../../components/ContainerStyled';
import styled from 'styled-components';
import { useClientCustomization, useAvgSegmentData } from '../../utils/hooks';
import LoadingOpportunitySegmentCard from './LoadingOpportunitySegmentCard';
import theme from '../../assets/css/theme';
import { ButtonGroups } from '../../components/ButtonGroups';

const Subtitle = styled.div`
font-size: 1.4rem;
font-weight: 500;
font-family: ${theme.ffInterMedium};
`

const ButtonGroupsWrapper = styled.div`
position: absolute;
top: 10px;
right: 8px;
@media (max-width: 1470px) and (min-width: 1280px){
  width: min-content;
}
@media (max-width: 560px) and (min-width: 280px) {
  width: min-content;
}
`

const TitleWrapper = styled.div`
position: relative;
width: 100%;
`

const ALL_CUSTOMERS = 'All Customers';
const MARKETABLE_VIA_EMAIL = 'Email';
const MARKETABLE_VIA_PHONE = 'Mobile';
const NUM_SEGMENTS = 6;
const NUM_DECIMAL_PLACES = 2

interface IDataGroupOption { name: string, isActive: boolean, onClick: () => void }

interface IProps extends IUserMeta {
  readonly is_data_requested? : boolean,
  fetchCustomerCount: typeof customersCountRequest;
  fetchSegmentInfo: typeof segmentCardRequest;
  fetchOpportunityValue: typeof opportunityValueRequest;
  segment: ISegmentReducer;
  opportunity: PointOptionsObject[];
  customerLifecycleDistribution: {
    total: number;
    contactableTotal: number;
    emailTotal: number;
    phoneTotal: number;
    allCustomerData: PointOptionsObject[];
    marketableEmail: PointOptionsObject[];
    marketablePhone: PointOptionsObject[];
  };
  segmentSummary: ICardProps[];
  dataGroupOpts?: IDataGroupOption[]
};

const Opportunity = ({
  client_name,
  customerLifecycleDistribution: { allCustomerData, marketableEmail, marketablePhone, total = 0, emailTotal = 0, phoneTotal = 0 },
  opportunity,
  segmentSummary,
  fetchCustomerCount,
  fetchSegmentInfo,
  fetchOpportunityValue,
}: IProps) => {
  const { industryGroup } = useClientCustomization()
  const dispatch = useDispatch()
  const [dataGrouping, setDataGrouping] = useState(ALL_CUSTOMERS)
  const isLoading = useSelector(isDataRequested);
  const ltvData = useSelector((state: IStoreState) => {
    return state.customers.lifetimeValues
  })

  const [population, data] = useMemo(() => {
    if (dataGrouping === MARKETABLE_VIA_EMAIL) {
      return [emailTotal, marketableEmail]
    }

    if (dataGrouping === MARKETABLE_VIA_PHONE) {
      return [phoneTotal, marketablePhone]
    }

    return [total, allCustomerData]
  }, [emailTotal, phoneTotal, total, marketableEmail, marketablePhone, allCustomerData, dataGrouping])

  const getSegmentedData = useAvgSegmentData()

  const ltvTableData = useMemo(() => {
    const segmentedLTV = getSegmentedData(ltvData, 'lifetimeValue')
    return segmentedLTV.filter((segment) => segment.segmentID !== 1).map(segmentData => ({
      name: segmentData.segmentName,
      y: segmentData.lifetimeValue < 0 ? 0 : parseFloat((segmentData.lifetimeValue).toFixed(NUM_DECIMAL_PLACES))
    }))
    // eslint-disable-next-line
  }, [ltvData])

  useEffect(() => {
    dispatch(lifetimeValueRequest())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // we only update this data once every 24 hours so we don't need to fetch
    // several times in one user session
    if (!allCustomerData.length || !marketableEmail.length || !marketablePhone.length) {
      fetchCustomerCount({ client_name });
    }

    if (isEmpty(segmentSummary)) {
      fetchSegmentInfo({ client_name });
    }

    if (!opportunity.length) {
      fetchOpportunityValue({ client_name })
    }

    // eslint-disable-next-line
  }, [client_name, fetchCustomerCount, fetchSegmentInfo, fetchOpportunityValue])

  const subtitle = <Subtitle>
    <div>{(population).toLocaleString()}</div>
  </Subtitle>;

  const segments = getSegmentDefinitions(industryGroup)

  segments.forEach((segment: any, i: number) => {
    if (opportunity[i]) {
      opportunity[i].name = segment.name
    }
  })

  const showAllCustomers = () => {
    setDataGrouping(ALL_CUSTOMERS)
  }

  const showMarketableEmail = () => {
    setDataGrouping(MARKETABLE_VIA_EMAIL)
  }

  const showMarketablePhone = () => {
    setDataGrouping(MARKETABLE_VIA_PHONE)
  }

  const cards: ReactElement[] = map(segmentSummary, card => <Grid key={card.segment_id} item={true} lg={GRID_SIZE_4} md={GRID_SIZE_6} sm={GRID_SIZE_6} xs={GRID_SIZE_12}>
    <OpportunitySegmentCard {...card} />
  </Grid>);

  const loadingCards = map(Array(NUM_SEGMENTS).fill(null), (_item, index) => (
    <Grid key={index} item={true} lg={GRID_SIZE_4} md={GRID_SIZE_6} sm={GRID_SIZE_6} xs={GRID_SIZE_12}>
      <LoadingOpportunitySegmentCard />
    </Grid>
  ));

  const displaySegmentCards = segmentSummary.length > 0 ? cards : loadingCards;

  if (!!isLoading && segmentSummary.length < 1) {
    return (
      <ContainerStyled maxWidth="xl" >
        <PageTitle>Opportunity</PageTitle>
        <Grid container={true} item={true} spacing={GRID_SIZE_3}>
          {loadingCards}
        </Grid>
      </ContainerStyled>
    )
  }

  return (
    <ContainerStyled maxWidth="xl" >
      <PageTitle>Opportunity</PageTitle>
      <Grid container={true} item={true} spacing={GRID_SIZE_3}>
        <Grid item={true} lg={GRID_SIZE_6} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12}>
        <TitleWrapper>
          {
          <DonutChart
            key={`donut-chart-${dataGrouping}`}
            title={`Customers by Life Stage`}
            subtitle={subtitle}
            data={data}
          />}
          <ButtonGroupsWrapper>
            <ButtonGroups
              options ={[
                {
                  name: ALL_CUSTOMERS,
                  isactive: dataGrouping === ALL_CUSTOMERS,
                  onClick: showAllCustomers
                },
                {
                  name: MARKETABLE_VIA_EMAIL,
                  isactive: dataGrouping === MARKETABLE_VIA_EMAIL ,
                  onClick: showMarketableEmail
                },
                {
                  name: MARKETABLE_VIA_PHONE,
                  isactive: dataGrouping === MARKETABLE_VIA_PHONE ,
                  onClick: showMarketablePhone
                },
              ]}
            />
          </ButtonGroupsWrapper>
        </TitleWrapper>
        </Grid>
        <Grid item={true} lg={GRID_SIZE_6} md={GRID_SIZE_12} sm={GRID_SIZE_12} xs={GRID_SIZE_12}>
          { !isEmpty(ltvTableData) && (
            <ColumnChart
              title={`Customer Average Spend To Date`}
              data={ltvTableData}
            />
          )}
        </Grid>
        {displaySegmentCards}
      </Grid>
    </ContainerStyled>
  )
}

const mapStateToProps = (state: IStoreState) => ({
  customerLifecycleDistribution: customerLifecycleDistribution(state),
  opportunity: opportunityValue(state),
  segmentSummary: cardData(state),
  is_data_requested: state.segment.is_data_requested,
  client_name: state.user.client_name,
});

const mapDispatchToProps = {
  fetchCustomerCount: customersCountRequest,
  fetchSegmentInfo: segmentCardRequest,
  fetchOpportunityValue: opportunityValueRequest,
};

export default withHeader(connect(mapStateToProps, mapDispatchToProps)(Opportunity));
