import React, { useEffect } from 'react';
import moment from 'moment';

import { useFilters } from '../../utils/hooks';
import { Select } from '../Form'
import { DateRangeType as DateRangeTypeContext, LAST_3_MONTHS, LAST_6_MONTHS, LAST_1_YEAR, LAST_2_YEARS } from '../../utils/context/filters';
export { LAST_3_MONTHS, LAST_6_MONTHS, LAST_1_YEAR, LAST_2_YEARS }

export type DateRangeType = DateRangeTypeContext

interface IProps {
  currentDateRange: DateRangeType | undefined
  handleDateRangeChange: (dateRangeKey: DateRangeType, dates: string[]) => void
}

interface IDateOptions {
  value: DateRangeType
  label: DateRangeType
}

export const TWO_YEARS_FORMAT = 'MMM-YY'
export const ONE_YEAR_FORMAT = 'MMM-YY'
export const SIX_MONTHS_FORMAT = 'MMM'
export const THREE_MONTHS_FORMAT = 'Do MMM'
export const DEFAULT_NUM_CELLS = 9
export const COLLAPSE_MAX_SHOW = 4
export const NUM_AUDIENCES = 13
const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]'
const DAYS_IN_A_WEEK = 7
const WEEKS_IN_A_YEAR = 52

const getDateRanges = () => {
  /* tslint:disable:no-magic-numbers */
  const yesterday = moment().subtract(1, 'days').startOf('day').format(DATE_FORMAT)

  const twoYearDates = [...Array(WEEKS_IN_A_YEAR * 2)].map((_, i) => {
    return moment(yesterday).subtract(DAYS_IN_A_WEEK * i, 'days').startOf('day').format(DATE_FORMAT)
  })

  const oneYearDates = [...Array(WEEKS_IN_A_YEAR)].map((_, i) => {
    return moment(yesterday).subtract(DAYS_IN_A_WEEK * i, 'days').startOf('day').format(DATE_FORMAT)
  })

  const sixMonthDates = [...Array(WEEKS_IN_A_YEAR / 2)].map((_, i) => {
    return moment(yesterday).subtract(DAYS_IN_A_WEEK * i, 'days').startOf('day').format(DATE_FORMAT)
  })

  const threeMonthDates = [...Array(WEEKS_IN_A_YEAR / 4)].map((_, i) => {
    return moment(yesterday).subtract(DAYS_IN_A_WEEK * i, 'days').startOf('day').format(DATE_FORMAT)
  })

  const dateValues = {
    [LAST_2_YEARS]: twoYearDates.reverse(),
    [LAST_1_YEAR]: oneYearDates.reverse(),
    [LAST_6_MONTHS]: sixMonthDates.reverse(),
    [LAST_3_MONTHS]: threeMonthDates.reverse()
  }
  /* tslint:enable:no-magic-numbers */

  return dateValues
}

export const DateRangeSelector = ({ currentDateRange, handleDateRangeChange }: IProps) => {
  const { dateRange, setDateRange } = useFilters();
  const dateValues = getDateRanges()

  const dateOptions: IDateOptions[] = [
    { value: LAST_2_YEARS, label: LAST_2_YEARS },
    { value: LAST_1_YEAR, label: LAST_1_YEAR },
    { value: LAST_6_MONTHS, label: LAST_6_MONTHS },
    { value: LAST_3_MONTHS, label: LAST_3_MONTHS },
  ]

  useEffect(() => {
    handleDateRangeChange(dateRange, dateValues[dateRange])
    // eslint-disable-next-line
  }, [])

  const handleDateChange = (event: any) => {
    const newDateRange = event.target.value
    if (newDateRange) {
      setDateRange(newDateRange)
      handleDateRangeChange(newDateRange, dateValues[newDateRange])
    }
  }

  // don't render the selector until we have set the default value
  if (!currentDateRange) {
    return null
  }

  return (
    <Select options={dateOptions} value={dateRange} onChange={handleDateChange} />
  )
}