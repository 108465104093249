import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  IAudienceCard,
  ICampaignCreateRequestPayload,
  ICampaignCreateResponse,
  ICampaignReDownloadData,
  ICampaignSummaryRequestPayload,
  ICampaignSummaryResponse,
  ISagaAction,
  ISegmentCampaignRequestPayload,
  ICampaignUpdateDatesRequestPayload,
  ISegmentCampaignsResponse,
  ICampaignStoresRevenuePayload,
  ICampaignStoresRevenueResponse,
  ICampaignCreationStatusResponse,
  IContactabilityFiltersResponse,
  ICampaignRequestPayload,
  ICampaignResponse,
  ICampaignEntriesRequestPayload,
  ICampaignEntriesResponse,
  ICampaignsParticipantLifecycleResponse,
  ICampaignsParticipantLifecycleRequestPayload,
  INotPurchasedCampaignListRequest,
} from '../../types';
import FileSaver from 'file-saver';
import { currentAudience, createdCampaign } from '../../selectors';

export const campaignCreationStatusesRequestSaga = function* (): SagaIterator {
  try {
    const data: ICampaignCreationStatusResponse = yield call(Api.Campaign.fetchCampaignCreationStatuses);
    yield put(actions.campaignCreationStatusesSuccess(data));
  } catch (e) {
    yield put(actions.campaignCreationStatusesFailed((e as Error).message));
  }
}

export const createCampaignRequestSaga = function* (action: ISagaAction<ICampaignCreateRequestPayload>): SagaIterator {
  const { segment_id, ...api_payload } = action.payload;
  try {
    const data: any = yield call(Api.Campaign.createCampaign, api_payload);
    yield call(campaignCreationStatusesRequestSaga)
    yield put(actions.campaignCreateSuccess(data));
    const current_audience: IAudienceCard = yield select(currentAudience);
    yield put(actions.audienceCardSuccess({
      ...current_audience,
      targeted: current_audience.targeted + current_audience.available,
      available: 0,
      untargeted_opportunity: 0,
    }));
  } catch (e) {
    yield put(actions.campaignCreateFailed((e as Error).message));
  }
};

export const updateCampaignDatesRequestSaga = function* (action: ISagaAction<ICampaignUpdateDatesRequestPayload>): SagaIterator {
  try {
    const data: any = yield call(Api.Campaign.updateCampaignDates, action.payload);
    yield put(actions.campaignUpdateDatesSuccess(data.campaign));
  } catch (e) {
    yield put(actions.campaignUpdateDatesFailed((e as Error).message));
  }
};

export const reDownloadCampaignDataRequestSaga = function* (action: ISagaAction<ICampaignReDownloadData>): SagaIterator {
  try {
    const data: any = yield call(Api.Campaign.reDownloadCampaignDataFile, action.payload);
    const csv_file = new Blob([data], {type: "text/csv;charset=utf-8"});
    const created_campaign: ICampaignCreateResponse = yield select(createdCampaign);
    FileSaver.saveAs(csv_file, `${created_campaign.name}.csv`);
    yield put(actions.campaignReDownloadDataSuccess());
  } catch (e) {
    yield put(actions.campaignReDownloadDataFailed((e as Error).message));
  }
};

export const campaignsSummarySegmentRequestSaga = function* (action: ISagaAction<ICampaignSummaryRequestPayload>): SagaIterator {
  try {
    const data: ICampaignSummaryResponse = yield call(Api.Campaign.fetchSegmentsSummary, action.payload);
    yield put(actions.campaignsSegmentSummarySuccess(data));
  } catch (e) {
    yield put(actions.campaignsSegmentSummaryFailed((e as Error).message));
  }
}

export const segmentCampaignsRequestSaga = function* (action: ISagaAction<ISegmentCampaignRequestPayload>): SagaIterator {
  try {
    const data: ISegmentCampaignsResponse = yield call(Api.Campaign.fetchSegmentsCampaigns, action.payload);
    yield put(actions.segmentCampaignsSuccess(data));
  } catch (e) {
    yield put(actions.segmentCampaignsFailed((e as Error).message));
  }
}

export const activeCampaignDownloadDataRequestSaga = function* (action: ISagaAction<ICampaignReDownloadData>): SagaIterator {
  try {
    const data: any = yield call(Api.Campaign.reDownloadCampaignDataFile, action.payload);
    FileSaver.saveAs(data.signedURL);
    yield put(actions.activeCampaignDownloadDataSuccess());
  } catch (e) {
    yield put(actions.activeCampaignDownloadDataFailed((e as Error).message));
    }
}

export const campaignDownloadControlCSVRequestSaga = function* (action: ISagaAction<ICampaignReDownloadData>): SagaIterator {
  try {
    const data: any = yield call(Api.Campaign.downloadCampaignControlCSV, action.payload);
    FileSaver.saveAs(data.signedURL);
    yield put(actions.campaignDownloadControlCSVSuccess());
  } catch (e) {
    yield put(actions.campaignDownloadControlCSVFailed((e as Error).message));
    }
}

export const campaignStoresRequestSaga = function* (action: ISagaAction<ICampaignStoresRevenuePayload>): SagaIterator {
  try {
    const data: ICampaignStoresRevenueResponse = yield call(Api.Campaign.fetchCampaignStoresData, action.payload);
    yield put(actions.campaignStoresRevenueSuccess(data));
  } catch (e) {
    yield put(actions.campaignStoresRevenueFailed((e as Error).message));
  }
}

export const contactabilityFiltersRequestSaga = function* (): SagaIterator {
  try {
    const data: IContactabilityFiltersResponse = yield call(Api.Campaign.fetchContactabilityFilters);
    yield put(actions.contactabilityFiltersSuccess(data));
  } catch (e) {
    yield put(actions.contactabilityFiltersFailed((e as Error).message));
  }
}

export const campaignRequestSaga = function* (action: ISagaAction<ICampaignRequestPayload>): SagaIterator {
  try {
    const data: ICampaignResponse = yield call(Api.Campaign.fetchCampaign, action.payload);
    yield put(actions.campaignSuccess(data));
  } catch (e) {
    yield put(actions.campaignFailed((e as Error).message));
  }
}

export const calendarEntriesRequestSaga = function* (action: ISagaAction<ICampaignEntriesRequestPayload>): SagaIterator {
  try {
    const data: ICampaignEntriesResponse = yield call(Api.Campaign.fetchCalendarEntries, action.payload)
    yield put(actions.calendarEntriesSuccess(data));
  } catch (e) {
    yield put(actions.calendarEntriesFailed((e as Error).message));
  }
}

export const campaignsParticipantLifecycleRequestSaga = function* (action: ISagaAction<ICampaignsParticipantLifecycleRequestPayload>): SagaIterator {
  try {
    const data: ICampaignsParticipantLifecycleResponse = yield call(Api.Campaign.fetchCampaignParticipant, action.payload)
    yield put(actions.campaignsParticipantLifecycleSuccess(data));
  } catch (e) {
    yield put(actions.campaignsParticipantLifecycleFailed((e as Error).message));
  }
}

export const retargetCampaignRequestSaga = function* (action: ISagaAction<INotPurchasedCampaignListRequest>): SagaIterator {
  try {
    yield call(Api.Campaign.retargetCampaign, action.payload);
    yield put(actions.retargetCampaignSuccess());
  } catch (e) {
    yield put(actions.retargetCampaignFailed((e as Error).message));
  }
}
