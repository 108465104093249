export const getCombinedPathAndQuery = (path: string, search?: string) => {
  if (!search) {
    return path
  }

  if (search.includes('start_date') && search.includes('end_date')) {
    // retain date filters across pages
    return `${path}${search}`
  }

  return path
}