import React from 'react'
import styled from 'styled-components'

import { variant } from 'styled-system'
import theme from '../../assets/css/theme'
import { LoadingSkeleton } from '../LoadingSkeleton'

type RowType = any[]

interface IProps {
  headers?: string[],
  rows: RowType[]
  flexSizes?: number[]
  shouldBoldLastRow?: boolean
}

interface IContainerProps {
  shouldBoldLastRow: boolean
}

interface ICellProps {
  flex: number
  isFirst: boolean
}

interface ILoadingProps {
  numRows: number
  numCells?: number
  flexSizes?: number[]
}

export const Table = ({ headers, rows , flexSizes, shouldBoldLastRow = false }: IProps) => {
  return (
    <Container shouldBoldLastRow={shouldBoldLastRow}>
      {headers && (
        <HeadersContainer>
          {headers?.map((header, i) => {
            const flex = flexSizes ? flexSizes[i] : 1
            return <HeaderCell key={i} flex={flex} isFirst={i === 0}>{header}</HeaderCell>
          })}
        </HeadersContainer>
      )}
      {rows.map((row, i) => {
        return (
          <Row key={i}>
            {row.map((cell, cellIdx) => {
              const flex = flexSizes ? flexSizes[cellIdx] : 1
              return <RowCell key={cellIdx} flex={flex} isFirst={cellIdx === 0}>{typeof cell === 'function' ? cell() : cell}</RowCell>
            })}
          </Row>
        )
      })}
    </Container>
  )
}

export const LoadingTable = ({ numRows, numCells = 1, flexSizes }: ILoadingProps) => {
  return (
    <Container shouldBoldLastRow={false}>
      <LoadingHeaders>
        {Array(numCells).fill(null).map((_item, i) => {
          const flex = flexSizes ? flexSizes[i] : 1
          return (
            <HeaderCell key={i} flex={flex}  isFirst={i === 0}>
              <LoadingSkeleton variant="text" />
            </HeaderCell>
          )
        })}
      </LoadingHeaders>
      {Array(numRows).fill(null).map((_item, i) => {
        return (
          <LoadingRow key={i}>
            {Array(numCells).fill(null).map((_cellItem, cellIdx) => {
              const flex = flexSizes ? flexSizes[cellIdx] : 1
              return (
                <RowCell key={cellIdx} flex={flex} isFirst={i === 0}>
                  <LoadingSkeleton variant="text" />
                </RowCell>
              )
            })}
          </LoadingRow>
        )
      })}
    </Container>
  )
}

const Container = styled.table<IContainerProps>`
  position: relative;
  white-space: nowrap;
  border-spacing: 0px;
  table-layout: fixed;
  min-width: 100%;
  border-collapse: collapse; 
  font-size: 0.735rem;
  font-family: ${theme.ffInterRegular};

  & > tr {
    border-bottom: 1px solid ${theme.colorBorderControls};

    &:first-child {
      border-bottom: none;
    }

    &:last-child {
      border-bottom: none;

      ${() => variant({
        prop: 'shouldBoldLastRow',
        variants: {
          true: {
            fontWeight: '900',
          },
        }
      })}
    }
  }

  td {
    width: 130px;
  }

  td+td {
    width: auto;
  }
`

const Row = styled.tr`
  :hover {
    & > td {
      background-color: ${theme.colorLightestGray}
    }
  }
`

const LoadingRow = styled(Row)`
  & > p {
    padding: 0 20px;

    &:first-child {
      padding: 0 20px 0 0;
    }

    &:last-child {
      padding: 0 0 0 20px;
    }
  }
`

const HeadersContainer = styled(Row)`
  width: 100%;
`

const LoadingHeaders = styled(HeadersContainer)`
  & > p {
    padding: 0 20px;

    &:first-child {
      padding: 0 20px 0 0;
    }

    &:last-child {
      padding: 0 0 0 20px;
    }
  }
`

const RowCell = styled.td<ICellProps>`
  text-align: right;
  background-color: ${theme.colorWhite};
  padding: 14px 12px 14px 0px;

  ${() => variant({
    prop: 'isFirst',
    variants: {
      true: {
        textAlign: 'left',
        padding: '14px 12px',
        position: 'sticky',
        left: 0,
        zIndex: 2,
      },
    }
  })}
`

const HeaderCell = styled.th<ICellProps>`
  text-align: right;
  background-color: ${theme.colorBorderControls};
  text-transform: uppercase;
  font-size: 0.92em;
  font-weight: bold;
  color: ${theme.colorDarkGray};
  padding: 14px 12px 14px 0px;

  ${() => variant({
    prop: 'isFirst',
    variants: {
      true: {
        textAlign: 'left',
        padding: '14px 12px',
        position: 'sticky',
        left: 0,
        zIndex: 2,
      },
    }
  })}
`