import React, { createContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Api } from '../../modules/utils'
import { isLoggedIn } from '../../modules/selectors';
import { IStoreState, IIntegrationPlatform } from '../../modules/types';
import { allIntegrationsRequest } from '../../modules/actions';

const DEFAULT_CONFIG = {
  clientName: '',
  industryGroup: 'retail',
  segments: [],
  audiences: [],
  isLoading: false
}

const DEFAULT_INTEGRATIONS = {
  integrations: {}
}

interface IDefinition {
  id: number
  name: string
  description: string
}

export interface IClientCustomizationContextType {
  clientName: string
  industryGroup: string
  segments: IDefinition[]
  audiences: IDefinition[]
  integrations: IIntegrationPlatform
  isLoading: boolean
}

export const ClientCustomizationContext = createContext<IClientCustomizationContextType>({...DEFAULT_CONFIG, ...DEFAULT_INTEGRATIONS})

export const ClientCustomizationProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true);
  const [customizationConfig, setCustomizationConfig] = useState<IClientCustomizationContextType | null>(null);
  const isUserLoggedIn: any = useSelector((state: IStoreState) => isLoggedIn(state))
  const integrations = useSelector((state: IStoreState) => {
    return state.integration.integrations
  })
  
  const isIntegrationDataLoading = useSelector((state: IStoreState) => {
    return state.integration.is_data_requested
  })
  
  const shouldRefetchIntegrations = useSelector((state: IStoreState) => {
    return state.integration.should_refetch
  })

  useEffect(() => {
    if (isUserLoggedIn && shouldRefetchIntegrations && !isIntegrationDataLoading) {
      dispatch(allIntegrationsRequest())
    }
    // eslint-disable-next-line
  }, [isUserLoggedIn, integrations, isIntegrationDataLoading])
  
  useEffect(() => {
    if (isUserLoggedIn && !customizationConfig) {
      fetchClientCustomization()
    }
  }, [isUserLoggedIn, customizationConfig])

  const fetchClientCustomization = async () => {
    const config = await Api.Client.fetchClientConfig()
    setIsLoading(false);
    setCustomizationConfig(config)
  }

  if (!isUserLoggedIn && isLoading) {
    setIsLoading(false);
  }

  const clientConfig = customizationConfig ? {...customizationConfig, isLoading} : {...DEFAULT_CONFIG, isLoading}
  const configInclIntegrations = integrations ? {...clientConfig, integrations} : {...clientConfig, ...DEFAULT_INTEGRATIONS}

  return (
    <ClientCustomizationContext.Provider value={configInclIntegrations}>
      {children}
    </ClientCustomizationContext.Provider>
  )
}